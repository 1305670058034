import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const MsgErrorInvalidDIC: FC = () => (
  <FormattedMessage
    defaultMessage='Prosím zadejte DIČ ve formátu CZXXXXXXXX nebo SKXXXXXXXXXX.'
    description='Form field error message for invalid companyTaxNo.'
    id='QdXQ5q'
  />
);

MsgErrorInvalidDIC.displayName = 'MsgErrorInvalidDIC';

export default MsgErrorInvalidDIC;
