import clsx from 'clsx';
import type { FC, ReactNode } from 'react';

export type DialogActionsProps = {
  children?: ReactNode;
  className?: string;
};

const DialogActions: FC<DialogActionsProps> = ({ children = null, className }) => (
  <div className={clsx('mt-4 flex justify-end gap-2', className)}>{children}</div>
);

DialogActions.displayName = 'DialogActions';

export default DialogActions;
