import { FALLBACK_LANGUAGE } from '@/constants';
import messagesDefinition from '@/i18n/_messages';
import type { SupportedLocale } from '@sprinx/knihovna-api-types';
import type { IntlShape } from 'react-intl';
import { createIntl, createIntlCache } from 'react-intl';
import { selectorFamily } from 'recoil';
import invariant from 'tiny-invariant';

export const intlCache = createIntlCache();

export const intlState = selectorFamily<IntlShape, SupportedLocale>({
  get: (locale) => async () => {
    // if (typeof window !== 'undefined') {
    //   window.document.documentElement.lang = locale;
    // }

    const msgsLoader =
      // exists definition for exact locale
      messagesDefinition[locale] ||
      // exists definition for lang from locale
      messagesDefinition[(locale.match(/^([^-]+)/) ?? [])[1]] ||
      // fallback to default locale
      messagesDefinition[FALLBACK_LANGUAGE];

    invariant(typeof msgsLoader === 'function', 'I18N message loader is not a function.');

    const messages = await msgsLoader();

    return createIntl({ locale, messages }, intlCache);
  },
  key: 'intl',
});
