import type { ProductAdditionalServiceRelated } from '@/types';
import type { TrpcRouterOutputs } from '../lib/trpc';

export function useCartSubjectAdditionalServices(
  subject: TrpcRouterOutputs['cart']['subjects'][number],
): ProductAdditionalServiceRelated[] {
  if (subject.product?.relatedAdditionalServices == null) return [];

  // Partial port of functionalty of `useProductAdditionalServices`.
  return subject.product.relatedAdditionalServices.map((service) => {
    const checked = !!(subject.indirectSubjects ?? []).find((inds) => inds.productSku === service.product.sku);
    let price: number = service.product.price?.amount ?? 0;
    let priceWithTax: number = service.product.price?.amountWithTax ?? 0;
    const vatAmount: number = service.product.price?.vatAmount ?? 0.21;
    const vatCode: string = service.product.price?.vatCode ?? '0.21';

    if (service.descriptor === 'extended-warranty' && ['zar01', 'zar02'].includes(service.product.sku)) {
      const parCode = service.product.sku === 'zar01' ? '/allservices/vprprodlzar1r' : '/allservices/vprprodlzar2r';
      const parVal = subject.product?.parameters?.find((p) => p.code === parCode)?.value;
      if (parVal != null) {
        const numParVal = parseInt(parVal);
        price = (subject.price.amount / 100) * numParVal;
        priceWithTax = (subject.price.amountWithTax / 100) * numParVal;
      }
    }

    return {
      checked,
      descriptor: service.descriptor,
      multiple: false,
      name: service.product.translations.name,
      order: service.order,
      price,
      priceWithTax,
      sku: service.product.sku,
      vatAmount,
      vatCode,
    };
  });
}
