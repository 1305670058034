import type { ProductBase } from '@/types';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSetRecoilState } from 'recoil';
import { recentlyViewedProducts } from '../atoms';
import { toastMessage } from '../compositions/AppToaster';

export function useResetRecentlyViewedProducts() {
  const intl = useIntl();
  const setRecentlyViewed = useSetRecoilState(recentlyViewedProducts);

  return useCallback(
    (product: ProductBase) => {
      setRecentlyViewed([product]);
      toastMessage.success(
        intl.formatMessage({
          defaultMessage: 'Historie naposledy zobrazených produktů byla smazána',
          description: "Message displayed when user's recently viewed products history is reset",
          id: '6q94QS',
        }),
      );
    },
    [intl, setRecentlyViewed],
  );
}
