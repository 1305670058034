import type { match } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { useTaxonomyDataPreviewDirect } from './useTaxonomyDataPreview';

function useDummy() {
  return undefined;
}

export const useActiveCategory = (): { code: string; hash: string } | null => {
  const {
    params: { hash },
  } = useRouteMatch() as match & { params: { hash: string } };
  const useH = hash == null ? useDummy : useTaxonomyDataPreviewDirect;
  const data = useH(hash);
  return data ?? null;
};
