import { authUserState, priceGroupsState, pricesTypeState } from '@/client/atoms';
import type { UiContext } from '@/context';
import { useCallback } from 'react';
import { useRecoilTransaction_UNSTABLE } from 'recoil';
import type { LogoutSuccessEventHandler } from '../types';
import useRedirectOnAuthChange from './useRedirectOnAuthChange';

export function useLogoutSuccess(): LogoutSuccessEventHandler {
  const redirectOnAuthChange = useRedirectOnAuthChange();

  const makeUserLoggedOut = useRecoilTransaction_UNSTABLE<[nextUiCtx: Partial<UiContext>]>(
    ({ reset, set }) =>
      (ctx) => {
        reset(authUserState);

        if (ctx?.priceGroups) {
          set(priceGroupsState, ctx.priceGroups);
        }
        if (ctx?.pricesType) {
          set(pricesTypeState, ctx.pricesType);
        }
      },
  );

  const handleLogoutSuccess = useCallback<LogoutSuccessEventHandler>(
    (nextUiCtx) => {
      makeUserLoggedOut(nextUiCtx);
      redirectOnAuthChange();

      // Refresh page after success logout.
      if (typeof window !== 'undefined') {
        window.location.reload();
      }
    },
    [makeUserLoggedOut, redirectOnAuthChange],
  );

  return handleLogoutSuccess;
}
