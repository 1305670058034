import { selector } from 'recoil';
import { authUserState } from './authUserState';

export const isAuthenticatedState = selector<boolean>({
  get: ({ get }) => {
    const user = get(authUserState);
    return !!user;
  },
  key: 'isAuthenticated',
});
