import { useCallback, useEffect, useRef } from 'react';

export function useDelayedCall<T extends (...args: any[]) => void>(
  action: T,
  ms: number,
): {
  call: T;
  clear: () => void;
} {
  const timerRef = useRef<NodeJS.Timeout>();
  const call: T = useCallback(
    (...args: Parameters<T>): void => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        action(...args);
        timerRef.current = undefined;
      }, ms);
    },
    [action, ms],
  ) as any;

  const clear = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    [],
  );

  return {
    call,
    clear,
  };
}
