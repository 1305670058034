import type { SupportedLocale } from '@sprinx/knihovna-api-types';
import { routePath } from './route-path';
import { routesDefinition } from './routes-definition';
import { routesBuilderDefaultdUrlBuilder } from './utils';

/**
 * Returnd builded URL from `type` and params...
 *
 * ```
 * routeUrl('home', {locale, params: { ... } }); // => '/'
 * routeUrl('productDetail', {locale, params: { ... } }); // => '/some-product-name-product-12344'
 * ```
 */
export function routeUrl<T extends keyof typeof routesDefinition, P extends (typeof routesDefinition)[T]['urlBuilder']>(
  type: T,
  opts: {
    locale: SupportedLocale;
    otherParamsToQs?: boolean | string[];
    params: P extends (...args: any[]) => any ? Parameters<P>[3] : Record<string, any> | undefined;
  },
): string {
  const path = routePath(type, opts);
  const def = routesDefinition[type];
  const inputParams = opts.params || {}; // ensure params exists

  // eslint-disable-next-line no-console
  // console.log('inputParams', inputParams);

  return def.urlBuilder && typeof def.urlBuilder === 'function'
    ? def.urlBuilder(opts.locale, path, opts.otherParamsToQs, inputParams as any)
    : routesBuilderDefaultdUrlBuilder(opts.locale, path, opts.otherParamsToQs, inputParams as any);
}
