import clsx from 'clsx';
import type { FC, ReactNode } from 'react';

export type CartAsidePreviewRootProps = {
  children?: ReactNode;
  className?: string;
};

const CartAsidePreviewRoot: FC<CartAsidePreviewRootProps> = ({ children = null, className }) => {
  return <div className={clsx('flex flex-col', className)}>{children}</div>;
};

CartAsidePreviewRoot.displayName = 'CartAsidePreviewRoot';

export default CartAsidePreviewRoot;
