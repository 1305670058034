import normalizeOpeningHours from '@/client/helpers/normalize-opening-hours';
import { usePragueShowroom, useRouteUrl } from '@/client/hooks';
import type { HTMLAttributes, RefAttributes } from 'react';
import { forwardRef, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppCustomFooterLinkBlocks from '../AppCustomFooterLinkBlocks';
import RedFooterNwlRegistration from '../AppCustomFooterNwlRegistration';
import AppCustomFooterSocials from '../AppCustomFooterSocials';

export type AppCustomLayoutFooterProps = HTMLAttributes<HTMLDivElement> & RefAttributes<HTMLDivElement>;

const AppCustomLayoutFooter = forwardRef<HTMLDivElement, AppCustomLayoutFooterProps>((props, ref) => {
  const routeUrl = useRouteUrl();
  const pragueShowroom = usePragueShowroom();

  const dividedAddress = useMemo(() => {
    if (pragueShowroom == null || typeof pragueShowroom.address !== 'string' || pragueShowroom.address.length === 0)
      return null;

    const idx = pragueShowroom.address.indexOf(',');

    if (idx === -1) return { additionalAddress: null, street: pragueShowroom.address };

    const street = pragueShowroom.address.slice(0, idx);
    const additionalAddress = pragueShowroom.address.slice(idx + 1);

    return { additionalAddress, street };
  }, [pragueShowroom]);

  return (
    <footer ref={ref} className=' mt-2 w-full bg-footer text-footer-contrast'>
      <div className='main-content-wrapper pb-6'>
        <RedFooterNwlRegistration />
        <div className='footer-main container flex flex-col print:hidden sm:mb-8 sm:flex-row sm:justify-between'>
          <AppCustomFooterLinkBlocks />
          <div className='flex flex-col lg:flex-row'>
            <div>
              <h3 className='h3 mb-6'>
                <span>Navštivte nás v </span>
                <RouterLink className='link-blue font-semibold' to={routeUrl('showrooms', {}, true)}>
                  {pragueShowroom?.name ?? ''}
                </RouterLink>
              </h3>
              <img alt='pobocka' className='my-4' height='192' loading='lazy' src='/images/showroom.jpeg' width='449' />
            </div>
            <div className='mt-6 flex flex-col md:mt-12 lg:ml-4'>
              <p>Adresa:</p>
              <a
                className='link-blue'
                href={`/showrooms${pragueShowroom?.NodeAlias ? `/${pragueShowroom.NodeAlias}` : ''}`}
              >
                {dividedAddress?.street
                  ? `${dividedAddress.street + (dividedAddress?.additionalAddress ? ',' : '')}`
                  : ''}
                {dividedAddress?.additionalAddress ? (
                  <span>
                    <br />
                    {dividedAddress.additionalAddress}
                  </span>
                ) : null}
              </a>
              {pragueShowroom?.openingHours ? (
                <>
                  <p className='mt-4 md:mt-auto'>Otevírací doba</p>
                  <p className='mb-2'>
                    <span dangerouslySetInnerHTML={{ __html: normalizeOpeningHours(pragueShowroom.openingHours) }} />
                  </p>
                </>
              ) : null}
            </div>
          </div>
        </div>

        <AppCustomFooterSocials />
      </div>
    </footer>
  );
});

AppCustomLayoutFooter.displayName = 'AppCustomLayoutFooter';

export default AppCustomLayoutFooter;
