import { useMemo } from 'react';
import { useWindowSize } from 'react-use';
import { TABLET_WINDOW_WIDTH } from './useWindowSize';

// !By default is desktop true as it is on the server (the same as !isMobile && !isTablet) on client)
export function useWindowSizeIsDesktop(): boolean {
  const { width } = useWindowSize();
  const isDesktop = useMemo(() => (typeof width === 'number' ? width > TABLET_WINDOW_WIDTH : true), [width]);
  return isDesktop;
}
