import type { useCheckoutUpdateContext_CheckoutContextMutation } from '@/graphql/__generated__/useCheckoutUpdateContext_CheckoutContextMutation.graphql';
import type { PricesType, SupportedCurrency, SupportedRegion } from '@sprinx/knihovna-api-types';
import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';
import { usePromisifyCommitMutation } from './usePromisifyCommitMutation';

type UseCheckoutUpdateContenxtCallbackOptions = {
  cartLeaveActiveAndReturnEmpty?: {
    nextCheckoutDataRetain?: boolean;
    nextPricingSettings?: {
      currency: SupportedCurrency;
      distributor: string;
      priceGroups: string[];
      pricesType: PricesType;
      region: SupportedRegion;
    };
    prevCartResetGifts?: boolean;
    prevCartResetMethods?: boolean;
  };
  checkoutId: string;
};

export function useCheckoutUpdateContext() {
  const [commitCheckoutUpdateContext] = useMutation<useCheckoutUpdateContext_CheckoutContextMutation>(
    graphql`
      mutation useCheckoutUpdateContext_CheckoutContextMutation(
        $checkoutId: UUID!
        $cartLeaveActiveAndReturnEmpty: CheckoutUpdateContextStrategyInput
        $locale: String!
      ) {
        checkout_context_update(
          checkoutId: $checkoutId
          cartLeaveActiveAndReturnEmpty: $cartLeaveActiveAndReturnEmpty
          locale: $locale
        ) {
          cart {
            ...cartState_cart
          }
          ...checkoutUserExistingCartsState_cart
        }
      }
    `,
  );

  const updateCheckoutContext = usePromisifyCommitMutation(
    commitCheckoutUpdateContext,
    (options: UseCheckoutUpdateContenxtCallbackOptions) => ({
      cartLeaveActiveAndReturnEmpty: options.cartLeaveActiveAndReturnEmpty,
      checkoutId: options.checkoutId,
      locale: 'cs',
    }),
    () => [null, null],
  );

  return useCallback(
    async (options: UseCheckoutUpdateContenxtCallbackOptions) => {
      await updateCheckoutContext(options);
    },
    [updateCheckoutContext],
  );
}
