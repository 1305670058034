import type {
  CartUpdateSubjectInput,
  CartUpdateIndirectSubjectInput as GqlCartUpdateIndirectSubjectInput,
  useShoppingCartUpdateMultipleSubjects_cart_update_subjectsMutation,
} from '@/graphql/__generated__/useShoppingCartUpdateMultipleSubjects_cart_update_subjectsMutation.graphql';
// import { isNotEmptyArray } from '@/helpers';
// import type { ProductPackQuantityPrice } from '@/types';
import { /* ProductPackType,  */ type ProductAdditionalServiceRelated, type ProductSimplePrice } from '@/types';
import type { EcomCartUpdateSubject, EcomCartUpdateSubjectOperation } from '@sprinx/knihovna-api-types';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { graphql, useMutation } from 'react-relay';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { cartUpdateInProgressState, checkoutContextState } from '../atoms';
import { toastMessage } from '../compositions/AppToaster';
// import { taxonomyDataPreviewWithAncestorsDirect, taxonomyHash } from '../helpers/getTaxonomies';
// import { addEEAddToCartDataLayer, removeEERemoveFromCartDataLayer } from '../lib/googleAnalytics';
// import type { TrpcRouterOutputs } from '../lib/trpc';
import trpc from '../lib/trpc';
import { useAddToCartFbEvent } from './useAddToCartFbEvent';
import { useCartCouponsQuery } from './useCartCouponsQuery';
// import { useCartSubjects } from './useCartSubjects';
import { useCartTotals } from './useCartTotals';
import { usePromisifyCommitMutation } from './usePromisifyCommitMutation';

/* async function addOrRemoveCartItemsDatalayer(
  products: CartUpdateSubjectInput[],
  prevCartSubjects: TrpcRouterOutputs['cart']['subjects'] | undefined,
  trpcCtx: any,
) {
  if (!prevCartSubjects) return;
  try {
    const productsMapped = await Promise.all(
      products.map(async (product) => {
        const productData = await trpcCtx.products.product.preview.fetch({ sku: product.productSku });
        const taxonomiesInfo = taxonomyDataPreviewWithAncestorsDirect(taxonomyHash(productData.taxonomies ?? []) ?? '');
        const taxonomies = (taxonomiesInfo ?? []).filter(
          (t): t is { code: string; hash: string; name: string } =>
            t.code !== '/catalogue' && typeof t.name === 'string',
        );

        const prevProduct = prevCartSubjects.find((item) => item.productSku === product.productSku);
        const prevProductQuantity = prevProduct?.quantity;

        const packType = product.productSku.endsWith('-carton') ? ProductPackType.carton : ProductPackType.pcs;
        const packPrices = productData.quantityPrices?.find(
          (p: ProductPackQuantityPrice) => p.pack?.packType === packType,
        )?.prices;
        const qtyPrice =
          isNotEmptyArray(packPrices) &&
          [...packPrices]
            .sort((a, b) => b.quantityFrom - a.quantityFrom)
            .find((i) => i.quantityFrom <= (product.quantity ?? 1));

        if (prevProductQuantity) {
          const qtyPrice =
            isNotEmptyArray(packPrices) &&
            [...packPrices]
              .sort((a, b) => b.quantityFrom - a.quantityFrom)
              .find((i) => i.quantityFrom <= (product.quantity === 0 ? prevProductQuantity : product.quantity ?? 1));
          return {
            prevQuantity: prevProductQuantity,
            product: {
              ...productData,
              mainTaxonomies: taxonomies.map((t) => t.name),
              ...(qtyPrice
                ? {
                    price: qtyPrice.amount ?? 0,
                    priceWithTax: qtyPrice.amountWithTax ?? 0,
                  }
                : {
                    price: product.price?.amount ?? 0,
                    priceWithTax: product.price?.amountWithTax ?? 0,
                  }),
              quantity: product.operation != 'add' ? product.quantity : product.quantity + prevProductQuantity,
            },
          };
        }
        return {
          prevQuantity: 0,
          product: {
            ...productData,
            mainTaxonomies: taxonomies.map((t) => t.name),
            ...(qtyPrice
              ? {
                  price: qtyPrice.amount ?? 0,
                  priceWithTax: qtyPrice.amountWithTax ?? 0,
                }
              : {
                  price: product.price?.amount ?? 0,
                  priceWithTax: product.price?.amountWithTax ?? 0,
                }),
            quantity: product.quantity,
          },
        };
      }),
    );

    const productsAdded = productsMapped.filter((item) => item.product.quantity >= item.prevQuantity);
    const productsRemoved = productsMapped.filter((item) => item.product.quantity < item.prevQuantity);
    if (productsAdded.length > 0)
      addEEAddToCartDataLayer(
        productsAdded.map((item) => {
          const productQuantity =
            item.product.quantity <= item.prevQuantity
              ? item.product.quantity
              : item.product.quantity - item.prevQuantity;
          return { ...item.product, quantity: productQuantity };
        }),
        'CZK',
      );
    if (productsRemoved.length > 0)
      removeEERemoveFromCartDataLayer(
        productsRemoved.map((item) => {
          const productQuantity = item.prevQuantity - item.product.quantity;
          return { ...item.product, quantity: productQuantity };
        }),
        'CZK',
      );
  } catch (error) {
    console.error('Error fetching product preview:', error);
  }
} */

export function useShoppingCartUpdateMultipleSubjects() {
  const intl = useIntl();
  const trpcCtx = trpc.useContext();
  const checkoutCtx = useRecoilValue(checkoutContextState);
  const setUpdateInProgress = useSetRecoilState(cartUpdateInProgressState);
  const addToCardEvent = useAddToCartFbEvent();

  // const prevCartSubjects = useCartSubjects();
  const prevCartTotals = useCartTotals();
  const prevSubjectsCount = prevCartTotals.data.subjects.count;
  const prevSubjectsTotal = prevCartTotals.data.subjects.total.amount;
  const prevTotal = prevCartTotals.data.total.total.amount;

  const prevCartCoupons: { couponCode: string }[] | null = useCartCouponsQuery().data ?? null;
  const prevCartCouponsMapped: string[] | null =
    prevCartCoupons !== null ? prevCartCoupons.map((item: { couponCode: string }) => item.couponCode) : null;
  const prevCartCouponsCount =
    prevCartCouponsMapped !== null ? prevCartCouponsMapped.length : Number(prevCartCouponsMapped);

  const [commitCartUpdate] = useMutation<useShoppingCartUpdateMultipleSubjects_cart_update_subjectsMutation>(
    graphql`
      mutation useShoppingCartUpdateMultipleSubjects_cart_update_subjectsMutation(
        $batch: [CartUpdateSubjectInput!]!
        $checkoutId: ID!
        $locale: String!
      ) {
        checkout_cart_update_subjects(batch: $batch, checkoutId: $checkoutId, locale: $locale) {
          ok
          errors
          cart {
            id
            ...cartState_cart
            couponsApplied {
              couponCode
            }
            totals {
              subjects {
                count
                total {
                  amount
                }
              }
              total {
                total {
                  amount
                }
              }
            }
          }
        }
      }
    `,
  );

  const isNumber = (x: any): x is number => typeof x === 'number';

  const updateCart = usePromisifyCommitMutation(
    commitCartUpdate,
    (checkoutId: string, batch: CartUpdateSubjectInput[]) => ({
      batch,
      checkoutId,
      locale: 'cs',
    }),
    (response) =>
      !response.checkout_cart_update_subjects.ok
        ? [new Error(response.checkout_cart_update_subjects.errors?.join(', ') ?? undefined)]
        : [null, response.checkout_cart_update_subjects.cart],
  );

  return useCallback(
    async (
      batch: {
        additionalServices?: ProductAdditionalServiceRelated[];
        operation: EcomCartUpdateSubjectOperation;
        prices?: ProductSimplePrice | null;
        product: EcomCartUpdateSubject & { externalId?: string; name?: string };
        quantity: number;
      }[],
    ) => {
      let callError: any = null;

      const addToCardEventExternalIds = batch.map((i) => i.product?.externalId).filter((x): x is string => x != null);
      if (addToCardEventExternalIds.length > 0) {
        addToCardEvent(addToCardEventExternalIds);
      }

      try {
        //  const productPack = productPackResolve(product.sku);
        // Pokud jsou do kosiku poslany ceny produktu, jsou fixni.

        setUpdateInProgress(true);

        const batchData: CartUpdateSubjectInput[] = batch.map((i) => ({
          indirectSubjects: i.additionalServices
            ? i.additionalServices.map<GqlCartUpdateIndirectSubjectInput>((ii) => ({
                operation: 'set',
                parentSku: i.product.sku,
                price:
                  isNumber(ii.price) && isNumber(ii.priceWithTax) && isNumber(ii.vatAmount)
                    ? {
                        amount: ii.price,
                        amountWithTax: ii.priceWithTax,
                        vatAmount: ii.vatAmount,
                        vatCode: ii.vatCode,
                      }
                    : undefined,
                productSku: ii.sku,
                quantity: ii.checked ? 1 : 0,
                quantityBinded: { ratio: 1 },
                text: ii.name,
                unit: 'pcs',
              }))
            : undefined,
          operation: i.operation,
          price:
            i.prices && isNumber(i.prices.price) && isNumber(i.prices.priceWithTax) && isNumber(i.prices.vatAmount)
              ? {
                  amount: i.prices.price,
                  amountWithTax: i.prices.priceWithTax,
                  vatAmount: i.prices.vatAmount,
                  vatCode: i.prices.vatCode,
                }
              : isNumber(i.product.price) && isNumber(i.product.priceWithTax) && isNumber(i.product.vatAmount)
              ? {
                  amount: i.product.price,
                  amountWithTax: i.product.priceWithTax,
                  vatAmount: i.product.vatAmount,
                  vatCode: i.product.vatCode,
                }
              : undefined,
          productSku: i.product.sku,
          quantity: i.quantity,
          text: i.product.name,
          unit: 'pcs',
        }));

        const res = await updateCart(checkoutCtx.checkoutId, batchData);

        if (!res) {
          throw new Error('Cart updated with NULL as result.');
        }

        /* addOrRemoveCartItemsDatalayer(
          batchData.map(({ operation, price, productSku, quantity }) => ({ operation, price, productSku, quantity })),
          prevCartSubjects.data,
          trpcCtx,
        ); */

        const nextSubjectsCount = res.totals.subjects.count;
        const nextSubjectsTotal = res.totals.subjects.total.amount;
        const nextTotal = res.totals.total.total.amount;

        const nextCartCoupons: string[] | null =
          res.couponsApplied !== null ? res.couponsApplied.map((item) => item.couponCode) : null;
        const nextCartCouponsCount = nextCartCoupons !== null ? nextCartCoupons.length : Number(null);
        const removedCartCoupons = prevCartCouponsMapped?.filter((item) => !nextCartCoupons?.includes(item)).join(', ');

        if (nextCartCouponsCount < prevCartCouponsCount) {
          toastMessage.info(
            intl.formatMessage(
              {
                defaultMessage:
                  'Z košíku {n, plural, one {odebrán slevový kupón: {removed_cart_coupons}. Z důvodu nesplnění podmínek kupónu} other {odebrány slevové kupóny: {removed_cart_coupons}. Z důvodu nesplnění podmínek kupónů}}',
                description: 'Message after auto removing coupons from cart by their restrictions',
                id: 'buLNE7',
              },
              {
                n: nextCartCouponsCount - prevCartCouponsCount,
                removed_cart_coupons: removedCartCoupons,
              },
            ),
          );
        }

        if (nextSubjectsCount > prevSubjectsCount) {
          toastMessage.info(
            intl.formatMessage(
              {
                defaultMessage: 'Do košíku {n, plural, one {byla přidána položka} other {byly přidány položky}}.',
                description: 'Message after successfully adding subjects to shopping cart.',
                id: 'wikcka',
              },
              {
                n: nextSubjectsCount - prevSubjectsCount,
              },
            ),
          );
        } else if (nextSubjectsCount < prevSubjectsCount) {
          toastMessage.info(
            intl.formatMessage(
              {
                defaultMessage: 'Z košíku {n, plural, one {byla odstraněna položka} other {byly odstraněny položky}}.',
                description: 'Message after successfully removing subjects to shopping cart.',
                id: 'hjsoZk',
              },
              {
                n: nextSubjectsCount - prevSubjectsCount,
              },
            ),
          );
        } else if (nextSubjectsTotal !== prevSubjectsTotal) {
          toastMessage.info(
            intl.formatMessage({
              defaultMessage: 'Množství produktů v košíku bylo změněno.',
              description: 'Message after successfully changed quantity number in shopping cart.',
              id: '2pw0QJ',
            }),
          );
        } else if (nextTotal !== prevTotal) {
          toastMessage.info(
            intl.formatMessage({
              defaultMessage: 'Obsah košíku byl aktualizován.',
              description: 'Message after successfully changed content of shopping cart.',
              id: 'mT0jLP',
            }),
          );
        } else {
          toastMessage.warning(
            intl.formatMessage({
              defaultMessage: 'Změna košíku proběhla, neměla však dopad na obsah ani cenu.',
              description: 'Message after change cart request ends without affect of cart content.',
              id: 'd+D3qj',
            }),
          );
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
          console.error('Predchozi chyba nastala pri updatovani kosiku.');
        }

        toastMessage.error(
          intl.formatMessage({
            defaultMessage: 'Chyba při změně obsahu košíku.',
            description: 'Shopping cart update error message.',
            id: 'pOfbhO',
          }),
        );

        callError = err;
      }

      setUpdateInProgress(false);
      trpcCtx.cart.invalidate();
      if (callError !== null) {
        throw callError;
      } else {
        return true;
      }
    },
    [
      setUpdateInProgress,
      trpcCtx,
      addToCardEvent,
      updateCart,
      checkoutCtx.checkoutId,
      // prevCartSubjects.data,
      prevCartCouponsMapped,
      prevCartCouponsCount,
      prevSubjectsCount,
      prevSubjectsTotal,
      prevTotal,
      intl,
    ],
  );
}
