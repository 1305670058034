import clsx from 'clsx';
import type { ComponentProps, ElementType, ReactElement } from 'react';

const defaultElement = 'div';

type AlertOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  className?: string;
  severity?: 'none' | 'success' | 'info' | 'warning' | 'error';
};

export type AlertProps<E extends ElementType> = AlertOwnProps<E> & Omit<ComponentProps<E>, keyof AlertOwnProps>;

interface AlerComponent {
  <E extends ElementType = typeof defaultElement>(props: AlertProps<E>): ReactElement<any, any> | null;
  displayName?: string | undefined;
}

const Alert: AlerComponent = ({ className, severity = 'none', ...rest }) => (
  <div
    {...rest}
    className={clsx(
      'rounded-md border py-2 px-4',
      {
        ['border-blue-400 bg-blue-400 bg-opacity-10 text-blue-400']: severity === 'info',
        // ['']: severity === 'none',
        ['border-green-500 bg-green-500 bg-opacity-10 text-green-500']: severity === 'success',

        ['border-red-500 bg-red-500 bg-opacity-10 text-red-500']: severity === 'error',
        ['border-yellow-400 bg-yellow-400 bg-opacity-10 text-yellow-400']: severity === 'warning',
      },

      className,
    )}
  />
);

Alert.displayName = 'Alert';

export default Alert;
