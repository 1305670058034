import SeoHelmet from '@/client/components/SeoHelmet';
import normalizeOpeningHours from '@/client/helpers/normalize-opening-hours';
import { usePragueShowroom, useRouteUrl } from '@/client/hooks';
import MainLayout from '@/client/layouts/ArticleLayout';
import trpc from '@/client/lib/trpc';
import { FormattedMessage, useIntl } from 'react-intl';
import { Route, Link as RouterLink } from 'react-router-dom';

export type NotFoundPageProps = {
  isLoading: boolean;
};

const NotFoundPage: React.FC<NotFoundPageProps> = ({ isLoading }) => {
  const routeUrl = useRouteUrl();
  const pragueShowroom = usePragueShowroom();
  const contentSettings = trpc.contentSettings.useQuery();
  const intl = useIntl();

  const pageTitle = intl.formatMessage({
    defaultMessage: 'Nenalezeno',
    description: 'Title of not found page',
    id: 'hENB2p',
  });

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 404;
        return (
          <MainLayout isLoading={isLoading}>
            <SeoHelmet pageTitle={pageTitle} />
            <div className='my-12 min-h-[400px] pr-4'>
              <div className='flex flex-col justify-between md:flex-row'>
                <div>
                  <h1 className='mb-2 mt-0 text-3xl font-semibold leading-normal text-gray-700 md:mt-6'>
                    <FormattedMessage
                      defaultMessage='Tato stránka bohužel neexistuje.'
                      description='Page title for a not found page'
                      id='bYfD+R'
                    />
                  </h1>
                  <p className='mb-4 w-full md:w-[58%]'>
                    <FormattedMessage
                      defaultMessage='Chyba 404 znamená, že stránku se nám nepodařilo nalézt. To nás mrzí. Nebojte, chyba je na naší straně.'
                      description='Page paragraph for a not found page'
                      id='PcnfcS'
                    />
                  </p>
                </div>

                <div className='relative w-full md:w-[440px]'>
                  <div className='relative bg-[#e3f1fb] p-[20px_25px_10px] text-sm text-textGrayDark'>
                    <h3 className='m-0 p-[0_0_15px] text-lg font-bold text-text'>
                      <FormattedMessage
                        defaultMessage='Potřebujete poradit?'
                        description='Do you need help headline for a not found page'
                        id='FnO+GX'
                      />
                    </h3>
                    <div className='mb-[10.5px] flex flex-col gap-[6px]'>
                      {pragueShowroom?.telephone && (
                        <a
                          className='text-lg text-primary'
                          href={`tel:${pragueShowroom.telephone.replace(/\s+/g, '')}`}
                        >
                          <strong>{pragueShowroom.telephone}</strong>
                        </a>
                      )}
                      <div className='flex flex-col pr-14'>
                        {pragueShowroom?.openingHours && (
                          <span
                            dangerouslySetInnerHTML={{ __html: normalizeOpeningHours(pragueShowroom.openingHours) }}
                          />
                        )}
                        {contentSettings.data != null && contentSettings.data.email && (
                          <a
                            className='break-all text-[15px] text-primary underline'
                            href={`mailto:${contentSettings.data.email}`}
                          >
                            {contentSettings.data.email}
                          </a>
                        )}
                      </div>
                    </div>
                    <img
                      alt='potrebujete poradit'
                      className='absolute bottom-0 right-0 select-none'
                      draggable={false}
                      src='/images/layout/potrebujete-poradit.png'
                    />
                  </div>
                </div>
              </div>
              <div className='flex flex-col'>
                <div className='mt-6 '>
                  <h3 className='text-lg font-bold'>
                    <FormattedMessage
                      defaultMessage='Prosím pokračujte na:'
                      description='Navigation for a not found page'
                      id='CZDfaw'
                    />
                  </h3>
                  <ul className='mt-1 list-disc pl-12'>
                    <li>
                      <RouterLink className='text-[15px] text-primary underline' to={routeUrl('home', {})}>
                        <FormattedMessage
                          defaultMessage='Úvodní stránka'
                          description='Home navigation for a not found page'
                          id='mgnZT0'
                        />
                      </RouterLink>
                    </li>
                    <li>
                      <RouterLink className='text-[15px] text-primary underline' to='/stranky/kontakty'>
                        <FormattedMessage
                          defaultMessage='Kontakty'
                          description='Contacts navigation for a not found page'
                          id='VHHqrS'
                        />
                      </RouterLink>
                    </li>
                  </ul>
                </div>
                <div className='mt-6 md:mt-0 2xl:relative 2xl:block'>
                  <img
                    alt='not found'
                    className='left-0 right-0 bottom-0 m-auto select-none 2xl:absolute'
                    draggable={false}
                    src='/images/notfound404.png'
                  />
                </div>
              </div>
            </div>
          </MainLayout>
        );
      }}
    />
  );
};

export default NotFoundPage;
