import { lazy } from 'react';
import type { ComponentWithGetInitialProps, LazyComponent, Module } from '../types';

export function lazyComponent<Props>(
  loader: () => Promise<Module<React.ComponentType<Props> | ComponentWithGetInitialProps<Props>>>,
): LazyComponent<Props> {
  let component: any;

  return {
    component: lazy(loader as any),
    async getInitialProps(ctx) {
      return component && component.getInitialProps ? component.getInitialProps(ctx) : null;
    },

    async load() {
      const ResolvedComponent = await loader();
      component = ResolvedComponent.default || ResolvedComponent;
    },
    loader,
  };
}
