import { UtilsResource } from '@/resources/UtilsResource';
import type { EcomProductBatchValidatorItem, EcomProductBatchValidatorResultItem } from '@sprinx/knihovna-api-types';
import type { AxiosResponse } from 'axios';
import axios from 'axios';

export class ClientUtilsResource extends UtilsResource {
  async validateProductBatch(params: {
    batch: EcomProductBatchValidatorItem[];
    checkoutId: string;
    onlyOnStock?: boolean;
  }): Promise<EcomProductBatchValidatorResultItem[] | null> {
    return axios
      .post<
        EcomProductBatchValidatorResultItem[],
        AxiosResponse<EcomProductBatchValidatorResultItem[]>,
        {
          batch: EcomProductBatchValidatorItem[];
          checkoutId: string;
          onlyOnStock?: boolean;
        }
      >(`/api/utils/validate-product-batch`, params)
      .then((result) => result.data)
      .catch(() => {
        return null;
      });
  }

  async saveNewsletterEmail(params: { agreement: boolean; email: string }): Promise<Record<string, any>> {
    return axios
      .post('/api/utils/save-newsletter-email', params)
      .then((result) => result.data)
      .catch(() => {
        return null;
      });
  }
}
