import { useOnClickOutside } from '@/client/hooks';
import { mdiAlertCircleOutline, mdiTrashCanOutline } from '@/client/icons';
import { Icon } from '@mdi/react';
import clsx from 'clsx';
import type { FC, HTMLAttributes, MouseEvent } from 'react';
import { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from '../Tooltip';

export type IconButtonDeleteWithConfirmProps = Omit<HTMLAttributes<HTMLButtonElement>, 'onClick'> & {
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void | PromiseLike<unknown>;
  size?: string | number;
};

const IconButtonDeleteWithConfirm: FC<IconButtonDeleteWithConfirmProps> = ({
  className,
  onClick,
  size = '1.2rem',
  ...props
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [clicked, setClicked] = useState<NodeJS.Timeout | undefined>(undefined);

  const handleConfirmed = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (clicked) {
        clearTimeout(clicked);
        const res = onClick ? onClick(event) : undefined;

        if (res && res.then) {
          res.then(
            (x) => {
              setClicked(undefined);
              return x;
            },
            (err) => {
              setClicked(undefined);
              throw err;
            },
          );
        } else {
          setClicked(undefined);
        }
      }
    },
    [clicked, onClick],
  );

  const handleButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (!clicked) {
        setClicked(
          setTimeout(() => {
            setClicked(undefined);
          }, 4000),
        );
        return;
      }

      handleConfirmed(event);
    },
    [clicked, handleConfirmed],
  );

  const handleClickOutside = useCallback(
    (/* event */) => {
      if (clicked) {
        setClicked(undefined);
      }
    },
    [clicked],
  );

  useOnClickOutside(buttonRef, handleClickOutside);

  return (
    <>
      <button
        ref={buttonRef}
        className={clsx('print:hidden', { ['text-red-500']: !!clicked }, className)}
        onClick={handleButtonClick}
        {...props}
      >
        <Icon path={!clicked ? mdiTrashCanOutline : mdiAlertCircleOutline} size={size} />
      </button>
      {!!clicked && (
        <Tooltip referenceElement={buttonRef.current}>
          <FormattedMessage
            defaultMessage='Potvrďte odstranění'
            description='Delete item button confirmation message.'
            id='ZVzrzq'
          />
        </Tooltip>
      )}
    </>
  );
};

IconButtonDeleteWithConfirm.displayName = 'IconButtonDeleteWithConfirm';

export default IconButtonDeleteWithConfirm;
