import type { useCheckoutUpdateAfterLogin_CheckoutContextMutation } from '@/graphql/__generated__/useCheckoutUpdateAfterLogin_CheckoutContextMutation.graphql';
import type { PricesType, SupportedCurrency, SupportedRegion } from '@sprinx/knihovna-api-types';
import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';
import { usePromisifyCommitMutation } from './usePromisifyCommitMutation';

type UseCheckoutUpdateAfterLoginCallbackOptions = {
  checkoutId: string;
  pricingSettings: {
    currency: SupportedCurrency;
    distributor: string;
    priceGroups: string[];
    pricesType: PricesType;
    region: SupportedRegion;
  };
};

export function useCheckoutUpdateAfterLogin() {
  const [commitCheckoutUpdateContext] = useMutation<useCheckoutUpdateAfterLogin_CheckoutContextMutation>(
    graphql`
      mutation useCheckoutUpdateAfterLogin_CheckoutContextMutation(
        $checkoutId: UUID!
        $pricingSettings: PricingSettingsUnresolvedInput!
        $locale: String!
      ) {
        checkout_context_afterlogin(checkoutId: $checkoutId, locale: $locale, pricingSettings: $pricingSettings) {
          cart {
            ...cartState_cart
          }

          existingCarts {
            id
            state
          }
        }
      }
    `,
  );

  const updateCheckoutContext = usePromisifyCommitMutation(
    commitCheckoutUpdateContext,
    (options: UseCheckoutUpdateAfterLoginCallbackOptions) => ({
      checkoutId: options.checkoutId,
      locale: 'cs',
      pricingSettings: options.pricingSettings,
    }),
    () => [null, null],
  );

  return useCallback(
    async (options: UseCheckoutUpdateAfterLoginCallbackOptions) => {
      await updateCheckoutContext(options);
    },
    [updateCheckoutContext],
  );
}
