import ms from 'ms';
import type { TrpcRouterOutputs } from '../lib/trpc';
import trpc from '../lib/trpc';

export function useMethodsMapQuery(initialData?: TrpcRouterOutputs['methods']['shippingPaymentMap']) {
  return trpc.methods.shippingPaymentMap.useQuery(undefined, {
    initialData,
    staleTime: ms('2h'),
  });
}
