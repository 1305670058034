import type { AppCommonData, NavigationType, NavItemDisplay } from '@/types';
import type { AtomEffect } from 'recoil';

export const atomEffectNavigationFromCommonData =
  (navigationType: NavigationType): AtomEffect<NavItemDisplay[] | undefined> =>
  ({ setSelf, trigger }) => {
    if (trigger === 'get') {
      const initialValue = (((typeof window !== 'undefined' ? window : global) as any).commonData as AppCommonData)
        ?.navigations;

      if (!initialValue || !initialValue[navigationType]) {
        setSelf(undefined);
      } else {
        setSelf(initialValue[navigationType]);
      }
    }
  };
