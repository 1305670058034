import { useRecoilAsyncValue } from '@/client/hooks';
import type { FC } from 'react';
import { footerState } from '../../atoms';
import type { FooterLinkItemProps } from '../../types';
import AppCustomFooterLinkBlocksItemLink from '../AppCustomFooterLinkBlocksItemLink';

const AppCustomFooterLinkBlocksContent: FC = () => {
  const footer = useRecoilAsyncValue(footerState);

  const renderLiContent = ({ href: itemHref, label: itemLabel, to: itemTo, ...itemRest }: FooterLinkItemProps) => {
    if (itemHref) {
      return (
        <AppCustomFooterLinkBlocksItemLink {...itemRest} as='a' className='link-blue' href={itemHref}>
          {itemLabel}
        </AppCustomFooterLinkBlocksItemLink>
      );
    } else if (itemTo) {
      return (
        <AppCustomFooterLinkBlocksItemLink className='link-blue' to={itemTo} {...itemRest}>
          {itemLabel}
        </AppCustomFooterLinkBlocksItemLink>
      );
    }

    return null;
  };

  return (
    <>
      {footer &&
        (footer.sections ?? []).map((block, idx) => (
          <div key={`footer-links-block-${idx}`}>
            <h3 className='md:mt:0 h3 mb-2 mt-2'>{block.title}</h3>
            <ul className='mb-6'>
              {(block.links ?? []).map((item, iidx) => (
                <li key={`footer-links-block-${idx}-link-${iidx}`} className='mb-2'>
                  {renderLiContent(item)}
                </li>
              ))}
            </ul>
          </div>
        ))}
    </>
  );
};

AppCustomFooterLinkBlocksContent.displayName = 'AppCustomFooterLinkBlocksContent';

export default AppCustomFooterLinkBlocksContent;
