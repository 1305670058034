import type { SupportedLocale } from '@sprinx/knihovna-api-types';
import kebabCase from 'lodash/kebabCase';
import pathToRegexp from 'path-to-regexp';
import qs from 'qs';
import { routesBuilderSplitInputParams } from './routesBuilderSplitInputParams';
import { safeAbsoluteUrl } from './safeAbsoluteUrl';

/**
 * Standard route builder
 * @param locale
 * @param path
 * @param otherParamsToQs
 * @param inputParams
 */
export function routesBuilderProductUrlBuilder(
  locale: SupportedLocale,
  path: string,
  otherParamsToQs: boolean | string[] | undefined | null,
  inputParams: { productName: string; sku: string },
): string {
  const { productName, sku } = inputParams || {};
  const iPath = kebabCase(productName);
  const nInputParams = {
    name: iPath.replace(/^\//, '').replace(/\/$/, '').split('/'),
    sku,
  };

  const { otherParamsExists, pathParams, queryParams } = routesBuilderSplitInputParams(
    otherParamsToQs,
    path,
    nInputParams,
  );

  // const pathParams = {
  //   ...oPathParams,
  //   hash: shorthash(oPathParams.path),
  //   path: oPathParams.path.replace(/^\//, '').replace(/\/$/, '').split('/'),
  // };

  const toUrl = pathToRegexp.compile(path);
  const urlB = toUrl(pathParams);

  return !otherParamsToQs || !otherParamsExists
    ? safeAbsoluteUrl(path, urlB)
    : safeAbsoluteUrl(
        path,
        `${urlB}${qs.stringify(queryParams, {
          addQueryPrefix: true,
        })}`,
      );
}
