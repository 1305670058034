import type { useCheckoutUserExistingCartRemove_decisionMutation } from '@/graphql/__generated__/useCheckoutUserExistingCartRemove_decisionMutation.graphql';
import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';
import { usePromisifyCommitMutation } from './usePromisifyCommitMutation';

export type UseCheckoutUserExistingCartRemoveOptions = {
  cartToRemoveId: string;
  checkoutId: string;
};

export function useCheckoutUserExistingCartRemove() {
  const [commitCheckoutUpdateContext] = useMutation<useCheckoutUserExistingCartRemove_decisionMutation>(
    graphql`
      mutation useCheckoutUserExistingCartRemove_decisionMutation(
        $checkoutId: ID!
        $cartToRemoveId: ID!
        $locale: String!
      ) {
        checkout_context_afterlogin_removeCart(
          checkoutId: $checkoutId
          cartToRemoveId: $cartToRemoveId
          locale: $locale
        ) {
          cart {
            ...cartState_cart
          }
          ...checkoutUserExistingCartsState_cart
        }
      }
    `,
  );

  const updateCheckoutContext = usePromisifyCommitMutation(
    commitCheckoutUpdateContext,
    (options: UseCheckoutUserExistingCartRemoveOptions) => ({
      cartToRemoveId: options.cartToRemoveId,
      checkoutId: options.checkoutId,
      locale: 'cs',
    }),
    () => [null, null],
  );

  return useCallback(
    async (options: UseCheckoutUserExistingCartRemoveOptions) => {
      await updateCheckoutContext(options);
    },
    [updateCheckoutContext],
  );
}
