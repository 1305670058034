import { Menu as HlMenu } from '@headlessui/react';
import clsx from 'clsx';
import type { ComponentProps, ElementType, FC, HTMLAttributes, ReactElement, ReactNode, RefAttributes } from 'react';
import { forwardRef } from 'react';
import Popup from 'reactjs-popup';
// import type { PopupActions } from 'reactjs-popup/dist/types';

export type MenuRef = {
  close: () => void;
  open: () => void;
  toggle: () => void;
};

export type MenuProps = {
  children?: ReactNode; // | ((closePopup: () => void, isOpen: boolean) => ReactNode);
  contentStyle?: React.CSSProperties;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  on?: 'hover' | 'click' | 'focus' | 'right-click';
  position?:
    | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'top center'
    | 'bottom center'
    | 'center center';

  trigger: ReactElement | ((isOpen: boolean) => ReactElement);
} & RefAttributes<MenuRef>;

const MenuRoot = forwardRef<MenuRef, MenuProps>(
  (
    {
      children,
      contentStyle,
      mouseEnterDelay = 0,
      mouseLeaveDelay = 300,
      on = 'click',
      position = 'bottom center',

      trigger,
    },
    ref,
  ) => {
    return (
      <HlMenu as='div' className='relative inline-block'>
        <HlMenu.Button>
          {({ open: isOpen }) => (typeof trigger === 'function' ? trigger(isOpen) : trigger)}
        </HlMenu.Button>
        {/* <HlMenu.Items className='absolute right-0 origin-top-right mt-2 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          {children}
        </HlMenu.Items> */}
        {children}
      </HlMenu>
    );
    return (
      <Popup
        ref={ref}
        arrow={false}
        className='menu'
        closeOnDocumentClick
        contentStyle={contentStyle}
        mouseEnterDelay={mouseEnterDelay}
        mouseLeaveDelay={mouseLeaveDelay}
        nested
        on={on}
        position={position}
        repositionOnResize
        trigger={trigger}
      >
        {children}
      </Popup>
    );
  },
);

MenuRoot.displayName = 'Menu';

const Items: FC<HTMLAttributes<HTMLDivElement> & { children: ReactNode }> = ({ children, className, ...rest }) => {
  return (
    <HlMenu.Items
      className={clsx(
        'absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
        className,
      )}
      {...rest}
    >
      {children}
    </HlMenu.Items>
  );
};

Items.displayName = 'Menu.Items';

const menuItemDefaultElement = 'div';

export type MenuItemOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children?:
    | ReactNode
    | ((props: { active: boolean; disabled: boolean; isSkeleton: boolean }) => ReactElement<any, any>);
  className?: string;
  isSkeleton?: boolean;
};

export type MenuItemProps<E extends ElementType> = MenuItemOwnProps<E> &
  Omit<ComponentProps<E>, keyof MenuItemOwnProps>;

interface BMenuItemComponent {
  <E extends ElementType = typeof menuItemDefaultElement>(props: MenuItemProps<E>): ReactElement<any, any> | null;
  displayName?: string | undefined;
}

const Item: BMenuItemComponent = ({
  as = menuItemDefaultElement,
  children = null,
  className,
  isSkeleton = false,
  onClick,
  // ...props
}) => {
  return (
    <HlMenu.Item
      as={as as ElementType}
      className={clsx(isSkeleton && !children && 'h-6 w-40 animate-pulse rounded-full bg-slate-200', 'p-2', className)}
      onClick={onClick}
    >
      {({ active, disabled }) => (
        <>{typeof children === 'function' ? children({ active, disabled, isSkeleton }) : children}</>
      )}
    </HlMenu.Item>
  );
  // return (
  //   <Component
  //     className={clsx(isSkeleton && !children && 'animate-pulse rounded-full bg-slate-200 h-6 w-40', 'p-2', className)}
  //     {...props}
  //     onMouseEnter={(e: any) => {
  //       setActive(true);
  //       if (props.onMouseEnter) {
  //         props.onMouseEnter(e);
  //       }
  //     }}
  //     onMouseLeave={(e: any) => {
  //       setActive(false);
  //       if (props.onMouseLeave) {
  //         props.onMouseLeave(e);
  //       }
  //     }}
  //   >
  //     {typeof children === 'function' ? children({ active }) : children}
  //   </Component>
  // );
};

Item.displayName = 'Menu.Item';

const Divider: FC<{ children?: ReactElement }> = ({ children }) => {
  return (
    <div className='relative flex items-center py-1'>
      <div className='flex-grow border-t border-gray-400'></div>
      {children && (
        <>
          <span className='mx-4 flex-shrink text-gray-400'>{children}</span>
          <div className='flex-grow border-t border-gray-400'></div>
        </>
      )}
    </div>
  );
};

Divider.displayName = 'Menu.Divider';

const Menu = Object.assign(MenuRoot, { Divider, Item, Items });

export default Menu;
