import { selectorFamily } from 'recoil';
import { cartSubjectsIndexState } from './cartSubjectsIndexState';

/**
 * Index poctu produktov v kosiku.
 *
 * Obsahuje objekt `{ [sku: string]: number }`.
 */
export const cartProductQtyState = selectorFamily<number, string>({
  get:
    (sku) =>
    ({ get }) => {
      const cartIndex = get(cartSubjectsIndexState);
      return cartIndex[sku] || 0;
    },
  key: 'cartProductQtyState',
});
