import type { UiContext } from '@/context';
import checkoutStateQueryNode, {
  type checkoutStateQuery$data,
} from '@/graphql/__generated__/checkoutStateQuery.graphql';
import { appRelayKey } from '@/relay';
import type { PricesType, SupportedCurrency, SupportedLocale, SupportedRegion } from '@sprinx/knihovna-api-types';
import { atom, selector } from 'recoil';
import { graphQLSelector } from 'recoil-relay';
import { graphql } from 'relay-runtime';
import invariant from 'tiny-invariant';
import { customerIdState } from './customerIdState';
import { priceGroupsState } from './priceGroupsState';
import { pricesTypeState } from './pricesTypeState';

/**
 * ! Interni stav
 * ! Obsahuje hodnotu checkout ID.
 */
const checkoutIdState = atom<string>({
  default: '',
  effects: [
    // initalize state
    ({ setSelf }) => {
      const initialValue = (((typeof window !== 'undefined' ? window : global) as any).appContext as UiContext)
        .sessionCheckoutId;

      if (typeof initialValue === 'string' && initialValue) {
        setSelf(initialValue);
      }
    },
  ],
  key: 'checkoutIdValueState',
});

// !
// ! In recoil-relay examples is query defined directlly in `graphQLSelector`.
// ! But this causes some unexpected behaviour. Prebuilded query used instead.
/* eslint-disable relay/must-colocate-fragment-spreads */
graphql`
  query checkoutStateQuery($checkoutId: UUID!, $locale: String!) {
    checkout(checkoutId: $checkoutId, locale: $locale) {
      cart {
        ...cartState_cart
      }
      ...checkoutUserExistingCartsState_cart
    }
  }
`;
/* eslint-enable relay/must-colocate-fragment-spreads */

export const checkoutState = graphQLSelector<{}, checkoutStateQuery$data['checkout']>({
  environment: appRelayKey,
  key: 'checkoutState',
  mapResponse: (data: checkoutStateQuery$data) => {
    invariant(data.checkout.cart, 'checkoutState: cart is not defined.');
    return data.checkout;
  },
  query: checkoutStateQueryNode,
  variables: ({ get }) => {
    const checkoutId = get(checkoutIdState);

    return { checkoutId, locale: 'cs' };
  },
});

/** @deprecated */
export const checkoutContextState = selector<{
  checkoutId: string;
  currency: SupportedCurrency;
  customerId: string | undefined;
  locale: SupportedLocale;
  priceGroups: string[];
  pricesType: PricesType;
  region: SupportedRegion;
}>({
  get: ({ get }) => {
    const checkoutId = get(checkoutIdState);
    const locale = 'cs';
    const currency = 'CZK';
    const priceGroups = get(priceGroupsState);
    const pricesType = get(pricesTypeState);
    const customerId = get(customerIdState);
    const region = 'CZ';

    invariant(checkoutId, 'checkoutId is required');

    return { checkoutId, currency, customerId, locale, priceGroups: priceGroups, pricesType, region };
  },
  key: 'checkoutContextState',
});
