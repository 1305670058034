import { shorthash } from '@/common';
import type { SupportedLocale } from '@sprinx/knihovna-api-types';
import pathToRegexp from 'path-to-regexp';
import qs from 'qs';
import invariant from 'tiny-invariant';
import { routesBuilderSplitInputParams } from './routesBuilderSplitInputParams';
import { safeAbsoluteUrl } from './safeAbsoluteUrl';

/**
 * Hash ended rote builder. For routes like `/:path*\/x-:hash`
 *
 * @param locale
 * @param path
 * @param otherParamsToQs
 * @param inputParams
 */
export function routesBuilderPathUrlBuilder(
  locale: SupportedLocale,
  path: string,
  otherParamsToQs: boolean | string[] | undefined | null,
  inputParams: { [key: string]: any; path: string } | undefined,
): string {
  const { path: iPath, ...rInputParams } = inputParams || {};

  invariant(iPath, 'path input parameter is required');

  const nInputParams = {
    ...rInputParams,
    hash: shorthash(iPath),
    path: iPath.replace(/^\//, '').replace(/\/$/, '').split('/'),
  };

  const { otherParamsExists, pathParams, queryParams } = routesBuilderSplitInputParams(
    otherParamsToQs,
    path,
    nInputParams,
  );

  // const pathParams = {
  //   ...oPathParams,
  //   hash: shorthash(oPathParams.path),
  //   path: oPathParams.path.replace(/^\//, '').replace(/\/$/, '').split('/'),
  // };

  const toUrl = pathToRegexp.compile(path);
  const urlB = toUrl(pathParams);

  return !otherParamsToQs || !otherParamsExists
    ? safeAbsoluteUrl(path, urlB)
    : safeAbsoluteUrl(
        path,
        `${urlB}${qs.stringify(queryParams, {
          addQueryPrefix: true,
        })}`,
      );
}
