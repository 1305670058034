import { shorthash } from '@/common';
import type { CatalogueSettingsWithPathAndPagination } from '@/types';
import type { SupportedLocale } from '@sprinx/knihovna-api-types';
import kebabCase from 'lodash/kebabCase';
import invariant from 'tiny-invariant';
import { routesBuilderDefaultdUrlBuilder } from '.';

/**
 * Standard route builder
 * @param locale
 * @param path
 * @param otherParamsToQs
 * @param inputParams
 */
export function routesBuilderCatalogueUrlBuilder(
  locale: SupportedLocale,
  path: string,
  otherParamsToQs: boolean | string[] | undefined | null,
  inputParams: Partial<CatalogueSettingsWithPathAndPagination>,
): string {
  const { hash = shorthash('/catalogue'), taxonomyName = 'Katalog', ...other } = inputParams || {};
  const iPath = taxonomyName ? kebabCase(taxonomyName) : other.taxonomyPath;

  invariant(iPath, 'taxonomyName or taxonomyPath is required.');

  const { after, filter, page, pageSize, ...rest } = other;
  const nextOther = {
    ...rest,
    ...(after == null ? {} : { after }),
    filter: filter == null ? undefined : JSON.stringify(filter),
  };

  return routesBuilderDefaultdUrlBuilder(locale, path, true, {
    ...nextOther,
    hash,
    path: iPath,
  });
}
