import { atom } from 'recoil';

export const appCustomLayoutState = atom<{
  categoriesNavExpanded: boolean;
  expandedCategories: string[];
  isMobileMenuOpen: boolean;
  isSearchOpen: boolean;
}>({
  default: {
    categoriesNavExpanded: true,
    expandedCategories: [],
    isMobileMenuOpen: false,
    isSearchOpen: false,
  },
  key: 'appCustomLayout',
});
