import { NotificationResource } from '@/resources/NotificationResource';
import { EmailTemplateType } from '@/types';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Container as IocContainer } from 'typescript-ioc';
import { toastMessage } from '../compositions/AppToaster';
import { useAppHostName } from './useAppHostName';
import { useProductDetailUrl } from './useProductDetailUrl';

export type ProductDemandFormData = {
  agreementMarketing: boolean;
  agreementPolicy: boolean;
  email: string;
  message: string;
  name: string;
};

export function useProductDemand() {
  const intl = useIntl();
  const productDetailUrl = useProductDetailUrl();
  const appHostName = useAppHostName();

  return useCallback(
    async (data: {
      companyName: string | null | undefined;
      companyRegNo: string | null | undefined;
      formData: ProductDemandFormData;
      productName: string;
      productSku: string;
    }) => {
      const notificationResource = IocContainer.get(NotificationResource);

      try {
        const res = await notificationResource.sendEmail({
          bodyProps: {
            companyName: data.companyRegNo != null && data.companyName != null ? data.companyName : '',
            email: data.formData.email,
            logoCid: 'cid:logo@notification',
            message: data.formData.message,
            productName: data.productName,
            productSku: data.productSku,
            productUrlPath: `${appHostName}${productDetailUrl({
              name: data.productName || 'na',
              sku: data.productSku,
            })}`,
            username: data.formData.name,
          },
          options: {
            from: 'noreply@vybaveniprouklid.cz',
            locale: 'cs',
            region: 'CZ',
            templateCode: EmailTemplateType.productDemand,
            to: ['obchod@allservices.cz'],
          },
          subjectProps: {
            productName: data.productName,
            productSku: data.productSku,
          },
        });

        if (!res) {
          throw new Error('Product demand with NULL as result.');
        }

        return res;
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
          console.error('Predchozi chyba nastala poptávce po produktu.');
        }

        toastMessage.error(
          intl.formatMessage({
            defaultMessage: 'Chyba při odesílání poptávky k produktu.',
            description: 'Product demand error message.',
            id: 'feua7G',
          }),
        );
      }
    },
    [appHostName, productDetailUrl, intl],
  );
}
