import { useMemo } from 'react';
import invariant from 'tiny-invariant';
import type { AspectRatioOptions } from './AspectRatioOptions';
import type { AspectRatioTuple } from './AspectRatioTuple';
import { convertAspectRatioOptions } from './convertAspectRatioOptions';

export function useAspectRatio(options: AspectRatioOptions): AspectRatioTuple {
  invariant(
    typeof options === 'string' ||
      (Array.isArray(options) &&
        options.length === 2 &&
        options[0] > 0 &&
        options[0] <= 16 &&
        options[1] > 0 &&
        options[1] <= 16),
    "'useAspectRatio' receives unsupported aspect ratio options.",
  );

  return useMemo(() => convertAspectRatioOptions(options) || [4, 3], [options]);
}
