import { useCallback } from 'react';

export function useAddToCartFbEvent() {
  return useCallback((externalIds: string[]) => {
    if (typeof window !== 'undefined' && typeof (window as any).fbq === 'function') {
      (window as any).fbq('track', 'AddToCart', {
        content_ids: externalIds,
        content_type: 'product',
      });
    }
  }, []);
}
