import { defineMessages } from 'react-intl';

const messages = defineMessages({
  buttonBack: {
    defaultMessage: 'Zpět',
    description: 'Back to previous page',
    id: 'A+bOG6',
  },
  cart_nextStep: {
    defaultMessage: 'Pokračovat k zaplacení',
    description: 'Process order',
    id: 'XUVxkv',
  },
  cart_share: {
    defaultMessage: 'Sdílet košík',
    description: 'Card share',
    id: 'HijyBq',
  },
  cart_toPurchase: {
    defaultMessage: 'Zpět',
    description: 'Back to purchase',
    id: '9QeMXj',
  },
  cartHeader_availability: {
    defaultMessage: 'Dostupnost',
    description: 'Card header availability',
    id: 'MbOZFw',
  },
  cartHeader_nameWithCode: {
    defaultMessage: 'Název a Kód',
    description: 'Card header name with code',
    id: 'WYUxXd',
  },
  cartHeader_quantity: {
    defaultMessage: 'Počet',
    description: 'Card header quantity',
    id: '0CWMhC',
  },
  cartHeader_totalPrice: {
    defaultMessage: 'Celkem',
    description: 'Card header total price',
    id: 'ecRWjS',
  },
  cartHeader_unit: {
    defaultMessage: 'Jednotka',
    description: 'Card header unit',
    id: 'J0q2fw',
  },
  cartHeader_unitPrice: {
    defaultMessage: 'Cena za kus',
    description: 'Card header unit price',
    id: 'uuY7pE',
  },
  gift_pointsX: {
    defaultMessage: '{qty, plural, zero {0 bodů} one {1 bod} few {# body} other {# bodů}}',
    description: 'Points value',
    id: 'WFyqgf',
  },
  gift_pointsXMissing: {
    defaultMessage: 'chybí {qty, plural, zero {0 bodů} one {1 bod} few {# body} other {# bodů}}',
    description: 'Points missing to recieve gift',
    id: '0h1pn1',
    locale: 'cs',
  },
  pack_carton: {
    defaultMessage: 'karton',
    description: 'Pack carton',
    id: 'a1dq3S',
  },
  pack_pcs: {
    defaultMessage: 'ks',
    description: 'Pack pcs',
    id: 'aydabT',
  },
  printToPdf: {
    defaultMessage: ' Tisk do PDF',
    description: 'Print to PDF',
    id: 'H63oYL',
  },
  qtyPack_carton: {
    defaultMessage:
      '<bold>{qty, plural, zero {0 kartonů} one {1 karton} few {{qty} kartony} other {{qty} kartonů}}</bold>',
    description: 'Pack quantity carton',
    id: 'pDqNmZ',
  },
  qtyPack_cartonFrom: {
    defaultMessage: '<bold>{qtyfrom} - {qtyto} {qtyto, plural, two {kartony} other {kartonů}}</bold>',
    description: 'Pack quantity pcs',
    id: 'AzI4NQ',
  },
  qtyPack_cartonXAndMore: {
    defaultMessage:
      '<bold>{qty, plural, zero {{qty} kartonů} one {1 karton} few {{qty} kartony} other {{qty} kartonů}}</bold> ({pcs, plural, zero {{pcs} kusů} one {1 kus} few {{pcs} kusy} other {{pcs} kusů}}) a více',
    description: 'Pack discount quantity carton',
    id: 'R55mxj',
  },
  qtyPack_pcs: {
    defaultMessage: '<bold>{qty, plural, zero {0 kusů} one {1 kus} few {{qty} kusy} other {{qty} kusů}}</bold>',
    description: 'Pack quantity pcs',
    id: 'n/0MYx',
  },
  qtyPack_pcsFrom: {
    defaultMessage: '<bold>{qtyfrom} - {qtyto} {qtyto, plural, =3 {kusy} few {kusy} other {kusů}}</bold>',
    description: 'Pack quantity pcs',
    id: '2/oboq',
  },
  qtyPack_pcsXAndMore: {
    defaultMessage: '<bold>{qty, plural, zero {0 kusů} one {1 kus} few {{qty} kusy} other {{qty} kusů}}</bold> a více',
    description: 'Pack discount quantity pcs',
    id: 'RloZ8g',
  },
});

// export type intlMessageType<T extends MessageDescriptor> = T;

export function useIntlMessages(): typeof messages {
  return messages;
}
