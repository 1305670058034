import { useRouteUrl } from '@/client/hooks';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import Checkbox from '../Checkbox';

export type AgreementNewsletterProps = {
  errorMessage?: ReactNode;
  helperText?: ReactNode;
  id: string;
  label?: ReactNode;
  name: string;
  required?: boolean;
};

const AgreementNewsletter = forwardRef<HTMLInputElement, AgreementNewsletterProps>((props, ref) => {
  const routeUrl = useRouteUrl();

  return (
    <div className='mb-2 flex'>
      <Checkbox
        ref={ref}
        className='revert'
        {...props}
        label={
          <>
            <div className='text-white'>
              <FormattedMessage defaultMessage='Souhlasím se' description='Newsletter agree text' id='nTD/P1' />
              <RouterLink to={routeUrl('article', { path: 'ochrana-osobnich-udaju' })}>
                <span className='underline hover:no-underline'> zpracováním osobních údajů</span>
              </RouterLink>
            </div>
          </>
        }
      />
    </div>
  );
});

AgreementNewsletter.displayName = 'AgreementNewsletter';

export default AgreementNewsletter;
