import AgreementNewsletter from '@/client/components/AgreementNewsletter';
import { toastMessage } from '@/client/compositions/AppToaster';
import { UtilsResource } from '@/resources';
import type { HTMLAttributes, RefAttributes } from 'react';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container as IocContainer } from 'typescript-ioc';

export type AppCustomFooterNwlRegistrationProps = HTMLAttributes<HTMLDivElement> & RefAttributes<HTMLDivElement>;

const AppCustomFooterNwlRegistration = forwardRef<HTMLDivElement, AppCustomFooterNwlRegistrationProps>((props, ref) => {
  const [showThank, setShowThank] = useState(false);
  const showThankTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const intl = useIntl();

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<{ agreement: boolean; email: string }>({
    defaultValues: {
      agreement: false,
      email: '',
    },
  });

  const onSubmit = useCallback(
    async (data: { agreement: boolean; email: string }) => {
      const resource = IocContainer.get(UtilsResource);
      const savedEmail = await resource.saveNewsletterEmail(data);

      if (savedEmail) {
        toastMessage.success(
          intl.formatMessage({
            defaultMessage: 'Byl jste úspěšně přihlášen k newsletteru.',
            description: 'Newsletter email saved successfully.',
            id: 'mUvwAr',
          }),
        );
        if ((window as any)._QONizer != null) {
          (window as any)._QONizer.collectEmails.setEmailData(data.email, data.agreement);
        }
        reset();
        setShowThank(true);
      } else {
        toastMessage.error(
          intl.formatMessage({
            defaultMessage: 'Bohužel se nám nepodařilo vás úspěšně přihlásit k newsletteru. Zkuste to prosím později',
            description: 'Newsletter email saved unsuccessfully.',
            id: 'G7s9oH',
          }),
        );
      }
    },
    [intl, reset],
  );

  useEffect(() => {
    if (showThank && !showThankTimeoutRef.current) {
      showThankTimeoutRef.current = setTimeout(() => {
        setShowThank(false);
        showThankTimeoutRef.current = undefined;
      }, 5000);
    }
  }, [showThank]);

  useEffect(
    () => () => {
      if (showThankTimeoutRef.current) {
        clearTimeout(showThankTimeoutRef.current);
      }
    },
    [],
  );

  return (
    <div ref={ref} className='relative mb-6 ml-[-1rem] mr-[-1rem] flex justify-center bg-primary pt-4 pb-20 text-white'>
      <div className='flex w-full flex-col px-2 print:hidden md:flex-row md:px-0'>
        <div className='mb-6 w-full justify-around px-2 text-center md:mb-0 md:w-1/2'>
          <h2 className='h2 mb-6 text-center md:mb-0'>
            <FormattedMessage
              defaultMessage='Výběr těch nejlepších slev, akcí a novinek na váš email'
              description='Nadpis, motivační text, registrace do newsletteru.'
              id='C2px+4'
            />
          </h2>
          <span className='w-full text-center'>(maximálně 2x měsíčně)</span>
        </div>

        <div className='w-full px-2 md:w-1/2'>
          {!showThank && (
            <form className='form-styles' noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className='relative mb-4 flex max-w-[570px] items-center'>
                <input
                  className='flex-1 pr-[110px] text-neutral-800'
                  placeholder='zadejte váš email'
                  type='email'
                  {...register('email', {
                    pattern:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                    required: true,
                  })}
                />
                <button
                  className='absolute top-[4px] right-[0px] ml-[-3px] h-[42px] rounded-md bg-orange px-4 text-white'
                  type='submit'
                >
                  Přihlásit se
                </button>
              </div>
              <div className='text-[13px]'>
                <AgreementNewsletter id='agreement' {...register('agreement', { required: true })} />
              </div>
              {errors.email && (
                <div className='text-AppCustom-500 mt-2 text-sm'>
                  <FormattedMessage
                    defaultMessage='Email je v nesprávném formátu.'
                    description='Chybová hláška invalidního emailu.'
                    id='lYC4NB'
                  />
                </div>
              )}
              {!errors.email && errors.agreement?.type === 'required' && (
                <div className='text-AppCustom-500 mt-2 text-sm'>
                  <FormattedMessage
                    defaultMessage='S prohlášením o ochraně osobních údajů musíte souhlasit.'
                    description="Error message of field 'agreementGdpr' in chceckout order data section form."
                    id='kyINMQ'
                  />
                </div>
              )}
            </form>
          )}
          {showThank && (
            <div className='mt-1 h-10 text-base text-green-300'>
              <FormattedMessage
                defaultMessage='Děkujeme za registraci.'
                description='Hláška zobrazovaná po úspěšné registraci k newsletteru.'
                id='NYh9j7'
              />
            </div>
          )}
        </div>
      </div>
      <img
        alt='pan_bublina'
        className='left-[calc(50% - 61px)] absolute bottom-[-26px] print:hidden'
        height={104}
        loading='lazy'
        src='/images/layout/bublina.png'
        width={123}
      />
      <img
        alt='logo'
        className='hidden object-contain object-left print:block'
        height={90}
        src='/images/logos/logo.webp'
        width={356}
      />
    </div>
  );
});

AppCustomFooterNwlRegistration.displayName = 'AppCustomFooterNwlRegistration';

export default AppCustomFooterNwlRegistration;
