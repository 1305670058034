import type { FC, ReactNode } from 'react';
import { useRef } from 'react';
import AppCustomLayout, { MainContent } from '../AppCustomLayout';
import AppCustomHeaderNavBar from '../AppCustomLayout/components/AppCustomHeaderNavBar';

export type ArticleLayoutProps = { children: ReactNode; isLoading?: boolean };

const ArticleLayout: FC<ArticleLayoutProps> = ({ children, isLoading }) => {
  const navRef = useRef<HTMLDivElement>(null);
  return (
    <AppCustomLayout isLoading={isLoading}>
      <div className='flex flex-col'>
        <div ref={navRef} className='container h-0'>
          <AppCustomHeaderNavBar className='md:max-w-[280px]' navOffsetTop={navRef?.current?.offsetTop} />
        </div>
        <MainContent className='pl-4'>{children}</MainContent>
      </div>
    </AppCustomLayout>
  );
};

ArticleLayout.displayName = 'ArticleLayout';

export default ArticleLayout;
