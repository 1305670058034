import type { FC } from 'react';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import AppCustomFooterLinkBlocksContent from './AppCustomFooterLinkBlocksContent';
import AppCustomFooterLinkBlocksSkeleton from './AppCustomFooterLinkBlocksSkeleton';

export type AppCustomFooterLinkBlocksProps = {};

const AppCustomFooterLinkBlocks: FC<AppCustomFooterLinkBlocksProps> = () => (
  <>
    <ErrorBoundary
      fallback={<div />}
      onError={(err) => {
        if (process.env.NODE_ENV === 'production') {
          console.error('Pri nahravani polozek paticky doslo k chybe.', err);
        }
      }}
    >
      <Suspense fallback={<AppCustomFooterLinkBlocksSkeleton />}>
        <AppCustomFooterLinkBlocksContent />
      </Suspense>
    </ErrorBoundary>
  </>
);

AppCustomFooterLinkBlocks.displayName = 'AppCustomFooterLinkBlocks';

export default AppCustomFooterLinkBlocks;
