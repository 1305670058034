import type { CheckoutPrsonalPickupInfo } from '@/types';
import { useCallback } from 'react';
import invariant from 'tiny-invariant';
import { useCartSelectedMethodsQuery } from './useCartSelectedMethodsQuery';
import { useCheckoutData } from './useCheckoutData';

type Data = CheckoutPrsonalPickupInfo | null;
type ValueOrSetter<T> = T | ((prev: T) => T);

export function useCheckoutPersonalPickupInfo(): {
  isPersonalPickup: boolean;
  personalPickupInfo: Data;
  setPersonalPickupInfo: (valueOrSetter: ValueOrSetter<Data>) => Promise<void>;
} {
  const checkoutData = useCheckoutData();
  const cartSelectedMethods = useCartSelectedMethodsQuery();
  const shippingMethod = cartSelectedMethods.data?.shipping;

  const setPersonalPickupInfo = useCallback(
    async (valueOrSetter: ValueOrSetter<Data>): Promise<void> => {
      invariant(checkoutData.isLoading === false && checkoutData.data !== undefined, 'Wait for checkout data');
      const next =
        typeof valueOrSetter === 'function' ? valueOrSetter(checkoutData.data.personalPickupInfo) : valueOrSetter;

      await checkoutData.update((p) => ({
        ...p,
        personalPickupInfo: next,
      }));
    },
    [checkoutData],
  );

  return {
    isPersonalPickup: !!shippingMethod?.personalPickup,
    personalPickupInfo: checkoutData.data?.personalPickupInfo ?? null,
    setPersonalPickupInfo,
  };
}
