/**
 * @generated SignedSource<<84ae68503bec8ecd6dc6e4b9a3d062a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CartSubjectType = "discount" | "gift" | "item" | "method" | "subjectIndirect" | "%future added value";
export type ProductQuantityStateType = "onWay" | "ordered" | "soldOut" | "stock" | "toOrder" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type cartSubjectsState_cart_subjects$data = {
  readonly subjects: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly discounts?: {
          readonly discountCodes: ReadonlyArray<string>;
          readonly totalDiscount: {
            readonly amount: number;
            readonly amountWithTax: number;
            readonly currency: string;
          };
        } | null;
        readonly indirectSubjects?: ReadonlyArray<{
          readonly price: {
            readonly amount: number;
            readonly amountWithTax: number;
          };
          readonly productSku: string;
          readonly quantity: number;
          readonly subjectTotal: {
            readonly amount: number;
            readonly amountWithTax: number;
            readonly vatAmount: number;
            readonly vatCode: string | null;
          };
          readonly subjectType: CartSubjectType;
          readonly text: string;
        }> | null;
        readonly price: {
          readonly amount: number;
          readonly amountWithTax: number;
        };
        readonly product: {
          readonly stockAvailableQuantity: {
            readonly note: string | null;
            readonly quantity: number | null;
            readonly state: ProductQuantityStateType;
            readonly timing: any | null;
            readonly unit: string | null;
          } | null;
        } | null;
        readonly productSku: string;
        readonly quantity: number;
        readonly subjectTotal: {
          readonly amount: number;
          readonly amountWithTax: number;
          readonly vatAmount: number;
          readonly vatCode: string | null;
        };
        readonly subjectType: CartSubjectType;
        readonly text: string;
      };
    }> | null;
  };
  readonly " $fragmentType": "cartSubjectsState_cart_subjects";
};
export type cartSubjectsState_cart_subjects$key = {
  readonly " $data"?: cartSubjectsState_cart_subjects$data;
  readonly " $fragmentSpreads": FragmentRefs<"cartSubjectsState_cart_subjects">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "cartSubjectsState_cart_subjects"
};

(node as any).hash = "cedc68ce4e3d12e88009b483b1a37764";

export default node;
