import {
  ArticleResource,
  AuthResource,
  CatalogueResource,
  CheckoutResource,
  NotificationResource,
  ProductDetailResource,
  RelatedProductsResource,
  ResourcesContext,
  ShoppingListsResource,
  TaxonomiesResource,
  UtilsResource,
} from '@/resources';
import { Container as IocContainer, Scope } from 'typescript-ioc';
// @index(['./*.{ts,tsx,js}', '!./*.{spec,test}.{ts,tsx,js}'], f => `import { ${f.name} } from '${f.path}';`)
import { ClientArticleResource } from './ClientArticleResource';
import { ClientAuthResource } from './ClientAuthResource';
import { ClientCatalogueResource } from './ClientCatalogueResource';
import { ClientCheckoutResource } from './ClientCheckoutResource';
import { ClientNotificationResource } from './ClientNotificationResource';
import { ClientProductDetailResource } from './ClientProductDetailResource';
import { ClientRelatedProductsResource } from './ClientRelatedProductsResource';
import { ClientShoppingListsResource } from './ClientShoppingListsResource';
import { ClientTaxonomiesResource } from './ClientTaxonomiesResource';
import { ClientUtilsResource } from './ClientUtilsResource';
// @endindex

export const allResources = [
  // @index(['./*.{ts,tsx,js}', '!./*.{spec,test}.{ts,tsx,js}'], f => `${f.name},`)
  ClientArticleResource,
  ClientAuthResource,
  ClientCatalogueResource,
  ClientCheckoutResource,
  ClientNotificationResource,
  ClientProductDetailResource,
  ClientRelatedProductsResource,
  ClientShoppingListsResource,
  ClientTaxonomiesResource,
  ClientUtilsResource,
  // @endindex
];

export function registerClientResources(): void {
  IocContainer.bind(ResourcesContext).scope(Scope.Singleton);

  // @index(['./*.{ts,tsx,js}', '!./*.{spec,test}.{ts,tsx,js}'], f => `IocContainer.bind(${f.name.replace(/^Client/, '')}).to(${f.name}).scope(Scope.Singleton);`)
  IocContainer.bind(ArticleResource).to(ClientArticleResource).scope(Scope.Singleton);
  IocContainer.bind(AuthResource).to(ClientAuthResource).scope(Scope.Singleton);
  IocContainer.bind(CatalogueResource).to(ClientCatalogueResource).scope(Scope.Singleton);
  IocContainer.bind(CheckoutResource).to(ClientCheckoutResource).scope(Scope.Singleton);
  IocContainer.bind(NotificationResource).to(ClientNotificationResource).scope(Scope.Singleton);
  IocContainer.bind(ProductDetailResource).to(ClientProductDetailResource).scope(Scope.Singleton);
  IocContainer.bind(RelatedProductsResource).to(ClientRelatedProductsResource).scope(Scope.Singleton);
  IocContainer.bind(ShoppingListsResource).to(ClientShoppingListsResource).scope(Scope.Singleton);
  IocContainer.bind(TaxonomiesResource).to(ClientTaxonomiesResource).scope(Scope.Singleton);
  IocContainer.bind(UtilsResource).to(ClientUtilsResource).scope(Scope.Singleton);
  // @endindex
}

// @index(['./*.{ts,tsx,js}', '!./*.{spec,test}.{ts,tsx,js}', './*/index.{ts,tsx}'], f => `export * from '${f.path.replace(/\/index$/, '')}';`)
export * from './ClientArticleResource';
export * from './ClientAuthResource';
export * from './ClientCatalogueResource';
export * from './ClientCheckoutResource';
export * from './ClientNotificationResource';
export * from './ClientProductDetailResource';
export * from './ClientRelatedProductsResource';
export * from './ClientShoppingListsResource';
export * from './ClientTaxonomiesResource';
export * from './ClientUtilsResource';
// @endindex
