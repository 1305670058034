/**
 * @generated SignedSource<<ea5ed837208d592c07eee3046e3b572d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CheckoutAfterLoginDecisionAction = "brandNew" | "keepCurrent" | "mergeWithNext" | "takeNext" | "%future added value";
export type useCheckoutUserExistingCartMakeDecision_decisionMutation$variables = {
  action: CheckoutAfterLoginDecisionAction;
  checkoutId: any;
  locale: string;
  nextCartId?: string | null;
};
export type useCheckoutUserExistingCartMakeDecision_decisionMutation$data = {
  readonly checkout_context_afterlogin_decision: {
    readonly cart: {
      readonly " $fragmentSpreads": FragmentRefs<"cartState_cart">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"checkoutUserExistingCartsState_cart">;
  };
};
export type useCheckoutUserExistingCartMakeDecision_decisionMutation = {
  response: useCheckoutUserExistingCartMakeDecision_decisionMutation$data;
  variables: useCheckoutUserExistingCartMakeDecision_decisionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "action"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "checkoutId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nextCartId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "action",
    "variableName": "action"
  },
  {
    "kind": "Variable",
    "name": "checkoutId",
    "variableName": "checkoutId"
  },
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  },
  {
    "kind": "Variable",
    "name": "nextCartId",
    "variableName": "nextCartId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountWithTax",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "price",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productSku",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "subjectTotal",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatCode",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subjectType",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timing",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "CartSubjectDiscount",
  "kind": "LinkedField",
  "name": "discounts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discountCodes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceSimple",
      "kind": "LinkedField",
      "name": "totalDiscount",
      "plural": false,
      "selections": [
        (v7/*: any*/),
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "Cart",
  "kind": "LinkedField",
  "name": "existingCarts",
  "plural": true,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCheckoutUserExistingCartMakeDecision_decisionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Checkout",
        "kind": "LinkedField",
        "name": "checkout_context_afterlogin_decision",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "cartState_cart",
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "cartSubjectsState_cart_subjects",
                    "selections": [
                      {
                        "alias": null,
                        "args": (v6/*: any*/),
                        "concreteType": "CartSubjectConnection",
                        "kind": "LinkedField",
                        "name": "subjects",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CartSubjectConnectionEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductCommerce",
                                    "kind": "LinkedField",
                                    "name": "product",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ProductStockQuantity",
                                        "kind": "LinkedField",
                                        "name": "stockAvailableQuantity",
                                        "plural": false,
                                        "selections": [
                                          (v14/*: any*/),
                                          (v10/*: any*/),
                                          (v15/*: any*/),
                                          (v16/*: any*/),
                                          (v17/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "CartSubjectIndirect",
                                        "kind": "LinkedField",
                                        "name": "indirectSubjects",
                                        "plural": true,
                                        "selections": [
                                          (v9/*: any*/),
                                          (v10/*: any*/),
                                          (v11/*: any*/),
                                          (v12/*: any*/),
                                          (v13/*: any*/),
                                          (v18/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v19/*: any*/)
                                    ],
                                    "type": "CartSubjectPrimary",
                                    "abstractKey": null
                                  },
                                  (v18/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "subjects(first:100)"
                      }
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineDataFragmentSpread",
            "name": "checkoutUserExistingCartsState_cart",
            "selections": [
              (v20/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "useCheckoutUserExistingCartMakeDecision_decisionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Checkout",
        "kind": "LinkedField",
        "name": "checkout_context_afterlogin_decision",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "CartSubjectConnection",
                "kind": "LinkedField",
                "name": "subjects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CartSubjectConnectionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductCommerce",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductStockQuantity",
                                "kind": "LinkedField",
                                "name": "stockAvailableQuantity",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  (v10/*: any*/),
                                  (v15/*: any*/),
                                  (v16/*: any*/),
                                  (v17/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CartSubjectIndirect",
                                "kind": "LinkedField",
                                "name": "indirectSubjects",
                                "plural": true,
                                "selections": [
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v18/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v19/*: any*/)
                            ],
                            "type": "CartSubjectPrimary",
                            "abstractKey": null
                          },
                          (v18/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "subjects(first:100)"
              }
            ],
            "storageKey": null
          },
          (v20/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6f9940f3cc86e02220654ce52824ca32",
    "id": null,
    "metadata": {},
    "name": "useCheckoutUserExistingCartMakeDecision_decisionMutation",
    "operationKind": "mutation",
    "text": "mutation useCheckoutUserExistingCartMakeDecision_decisionMutation(\n  $checkoutId: UUID!\n  $action: CheckoutAfterLoginDecisionAction!\n  $nextCartId: ID\n  $locale: String!\n) {\n  checkout_context_afterlogin_decision(checkoutId: $checkoutId, action: $action, nextCartId: $nextCartId, locale: $locale) {\n    cart {\n      ...cartState_cart\n      id\n    }\n    ...checkoutUserExistingCartsState_cart\n    id\n  }\n}\n\nfragment cartState_cart on Cart {\n  id\n  ...cartSubjectsState_cart_subjects\n}\n\nfragment cartSubjectsState_cart_subjects on Cart {\n  subjects(first: 100) {\n    edges {\n      node {\n        __typename\n        price {\n          amount\n          amountWithTax\n        }\n        quantity\n        productSku\n        subjectTotal {\n          amount\n          amountWithTax\n          vatAmount\n          vatCode\n        }\n        subjectType\n        product {\n          stockAvailableQuantity {\n            note\n            quantity\n            state\n            timing\n            unit\n            id\n          }\n          id\n        }\n        ... on CartSubjectPrimary {\n          indirectSubjects {\n            price {\n              amount\n              amountWithTax\n            }\n            quantity\n            productSku\n            subjectTotal {\n              amount\n              amountWithTax\n              vatAmount\n              vatCode\n            }\n            subjectType\n            text\n            id\n          }\n          discounts {\n            discountCodes\n            totalDiscount {\n              amount\n              amountWithTax\n              currency\n            }\n          }\n        }\n        text\n        id\n      }\n    }\n  }\n}\n\nfragment checkoutUserExistingCartsState_cart on Checkout {\n  existingCarts {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9c4c8f73f41a8d537d6fce536bd03995";

export default node;
