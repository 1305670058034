import type { PimProductQuantityLimits } from '@sprinx/knihovna-api-types';
import { PimProductQuantityLimitsRounding } from '@sprinx/knihovna-api-types';
import clsx from 'clsx';
import type { FC, HTMLAttributes } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { FormatNumberOptions, IntlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import InputNumber from '../InputNumber';
import QuantityInputIconButton from './QuantityInputIconButton';

export type QuantityInputProps = HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean;
  formatOptions?: FormatNumberOptions;
  innerWrapperClassName?: string;
  intl: IntlShape;
  limits: PimProductQuantityLimits;
  onQuantityChange: (nextQuantity: number) => void;
  postponeUpdate?: boolean;
  qty?: number;
};

const QuantityInput: FC<QuantityInputProps> = ({
  className,
  disabled = false,
  formatOptions,
  innerWrapperClassName,
  intl,
  limits,
  onQuantityChange,
  postponeUpdate = false,
  qty,
  ...props
}) => {
  const [disableDecrement, setDisableDecrement] = useState(disabled);
  const [disableIncrement, setDisableIncrement] = useState(disabled);

  const [wasRounded, setWasRounded] = useState(false);
  const wasRoundedTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(
    () => () => {
      if (wasRoundedTimeoutRef.current) {
        clearTimeout(wasRoundedTimeoutRef.current);
      }
    },
    [],
  );
  useEffect(() => {
    if (wasRounded) {
      wasRoundedTimeoutRef.current = setTimeout(() => {
        setWasRounded(false);
      }, 5000);
    }
  }, [wasRounded]);

  // Disable decrement/increment buttons if needed
  useEffect(() => {
    if ((qty ?? 0) - limits.quantityStep < limits.quantityMin) {
      setDisableDecrement(true);
    } else {
      setDisableDecrement(false);
    }
    if ((qty ?? 0) + limits.quantityStep > limits.quantityMax) {
      setDisableIncrement(true);
    } else {
      setDisableIncrement(false);
    }
  }, [limits.quantityMax, limits.quantityMin, limits.quantityStep, qty]);

  const hanleChangeQuantity = useCallback(
    (nextValue: number) => {
      let nq = Math.min(Math.max(nextValue, limits.quantityMin), limits.quantityMax);

      const md = nq % limits.quantityStep;
      if (md > 0) {
        nq +=
          limits.quantityStepRounding === PimProductQuantityLimitsRounding.up
            ? nq + (limits.quantityStep - md)
            : nq + md;
        setWasRounded(true);
      }

      if (onQuantityChange) {
        onQuantityChange(nq);
      }
    },
    [onQuantityChange, limits.quantityMax, limits.quantityMin, limits.quantityStep, limits.quantityStepRounding],
  );

  const handleChangeQtyByStep = useCallback(
    (prev: number | undefined, step: -1 | 1) => {
      if (typeof prev !== 'number' && step === -1) return;
      hanleChangeQuantity(typeof prev === 'number' ? prev + step * limits.quantityStep : limits.quantityDefault || 1);
    },
    [hanleChangeQuantity, limits.quantityDefault, limits.quantityStep],
  );

  const postponeUpdateTimer = useRef<NodeJS.Timeout | undefined>(undefined);
  const [inputQty, setInputQty] = useState<string>(qty?.toString() ?? '');
  // Update inputState if outher prop value changes.
  useEffect(() => {
    setInputQty(qty?.toString() ?? '');
  }, [qty]);
  const hanleChangeInputQuantity = useCallback(
    (nextStringValue: string) => {
      setInputQty(nextStringValue ?? '');
      if (nextStringValue === '') return;
      const nextValue = Number(nextStringValue);
      if (isNaN(nextValue)) return;
      if (postponeUpdate) {
        if (postponeUpdateTimer.current) {
          clearTimeout(postponeUpdateTimer.current);
        }
        postponeUpdateTimer.current = setTimeout(() => {
          hanleChangeQuantity(nextValue);
        }, 1000);
      } else {
        hanleChangeQuantity(nextValue);
      }
    },
    [hanleChangeQuantity, postponeUpdate],
  );

  return (
    <div className={clsx('flex items-center', className)} {...props}>
      <div className={clsx('w-37 relative flex md:bottom-0', innerWrapperClassName)}>
        <QuantityInputIconButton
          aria-label='Snížit množství'
          disabled={disableDecrement}
          onClick={() => handleChangeQtyByStep(qty, -1)}
          type='button'
        >
          -
        </QuantityInputIconButton>
        <InputNumber
          aria-label='Zadat požadované množství, které má být vloženo do košíku.'
          className='text-grey-700 placeholder-grey-600 focus:shadow-outline h-8 w-1/3 rounded-lg border px-1 text-center text-sm md:w-12'
          disabled={disabled}
          formatOptions={formatOptions}
          intl={intl}
          onBlur={(e) => {
            // If one leaves empty input, set as display value current qty.
            if (e.target.value == null || e.target.value === '') {
              setInputQty((qty || limits.quantityDefault || 1).toString());
            }
          }}
          onChange={(e) => {
            hanleChangeInputQuantity(e.target.value);
          }}
          // value={qty}
          value={inputQty}
        />
        <QuantityInputIconButton
          aria-label='Zvýšit množství'
          disabled={disableIncrement}
          onClick={() => handleChangeQtyByStep(qty, 1)}
          type='button'
        >
          +
        </QuantityInputIconButton>
      </div>
      {wasRounded && (
        <div>
          <FormattedMessage
            defaultMessage='Nakupované množství bylo změněno dle nastavení produktu.'
            description='Label of qunatity rounding.'
            id='3Lj+pA'
          />
        </div>
      )}
    </div>
  );
};

QuantityInput.displayName = 'QuantityInput';

export default QuantityInput;
