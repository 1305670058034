import type { RecoilValue } from 'recoil';
import { useRecoilValueLoadable } from 'recoil';

export function useRecoilAsyncValue<T>(recoilValue: RecoilValue<T>): T {
  const lodable = useRecoilValueLoadable(recoilValue);
  if (lodable.state !== 'hasValue') {
    throw lodable.contents;
  }
  return lodable.contents;
}
