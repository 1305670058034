import { useInitialData } from './useInitialData';

/** @deprecated REMOVEME */
export function useInitialDataFromGlobal<T>(
  key: string,
  loadData?: () => T | Promise<T>,
): [loadingState: 'loading' | 'hasValue' | 'hasError', data: T | undefined] {
  return useInitialData(
    () => (((typeof window !== 'undefined' ? window : global) as any)['initialData'] || {})[key],
    () => {
      const initialData = ((typeof window !== 'undefined' ? window : global) as any)['initialData'];
      if (typeof (global as any)['initialData'] === 'object' && initialData) {
        initialData[key] = undefined;
      }
    },
    loadData,
  );
}
