import clsx from 'clsx';
import type { FC, HTMLAttributes, ReactNode } from 'react';

export type MainContentProps = HTMLAttributes<HTMLDivElement> & { children: ReactNode };

const MainContent: FC<MainContentProps> = ({ children, id = 'main-content', ...props }) => (
  <main {...props} className={clsx('main-content', 'flex-1', props.className)} id={id}>
    <div className='main-content-wrapper'>{children}</div>
  </main>
);

MainContent.displayName = 'MainContent';

export default MainContent;
