import { authUserState } from '@/client/atoms';
import { mdiClose } from '@/client/icons';
import { MsgErrorInvalidEmail, MsgErrorRequired } from '@/client/localized-messages';
import { EMAIL_ADDRESS_PATTERN } from '@/constants';
import { Dialog, Transition } from '@headlessui/react';
import { Icon } from '@mdi/react';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import type { FormData } from './CartShareHandler';

// *****************************************
// *
// * CartShareActionDialog
// *
// *****************************************

export default function CartShareActionDialog({
  isOpen,
  onSubmit,
  setIsOpen,
}: {
  isOpen: boolean;
  onSubmit: (data: FormData) => Promise<any>;
  setIsOpen: (v: boolean) => void;
}) {
  const authUser = useRecoilValue(authUserState);

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      customerEmail: authUser?.email,
      customerName: authUser?.name,
      message: '',
    },
  });

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog as='div' className='relative z-[51]' onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='flex max-h-[100vh] w-full max-w-md transform flex-col bg-footer p-6 text-left align-middle shadow-xl transition-all'>
                <Dialog.Title className={'mb-4 flex text-2xl font-medium'}>
                  <span className='flex-1'>Sdílet košík</span>
                  <button
                    className='iconbutton-default-text-large'
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    type='button'
                  >
                    <Icon path={mdiClose} size={'1.5rem'} />
                  </button>
                </Dialog.Title>

                <form
                  className='form-styles'
                  onSubmit={handleSubmit(async (data) => {
                    await onSubmit(data);
                    reset();
                  })}
                >
                  <div>
                    <label htmlFor='customerName'>
                      Vaše jméno<abbr>*</abbr>
                    </label>
                    <input
                      id='customerName'
                      type='text'
                      {...register('customerName', { required: true })}
                      aria-describedby={'ustomerName-helper-text'}
                      aria-errormessage={errors?.customerName && 'customerName-err-required'}
                      aria-invalid={errors?.customerName ? 'true' : 'false'}
                    />
                    {!errors?.customerName && (
                      <div id={'customerName-helper-text'} role='tooltip'>
                        {'Prosím zadejte zadejte své jméno a příjmení.'}
                      </div>
                    )}
                    {errors.customerName != null && (
                      <div id='customerName-err-required' role='alert'>
                        <MsgErrorRequired />
                      </div>
                    )}
                  </div>
                  <div className='mt-4'>
                    <label htmlFor='email-from'>
                      Váš e-mail<abbr>*</abbr>
                    </label>
                    <input
                      id='email-from'
                      type='text'
                      {...register('customerEmail', { pattern: EMAIL_ADDRESS_PATTERN, required: true })}
                      aria-errormessage={errors?.to && 'email-form-err'}
                      aria-invalid={errors?.to ? 'true' : 'false'}
                    />
                    {errors.customerEmail != null && (
                      <div id='email-form-err' role='alert'>
                        {errors.customerEmail.type === 'pattern' && <MsgErrorInvalidEmail />}
                        {errors.customerEmail.type === 'required' && <MsgErrorRequired />}
                      </div>
                    )}
                  </div>
                  <div className='mt-4'>
                    <label htmlFor='email-to'>
                      E-mail příjemce<abbr>*</abbr>
                    </label>
                    <input
                      id='email-to'
                      type='text'
                      {...register('to', { pattern: EMAIL_ADDRESS_PATTERN, required: true })}
                      aria-errormessage={errors?.to && 'email-to-err'}
                      aria-invalid={errors?.to ? 'true' : 'false'}
                    />
                    {errors.to != null && (
                      <div id='email-to-err' role='alert'>
                        {errors.to.type === 'pattern' && <MsgErrorInvalidEmail />}
                        {errors.to.type === 'required' && <MsgErrorRequired />}
                      </div>
                    )}
                  </div>
                  <div className='mt-4'>
                    <label htmlFor='message'>Vzkaz pro příjemce</label>
                    <textarea id='message' {...register('message')} rows={3} />
                  </div>
                  <div className='my-4'>
                    <button className='btn-secondary bg-secondary-500' type='submit'>
                      Odeslat
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
