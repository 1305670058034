import { productComparisonProducts } from '@/client/atoms';
import { useRouteUrl } from '@/client/hooks';
import { mdiCompareHorizontal } from '@/client/icons';
import Icon from '@mdi/react';
import type { HTMLAttributes, RefAttributes } from 'react';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

export type AppCustomProductComparisonWidgetProps = HTMLAttributes<HTMLDivElement> & RefAttributes<HTMLDivElement>;

const AppCustomProductComparisonWidget = forwardRef<HTMLDivElement, AppCustomProductComparisonWidgetProps>(
  (props, ref) => {
    const routeUrl = useRouteUrl();
    const comparedProducts = useRecoilValue(productComparisonProducts);
    const showWidget = comparedProducts.length > 0;

    return showWidget ? (
      <div
        ref={ref}
        className='max-md:min-content fixed bottom-0 left-1/2 z-[8888] mx-auto flex -translate-x-2/4 items-center  justify-between rounded-none border-none bg-[#0174A9] p-[10px_22px] font-semibold text-white print:hidden max-sm:max-w-[60%] md:w-[395px]'
      >
        <span className='mb-0 mr-3 overflow-hidden text-ellipsis whitespace-nowrap p-0 text-base font-normal'>
          {comparedProducts.length}{' '}
          {comparedProducts.length === 1
            ? 'produkt'
            : comparedProducts.length > 1 && comparedProducts.length < 5
            ? 'produkty'
            : 'produktů'}{' '}
          <FormattedMessage defaultMessage='k porovnání' description='To compare' id='hangNK' />
        </span>
        <RouterLink
          className='flex items-center gap-2 rounded border-none bg-orange py-2 text-base font-semibold text-white no-underline hover:bg-orange-600 active:bg-gray-400 active:text-gray-700 max-md:px-2 md:px-5'
          to={routeUrl('productComparisonPage', {})}
        >
          <Icon path={mdiCompareHorizontal} size='2rem' />
          <span className='max-md:hidden'>
            <FormattedMessage defaultMessage='Zobrazit' description='Display' id='B83eW+' />
          </span>
        </RouterLink>
      </div>
    ) : null;
  },
);

AppCustomProductComparisonWidget.displayName = 'AppCustomProductComparisonWidget';

export default AppCustomProductComparisonWidget;
