import slashStripTrailing from './server/utils/utils/slash-strip-trailing';

export const FALLBACK_LANGUAGE = 'cs';
// export const FALLBACK_REGION = 'CZ';

export const EMAIL_ADDRESS_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const ICO_PATTERN = /^\d{8,10}$/;
export const DIC_PATTERN = /^(CZ\d{8,10}|SK\d{10})$/;

/* SUPPORTED_COUNTRIES is available countries for delivery and invoice addresses,
first country in array is default */
export const SUPPORTED_COUNTRIES = ['CZ', 'SK'] as const;

export const STORED_CLOSED_STATUS_BARS = 'closedStatusBars';

export const WCT_CONFIG = {
  cmsCode: 'vybaveniprouklid-kentico',
  mediaPrefix: slashStripTrailing(process.env.RAZZLE_MEDIA_PREFIX),
};

export const DOMAIN = (
  {
    canary: 'https://vybaveniprouklid.stages.udolni.net',
    dev: 'http://localhost:5000',
    next: 'https://vybaveniprouklid.uats.udolni.net',
    stable: 'https://vybaveniprouklid.cz',
  } as const
)[process.env.RAZZLE_SOLUTION_ENV || 'dev'];
