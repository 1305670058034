import type { AppConfiguration } from '@/context';
import debug from 'debug';
import type { FC, HTMLAttributes } from 'react';
import { useEffect, useRef } from 'react';
import { Container as IocContainer } from 'typescript-ioc';
import './luigi.css';

const dbg = debug('externals:luigis');

export type GlobalSearchIconButtonProps = HTMLAttributes<HTMLDivElement>;

const GlobalSearchIconButton: FC<GlobalSearchIconButtonProps> = ({ className, ...props }) => {
  // !! Switch between two different labels causes hydration issue. Single label used.
  // const isMobile = useWindowSizeIsMobile();
  // const placeholder = isMobile ? 'kód, značka, produkt.' : 'Hledejte kód, značku nebo produkt';
  const placeholder = 'kód, značka, produkt.';
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      searchRef.current != null &&
      typeof (window as any)['AutoComplete'] === 'function'
    ) {
      const { LUIGI_TRACKER_ID }: AppConfiguration = IocContainer.getValue('config') || {};
      dbg('GlobalSearchIconButton LUIGI_TRACKER_ID', LUIGI_TRACKER_ID);
      (window as any)['AutoComplete'](
        {
          Layout: 'hero',
          Locale: 'cz',
          NoResultsMessage: (query: string, numberOfResults: number) => {
            if (numberOfResults == 0) {
              return `Na frázi "${query}" jsme nic nenašli.`;
            } else {
              return `Pro frázi "${query}" jsme našli pouze jediný produkt.`;
            }
          },
          TrackerId: LUIGI_TRACKER_ID || '99903-110781',
          Types: [
            {
              name: 'Top fráze',
              size: 3,
              type: 'query',
            },
            {
              name: 'Top značky',
              size: 4,
              type: 'brand',
            },
            {
              name: 'Top kategorie',
              size: 4,
              type: 'category',
            },
            {
              name: 'Top články',
              size: 3,
              type: 'articles',
            },
            {
              name: 'Top produkty',
              recommend: {
                name: 'Top produkt',
              },
              size: 7,
              type: 'item',
            },
          ],
        },
        searchRef.current,
      );
    }
  }, [searchRef]);

  return (
    <>
      <form
        action='/vyhledavani'
        aria-label='Global search'
        className='w-full focus-within:static'
        method='GET'
        noValidate
        // onSubmit={(e) => {
        //   e.preventDefault();
        //   if (searchRef.current != null && searchRef.current.value.length > 0) {
        //     history.push(`/vyhledavani?q=${encodeURIComponent(searchRef.current.value)}`);
        //   }
        // }}
      >
        <div className={'flex justify-center'} {...props}>
          <div className='relative mx-auto w-full min-w-[290px] max-w-full text-gray-600'>
            <input
              ref={searchRef}
              className='h-[35px] w-full rounded-md border-2 border-gray-300 bg-white py-0.5 px-1 pl-6 pr-4 text-sm lg:h-[55px]'
              id='autocomplete'
              name='q'
              placeholder={placeholder}
            />
            <svg
              className='absolute left-[10px] top-[13px] h-3 w-3 fill-current text-gray-600 lg:top-[22px]'
              id='Capa_1'
              version='1.1'
              viewBox='0 0 56.966 56.966'
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              xmlSpace='preserve'
            >
              <path d='M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z' />
            </svg>
            <button
              className='absolute right-0 top-0 h-[35px] rounded-md bg-orange px-[20px] text-white lg:h-[55px] lg:px-[35px]'
              type='submit'
            >
              Hledat
            </button>
          </div>
        </div>
      </form>
      <script async src='https://cdn.luigisbox.com/autocomplete.js'></script>
    </>
  );
};

GlobalSearchIconButton.displayName = 'GlobalSearchIconButton';
export default GlobalSearchIconButton;
