import { useRouteUrl, useSetRecoilStateEventHandler, useTaxonomyDataPreviewDirect } from '@/client/hooks';
import trpc from '@/client/lib/trpc';
import type { NavItemDisplay, TaxonomyMeta } from '@/types';
import {
  isNavItemDisplayAction,
  isNavItemDisplayLocalLink,
  isNavItemDisplayRemoteLink,
  isNavItemDisplayStaticLabel,
  isNavItemDisplayTaxonomy,
} from '@/types';
import type { FC, MouseEvent, ReactNode, RefAttributes } from 'react';
import { forwardRef } from 'react';
import invariant from 'tiny-invariant';
import { appCustomLayoutState } from '../../atoms/appCustomLayoutState';
import AppCustomHeaderNavBarLinkItemComponent from './AppCustomHeaderNavBarLinkItemComponent';

// NavItemProps

// define props except element props :)
export type AppCustomHeaderNavBarLinkItemProps = RefAttributes<any> & {
  className?: string;
  navbarItem: NavItemDisplay;
  onActionClick?: (action: string, event: MouseEvent<any>) => void;
};

/**
 * @component
 */

const AppCustomHeaderNavBarLinkItem = forwardRef(
  (
    { className, navbarItem, onActionClick: handleActionClick, ...rest }: AppCustomHeaderNavBarLinkItemProps,
    ref: any,
  ) => {
    const handleSetMobileOpen = useSetRecoilStateEventHandler(
      appCustomLayoutState,
      (v: boolean, { isMobileMenuOpen, ...o }) => ({ isMobileMenuOpen: v, ...o }),
    );

    if (isNavItemDisplayAction(navbarItem)) {
      const { action: itemAction, label: itemLabel } = navbarItem;

      return (
        <AppCustomHeaderNavBarLinkItemComponent
          ref={ref}
          as='button'
          className={className}
          onClick={(e: MouseEvent) => {
            handleSetMobileOpen(false);
            e.preventDefault();
            if (handleActionClick) {
              handleActionClick(itemAction, e);
            }
          }}
          {...rest}
        >
          {itemLabel}
        </AppCustomHeaderNavBarLinkItemComponent>
      );
    } else if (isNavItemDisplayRemoteLink(navbarItem)) {
      const { href: itemHref, label: itemLabel, target } = navbarItem;
      return (
        <AppCustomHeaderNavBarLinkItemComponent ref={ref} as='a' className={className} href={itemHref} target={target}>
          {itemLabel}
        </AppCustomHeaderNavBarLinkItemComponent>
      );
    } else if (isNavItemDisplayLocalLink(navbarItem)) {
      const { label: itemLabel, to: itemTo } = navbarItem;

      return (
        <AppCustomHeaderNavBarLinkItemComponent
          ref={ref}
          className={className}
          onClick={handleSetMobileOpen(false)}
          to={itemTo}
        >
          {itemLabel}
        </AppCustomHeaderNavBarLinkItemComponent>
      );
    } else if (isNavItemDisplayTaxonomy(navbarItem)) {
      return <TaxonomyCatalogueBreadcrumbsItemTaxonomy className={className} taxonomy={navbarItem} />;
    } else if (isNavItemDisplayStaticLabel(navbarItem)) {
      const { label: itemLabel } = navbarItem;

      return (
        <AppCustomHeaderNavBarLinkItemComponent ref={ref} as='span' className={className}>
          {itemLabel}
        </AppCustomHeaderNavBarLinkItemComponent>
      );
    }

    invariant(
      false,
      'AppCustomHeaderNavBarLinkItem: item must have either action, href or to. Note: Taxonomy navitems not supported.',
    );
  },
);

AppCustomHeaderNavBarLinkItem.displayName = 'AppCustomHeaderNavBarLinkItem';

export default AppCustomHeaderNavBarLinkItem;

export type TaxonomyCatalogueBreadcrumbsItemTaxonomyProps = {
  className?: string;
  disableLink?: boolean;
  taxonomy: TaxonomyMeta;
};

const TaxonomyCatalogueBreadcrumbsItemTaxonomy: FC<TaxonomyCatalogueBreadcrumbsItemTaxonomyProps> = ({
  className,
  disableLink,
  taxonomy: pTx,
}) => {
  const trpcUtils = trpc.useContext();
  const routeUrl = useRouteUrl();
  const taxonomy = useTaxonomyDataPreviewDirect(pTx.hash);
  const handleSetMobileOpen = useSetRecoilStateEventHandler(
    appCustomLayoutState,
    (v: boolean, { isMobileMenuOpen, ...o }) => ({ isMobileMenuOpen: v, ...o }),
  );

  if (taxonomy == null) return null;

  const to = routeUrl('catalogue', {
    hash: taxonomy.hash,
    taxonomyName: taxonomy.name ?? 'na',
  });
  const label: ReactNode = taxonomy.name?.toUpperCase() ?? 'N/A';

  return disableLink ? (
    <AppCustomHeaderNavBarLinkItemComponent as='span' className={className}>
      {label}
    </AppCustomHeaderNavBarLinkItemComponent>
  ) : (
    <AppCustomHeaderNavBarLinkItemComponent
      className={className}
      onClick={handleSetMobileOpen(false)}
      onMouseOver={() => {
        trpcUtils.taxonomies.detail.byHash.prefetch({ hash: taxonomy.hash }, { staleTime: 2 * 60 * 60 * 1000 });
      }}
      to={to}
    >
      {label}
    </AppCustomHeaderNavBarLinkItemComponent>
  );
};

TaxonomyCatalogueBreadcrumbsItemTaxonomy.displayName = 'TaxonomyCatalogueBreadcrumbsItemTaxonomy';
