import type { ProductHeurekaReviews, TaxonomyPreview } from '@/types';
import {
  TaxonomyDataStateLevel,
  type AppCommonData,
  type NavItemDisplay,
  type NavItemDisplayTaxonomy,
  type TaxonomyDataState,
} from '@/types';

declare global {
  // eslint-disable-next-line no-var
  var __filterEnumValuesTranslation__: Record<string, string>;
  // eslint-disable-next-line no-var
  var __taxonomiesStates__: TaxonomyDataState[];
  // eslint-disable-next-line no-var
  var heurekaProductsReviews: ProductHeurekaReviews;

  interface Window {
    __filterEnumValuesTranslation__: Record<string, string>;
    __taxonomiesStates__: TaxonomyDataState[];
    heurekaProductsReviews: ProductHeurekaReviews;
  }
}

function extractTaxonomyStates(
  from: AppCommonData['navigations']['categories'],
  updater: (r: TaxonomyDataState[]) => void,
): void {
  const s: TaxonomyDataState[] = [
    // horizontal navigation taxonomies
    {
      data: {
        children: undefined,
        code: '',
        hasChildren: false,
        hash: '1EhGcw',
        name: 'VÝPRODEJ',
      },
      level: TaxonomyDataStateLevel.preview,
    },
    {
      data: {
        children: undefined,
        code: '',
        hasChildren: false,
        hash: '1EhFFW',
        name: 'NOVINKY',
      },
      level: TaxonomyDataStateLevel.preview,
    },
    {
      data: {
        children: undefined,
        code: '',
        hasChildren: false,
        hash: '1EhFFV',
        name: 'AKCE',
      },
      level: TaxonomyDataStateLevel.preview,
    },
    {
      data: {
        children: undefined,
        code: '',
        hasChildren: false,
        hash: '1EhFF0',
        name: 'BAZAR',
      },
      level: TaxonomyDataStateLevel.preview,
    },
  ];
  const toState = (i: NavItemDisplayTaxonomy): TaxonomyDataState => {
    const data: TaxonomyPreview = {
      children: i.items as { code: string; hash: string }[] | undefined,
      code: i.code,
      hasChildren: i.isExpandable,
      hash: i.hash,
      image: i.image ?? undefined,
      name: i.name,
    };
    return { data, level: TaxonomyDataStateLevel.preview };
  };
  function tr(arr: AppCommonData['navigations']['categories']): void {
    if (arr == null) return;
    const isTaxonomy = (i: NavItemDisplay): i is NavItemDisplayTaxonomy => typeof (i as any).hash === 'string';
    for (const item of arr) {
      if (isTaxonomy(item)) {
        s.push(toState(item));
        if (Array.isArray(item.items)) {
          tr(item.items);
        }
      }
    }
  }
  tr(from);
  updater(s);
}

export function getTaxonomyStateExtStore() {
  if (typeof window !== 'undefined') {
    if (window.__taxonomiesStates__ == null) {
      extractTaxonomyStates(window.commonData.navigations.categories, (r) => {
        window.__taxonomiesStates__ = r;
      });
    }
    return window.__taxonomiesStates__;
  } else {
    if (global.__taxonomiesStates__ == null) {
      extractTaxonomyStates(global.commonData.navigations.categories, (r) => {
        global.__taxonomiesStates__ = r;
      });
    }
    return global.__taxonomiesStates__;
  }
}

export function setFilterEnumValuesTranslationExtStore(items: { displayValue: string; value: string }[]) {
  if (typeof window !== 'undefined') {
    window.__filterEnumValuesTranslation__ = {
      ...(window.__filterEnumValuesTranslation__ ?? {}),
      ...items.reduce<Record<string, string>>((res, i) => ({ ...res, [i.value]: i.displayValue }), {}),
    };
  } else {
    global.__filterEnumValuesTranslation__ = {
      ...(global.__filterEnumValuesTranslation__ ?? {}),
      ...items.reduce<Record<string, string>>((res, i) => ({ ...res, [i.value]: i.displayValue }), {}),
    };
  }
}

export function getFilterEnumValuesTranslationExtStore() {
  if (typeof window !== 'undefined') {
    return window.__filterEnumValuesTranslation__ ?? {};
  } else {
    return global.__filterEnumValuesTranslation__ ?? {};
  }
}

export function getHeurekaProductsReviews() {
  if (typeof window !== 'undefined') {
    if (window.heurekaProductsReviews == null) {
      window.heurekaProductsReviews = global.heurekaProductsReviews;
    }
    return window.heurekaProductsReviews;
  } else {
    return global.heurekaProductsReviews;
  }
}

export function globalizeData() {
  getFilterEnumValuesTranslationExtStore();
  getTaxonomyStateExtStore();
  getHeurekaProductsReviews();
}
