import { methodIsAllowed } from '@/helpers';
import type {
  CheckoutMethodOutput,
  CheckoutMethodPayment,
  CheckoutMethodShipping,
  CheckoutMethodState,
  ContactBase,
} from '@/types';
import { CheckoutPaymentType } from '@/types';
import { useMemo } from 'react';
import trpc, { type TrpcRouterInputs, type TrpcRouterOutputs } from '../lib/trpc';

export function useCartUpdateMethods(props: {
  cartSelectedMethods: TrpcRouterOutputs['cart']['methods'];
  cartStats: TrpcRouterOutputs['cart']['stats'];
  cartSubjectSkus: string[];
  isAuth: boolean;
  shippingPaymentMap: TrpcRouterOutputs['methods']['shippingPaymentMap'];
  totalAmount: number;
}) {
  const trpcCtx = trpc.useContext();
  const cartSelectedMethodsMutation = trpc.cart.update.methods.useMutation();

  return useMemo(() => {
    const callUpdateMethods = async (
      input: Partial<{
        payment: CheckoutMethodPayment | null;
        shipping: CheckoutMethodShipping | null;
      }>,
    ) => {
      const toOrderOutput: CheckoutMethodOutput[] = [];
      const newShipping = input.shipping === undefined ? props.cartSelectedMethods.shipping : input.shipping;
      const newPayment = input.payment;

      const matchedItems = props.shippingPaymentMap.filter(
        (x) =>
          x.paymentMethodSku == newPayment?.sku &&
          x.shippingMethodSku == newShipping?.sku &&
          methodIsAllowed(x, {
            cartStats: props.cartStats,
            cartSubjectSkus: props.cartSubjectSkus,
            isAuth: props.isAuth,
            totalAmount: props.totalAmount,
          }),
      );
      const minimalMatch = matchedItems.sort((a, b) => a.price - b.price).at(0);

      // Create order output.
      // In our case there will single row
      if (minimalMatch) {
        toOrderOutput.push({
          isShippingPayment: true,
          price: minimalMatch.price,
          priceWithTax: minimalMatch.priceWithTax,
          quantity: 1,
          sku: `${minimalMatch.shippingMethodSku}-${minimalMatch.paymentMethodSku}`,
          text: 'Doprava a platba',
          unit: 'pcs',
          vatAmount: minimalMatch.tax ? minimalMatch.tax / 100 : newShipping?.vatAmount ?? newPayment?.vatAmount ?? 0,
          vatCode: minimalMatch.tax
            ? (minimalMatch.tax / 100).toFixed(2).replace(',', '.')
            : newShipping?.vatCode ?? newPayment?.vatCode ?? undefined,
        });
      }

      try {
        await cartSelectedMethodsMutation.mutateAsync({
          methodsOutput: toOrderOutput.map((i) => ({
            price:
              i.price == null || i.priceWithTax == null || i.vatAmount == null
                ? null
                : {
                    amount: i.price,
                    amountWithTax: i.priceWithTax,
                    vatAmount: i.vatAmount,
                    vatCode: i.vatCode ?? null,
                  },
            quantity: i.quantity,
            sku: i.sku,
            text: i.text ?? '',
            unit: i.unit,
          })),
          payment:
            newPayment == null
              ? null
              : {
                  cartTotalSettlementOfPettyCash: newPayment.cartTotalSettlementOfPettyCash,
                  description: newPayment.description,
                  disabled: newPayment.disabled,
                  image: newPayment.image,
                  name: newPayment.name,
                  paymentGateway: newPayment.paymentGateway,
                  paymentType: convertPaymentType(newPayment.paymentType),
                  price:
                    newPayment.price == null || newPayment.priceWithTax == null || newPayment.vatAmount == null
                      ? null
                      : {
                          amount: newPayment.price,
                          amountWithTax: newPayment.priceWithTax,
                          vatAmount: newPayment.vatAmount,
                          vatCode: newPayment.vatCode ?? null,
                        },
                  sku: newPayment.sku,
                  unit: newPayment.unit,
                },
          shipping:
            newShipping == null
              ? null
              : {
                  description: newShipping.description,
                  disabled: newShipping.disabled,
                  image: newShipping.image,
                  name: newShipping.name,
                  parcelService: newShipping.parcelService,
                  personalPickup: convertPP(newShipping.personalPickup as any),
                  price:
                    newShipping.price == null || newShipping.priceWithTax == null || newShipping.vatAmount == null
                      ? null
                      : {
                          amount: newShipping.price,
                          amountWithTax: newShipping.priceWithTax,
                          vatAmount: newShipping.vatAmount,
                          vatCode: newShipping.vatCode ?? null,
                        },
                  sku: newShipping.sku,
                  unit: newShipping.unit,
                },
        });

        trpcCtx.cart.invalidate();
        trpcCtx.checkout.checkoutData.invalidate();
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Updame cart methods error', err);
        }
      }
    };

    return {
      async payment(method: CheckoutMethodPayment | null) {
        await callUpdateMethods({ payment: method });
      },
      async shipping(method: CheckoutMethodShipping | null) {
        await callUpdateMethods({
          // Reset payment if shipping selection has changed.
          payment: null,
          shipping: method,
        });
      },
    };
  }, [
    cartSelectedMethodsMutation,
    props.cartSelectedMethods.shipping,
    props.cartStats,
    props.cartSubjectSkus,
    props.isAuth,
    props.shippingPaymentMap,
    props.totalAmount,
    trpcCtx.cart,
    trpcCtx.checkout.checkoutData,
  ]);
}

function convertPP(
  pp: NonNullable<CheckoutMethodState['shipping']>['personalPickup'],
): NonNullable<TrpcRouterInputs['cart']['update']['methods']['shipping']>['personalPickup'] {
  if (pp == null || pp === false) return null;
  if (pp === true) return [];
  type C = NonNullable<
    NonNullable<TrpcRouterInputs['cart']['update']['methods']['shipping']>['personalPickup']
  >[number];
  const s = ({ contactType, ...v }: ContactBase): C => ({ ...v, contactType: contactType });
  if (Array.isArray(pp)) return pp.map(s);
  return [s(pp)];
}

function convertPaymentType(pt: CheckoutPaymentType): 'cashOnDelivery' | 'inAdvance' | 'online' {
  if (pt === CheckoutPaymentType.online) return 'online';
  if (pt === CheckoutPaymentType.inAdvance) return 'inAdvance';
  return 'cashOnDelivery';
}
