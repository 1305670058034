import trpc from '@/client/lib/trpc';
import { defineMessage, useIntl } from 'react-intl';

const weekdayNames = [
  defineMessage({
    defaultMessage: 'V neděli',
    description: 'On sunday',
    id: 'y1nJVi',
  }),
  defineMessage({
    defaultMessage: 'V pondělí',
    description: 'On monday',
    id: '47q4UH',
  }),
  defineMessage({
    defaultMessage: 'V úterý',
    description: 'On tuesday',
    id: 'IIRhiK',
  }),
  defineMessage({
    defaultMessage: 'Ve středu',
    description: 'On wednesday',
    id: 'bOFrfv',
  }),
  defineMessage({
    defaultMessage: 'Ve čtvrtek',
    description: 'On thursday',
    id: 'DUGfMz',
  }),
  defineMessage({
    defaultMessage: 'V pátek',
    description: 'On friday',
    id: '8ZU8LQ',
  }),
  defineMessage({
    defaultMessage: 'V sobotu',
    description: 'On saturday',
    id: 'rRJmpk',
  }),
];

export default function ProductAvailability() {
  const intl = useIntl();

  const contentSettings = trpc.contentSettings.useQuery();
  const holidaysConfig = trpc.holidaysConfig.useQuery();

  const m = contentSettings.data?.nextDayTime?.match(/(\d+):(\d+)/);
  const limit = m ? parseInt(m[1]) * 60 + parseInt(m[2]) : 15 * 60;
  const now = new Date();
  const isAfterLimit = now.getHours() * 60 + now.getMinutes() >= limit;
  const deliveryDate = isAfterLimit
    ? holidaysConfig.data?.nextBussinessDay2
      ? new Date(holidaysConfig.data.nextBussinessDay2)
      : addDays(now, 2)
    : holidaysConfig.data?.nextBussinessDay1
    ? new Date(holidaysConfig.data.nextBussinessDay1)
    : addDays(now, 1);

  return (
    <>
      <span>
        {intl.formatMessage(weekdayNames[deliveryDate.getDay()])}{' '}
        {intl.formatDate(deliveryDate, { day: 'numeric', month: 'numeric' })}
      </span>
      <span className='ml-1'>
        {intl.formatMessage({
          defaultMessage: 'u Vás',
          description: 'Delivery date suffix',
          id: '7t6eRK',
        })}
      </span>
    </>
  );
}

function addDays(date: Date, days: number): Date {
  const someDate = new Date(date);
  someDate.setTime(someDate.getTime() + days * 24 * 60 * 60 * 1000);
  return someDate;
}
