import type { MethodsResourceParams } from '@/resources';
import { CheckoutResource } from '@/resources';
import type {
  CheckoutCreatePurchaseRequest,
  CheckoutCreatePurchaseResult,
  CheckoutMethodState,
  ShippingPaymentMapItem,
  UpdateSubject,
  UpdateSubjectIndirect,
} from '@/types';
import { CheckoutCreatePurchaseResultStatusType } from '@/types';
import type { AvailablePaymentMethod } from '@/types/checkout/AvailablePaymentMethod';
import type { AvailableShippingMethod } from '@/types/checkout/AvailableShippingMethod';
import type { EcomCartInfo, EcomCartSubjectItem, EcomCartUpdateSubjectResult } from '@sprinx/knihovna-api-types';
import type { AxiosResponse } from 'axios';
import axios from 'axios';

export class ClientCheckoutResource extends CheckoutResource {
  /** Updates settings of checkout after user login. */
  async afterLogin(): Promise<void> {
    throw new Error('Not implemented');
  }

  /** Returns shopping cart content. */
  async cartContent(checkoutId: string): Promise<EcomCartSubjectItem[] | null> {
    const url = `/api/checkout/${encodeURIComponent(checkoutId)}/cart`;

    const d = await axios
      .get<EcomCartSubjectItem[] | null>(url)
      .then((r) => r.data)
      .catch(() => {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Error while fetching cart content');
        }

        return null;
      });

    return d;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async cartReset(checkoutId: string): Promise<EcomCartInfo | null> {
    const url = `/api/checkout/${encodeURIComponent(checkoutId)}/cart/reset`;
    return axios
      .post<any>(url, {})
      .then((result) => result.data || null)
      .catch(() => {
        return null;
      });
  }

  /** Updates shopping cart content with batch of subjects. */
  async cartUpdateMethod(
    checkoutId: string,
    updateData: CheckoutMethodState,
  ): Promise<EcomCartUpdateSubjectResult | null> {
    const url = `/api/checkout/${encodeURIComponent(checkoutId)}/cart/update-methods`;

    const result = await axios
      .post<EcomCartUpdateSubjectResult | null>(url, updateData)
      .then((result) => result.data || null)
      .catch(() => {
        return null;
      });

    if (result !== null) {
      this.emitCartInfoUpdated(result.cartInfo);
    }

    return result;
  }

  /** Updates shopping cart content. */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async cartUpdateSubject(
    checkoutId: string,
    updateSubject: UpdateSubject,
  ): Promise<EcomCartUpdateSubjectResult | null> {
    const url = `/api/checkout/${encodeURIComponent(checkoutId)}/cart/update-subject`;

    return axios
      .post<any>(url, updateSubject)
      .then((result) => result.data || null)
      .catch(() => {
        return null;
      });
  }

  /** Updates shopping cart content with batch of subjects. */
  async cartUpdateSubjectBatch(
    checkoutId: string,
    updateSubject: UpdateSubject[],
  ): Promise<EcomCartUpdateSubjectResult | null> {
    const url = `/api/checkout/${encodeURIComponent(checkoutId)}/cart/update-subject-batch`;

    return axios
      .post<any>(url, updateSubject)
      .then((result) => result.data || null)
      .catch(() => {
        return null;
      });
  }

  /** Updates shopping cart indirect subject. */
  async cartUpdateSubjectIndirect(
    checkoutId: string,
    updateSubject: UpdateSubjectIndirect,
  ): Promise<EcomCartUpdateSubjectResult | null> {
    const url = `/api/checkout/${encodeURIComponent(checkoutId)}/cart/update-subject-indirect`;

    return axios
      .post<any>(url, updateSubject)
      .then((result) => result.data || null)
      .catch(() => {
        return null;
      });
  }

  /** Updates settings of checkout */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async changeSettings(checkoutId: string, nextSettings: Record<string, any>): Promise<void> {
    throw new Error('Not implemented');
  }

  /** Applyes an coupon. */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async couponApply(checkoutId: string, couponCode: string): Promise<void> {
    throw new Error('Not implemented');
  }

  /** Removes an coupon. */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async couponRemove(checkoutId: string, couponCode: string): Promise<void> {
    throw new Error('Not implemented');
  }

  async createPurchase(
    checkoutId: string,
    params: CheckoutCreatePurchaseRequest,
  ): Promise<CheckoutCreatePurchaseResult> {
    return axios
      .post<CheckoutCreatePurchaseResult, AxiosResponse<CheckoutCreatePurchaseResult>, CheckoutCreatePurchaseRequest>(
        `/api/checkout/${encodeURIComponent(checkoutId)}/create-purchase`,
        params,
      )
      .then((result) => result.data)
      .catch(() => {
        return { status: CheckoutCreatePurchaseResultStatusType.error };
      });
  }

  /** Returns list of available payment methods. */
  async methodsPayment(checkoutId: string, params: MethodsResourceParams): Promise<AvailablePaymentMethod[]> {
    // const { cartId, ...query } = params;
    const url = `/api/checkout/${checkoutId}/payment-methods`;

    const result = await axios
      .get<any[]>(url, {
        params,
      })
      .then((r) => r.data);

    return result;
  }

  /** Returns list of available shipping methods. */
  async methodsShipping(checkoutId: string, params: MethodsResourceParams): Promise<AvailableShippingMethod[]> {
    // const { cartId, ...query } = params;
    const url = `/api/checkout/${checkoutId}/shipping-methods`;

    const result = await axios
      .get<any[]>(url, {
        params,
      })
      .then((r) => r.data);

    return result;
  }

  /** Returns list of available shipping-payment configuration. */
  async shippingPaymentMap(checkoutId: string, params: MethodsResourceParams): Promise<ShippingPaymentMapItem[]> {
    const url = `/api/checkout/${checkoutId}/shipping-payment-map`;

    const result = await axios
      .get<any[]>(url, {
        params,
      })
      .then((r) => r.data);

    return result;
  }
}
