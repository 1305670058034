import { useMemo } from 'react';

export function useShoppingCartProductIsAllowed(product: {
  onSale?: boolean;
  price: number;
  priceWithTax: number;
  soldOut?: boolean;
  taxonomies?: string[];
}): boolean {
  return useMemo<boolean>(() => {
    return (
      (product.taxonomies ?? []).reduce((r, tx) => {
        if (tx.startsWith('/virtual/')) return false;
        return r;
      }, true) &&
      product.price > 0 &&
      product.priceWithTax > 0 &&
      (product.onSale ?? false) &&
      !product.soldOut
    );
  }, [product]);
}
