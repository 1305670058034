import { CatalogueResource } from '@/resources';
import type {
  CatalogueCategory,
  CatalogueSettingsForFilterOptionsParameter,
  CatalogueSettingsForResource,
  CatalogueSettingsForResourceWithLimitation,
  TaxonomyMeta,
} from '@/types';
import type {
  EcomCatalogueFilterOptionsNumberRange,
  EcomCatalogueFilterOptionsParameterValueDisplay,
  EcomCatalogueItem,
  PricesType,
  SupportedLocale,
  SupportedRegion,
} from '@sprinx/knihovna-api-types';
import axios from 'axios';

export class ClientCatalogueResource extends CatalogueResource {
  async getCatalogue(params: CatalogueSettingsForResourceWithLimitation): Promise<EcomCatalogueItem[] | null> {
    return axios.post<EcomCatalogueItem[]>('/api/catalogue', params).then((result) => result.data);
  }

  async getCatalogueCategory(params: {
    hash: string;
    locale: SupportedLocale;
    region: SupportedRegion;
  }): Promise<CatalogueCategory | null> {
    const result = await axios
      .get<TaxonomyMeta[] | null>('/api/taxonomies/me-and-ancestors', {
        params,
      })
      .then((r) => r.data);

    return result ? { taxonomyPath: result } : null;
  }

  async getFilterPriceRange(
    params: CatalogueSettingsForResource & { forceCheckoutPricesType?: PricesType },
  ): Promise<EcomCatalogueFilterOptionsNumberRange | null> {
    return axios
      .post<EcomCatalogueFilterOptionsNumberRange>('/api/catalogue/filter-options/price-range', params)
      .then((result) => result.data);
  }

  async getFilterParameterRange(
    params: CatalogueSettingsForResource & { parameterCode: string },
  ): Promise<EcomCatalogueFilterOptionsNumberRange | null> {
    return axios
      .post<EcomCatalogueFilterOptionsNumberRange>('/api/catalogue/filter-options/parameter-range', params)
      .then((result) => result.data);
  }

  async getFilterOptionsParameter(
    params: CatalogueSettingsForFilterOptionsParameter,
  ): Promise<EcomCatalogueFilterOptionsParameterValueDisplay[] | null> {
    return axios
      .post<EcomCatalogueFilterOptionsParameterValueDisplay[]>('/api/catalogue/filter-options/parameter', params)
      .then((result) => result.data);
  }
}
