import type { useCheckoutUserExistingCartMakeDecision_decisionMutation } from '@/graphql/__generated__/useCheckoutUserExistingCartMakeDecision_decisionMutation.graphql';
import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';
import { usePromisifyCommitMutation } from './usePromisifyCommitMutation';

export type UseCheckoutUserExistingCartMakeDecisionOptions = {
  action: 'brandNew' | 'keepCurrent' | 'takeNext' | 'mergeWithNext';
  checkoutId: string;
  nextCartId?: string;
};

export function useCheckoutUserExistingCartMakeDecision() {
  const [commitCheckoutUpdateContext] = useMutation<useCheckoutUserExistingCartMakeDecision_decisionMutation>(
    graphql`
      mutation useCheckoutUserExistingCartMakeDecision_decisionMutation(
        $checkoutId: UUID!
        $action: CheckoutAfterLoginDecisionAction!
        $nextCartId: ID
        $locale: String!
      ) {
        checkout_context_afterlogin_decision(
          checkoutId: $checkoutId
          action: $action
          nextCartId: $nextCartId
          locale: $locale
        ) {
          cart {
            ...cartState_cart
          }
          ...checkoutUserExistingCartsState_cart
        }
      }
    `,
  );

  const updateCheckoutContext = usePromisifyCommitMutation(
    commitCheckoutUpdateContext,
    (options: UseCheckoutUserExistingCartMakeDecisionOptions) => ({
      action: options.action,
      checkoutId: options.checkoutId,
      locale: 'cs',
      nextCartId: options.nextCartId,
    }),
    () => [null, null],
  );

  return useCallback(
    async (options: UseCheckoutUserExistingCartMakeDecisionOptions) => {
      await updateCheckoutContext(options);
    },
    [updateCheckoutContext],
  );
}
