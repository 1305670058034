import type { InputHTMLAttributes, RefAttributes } from 'react';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import type { FormatNumberOptions, IntlShape } from 'react-intl';
import invariant from 'tiny-invariant';
import { AsYouTypeNumber } from '.';

export type InputNumberProps = InputHTMLAttributes<HTMLInputElement> &
  RefAttributes<HTMLInputElement> & {
    formatOptions?: FormatNumberOptions;
    intl: IntlShape;
  };

const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  (
    {
      className,
      formatOptions,
      intl,
      name,
      onBlur: handleBlur,
      onChange: handleChange,
      onFocus: handleFocus,
      style,
      value,
      ...props
    },
    ref,
  ) => {
    invariant(typeof value === 'undefined' || typeof value === 'string' || typeof value === 'number');
    const ayt = useMemo<AsYouTypeNumber>(() => new AsYouTypeNumber(intl, formatOptions), [formatOptions, intl]);
    const [innerValue, setInnerValue] = useState<string>(ayt.input(value));

    useEffect(() => {
      setInnerValue(ayt.input(value));
    }, [ayt, value]);

    return (
      <input
        ref={(r) => {
          if (ref) {
            if (typeof ref === 'function') {
              ref(r);
            } else {
              ref.current = r;
            }
          }
          if (r?.value && innerValue !== r.value) {
            setInnerValue(ayt.input(r.value));
          }
        }}
        className={className}
        name={name}
        onBlur={(e) => {
          ayt.input(innerValue);
          ayt.makeStrict();
          setInnerValue(ayt.getChars());
          if (handleBlur)
            handleBlur({
              ...e,
              target: { ...e.target, name: name ?? '', value: ayt.getNumberDirty()?.toString() ?? '' },
            });
        }}
        onChange={(e) => {
          const v = e.target.value;
          ayt.input(v);
          setInnerValue(ayt.getChars());
          if (handleChange)
            handleChange({
              ...e,
              target: { ...e.target, name: name ?? '', value: ayt.getNumberDirty()?.toString() ?? '' },
            });
        }}
        onFocus={(e) => {
          if (handleFocus) handleFocus({ ...e, target: { ...e.target, name: name ?? '' } });
        }}
        style={style}
        type='text'
        {...props}
        value={innerValue}
      />
    );
  },
);

InputNumber.displayName = 'InputNumber';

export default InputNumber;
