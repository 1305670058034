import { ShoppingListsResource } from '@/resources';
import type { ShoppingListCreateValues } from '@/types';
import type {
  EcomShoppingListCreationResultError,
  EcomShoppingListCreationResultSuccess,
} from '@sprinx/knihovna-api-types';
import axios from 'axios';

export class ClientShoppingListsResource extends ShoppingListsResource {
  async create(
    checkoutId: string,
    userId: string,
    values: ShoppingListCreateValues,
  ): Promise<EcomShoppingListCreationResultSuccess | EcomShoppingListCreationResultError> {
    return axios
      .post<EcomShoppingListCreationResultSuccess | EcomShoppingListCreationResultError>('/api/shopping-lists', {
        checkoutId,
        name: values.name,
        userId,
      })
      .then((result) => result.data ?? { status: 'error' })
      .catch(() => {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ClientShoppingListsResource: Failed to create shopping list.');
        }
        return { status: 'error' };
      });
  }
}
