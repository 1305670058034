import { addUserDataLayer } from '@/client/lib/googleAnalytics';
import clsx from 'clsx';
import type { FC } from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuthHandlerContext } from '../AuthHandler';

export type LoginButtonProps = {
  className?: string;
};

const LoginButton: FC<LoginButtonProps> = ({ className }) => {
  const { handleLoginClick } = useAuthHandlerContext();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      addUserDataLayer(undefined);
    }
  }, []);

  return (
    <div>
      <button className={clsx('flex flex-col items-center', className)} onClick={handleLoginClick}>
        <img alt='user' className='mx-2 lg:mx-4' height='28' src='/images/user.webp' width='28' />
        <span className='hidden font-bold lg:flex'>
          <FormattedMessage defaultMessage='Registrace' description='Label of login or register action.' id='wAupBt' />
        </span>
        <span className='hidden lg:flex'>
          <FormattedMessage defaultMessage='Přihlášení' description='Label of login or register action.' id='lpltdM' />
        </span>
      </button>
    </div>
  );
};

LoginButton.displayName = 'LoginButton';

export default LoginButton;
