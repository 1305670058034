import type { Location, LocationDescriptor } from 'history';
import type { AnchorHTMLAttributes, ReactNode } from 'react';

/**
 * Datove vlastnosti polozek v navigaci.
 */
export type NavItemDisplay =
  | NavItemDisplayRemoteLink
  | NavItemDisplayLocalLink
  | NavItemDisplayAction
  | NavItemDisplayTaxonomy
  | NavItemDisplayStaticLabel
  | NavItemDisplaySeparator;

export interface NavItemDisplayRemoteLink {
  href: AnchorHTMLAttributes<HTMLAnchorElement>['href'];
  label: ReactNode;
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
}

export function isNavItemDisplayRemoteLink(item: NavItemDisplay): item is NavItemDisplayRemoteLink {
  return !!item && typeof (item as NavItemDisplayRemoteLink).href === 'string' && !!(item as any).href;
}

export interface NavItemDisplayLocalLink {
  label: ReactNode;
  to: LocationDescriptor | ((location: Location) => LocationDescriptor);
}

export function isNavItemDisplayLocalLink(item: NavItemDisplay): item is NavItemDisplayLocalLink {
  return !!item && !!(item as NavItemDisplayLocalLink).to;
}

export interface NavItemDisplayAction {
  action: string;
  label: ReactNode;
}

export function isNavItemDisplayAction(item: NavItemDisplay): item is NavItemDisplayAction {
  return !!item && typeof (item as NavItemDisplayAction).action === 'string' && !!(item as NavItemDisplayAction).action;
}

export interface NavItemDisplayTaxonomy extends NavItemDisplayExpandable {
  hash: string;
  image?: string | null;
  name?: string;
}

export function isNavItemDisplayTaxonomy(item: NavItemDisplay): item is NavItemDisplayTaxonomy {
  return !!item && typeof (item as NavItemDisplayTaxonomy).hash === 'string' && !!(item as NavItemDisplayTaxonomy).hash;
}

export type NavItemDisplayStaticLabel = NavItemDisplayStaticLabelTextOnly | NavItemDisplayStaticLabelExpandable;

export interface NavItemDisplayStaticLabelTextOnly {
  label: ReactNode;
}

export interface NavItemDisplayStaticLabelExpandable extends NavItemDisplayExpandable {
  label: ReactNode;
}

export function isNavItemDisplayStaticLabel(item: NavItemDisplay): item is NavItemDisplayStaticLabel {
  return !!item && !!(item as NavItemDisplayStaticLabel).label;
}

export interface NavItemDisplaySeparator {
  isSeparator: true;
}

export function isNavItemDisplaySeparator(item: NavItemDisplay): item is NavItemDisplaySeparator {
  return !!item && (item as NavItemDisplaySeparator).isSeparator === true;
}

interface NavItemDisplayExpandable {
  code: string;
  defaultExpanded?: boolean;
  isExpandable: boolean;
  items?: NavItemDisplay[];
}

export function isNavItemDisplayExpandable(item: Record<string, any>): item is NavItemDisplayExpandable {
  return !!item && !!(item as any).isExpandable;
}
