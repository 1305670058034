import { atom } from 'recoil';

export enum ShoppingCartStep {
  CART = 1,
  GIFT = 2,
  DELIVERY = 3,
  ADDRESS = 4,
  SUMMARY = 5,
}

export const cartLastStepState = atom<ShoppingCartStep>({
  default: ShoppingCartStep.CART,
  key: 'cartLastStep',
});
