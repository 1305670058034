import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export type MsgErrorMinLengthProps = {
  length: number;
};

const MsgErrorMinLength: FC<MsgErrorMinLengthProps> = ({ length }) => (
  <FormattedMessage
    defaultMessage={`Je vyžadovaný text o minimální délce {length, plural, one {1 znak} few {# znaky} other {# znaků}}.`}
    description='Form field error message for minimal string length.'
    id='6PM6SA'
    values={{ length }}
  />
);

MsgErrorMinLength.displayName = 'MsgErrorMinLength';

export default MsgErrorMinLength;
