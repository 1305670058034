import type { RelatedProductsResourceGetRelatedProductsParams } from '@/resources';
import { RelatedProductsResource } from '@/resources';
import type { EcomCatalogueItem } from '@sprinx/knihovna-api-types';
import axios from 'axios';

export class ClientRelatedProductsResource extends RelatedProductsResource {
  async getRelatedProducts(params: RelatedProductsResourceGetRelatedProductsParams): Promise<EcomCatalogueItem[]> {
    const { relationType, sku, ...body } = params;

    return axios
      .post(`/api/products/${encodeURIComponent(sku)}/related/${encodeURIComponent(relationType)}`, body)
      .then((result) => result.data)
      .catch(() => {
        return { status: 'error' };
      });
  }
}
