import { useCartSubjects } from '@/client/hooks';
import { mdiTruckFast } from '@/client/icons';
import ProductAvailability from '@/client/pages/ProductDetailPage/components/ProductAvailability';
import Icon from '@mdi/react';
import clsx from 'clsx';
import { Suspense } from 'react';
import { useIntl } from 'react-intl';

export default function CartAsideDelivery({ className }: { className?: string }) {
  const intl = useIntl();
  const cartSubjects = useCartSubjects();

  if (cartSubjects.data == null || cartSubjects.data.length === 0) return <div className={className} />;

  const atleastOneOnStock = cartSubjects.data.reduce(
    (r, s) =>
      r ||
      (s.product?.stockAvailableQuantity?.state === 'stock' &&
        typeof s.product?.stockAvailableQuantity?.quantity === 'number' &&
        s.product?.stockAvailableQuantity?.quantity > 0),
    false,
  );

  const allOnStock = cartSubjects.data.reduce(
    (r, s) =>
      r &&
      s.product?.stockAvailableQuantity?.state === 'stock' &&
      typeof s.product?.stockAvailableQuantity?.quantity === 'number' &&
      s.product?.stockAvailableQuantity?.quantity > 0,
    true,
  );

  return (
    <div className={clsx('flex items-center', className)}>
      <div className='inline-flex w-full'>
        <span className='px-2'>
          <Icon className='scale-x-[-1]' color='#2497CD' path={mdiTruckFast} size='1.3rem' />
        </span>
        <span className='font-bold'>
          {intl.formatMessage({
            defaultMessage: 'Dodání:',
            description: 'delivery',
            id: '+XkLVN',
          })}
        </span>
        <span className='mx-1 inline-flex flex-1 flex-col'>
          {atleastOneOnStock && (
            <>
              <span>
                <Suspense>
                  <ProductAvailability />
                </Suspense>
              </span>
              {!allOnStock && (
                <span className='text-sm text-gray-500'>
                  {'('}
                  {intl.formatMessage({
                    defaultMessage: 'Některé produkty budou dodány později',
                    description: 'Some products will be delivered in separate and later delivery information message.',
                    id: 'mxxf8B',
                  })}
                  {')'}
                </span>
              )}
            </>
          )}
          {!atleastOneOnStock && (
            <>
              <span>
                {intl.formatMessage({
                  defaultMessage: 'Neznámé',
                  description: 'Date of delivery is unknown message.',
                  id: 'biB7+H',
                })}
              </span>
              <span className='text-sm text-gray-500'>
                {'('}
                {intl.formatMessage({
                  defaultMessage: 'Žádný z produktů v košíku není skladem.',
                  description: 'All products in cart in not on stock information message.',
                  id: 'Y5cWZ9',
                })}
                {')'}
              </span>
            </>
          )}
        </span>
      </div>
    </div>
  );
}
