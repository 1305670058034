import type { CheckoutData } from '@/types';
import { CheckoutDataDeliveryInfoUtilization, CheckoutDataOrderInfoUtilization, createContactBase } from '@/types';
import { useCallback, useMemo } from 'react';
import invariant from 'tiny-invariant';
import type { TrpcRouterOutputs } from '../lib/trpc';
import { useCartSelectedMethodsQuery } from './useCartSelectedMethodsQuery';
import { useCheckoutData } from './useCheckoutData';

type Utilization = CheckoutData['deliveryInfo']['utilization'];
type Conatact = CheckoutData['deliveryInfo']['contact'];
type CustomerContact = CheckoutData['deliveryInfo']['customerContact'];
type ValueOrSetter<T> = T | ((prev: T) => T);

export function useCheckoutDataDeliveryInfo() {
  const checkoutData = useCheckoutData();
  const cartSelectedMethods = useCartSelectedMethodsQuery();
  const shippingMethod = cartSelectedMethods.data?.shipping;

  const setDeliveryInfo = useCallback(
    async (valueOrSetter: ValueOrSetter<Conatact>): Promise<void> => {
      invariant(checkoutData.isLoading === false && checkoutData.data !== undefined, 'Wait for chechcout data.');
      const next =
        typeof valueOrSetter === 'function' ? valueOrSetter(checkoutData.data.deliveryInfo.contact) : valueOrSetter;

      await checkoutData.update((p) => ({
        ...p,
        deliveryInfo: {
          ...p.deliveryInfo,
          contact: next,
          utilization: CheckoutDataDeliveryInfoUtilization.assigned,
        },
      }));
    },
    [checkoutData],
  );

  const setCustomerContact = useCallback(
    async (valueOrSetter: ValueOrSetter<CustomerContact>) => {
      invariant(checkoutData.isLoading === false && checkoutData.data !== undefined, 'Wait for chechcout data.');
      const next =
        typeof valueOrSetter === 'function' ? valueOrSetter(checkoutData.data.orderInfo.customer) : valueOrSetter;

      await checkoutData.update((p) => ({
        ...p,
        deliveryInfo: {
          ...p.deliveryInfo,
          ...(next == null ? undefined : { contact: (({ id, ...withoutId }) => withoutId)(next) }),
          customerContact: next,
          utilization:
            next == null ? CheckoutDataDeliveryInfoUtilization.assigned : CheckoutDataDeliveryInfoUtilization.customer,
        },
      }));
    },
    [checkoutData],
  );

  const setDeliveryInfoUtilization = useCallback(
    async (valueOrSetter: ValueOrSetter<Utilization>): Promise<void> => {
      invariant(checkoutData.isLoading === false && checkoutData.data !== undefined, 'Wait for chechcout data.');
      const next =
        typeof valueOrSetter === 'function' ? valueOrSetter(checkoutData.data.deliveryInfo.utilization) : valueOrSetter;

      await checkoutData.update((p) => ({
        ...p,
        deliveryInfo: {
          ...p.deliveryInfo,
          contact:
            next === CheckoutDataDeliveryInfoUtilization.assigned && p.deliveryInfo.contact == null
              ? createContactBase()
              : p.deliveryInfo.contact,
          utilization: next,
        },
      }));
    },
    [checkoutData],
  );

  const deliveryInfoPreview = useMemo(() => {
    return getDeliveryInfoPreview({
      checkoutData: checkoutData.data ?? null,
      personalPickup: shippingMethod?.personalPickup ?? null,
    });
  }, [checkoutData.data, shippingMethod?.personalPickup]);

  return {
    deliveryInfo: checkoutData.data?.deliveryInfo.contact ?? null,
    deliveryInfoPreview: deliveryInfoPreview ?? null,
    deliveryInfoUtilization: checkoutData.data?.deliveryInfo.utilization,
    setCustomerContact,
    setDeliveryInfo,
    setDeliveryInfoUtilization,
  };
}

export function getDeliveryInfoPreview(props: {
  checkoutData: TrpcRouterOutputs['checkout']['checkoutData'] | null;
  personalPickup: NonNullable<NonNullable<TrpcRouterOutputs['cart']['methods']>['shipping']>['personalPickup'] | null;
}) {
  if (props.personalPickup && props.checkoutData?.personalPickupInfo != null) {
    return props.checkoutData.personalPickupInfo.contact;
  }

  if (
    props.checkoutData?.orderInfo.utilization === CheckoutDataOrderInfoUtilization.none &&
    props.checkoutData?.deliveryInfo.utilization === CheckoutDataDeliveryInfoUtilization.inherit
  ) {
    return null;
  }

  if (
    props.checkoutData?.deliveryInfo.utilization === CheckoutDataDeliveryInfoUtilization.customer &&
    props.checkoutData?.deliveryInfo.customerContact != null
  ) {
    return props.checkoutData.deliveryInfo.customerContact;
  }

  if (
    props.checkoutData?.deliveryInfo.utilization === CheckoutDataDeliveryInfoUtilization.assigned &&
    props.checkoutData?.deliveryInfo.contact != null
  ) {
    return props.checkoutData.deliveryInfo.contact;
  }

  if (
    props.checkoutData?.deliveryInfo.utilization === CheckoutDataDeliveryInfoUtilization.invoice &&
    props.checkoutData?.invoiceInfo.contact != null
  ) {
    return props.checkoutData.invoiceInfo.contact;
  }

  return props.checkoutData?.orderInfo.contact;
}
