import { productPackResolve } from '@/common/productPackResolve';
import { routeUrl } from '@/routes';
import { useCallback } from 'react';

export function useProductDetailUrl() {
  return useCallback(
    (product: { name: string; sku: string }) => {
      const { name, sku } = product;
      const pack = productPackResolve(sku);

      return routeUrl('product', {
        locale: 'cs',
        params: {
          productName: name || 'na',
          sku: pack.parentSku ?? '',
        },
      });
    },

    [],
  );
}
