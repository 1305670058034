import { authUserState, pricesTypeState } from '@/client/atoms';
import { addPriceVatViewDataLayer } from '@/client/lib/googleAnalytics';
import { PricesType } from '@sprinx/knihovna-api-types';
import clsx from 'clsx';
import type { FC, HTMLAttributes } from 'react';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

export type ChangePricesTypeProps = HTMLAttributes<HTMLDivElement>;

const ChangePricesType: FC<ChangePricesTypeProps> = ({ className, ...props }) => {
  const [pricesType, setPricesType] = useRecoilState(pricesTypeState);
  const authUser = useRecoilValue(authUserState);

  useEffect(() => {
    addPriceVatViewDataLayer(pricesType, authUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={clsx('form-styles mx-2 flex min-w-[111px] flex-col', className)} {...props}>
      <div className='text-text'>
        <span>Zobrazit ceny:</span>
      </div>
      <fieldset>
        <div>
          <label htmlFor='change-price-type-no-vat'>
            <input
              checked={pricesType === PricesType.B2B}
              className='text-primary'
              id='change-price-type-no-vat'
              name='change-price-type'
              onChange={() => {
                setPricesType(PricesType.B2B);
                addPriceVatViewDataLayer(PricesType.B2B, authUser);
              }}
              type='radio'
              value='no-vat'
            />

            <span className='ml-2 text-text'>bez DPH</span>
          </label>
        </div>
        <div>
          <label htmlFor='change-price-type-with-vat'>
            <input
              checked={pricesType === PricesType.B2C}
              className='text-primary'
              id='change-price-type-with-vat'
              name='change-price-type'
              onChange={() => {
                setPricesType(PricesType.B2C);
                addPriceVatViewDataLayer(PricesType.B2C, authUser);
              }}
              type='radio'
              value='with-vat'
            />
            <span className='ml-2 text-text peer-checked:bg-red'>s DPH</span>
          </label>
        </div>
      </fieldset>
    </div>
  );
};

ChangePricesType.displayName = 'ChangePricesType';

export default ChangePricesType;
