import { routesDefinition } from './routes-definition';
import type { AsyncRouteProps, RouteDefinition } from './types';

/**
 * Returns all router routes definitions...
 */
export function routeAllRoutesToRouter(): AsyncRouteProps[] {
  const extr = ({ path, urlBuilder, ...rest }: RouteDefinition): Omit<AsyncRouteProps, 'path'> => rest;

  return Object.values(routesDefinition).reduce((acc, itm) => {
    if (typeof itm.path === 'string') return [...acc, { path: itm.path, ...extr(itm as any) }] as AsyncRouteProps[];
    return [...acc, ...Object.values(itm.path).map((path) => ({ path, ...extr(itm as any) }))] as AsyncRouteProps[];
  }, [] as AsyncRouteProps[]);
}
