import type { FC } from 'react';

const AppCustomHeaderNavBarSkeleton: FC = () => (
  <>
    <div className='flex h-10 animate-pulse items-center text-navbar-contrast sm:h-14'>
      <div className='ml-4 flex h-8 w-full flex-1 sm:h-12'>
        <div className='w-40'>
          <div className='h-3/4 w-full' />
        </div>
        <div className='ml-3 w-40'>
          <div className='h-3/4 w-full' />
        </div>
        <div className='ml-3 w-40'>
          <div className='h-3/4 w-full' />
        </div>
      </div>
    </div>
  </>
);

AppCustomHeaderNavBarSkeleton.displayName = 'AppCustomHeaderNavBarSkeleton';

export default AppCustomHeaderNavBarSkeleton;
