import { isAuthenticatedState } from '@/client/atoms';
import type { FC } from 'react';
import { useRecoilValue } from 'recoil';
import LoggedIn from '../LoggedIn';
import LoginButton from '../LoginButton';

export type AuthProps = {
  className?: string;
};

const Auth: FC<AuthProps> = ({ className }) => {
  const isAuth = useRecoilValue(isAuthenticatedState);

  return isAuth ? <LoggedIn className={className} /> : <LoginButton className={className} />;
};

export default Auth;
