import type { ReactNode } from 'react';
import { RawIntlProvider } from 'react-intl';
import { intlState } from './atoms';
import { useRecoilAsyncValue } from './hooks';

/**
 * AppInt React Component
 */
export default function AppIntl({ children, locale }: { children: ReactNode; locale?: string }) {
  const intl = useRecoilAsyncValue(intlState((locale as any) ?? 'cs'));

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
}
