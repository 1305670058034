import { useActiveCategory } from '@/client/hooks';
import { mdiChevronDown } from '@/client/icons';
import { isNavItemDisplayExpandable, NavigationType } from '@/types';
import Icon from '@mdi/react';
import clsx from 'clsx';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { navigationsState } from '../../atoms';
import { appCustomLayoutState } from '../../atoms/appCustomLayoutState';
import AppCustomHeaderNavBarLinkItem from '../AppCustomHeaderNavBarLinkItem';

const isExpanable = isNavItemDisplayExpandable;

export type AppCustomHeaderNavBarContentProps = {
  open: boolean;
};

const AppCustomHeaderNavBarContent: FC<AppCustomHeaderNavBarContentProps> = ({ open }) => {
  const { expandedCategories } = useRecoilValue(appCustomLayoutState);
  const setLayoutState = useSetRecoilState(appCustomLayoutState);
  const activeCategory = useActiveCategory();

  const navItems = useRecoilValue(navigationsState(NavigationType.categories));

  const handleAction = useCallback(
    (action: string) => {
      // console.log('nav-action', action);

      switch (action) {
        case 'toggle-category-navigation':
          setLayoutState((p) => ({ ...p, categoriesNavExpanded: !p.categoriesNavExpanded }));
          break;
      }
    },
    [setLayoutState],
  );

  const handleToggle = useCallback(
    (me: string, ...parentCategories: string[]) =>
      () => {
        setLayoutState((p) => ({
          ...p,
          expandedCategories: p.expandedCategories.includes(me) ? parentCategories : [me, ...parentCategories],
        }));
      },
    [setLayoutState],
  );

  useEffect(() => {
    let expanded: string[] = [];
    if (typeof activeCategory?.code === 'string') {
      const parts = activeCategory.code.split('/').slice(1);
      if (parts.length > 1) {
        expanded = parts.reduce((r: string[], part, idx) => {
          return [...r, `${r[idx - 1] ?? ''}/${part}`];
        }, []);
      }
    }

    setLayoutState((p) => ({
      ...p,
      expandedCategories: expanded,
    }));
  }, [activeCategory?.code, setLayoutState]);

  return (
    <ul
      className={clsx('z-10 bg-primary-200 text-primary shadow-lg md:bg-white', {
        hidden: !open,
      })}
    >
      {navItems &&
        navItems.map((i1, idx1) => (
          <li
            key={`catategories-nav-item-${idx1}`}
            className={clsx('w-100 flex w-full flex-col justify-between border-b px-4 py-4', {
              'bg-[#179AD0] text-white': expandedCategories.includes((i1 as any).code),
            })}
          >
            <div
              className={clsx('flex flex-row justify-between', {
                'pb-4': isExpanable(i1) && expandedCategories.includes(i1.code),
              })}
            >
              <AppCustomHeaderNavBarLinkItem navbarItem={i1} onActionClick={handleAction} />
              {isExpanable(i1) && (
                <button
                  aria-label='Rozbalit kategorii'
                  className='flex items-center'
                  onClick={handleToggle(i1.code)}
                  type='button'
                >
                  <Icon
                    className={clsx('absolute right-2 text-primary transition-all duration-300', {
                      '!text-white':
                        (activeCategory?.code?.startsWith((i1 as any).code) && expandedCategories.length !== 0) ||
                        expandedCategories.includes((i1 as any).code),
                      'rotate-180 transform': expandedCategories.includes(i1.code),
                    })}
                    path={mdiChevronDown}
                    size='2rem'
                  />
                </button>
              )}
            </div>
            {isExpanable(i1) && expandedCategories.includes(i1.code) && i1.items && (
              <ul
                className={clsx('w-100 mx-[-1rem] mb-[-1rem] block bg-[#E6F1FB] pl-8 pr-4 text-primary md:pl-4', {
                  hidden: !expandedCategories.includes(i1.code),
                })}
              >
                {i1.items
                  .filter((i2) => (i2 as any).hash !== '6bgNx')
                  .map((i2, idx2) => (
                    <li key={`catategories-nav-item-${idx1}-${idx2}`} className='py-2'>
                      <div
                        className={clsx('relative flex flex-row justify-between', {
                          'font-bold': activeCategory?.code?.startsWith((i2 as any).code),
                        })}
                      >
                        <AppCustomHeaderNavBarLinkItem navbarItem={i2} onActionClick={handleAction} />
                        {isExpanable(i2) && i2.items && (
                          <button
                            aria-label='Rozbalit kategorii'
                            className='relative pl-1'
                            onClick={handleToggle(i2.code, i1.code)}
                            type='button'
                          >
                            <Icon
                              className={clsx(
                                'absolute top-[-0.2rem] right-[-0.625rem] text-primary transition-all duration-300',
                                {
                                  'rotate-180 transform': expandedCategories.includes(i2.code),
                                },
                              )}
                              path={mdiChevronDown}
                              size='2rem'
                            />
                          </button>
                        )}
                      </div>
                      {isExpanable(i2) && expandedCategories.includes(i2.code) && i2.items && (
                        <ul
                          className={clsx('w-100 mx-[-1rem] mb-[-1rem] block pl-8 text-primary', {
                            hidden: !expandedCategories.includes(i2.code),
                          })}
                        >
                          {i2.items.map((i3, idx3) => (
                            <li key={`catategories-nav-item-${idx1}-${idx2}-${idx3}`} className='py-2'>
                              {/* <AppCustomHeaderNavBarLinkItem navbarItem={i3} onActionClick={handleAction} /> */}
                              <div
                                className={clsx('relative flex flex-row justify-between md:pr-4', {
                                  'font-bold': activeCategory?.code?.startsWith((i3 as any).code),
                                })}
                              >
                                <AppCustomHeaderNavBarLinkItem navbarItem={i3} onActionClick={handleAction} />
                                {isExpanable(i3) && i3.items && (
                                  <button
                                    aria-label='Rozbalit kategorii'
                                    className='relative pl-1'
                                    onClick={handleToggle(i3.code, i2.code, i1.code)}
                                    type='button'
                                  >
                                    <Icon
                                      className={clsx(
                                        'absolute top-[-0.2rem] right-[-0.625rem] text-primary transition-all duration-300',
                                        {
                                          'rotate-180 transform': expandedCategories.includes(i3.code),
                                        },
                                      )}
                                      path={mdiChevronDown}
                                      size='2rem'
                                    />
                                  </button>
                                )}
                              </div>
                              {isExpanable(i3) && expandedCategories.includes(i3.code) && i3.items && (
                                <ul
                                  className={clsx('w-100 mx-[-1rem] mb-[-1rem] block pl-8 text-primary', {
                                    hidden: !expandedCategories.includes(i3.code),
                                  })}
                                >
                                  {i3.items.map((i4, idx4) => (
                                    <li
                                      key={`catategories-nav-item-${idx1}-${idx2}-${idx3}-${idx4}`}
                                      className={clsx('py-2', {
                                        'font-bold': activeCategory?.code?.startsWith((i4 as any).code),
                                      })}
                                    >
                                      <AppCustomHeaderNavBarLinkItem navbarItem={i4} onActionClick={handleAction} />
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
              </ul>
            )}
          </li>
        ))}
    </ul>
  );
};

AppCustomHeaderNavBarContent.displayName = 'AppCustomHeaderNavBarContent';

export default AppCustomHeaderNavBarContent;
