import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const MsgAdd: FC = () => (
  <FormattedMessage defaultMessage='Nová dodací adresa' description='Lebel of add action.' id='VLN/tu' />
);

MsgAdd.displayName = 'MsgAdd';

export default MsgAdd;
