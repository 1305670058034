import type { CartStats } from '@/client/hooks';
import { isNotEmptyArray } from '@/helpers';
import type { ShippingPaymentMapItem } from '@/types';

export const methodIsAllowed = (
  item: ShippingPaymentMapItem,
  {
    cartStats,
    cartSubjectSkus,
    isAuth,
    totalAmount,
  }: { cartStats: CartStats; cartSubjectSkus: string[]; isAuth: boolean; totalAmount: number },
): boolean => {
  return (
    (!item.maxWeight || cartStats.weight <= item.maxWeight) &&
    (item.shippingMethodSku === 'ZAS.a' ? cartStats.weight < 5 : true) &&
    (item.shippingMethodSku === 'ZAS.b' ? cartStats.weight >= 5 && cartStats.weight <= item.maxWeight : true) &&
    (!item.dimension || cartStats.maxProductDimension <= item.dimension) &&
    (!item.maxVolume || cartStats.volume <= item.maxVolume) &&
    (!item.productSkus ||
      !isNotEmptyArray(item.productSkus) ||
      cartSubjectSkus?.some((sku) => item.productSkus.includes(sku))) &&
    (!item.priceFrom || totalAmount >= item.priceFrom) &&
    (!item.priceTo || totalAmount <= item.priceTo) &&
    (!item.registeredUsers || isAuth)
  );
};
