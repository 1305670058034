import { NavigationType } from '@/types';
import clsx from 'clsx';
import type { FC, HTMLAttributes } from 'react';
import { useRecoilValue } from 'recoil';
import { navigationsState } from '../../atoms';
import AppCustomHeaderNavBarLinkItem from '../AppCustomHeaderNavBarLinkItem';

export type AppCustomHeaderTopHorizontalNavProps = HTMLAttributes<HTMLDivElement> & {};

const AppCustomHeaderTopHorizontalNav: FC<AppCustomHeaderTopHorizontalNavProps> = ({ className, ...props }) => {
  const navItems = useRecoilValue(navigationsState(NavigationType.horizontalNav));

  return (
    <div className={clsx('hidden bg-primary lg:block', className)} {...props}>
      <div className='container h-auto w-full lg:flex lg:flex-row'>
        <nav className='h-[65px] flex-col items-center bg-primary px-4 pl-[330px] text-white md:flex md:flex-row'>
          {navItems &&
            navItems.map((i, idx) => (
              <AppCustomHeaderNavBarLinkItem key={`horizontal-nav-item-${idx}`} className='px-4' navbarItem={i} />
            ))}
        </nav>
      </div>
    </div>
  );
};

AppCustomHeaderTopHorizontalNav.displayName = 'AppCustomHeaderTopHorizontalNav';

export default AppCustomHeaderTopHorizontalNav;
