import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const MsgErrorInvalidICO: FC = () => (
  <FormattedMessage
    defaultMessage='Prosím zadejte IČO ve formátu XXXXXXXX.'
    description='Form field error message for invalid companyRegNo.'
    id='CIL2HF'
  />
);

MsgErrorInvalidICO.displayName = 'MsgErrorInvalidICO';

export default MsgErrorInvalidICO;
