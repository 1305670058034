import type { MouseEventHandler } from 'react';
import { createContext } from 'react';
import type { LoginFormCredentials } from '../../types';

export const AuthHandlerContext = createContext<{
  handleLoginClick: MouseEventHandler;
  handleLoginSubmit: (credentials: LoginFormCredentials) => void;
  handleLogoutClick: MouseEventHandler;
  handleLogoutConfimYesClick: MouseEventHandler;
}>({
  handleLoginClick: () => {
    throw new Error('Auth handler context not initialized.');
  },
  handleLoginSubmit: () => {
    throw new Error('Auth handler context not initialized.');
  },
  handleLogoutClick: () => {
    throw new Error('Auth handler context not initialized.');
  },
  handleLogoutConfimYesClick: () => {
    throw new Error('Auth handler context not initialized.');
  },
});
