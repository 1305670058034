import type { ChangeEventHandler, ReactNode } from 'react';
import { forwardRef } from 'react';

export type CheckboxProps = {
  checked?: boolean;
  className?: string;
  errorMessage?: ReactNode;
  helperText?: ReactNode;
  id: string;
  label?: ReactNode;
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
};

const RequiredSign = ({ required = false }: { required?: boolean }) =>
  required ? (
    <abbr className='text-red no-underline' title='required'>
      {' '}
      *
    </abbr>
  ) : null;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ checked, className, id, name, onChange, ...props }, ref) => (
    <div className='flex flex-row items-center'>
      <input
        ref={ref}
        checked={checked}
        className={className}
        id={id}
        name={name}
        onChange={onChange}
        type='checkbox'
      />
      {props.label && (
        <label className='ml-2' htmlFor={name}>
          {props.label}
          <RequiredSign required={props.required} />
        </label>
      )}
    </div>
  ),
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
