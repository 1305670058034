import { authUserState } from '@/client/atoms';
import { useRouteUrl } from '@/client/hooks';
import { mdiAccount, mdiBasket, mdiCashRefund, mdiFile, mdiHome, mdiLogout } from '@/client/icons';
import Icon from '@mdi/react';
import clsx from 'clsx';
import type { FC } from 'react';
import { forwardRef } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import invariant from 'tiny-invariant';
import LogoutButton from '../Auth/AuthModal/LogoutButton';

const navigationItems = defineMessages({
  Account: {
    defaultMessage: 'Můj účet',
    description: 'Label of navigation item to page with customers accout settings.',
    id: 'gPQW/R',
  },
  Addresses: {
    defaultMessage: 'Dodací adresy',
    description: 'Label of navigation item to page with delivery addresses.',
    id: 'vEtykR',
  },
  Dashboard: {
    defaultMessage: '{customer}',
    description: 'Label of navigation item to page with customers dashboard.',
    id: '7Hss6v',
  },
  Invoices: {
    defaultMessage: 'Faktury',
    description: 'Label of navigation item to page with customers invoices.',
    id: 'slZL//',
  },
  LoyaltyProgramme: {
    defaultMessage: 'Věrnostní program',
    description: 'Label of navigation item to page with loyalty programme.',
    id: 'j4aMn2',
  },
  Orders: {
    defaultMessage: 'Objednávky',
    description: 'Label of navigation item to page with customers orders.',
    id: 'stbA0a',
  },
});

const Dashboard: FC = (): JSX.Element => {
  //const routeUrl = useRouteUrl();
  const user = useRecoilValue(authUserState);

  invariant(user);

  return (
    /*
    <RouterLink ref={ref} className={props.className} to={routeUrl('profileDashboard', {})}>
      <FormattedMessage {...navigationItems.Dashboard} values={{ customer: user.name }} />
  </RouterLink>
  */
    <FormattedMessage {...navigationItems.Dashboard} values={{ customer: user.name }} />
  );
};

Dashboard.displayName = 'ProfileNavigationItems.Dashboard';

const Account = forwardRef((props: { className?: string }, ref: any) => {
  const routeUrl = useRouteUrl();

  return (
    <RouterLink ref={ref} className={props.className} to={routeUrl('profileAccount', {})}>
      <FormattedMessage {...navigationItems.Account} />
    </RouterLink>
  );
});

Account.displayName = 'ProfileNavigationItems.Account';

const AccountNav = forwardRef((props: { className?: string }, ref: any) => {
  const routeUrl = useRouteUrl();

  return (
    <RouterLink ref={ref} className={props.className} to={routeUrl('profileAccount', {})}>
      <div className='flex flex-row gap-2'>
        <Icon path={mdiAccount} size='1.4rem' />
        <span>
          <FormattedMessage {...navigationItems.Account} />
        </span>
      </div>
    </RouterLink>
  );
});

AccountNav.displayName = 'ProfileNavigationItems.AccountNav';

const Orders = forwardRef((props: { className?: string; dropdown?: boolean }, ref: any) => {
  const routeUrl = useRouteUrl();

  return (
    <RouterLink ref={ref} className={props.className} to={routeUrl('profileOrders', {})}>
      <span className='flex flex-row gap-2'>
        {!props.dropdown && <Icon path={mdiBasket} size='1.4rem' />}
        <span>
          <FormattedMessage {...navigationItems.Orders} />
        </span>
      </span>
    </RouterLink>
  );
});

Orders.displayName = 'ProfileNavigationItems.Orders';

const Invoices = forwardRef((props: { className?: string; dropdown?: boolean }, ref: any) => {
  const routeUrl = useRouteUrl();

  return (
    <RouterLink ref={ref} className={props.className} to={routeUrl('profileInvoices', {})}>
      <span className='flex flex-row gap-2'>
        {!props.dropdown && <Icon path={mdiFile} size='1.4rem' />}
        <span>
          <FormattedMessage {...navigationItems.Invoices} />
        </span>
      </span>
    </RouterLink>
  );
});

Invoices.displayName = 'ProfileNavigationItems.Invoices';

const LoyaltyProgramme = forwardRef((props: { className?: string; dropdown?: boolean }, ref: any) => {
  const routeUrl = useRouteUrl();

  return (
    <RouterLink ref={ref} className={props.className} to={routeUrl('profileLoyaltyProgramme', {})}>
      <span className='flex flex-row gap-2'>
        {!props.dropdown && <Icon path={mdiCashRefund} size='1.4rem' />}
        <span>
          <FormattedMessage {...navigationItems.LoyaltyProgramme} />
        </span>
      </span>
    </RouterLink>
  );
});

LoyaltyProgramme.displayName = 'ProfileNavigationItems.LoyaltyProgramme';

const Addresses = forwardRef((props: { className?: string }, ref: any) => {
  const routeUrl = useRouteUrl();

  return (
    <RouterLink ref={ref} className={props.className} to={routeUrl('profileDeliveryContacts', {})}>
      <div className='flex flex-row gap-2'>
        <Icon path={mdiHome} size='1.4rem' />
        <span>
          <FormattedMessage {...navigationItems.Addresses} />
        </span>
      </div>
    </RouterLink>
  );
});

Addresses.displayName = 'ProfileNavigationItems.Addresses';

export type ProfileNavigationProps = {
  className?: string;
};

const Logout: FC<ProfileNavigationProps> = ({ className }) => {
  return (
    <div className={clsx('flex flex-row gap-2', className)}>
      <Icon path={mdiLogout} size='1.4rem' />
      <LogoutButton className='font-semibold text-primary hover:underline' />
    </div>
  );
};

Logout.displayName = 'ProfileNavigationItems.Logout';

const ProfileNavigationItems = Object.assign(
  {},
  {
    Account,
    AccountNav,
    Addresses,
    Dashboard,
    definitions: navigationItems,
    Invoices,
    Logout,
    LoyaltyProgramme,
    Orders,
  },
);

export default ProfileNavigationItems;
