import type {
  CheckoutCreatePurchaseRequest,
  CheckoutCreatePurchaseResult,
  CheckoutMethodState,
  ShippingPaymentMapItem,
  UpdateSubject,
  UpdateSubjectIndirect,
} from '@/types';
import type { AvailablePaymentMethod } from '@/types/checkout/AvailablePaymentMethod';
import type { AvailableShippingMethod } from '@/types/checkout/AvailableShippingMethod';
import type {
  EcomCartInfo,
  EcomCartSubjectItem,
  EcomCartUpdateSubjectResult,
  SupportedCurrency,
  SupportedLocale,
} from '@sprinx/knihovna-api-types';
import EventEmitter from 'eventemitter3';
import { Container as IocContainer } from 'typescript-ioc';
import { ResourcesContext } from './ResourcesContext';

export interface MethodsResourceParams {
  currency: SupportedCurrency;
  locale: SupportedLocale;
  priceGroups: string[];
}

enum CheckoutResourceEvent {
  CARTINFO_UPDATED = 'checkout:cart:updated',
  METHODS_UPDATED = 'checkout:methods:updated',
}

export abstract class CheckoutResource {
  private eventEmitter = new EventEmitter();

  public ctx: ResourcesContext;

  constructor() {
    this.ctx = IocContainer.get(ResourcesContext);
  }

  /** Updates settings of checkout after user login. */
  abstract afterLogin(): Promise<void>;

  /** Returns shopping cart content. */
  abstract cartContent(checkoutId: string): Promise<EcomCartSubjectItem[] | null>;

  /** Performs shopping cart content. */
  abstract cartReset(checkoutId: string): Promise<EcomCartInfo | null>;

  /** Updates shopping cart content with batch of subjects. */
  abstract cartUpdateMethod(
    checkoutId: string,
    updateSubject: CheckoutMethodState,
  ): Promise<EcomCartUpdateSubjectResult | null>;

  /** Updates shopping cart content. */
  abstract cartUpdateSubject(
    checkoutId: string,
    updateData: UpdateSubject,
  ): Promise<EcomCartUpdateSubjectResult | null>;

  /** Updates shopping cart content with batch of subjects. */
  abstract cartUpdateSubjectBatch(
    checkoutId: string,
    updateData: UpdateSubject[],
  ): Promise<EcomCartUpdateSubjectResult | null>;

  /** Updates shopping cart indirect subject. */
  abstract cartUpdateSubjectIndirect(
    checkoutId: string,
    updateData: UpdateSubjectIndirect,
  ): Promise<EcomCartUpdateSubjectResult | null>;

  /** Updates settings of checkout */
  abstract changeSettings(checkoutId: string, nextSettings: Record<string, any>): Promise<void>;

  /** Applyes an coupon. */
  abstract couponApply(checkoutId: string, couponCode: string): Promise<void>;

  /** Removes an coupon. */
  abstract couponRemove(checkoutId: string, couponCode: string): Promise<void>;

  /** Creates `Purchase` from current purchase. */
  abstract createPurchase(
    checkoutId: string,
    params: CheckoutCreatePurchaseRequest,
  ): Promise<CheckoutCreatePurchaseResult>;

  /** Returns list of available payment methods. */
  abstract methodsPayment(checkoutId: string, params: MethodsResourceParams): Promise<AvailablePaymentMethod[]>;

  /** Returns list of available shipping methods. */
  abstract methodsShipping(checkoutId: string, params: MethodsResourceParams): Promise<AvailableShippingMethod[]>;

  /** Returns list of available shipping-payment configuration. */
  abstract shippingPaymentMap(checkoutId: string, params: MethodsResourceParams): Promise<ShippingPaymentMapItem[]>;

  onMethodsUpdated(handler: (nextMethods: CheckoutMethodState) => void): { cleanup: () => void } {
    this.eventEmitter.addListener(CheckoutResourceEvent.METHODS_UPDATED, handler);

    return {
      cleanup: () => {
        this.eventEmitter.removeListener(CheckoutResourceEvent.METHODS_UPDATED, handler);
      },
    };
  }

  protected emitMethodsUpdated(nextMethods: CheckoutMethodState): void {
    this.eventEmitter.emit(CheckoutResourceEvent.METHODS_UPDATED, nextMethods);
  }

  onCartInfoUpdated(handler: (nextCartInfo: EcomCartInfo) => void): { cleanup: () => void } {
    this.eventEmitter.addListener(CheckoutResourceEvent.CARTINFO_UPDATED, handler);

    return {
      cleanup: () => {
        this.eventEmitter.removeListener(CheckoutResourceEvent.CARTINFO_UPDATED, handler);
      },
    };
  }

  protected emitCartInfoUpdated(cartInfo: EcomCartInfo) {
    this.eventEmitter.emit(CheckoutResourceEvent.CARTINFO_UPDATED, cartInfo);
  }
}
