import type { Location } from 'history';
import type { FC } from 'react';
import { startTransition, useEffect, useState } from 'react';
import { matchPath, Route, Switch, useLocation } from 'react-router-dom';
import { routes } from '../routes';

type AppRouteHandlerProps = {
  initialData?: any;
};

const AppRouteHandler: FC<AppRouteHandlerProps> = ({ initialData: pData = {} }) => {
  const location = useLocation();

  const [state, setState] = useState<{
    currentLocation: Location | null;
    data: any;
    load: null | string;
    previousLocation: Location | null;
  }>({
    currentLocation: null,
    data: pData,
    load: null,
    previousLocation: null,
  });

  useEffect(() => {
    // If initial render, fallback to parent suspense
    // If currenctLocation is different load data.

    startTransition(() => {
      setState((p) => ({
        ...p,
        currentLocation: location,
        data: p.currentLocation && location !== p.currentLocation ? undefined : p.data,
        load: location !== p.currentLocation ? location.pathname : p.currentLocation.pathname,
        previousLocation: p.previousLocation || p.currentLocation,
      }));
    });
  }, [location]);

  useEffect(() => {
    if (
      state.previousLocation !== null &&
      state.currentLocation?.pathname !== state.previousLocation.pathname &&
      typeof window !== 'undefined'
    ) {
      window.scrollTo(0, 0);
    }
  }, [state.currentLocation, state.previousLocation]);

  useEffect(() => {
    if (state.load) {
      const promises: Promise<any>[] = routes.reduce((proms: Promise<any>[], route: any) => {
        const match = matchPath(state.load ?? '', { ...route, path: route.path || '*' });
        return match && route.component && typeof (route.component as any).load !== 'undefined'
          ? [...proms, (route.component as any).load()]
          : proms;
      }, []);

      (promises.length ? Promise.all(promises) : Promise.resolve([])).then(() => {
        startTransition(() => {
          setState((p) => ({ ...p, load: null, previousLocation: null }));
        });
      });
    }
  }, [state.load]);

  return (
    <Switch location={state.currentLocation || location}>
      {routes.map((route, idx) => (
        <Route
          key={`route--${idx}`}
          exact={route.exact}
          path={route.path}
          render={(routeProps) => {
            const C: any = (route.component as any).load ? (route.component as any).component : route.component;
            return <C {...routeProps} isLoading={!!state.load} location={location} {...(state.data ?? {})} />;
          }}
        />
      ))}
    </Switch>
  );
};

AppRouteHandler.displayName = 'AppRouteHandler';

export default AppRouteHandler;
