import { useCallback } from 'react';
import type { UseMutationConfig } from 'react-relay';
import type { Disposable, MutationParameters, PayloadError, VariablesOf } from 'relay-runtime';

export type PromisifyCommitMutation<Args extends any[], R> = (...args: Args) => Promise<R>;

class PromisifyCommitMutationUnhandledResponseError extends Error {
  parentErrors: any;

  constructor(parentErrors: any) {
    super('Unhandled graphql response errors.');
    this.parentErrors = parentErrors;
  }
}

export function usePromisifyCommitMutation<TMutation extends MutationParameters, Args extends any[], R>(
  commitMutation: (config: UseMutationConfig<TMutation>) => Disposable,
  buildVariables: (...params: Args) => VariablesOf<TMutation>,
  resolveResponse: (response: TMutation['response']) => [Error | string] | [false | null | undefined, R],
  resolvePayloadErrors?: (errors: PayloadError[]) => [Error | string] | [false | null | undefined, R],
): PromisifyCommitMutation<Args, R> {
  return useCallback<PromisifyCommitMutation<Args, R>>(
    (...params) => {
      const variables = buildVariables(...params);

      return new Promise((resolve, reject) => {
        commitMutation({
          onCompleted: (response: any, errors: any) => {
            if (errors && resolvePayloadErrors) {
              const [err, d] = resolvePayloadErrors(errors);

              if (err !== null && err !== false && err !== undefined) {
                reject(err);
              } else {
                resolve(d as any);
              }
            } else if (errors) {
              if (process.env.NODE_ENV !== 'production') {
                console.error('GQL errors', errors);
              }
              reject(new PromisifyCommitMutationUnhandledResponseError(errors));
            } else {
              const [err, d] = resolveResponse(response);

              if (err !== null && err !== false && err !== undefined) {
                reject(err);
              } else {
                resolve(d as any);
              }
            }
          },
          onError: (error: any) => {
            reject(error);
          },
          variables,
        });
      });
    },
    [buildVariables, commitMutation, resolvePayloadErrors, resolveResponse],
  );
}

usePromisifyCommitMutation.errors = {
  UnhandledResponseError: PromisifyCommitMutationUnhandledResponseError,
};
