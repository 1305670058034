import { useCallback } from 'react';
import type { RecoilState } from 'recoil';
import { useRecoilState } from 'recoil';

export function useRecoilStateEventHandler<T>(recoilState: RecoilState<T>): [T, (v: T) => () => void];
export function useRecoilStateEventHandler<T, V>(
  recoilState: RecoilState<T>,
  setter: (val: V, prevVal: T) => T,
): [T, (v: V) => () => void];
export function useRecoilStateEventHandler<T, V>(
  recoilState: RecoilState<T>,
  setter?: (val: V, prevVal: T) => T,
): unknown {
  const [value, setState] = useRecoilState(recoilState);

  const innerSetter = useCallback(
    (v: V) =>
      (prev: T): T => {
        return setter ? setter(v as any, prev) : (v as any);
      },
    [setter],
  );
  const cb = useCallback((v: V) => () => setState(innerSetter(v)), [innerSetter, setState]);
  return [value, cb];
}
