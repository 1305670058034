import { useCallback, useEffect, useMemo, useState } from 'react';

export const MOBILE_WINDOW_WIDTH = 768;
export const TABLET_WINDOW_WIDTH = 1023;

export function useWindowSize(): {
  height: number | undefined;
  width: number | undefined;
} {
  const canUseDOM = useMemo(() => typeof window !== 'undefined', []);

  const getSize = useCallback(() => {
    return {
      height: canUseDOM ? window.innerHeight : undefined,
      width: canUseDOM ? window.innerWidth : undefined,
    };
  }, [canUseDOM]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(
    () => {
      if (!canUseDOM) {
        return undefined;
      }

      function handleResize() {
        setWindowSize(getSize());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    },
    // Empty array ensures that effect is only run on mount and unmount
    [getSize, canUseDOM],
  );

  return windowSize;
}
