import { useMemo } from 'react';

export function useAppHostName(): string {
  return useMemo(() => {
    if (typeof window === 'undefined') return '';

    let port = '';
    if (!['80', '443'].includes(window.location.port)) {
      port = `:${window.location.port}`;
    }

    let protocol = window.location.protocol;
    if (protocol.endsWith('://')) {
      protocol = protocol.slice(0, -2);
    }

    let hostname = window.location.host;
    if (hostname.endsWith(port)) {
      hostname = hostname.slice(0, -1 * port.length);
    }
    return `${protocol}//${hostname}${port}`;
  }, []);
}
