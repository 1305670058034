import clsx from 'clsx';
import type { FC, HTMLAttributes } from 'react';
import Auth from '../Auth';

export type AppbarLoginRegistrationActionProps = HTMLAttributes<HTMLDivElement>;

const AppbarLoginRegistrationAction: FC<AppbarLoginRegistrationActionProps> = ({ className, ...props }) => {
  return (
    <div className={clsx('relative flex flex-col items-center justify-center', className)} {...props}>
      <div className='absolute left-0 h-[55px] w-[1px] bg-gray-300'></div>
      <div className='absolute right-0 h-[55px] w-[1px] bg-gray-300 '></div>
      <Auth />
    </div>
  );
};

AppbarLoginRegistrationAction.displayName = 'AppbarLoginRegistrationAction';

export default AppbarLoginRegistrationAction;
