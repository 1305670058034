import {
  mdiAlertCircleOutline,
  mdiAlertOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiInformationOutline,
} from '@/client/icons';
import { Icon } from '@mdi/react';
import clsx from 'clsx';
import type { FC, ReactNode } from 'react';
import type { Toast } from 'react-hot-toast';
import toast from 'react-hot-toast';

const TWToast: FC<{
  action?: ReactNode;
  className?: string;
  icon?: ReactNode;
  message: ReactNode;
  t: Toast;
}> = ({ action, className, icon, message, t }) => (
  <div
    className={clsx(
      { 'animate-enter': t.visible, 'animate-leave': !t.visible },
      'pointer-events-auto flex w-full max-w-xs rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 print:hidden',
      className,
    )}
  >
    <div className='w-0 flex-1 p-4'>
      <div className='flex items-center'>
        {icon && <div className='flex-shrink-0 pt-0.5'>{icon}</div>}
        <div className='ml-3 flex-1'>
          <p className='text-sm font-medium'>{message}</p>
        </div>
      </div>
    </div>
    {action && <div className='flex border-l border-gray-200/40'>{action}</div>}
  </div>
);

TWToast.displayName = 'TWToast';

type ToastHandler = (message: string | ReactNode) => string;

export interface ToastMessage {
  (message: string | ReactNode): string;

  dismiss(toastId?: string | undefined): void;
  error: ToastHandler;
  info: ToastHandler;
  remove(toastId?: string | undefined): void;
  success: ToastHandler;
  warning: ToastHandler;
}
export const toastMessage: ToastMessage = (message) => {
  return toast.custom((t) => <TWToast className='bg-white text-gray-900' message={message} t={t} />);
};

toastMessage.dismiss = toast.dismiss;

toastMessage.error = (message) => {
  return toast.custom((t) => (
    <TWToast
      className='bg-red-700 text-red-50'
      icon={<Icon path={mdiAlertCircleOutline} size={1} />}
      message={message}
      t={t}
    />
  ));
};

toastMessage.info = (message) => {
  return toast.custom((t) => (
    <TWToast
      className='bg-sky-500 text-sky-50'
      icon={<Icon path={mdiInformationOutline} size={1} />}
      message={message}
      t={t}
    />
  ));
};

toastMessage.remove = toast.remove;

toastMessage.success = (message) => {
  return toast.custom((t) => (
    <TWToast
      className='bg-green-600 text-green-50'
      icon={<Icon path={mdiCheckboxMarkedCircleOutline} size={1} />}
      message={message}
      t={t}
    />
  ));
};
toastMessage.warning = (message) => {
  return toast.custom((t) => (
    <TWToast
      className='bg-amber-600 text-amber-50'
      icon={<Icon path={mdiAlertOutline} size={1} />}
      message={message}
      t={t}
    />
  ));
};
