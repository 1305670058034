/**
 * Defince dostupnych navigaci.
 */
export enum NavigationType {
  categories = 'categories',
  horizontalNav = 'horizontalNav',
  main = 'main',
  mobileAfter = 'mobileAfter',
  topNav = 'topNav',
  topNavLeft = 'topNavLeft',
}
