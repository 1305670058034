import Dialog from '@/client/components/Dialog';
import type { FC } from 'react';
import type { LoginErrorReason } from '../../types';
import LoginFormModal from './LoginFormModal';
import { useAuthHandlerContext } from './useAuthHandlerContext';

export type LoginDialogProps = {
  close: () => void;
  loginError?: LoginErrorReason;
};

const LoginDialog: FC<LoginDialogProps> = ({ close, loginError }) => {
  const { handleLoginSubmit } = useAuthHandlerContext();

  return (
    <Dialog
      closeOnClickOutside
      onClose={close}
      open={true}
      renderModal={(dialogProps) => (
        <LoginFormModal {...dialogProps} loginError={loginError} onLogin={handleLoginSubmit} />
      )}
    />
  );
};

LoginDialog.displayName = 'LoginDialog';

export default LoginDialog;
