import { useCallback } from 'react';
import type { RecoilState } from 'recoil';
import { useSetRecoilState } from 'recoil';

export function useSetRecoilStateEventHandler<T>(recoilState: RecoilState<T>): (v: T) => () => void;
export function useSetRecoilStateEventHandler<T, V>(
  recoilState: RecoilState<T>,
  setter: (val: V, prevVal: T) => T,
): (v: V) => () => void;
export function useSetRecoilStateEventHandler<T, V>(
  recoilState: RecoilState<T>,
  setter?: (val: V, prevVal: T) => T,
): unknown {
  const setState = useSetRecoilState(recoilState);

  const innerSetter = useCallback(
    (v: V) =>
      (prev: T): T => {
        return setter ? setter(v as any, prev) : (v as any);
      },
    [setter],
  );

  return useCallback((v: V) => () => setState(innerSetter(v)), [innerSetter, setState]);
}
