import type { loadCustomer_CustomerQuery } from '@/graphql/__generated__/loadCustomer_CustomerQuery.graphql';
import { excludeFalsy } from '@/helpers';
import type { ContactBase } from '@/types';
import { convertToContactBase } from '@/types';
import type { IEnvironment } from 'relay-runtime';
import { fetchQuery, graphql } from 'relay-runtime';

export type CustomerInfoState =
  | ContactBase & {
      deliveryContacts: ContactBase[];
    };

// !
// ! In recoil-relay examples is query defined directlly in `graphQLSelector`.
// ! But this causes some unexpected behaviour. Prebuilded query used instead.
/* eslint-disable relay/must-colocate-fragment-spreads */
const loadCustomerQuery = graphql`
  query loadCustomer_CustomerQuery {
    customer {
      id
      emailAddress
      personName
      phoneNumber
      street
      city
      postalCode
      country
      companyName
      companyRegNo
      companyTaxNo
      contactType
      deliveryContacts(first: 100) {
        edges {
          node {
            id
            city
            companyName
            companyRegNo
            companyTaxNo
            contactType
            country
            emailAddress
            personName
            phoneNumber
            postalCode
            street
          }
        }
      }
    }
  }
`;
/* eslint-enable relay/must-colocate-fragment-spreads */

/**
 * @deprecated
 */
export async function loadCustomer(environment: IEnvironment): Promise<CustomerInfoState> {
  const data = await fetchQuery<loadCustomer_CustomerQuery>(environment, loadCustomerQuery, {}).toPromise();

  if (process.env.NODE_ENV !== 'production') {
    if (data == null) {
      console.error('Fetch of customer data failed.');
    }
  }

  const customer = data?.customer;
  if (customer == null) {
    throw new Error('Customer load failed.');
  }

  const customerContact = convertToContactBase(customer);

  const customerInfo: CustomerInfoState = {
    ...customerContact,
    deliveryContacts:
      customer.deliveryContacts?.edges
        ?.map((edge) =>
          edge?.node == null
            ? null
            : convertToContactBase({
                city: edge.node.city,
                companyName: edge.node.companyName,
                companyRegNo: edge.node.companyRegNo,
                companyTaxNo: edge.node.companyTaxNo,
                contactType: edge.node.contactType,
                country: edge.node.country,
                emailAddress: edge.node.emailAddress,
                id: edge.node.id,
                personName: edge.node.personName,
                phoneNumber: edge.node.phoneNumber,
                postalCode: edge.node.postalCode,
                street: edge.node.street,
              }),
        )
        .filter(excludeFalsy) ?? [],
  };

  return customerInfo;
}
