import { checkoutContextState } from '@/client/atoms';
import { toastMessage } from '@/client/compositions/AppToaster';
import { usePromisifyCommitMutation } from '@/client/hooks';
import type { useCartUpdateGift_addGiftMutation } from '@/graphql/__generated__/useCartUpdateGift_addGiftMutation.graphql';
import type { useCartUpdateGift_removeGiftMutation } from '@/graphql/__generated__/useCartUpdateGift_removeGiftMutation.graphql';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { graphql, useMutation } from 'react-relay';
import { useRecoilValue } from 'recoil';
import trpc from '../lib/trpc';

export type CartGiftDescriptor = { id: string; loyaltyPoints: number; sku: string; text: string };

export function useCartUpdateGift() {
  const checkoutCtx = useRecoilValue(checkoutContextState);
  const trpcCtx = trpc.useContext();
  const intl = useIntl();

  const [commitAddGiftMutation] = useMutation<useCartUpdateGift_addGiftMutation>(
    graphql`
      mutation useCartUpdateGift_addGiftMutation(
        $checkoutId: String!
        $locale: String!
        $gifts: [CartUpdateSubjectGiftInput!]!
      ) {
        checkout_cart_gifts_add(checkoutId: $checkoutId, locale: $locale, gifts: $gifts) {
          ok
          errors
          cart {
            ...cartState_cart
          }
        }
      }
    `,
  );

  const giftPrice = 0.1;
  const giftVat = 0.21;
  const addGitfAction = usePromisifyCommitMutation(
    commitAddGiftMutation,
    (checkoutId: string, gift: CartGiftDescriptor) => ({
      checkoutId,
      gifts: [
        {
          loyaltyPoints: gift.loyaltyPoints,
          operation: 'set' as const,
          price: {
            amount: giftPrice,
            amountWithTax: Number((giftPrice * (1 + giftVat)).toFixed(2)),
            vatAmount: giftVat,
            vatCode: giftVat.toString(),
          },
          productSku: gift.sku,
          quantity: 1,
          text: gift.text,
          unit: 'pcs',
        },
      ],
      locale: 'cs',
    }),
    (response) =>
      !response.checkout_cart_gifts_add.ok
        ? [new Error(response.checkout_cart_gifts_add.errors?.join(', ') ?? undefined)]
        : [null, response.checkout_cart_gifts_add.cart],
  );

  const [commitRemoveGiftMutation] = useMutation<useCartUpdateGift_removeGiftMutation>(
    graphql`
      mutation useCartUpdateGift_removeGiftMutation($checkoutId: String!, $locale: String!, $gifts: [NodeInput!]!) {
        checkout_cart_gifts_remove(checkoutId: $checkoutId, locale: $locale, gifts: $gifts) {
          ok
          errors
          cart {
            ...cartState_cart
          }
        }
      }
    `,
  );

  const removeGitfAction = usePromisifyCommitMutation(
    commitRemoveGiftMutation,
    (checkoutId: string, gift: { id: string }) => ({
      checkoutId,
      gifts: [{ id: gift.id }],
      locale: 'cs',
    }),
    (response) =>
      !response.checkout_cart_gifts_remove.ok
        ? [new Error(response.checkout_cart_gifts_remove.errors?.join(', ') ?? undefined)]
        : [null, response.checkout_cart_gifts_remove.cart],
  );

  return useMemo(
    () => ({
      add: async (gift: CartGiftDescriptor) => {
        await addGitfAction(checkoutCtx.checkoutId, gift);
        trpcCtx.cart.invalidate();
        toastMessage.success(
          intl.formatMessage({
            defaultMessage: 'Dárek byl přidán.',
            description: 'Information message after an gift was added to cart.',
            id: 'JMrc0c',
          }),
        );
      },
      remove: async (gift: { id: string }) => {
        await removeGitfAction(checkoutCtx.checkoutId, gift);
        trpcCtx.cart.invalidate();
        toastMessage.warning(
          intl.formatMessage({
            defaultMessage: 'Dárek byl odebrán.',
            description: 'Information message after an gift was removed from cart.',
            id: 'vxs3fn',
          }),
        );
      },
    }),
    [addGitfAction, checkoutCtx, intl, removeGitfAction, trpcCtx.cart],
  );
}
