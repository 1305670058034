import Alert from '@/client/components/Alert';
import type { FC } from 'react';

export type AppCustomHeaderTopToolbarErrorProps = {};

const AppCustomHeaderTopToolbarError: FC<AppCustomHeaderTopToolbarErrorProps> = () => <Alert>Chyba</Alert>;

AppCustomHeaderTopToolbarError.displayName = 'AppCustomHeaderTopToolbarError';

export default AppCustomHeaderTopToolbarError;
