import type { CheckoutPrsonalPickupInfo } from './CheckoutPrsonalPickupInfo';
import type { ContactBase } from './ContactBase';

export enum CheckoutDataDestiny {
  inquiry = 'inquiry',
  purchase = 'purchase',
  reservation = 'reservation',
}

export function convertToCheckoutDataDestiny<T extends string>(
  v: T | null | undefined,
  defaultValue = CheckoutDataDestiny.purchase,
): CheckoutDataDestiny {
  if (Object.values(CheckoutDataDestiny).includes(v as any)) return v as CheckoutDataDestiny;
  return defaultValue;
}

export function convertFromCheckoutDataDestiny(v: CheckoutDataDestiny): 'inquiry' | 'purchase' | 'reservation' {
  return v as any;
}

export enum CheckoutDataDeliveryInfoUtilization {
  assigned = 'assigned',
  customer = 'customer',
  inherit = 'inherit',
  invoice = 'invoice',
}

export function convertToCheckoutDataDeliveryInfoUtilization<T extends string>(
  v: T | null | undefined,
  defaultValue = CheckoutDataDeliveryInfoUtilization.inherit,
): CheckoutDataDeliveryInfoUtilization {
  if (Object.values(CheckoutDataDeliveryInfoUtilization).includes(v as any))
    return v as CheckoutDataDeliveryInfoUtilization;
  return defaultValue;
}

export function convertFromCheckoutDataDeliveryInfoUtilization(
  v: CheckoutDataDeliveryInfoUtilization,
): 'assigned' | 'inherit' | 'invoice' | 'customer' {
  return v;
}

export enum CheckoutDataInvoiceInfoUtilization {
  assigned = 'assigned',
  inherit = 'inherit',
}

export function convertToCheckoutDataInvoiceInfoUtilization(
  v: string | null | undefined,
  defaultValue = CheckoutDataInvoiceInfoUtilization.inherit,
): CheckoutDataInvoiceInfoUtilization {
  if (Object.values(CheckoutDataInvoiceInfoUtilization).includes(v as any))
    return v as CheckoutDataInvoiceInfoUtilization;
  return defaultValue;
}

export function convertFromCheckoutDataInvoiceInfoUtilization(
  v: CheckoutDataInvoiceInfoUtilization,
): 'assigned' | 'inherit' {
  return v;
}

export enum CheckoutDataOrderInfoUtilization {
  assigned = 'assigned',
  customer = 'customer',
  none = 'none',
}

export function convertToCheckoutDataOrderInfoUtilization(
  v: string | null | undefined,
  defaultValue = CheckoutDataOrderInfoUtilization.assigned,
): CheckoutDataOrderInfoUtilization {
  if (Object.values(CheckoutDataOrderInfoUtilization).includes(v as any)) return v as CheckoutDataOrderInfoUtilization;
  return defaultValue;
}

export function convertFromCheckoutDataOrderInfoUtilization(
  v: CheckoutDataOrderInfoUtilization,
): 'assigned' | 'customer' | 'none' {
  return v;
}

export interface CheckoutData {
  agreements: {
    doNotSendGoogle: boolean;
    doNotSendHeureka: boolean;
    doNotSendZbozi: boolean;
    gdpr: boolean;
    termsAndConditions: boolean;
  };

  customerOrderNumber: string | null;

  deliveryInfo: {
    contact: ContactBase | null;
    customerContact: ContactBase | null;
    utilization: CheckoutDataDeliveryInfoUtilization;
  };

  destiny: CheckoutDataDestiny;

  invoiceInfo: {
    contact: ContactBase | null;
    utilization: CheckoutDataInvoiceInfoUtilization;
  };

  notes: {
    parcel: string;
    seller: string;
  };

  orderInfo: {
    contact: ContactBase;
    customer: ContactBase | null;
    utilization: CheckoutDataOrderInfoUtilization;
  };

  personalPickupInfo: CheckoutPrsonalPickupInfo | null;
}
