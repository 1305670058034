import type { DialogRenderModalProps } from '@/client/components/Dialog';
import { DialogActions, DialogContent, DialogFormModal, DialogTitle } from '@/client/components/Dialog';
import { MsgErrorInvalidEmail, MsgErrorRequired } from '@/client/localized-messages';
import { EMAIL_ADDRESS_PATTERN } from '@/constants';
import type { FC, ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { usePasswordRenewCall } from '../../hooks';

export type PasswordRenewModalProps = DialogRenderModalProps;

const PasswordRenewModal: FC<PasswordRenewModalProps> = ({ close }) => {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset: resetForm,
  } = useForm<{ email: string }>({
    defaultValues: { email: '' },
  });

  const handleRenewPassword = usePasswordRenewCall({
    onSuccess() {
      close();
      resetForm();
    },
  });

  return (
    <DialogFormModal
      className='bg-primary-200 p-4'
      onSubmit={(event) => {
        event.stopPropagation();
        return handleSubmit(handleRenewPassword)(event);
      }}
    >
      <DialogTitle className='py-4' onCloseDialog={close} showCloseAction>
        <h1>
          <FormattedMessage defaultMessage='Zapomenuté heslo' description='Password renew dialog title' id='5zrcT+' />
        </h1>
      </DialogTitle>
      <DialogContent className='flex flex-col gap-4'>
        <div className='border border-slate-400 p-6'>
          <FormattedMessage
            defaultMessage='<p>Po odeslání formuláře bude ověřeno, že uvedený email je registrován a bude Vám na něj doručena zpráva s novým heslem.</p><p>Doporučujeme po prvním přihlášení vygenerované heslo změnit.</p><p>Pro Vaši větší bezpečnost heslo uchovejte v tajnosti, zapamatujte si jej nebo uložte do prohlížeče.</p>'
            description='Password renew dialog close action.'
            id='uPW1ir'
            values={{
              p: (ch: ReactNode) => <div>{ch}</div>,
            }}
          />
        </div>
        <div className='form-styles'>
          <div>
            <label htmlFor='email'>
              <FormattedMessage
                defaultMessage='Email'
                description='Password renew dialog email field label.'
                id='njdh7z'
              />
            </label>
            <input
              id='email'
              {...register('email', { pattern: EMAIL_ADDRESS_PATTERN, required: true })}
              aria-errormessage={
                {
                  pattern: 'email-err-format',
                  required: 'email-err-required',
                }[(errors?.email?.type ?? '') as string]
              }
              aria-invalid={errors?.email ? 'true' : 'false'}
              type='text'
            />
            {errors?.email?.type === 'required' && (
              <div id={'email-err-required'} role='alert'>
                <MsgErrorRequired />
              </div>
            )}
            {errors?.email?.type === 'pattern' && (
              <div id={'email-err-format'} role='alert'>
                <MsgErrorInvalidEmail />
              </div>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <button
          className='mr-4 p-[6px_12px] font-bold text-primary underline hover:no-underline'
          onClick={close}
          type='button'
        >
          <FormattedMessage defaultMessage='Zavřít' description='Password renew dialog close button' id='ox9B0f' />
        </button>
        <button
          className='max-w-[150px] cursor-pointer rounded-md bg-secondary-500 px-4 py-2 font-bold text-white hover:bg-secondary-600'
          disabled={isSubmitting}
          type='submit'
        >
          <FormattedMessage defaultMessage='Odeslat' description='Password renew dialog submit action.' id='4w5G/R' />
        </button>
      </DialogActions>
    </DialogFormModal>
  );
};

PasswordRenewModal.displayName = 'PasswordRenewModal';

export default PasswordRenewModal;
