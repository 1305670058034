import type { ProductBase } from '@/types';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { productComparisonProducts } from '../atoms';
import { toastMessage } from '../compositions/AppToaster';

export function useProductComparisonRemoveProduct() {
  const intl = useIntl();
  const [productsToCompare, setProductsToCompare] = useRecoilState(productComparisonProducts);

  return useCallback(
    (product: ProductBase) => {
      if (product && productsToCompare.some((p) => p.sku === product.sku)) {
        setProductsToCompare(productsToCompare.filter((p) => p.sku !== product.sku));
        toastMessage.info(
          intl.formatMessage({
            defaultMessage: 'Produkt byl odebrán z porovnání',
            description: 'Message after successfully removing product from comparison.',
            id: '2P2qCS',
          }),
        );
      } else {
        toastMessage.error(
          intl.formatMessage({
            defaultMessage: 'Nastala chyba',
            description: 'Message after error adding product to comparison.',
            id: 'GkeX9Z',
          }),
        );
      }
    },
    [intl, productsToCompare, setProductsToCompare],
  );
}
