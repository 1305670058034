import type { FormatNumberOptions, IntlShape } from 'react-intl';
import NumberParser from './NumberParser';

export default class AsYouTypeNumber {
  private _intl: IntlShape;
  private _formatOptions: FormatNumberOptions | undefined;
  private _parser: NumberParser;
  private _chars = '';
  private _number: number = Number.NaN;

  constructor(intl: IntlShape, formatOptions?: FormatNumberOptions) {
    this._intl = intl;
    this._formatOptions = formatOptions;
    this._parser = new NumberParser(intl);
  }

  input(inp: string | number | undefined): string {
    if (typeof inp === 'number' && !Number.isNaN(inp)) {
      const chrs = this._intl.formatNumber(inp, this._formatOptions);
      this._chars = chrs === '-0' ? '-' : chrs;
      this._number = inp;
    } else if (typeof inp === 'string' && inp !== '') {
      if (inp === '-') {
        this._chars = inp;
        this._number = -0;
      } else {
        const endCh = inp.slice(-1);
        const decimalOnEnd = this._parser._decimal.test(endCh);
        this._number = this._parser.parse(inp);
        this._chars = Number.isNaN(this._number)
          ? ''
          : `${this._intl.formatNumber(this._number, this._formatOptions)}${decimalOnEnd ? endCh : ''}`;
      }
    } else {
      this._chars = '';
      this._number = Number.NaN;
    }

    return this._chars;
  }

  getChars(): string {
    return this._chars;
  }

  getNumber(): number {
    return this._number;
  }

  getNumberDirty(): number | undefined {
    return Number.isNaN(this._number) ? undefined : this._number;
  }

  makeStrict(): void {
    this._number = this._parser.parse(this._chars);
    this._chars = Number.isNaN(this._number) ? '' : this._intl.formatNumber(this._number, this._formatOptions);
  }
}
