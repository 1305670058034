import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const MsgErrorRequired: FC = () => (
  <FormattedMessage
    defaultMessage='Pole je povinné.'
    description='Form field error message for required field.'
    id='WHIL58'
  />
);

MsgErrorRequired.displayName = 'MsgErrorRequired';

export default MsgErrorRequired;
