import type { BlogArticleCategory } from '@/types';
import { useIntl } from 'react-intl';

export function useBlogCategories(): Record<BlogArticleCategory, string> {
  const intl = useIntl();

  return {
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    akce: intl.formatMessage({
      defaultMessage: 'Akce',
      description: 'Action category name',
      id: 'Hb6sMK',
    }),
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    aktuality: intl.formatMessage({
      defaultMessage: 'Aktuality',
      description: 'News category name',
      id: '2gPNj2',
    }),
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    novinky: intl.formatMessage({
      defaultMessage: 'Novinky z naší firmy',
      description: 'News from our company',
      id: 'DSyjCa',
    }),
  };
}
