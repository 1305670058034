import AppbarLoginRegistrationAction from '@/client/compositions/AppbarLoginRegistrationAction';
import ChangePricesType from '@/client/compositions/ChangePricesType';
import GlobalSearchIconButton from '@/client/compositions/GlobalSearchIconButton';
import ShoppingCartIndicator from '@/client/compositions/ShoppingCartIndicator';
import { useRecoilStateEventHandler } from '@/client/hooks';
import { useWindowSizeIsDesktop } from '@/client/hooks/useWindowSizeIsDesktop';
import { mdiClose, mdiCloseThick, mdiMagnify, mdiMenu } from '@/client/icons';
import Icon from '@mdi/react';
import clsx from 'clsx';
import { useEffect, type FC, type HTMLAttributes } from 'react';
import { useRecoilValue } from 'recoil';
import { appCustomLayoutState } from '../../atoms/appCustomLayoutState';
import AppCustomHeaderLogo from '../AppCustomHeaderLogo';

export type AppCustomHeaderTopToolbarContentProps = HTMLAttributes<HTMLDivElement>;

const AppCustomHeaderTopToolbarContent: FC<AppCustomHeaderTopToolbarContentProps> = () => {
  const { isSearchOpen } = useRecoilValue(appCustomLayoutState);
  const isDesktop = useWindowSizeIsDesktop();

  return (
    <div className='flex w-full flex-wrap items-center text-toptoolbar-contrast lg:flex-nowrap'>
      <AppCustomHeaderLogo />
      <div
        className={clsx(
          'w-full',
          !isDesktop && isSearchOpen
            ? 'fixed top-16 left-0 flex justify-center bg-white px-2 pb-1 lg:hidden'
            : 'mx-auto mb-0 hidden lg:flex lg:justify-center',
        )}
      >
        <div className='w-full max-w-[570px]'>
          <GlobalSearchIconButton />
        </div>
      </div>
      <ChangePricesType className='hidden lg:flex' />
      <AppCustomMobileSearchOpenButton />
      <AppbarLoginRegistrationAction className='px-2' />
      <ShoppingCartIndicator />
      <AppCustomMobileMenuOpenButton />
    </div>
  );
};

AppCustomHeaderTopToolbarContent.displayName = 'AppCustomHeaderTopToolbarContent';

export default AppCustomHeaderTopToolbarContent;

const AppCustomMobileMenuOpenButton: FC = () => {
  const [layoutState, handleSetMobileOpen] = useRecoilStateEventHandler(
    appCustomLayoutState,
    (v: boolean, { isMobileMenuOpen, isSearchOpen, ...o }) => ({ isMobileMenuOpen: v, isSearchOpen: false, ...o }),
  );
  const { isMobileMenuOpen } = layoutState;
  return (
    <div>
      <button
        className='relative my-2 flex max-h-[52px] flex-col justify-center rounded-md bg-primary-200 py-2 px-1 lg:hidden'
        onClick={handleSetMobileOpen(!isMobileMenuOpen)}
      >
        {!isMobileMenuOpen ? (
          <Icon className='mb-2' path={mdiMenu} size='33px' />
        ) : (
          <Icon className='mb-2' path={mdiCloseThick} size='33px' />
        )}

        <span className='absolute bottom-[5px] left-[7px] flex text-[10px] text-black'>Menu</span>
      </button>
    </div>
  );
};

const AppCustomMobileSearchOpenButton: FC = () => {
  const isDesktop = useWindowSizeIsDesktop();

  const [layoutState, handleUpdateLayoutState] = useRecoilStateEventHandler(
    appCustomLayoutState,
    (v: boolean, { isMobileMenuOpen, isSearchOpen, ...o }) => ({ isMobileMenuOpen: false, isSearchOpen: v, ...o }),
  );
  const { isSearchOpen } = layoutState;

  useEffect(() => {
    if (isDesktop) {
      handleUpdateLayoutState(false);
    }
  }, [isDesktop, handleUpdateLayoutState]);

  return (
    <button className='ml-auto flex h-[30px] px-2 lg:ml-2 lg:hidden' onClick={handleUpdateLayoutState(!isSearchOpen)}>
      {isSearchOpen ? (
        <Icon path={mdiClose} size='33px' title='Zavřít vyhledávání' />
      ) : (
        <Icon path={mdiMagnify} size='33px' title='Vyhledat' />
      )}
    </button>
  );
};
