const hideHostsConfig = [
  ['https://mds-dev.sprinx.cz', 'https://mediaserver'],
  ['https://vybaveniprouklid.sprinx.com', 'https://cms'],
];

function hideHosts(src: string): string {
  if (src.startsWith(hideHostsConfig[0][0])) {
    return src.replace(hideHostsConfig[0][0], hideHostsConfig[0][1]);
  }
  if (src.startsWith(hideHostsConfig[1][0])) {
    return src.replace(hideHostsConfig[1][0], hideHostsConfig[1][1]);
  }
  return src;
}

export function revealHosts(src: string): string {
  if (src.startsWith(hideHostsConfig[0][1])) {
    return src.replace(hideHostsConfig[0][1], hideHostsConfig[0][0]);
  }
  if (src.startsWith(hideHostsConfig[1][1])) {
    return src.replace(hideHostsConfig[1][1], hideHostsConfig[1][0]);
  }
  return src;
}

export function modernImgLoader(src: string, width?: number): string {
  if (src == null || src === '') return '';
  // Prevent recursive replacement
  if (src.startsWith('/images/modern')) return src;
  let queryString = '';
  if (width) {
    if (width <= 50) {
      width = 50;
    }
    const qs = new URLSearchParams();
    qs.set('a', '4:3');
    qs.set('w', width.toString());
    queryString = `?${qs.toString()}`;
  }
  return `/images/modern/${encodeURIComponent(hideHosts(src))}${queryString}`;
}

export function modernImgLoaderNullish<D extends null | undefined>(
  emptyResult: D,
  src: string | null | undefined,
): string | D {
  if (src == null) return emptyResult;
  return modernImgLoader(src);
}

export function modernImgLoaderMediaSource<T extends { src: string }>(mediaSource: T): T {
  return {
    ...mediaSource,
    src: modernImgLoader(mediaSource.src),
  };
}

export function modernImgLoaderMediaSourceNullish<T extends { src: string }, D extends null | undefined>(
  emptyResult: D,
  mediaSource: T | null | undefined,
): T | D {
  if (mediaSource == null) return emptyResult;
  return modernImgLoaderMediaSource(mediaSource);
}
