import type {
  EcomCatalogueItem,
  PricesType,
  SupportedCurrency,
  SupportedLocale,
  SupportedRegion,
} from '@sprinx/knihovna-api-types';
import { Container as IocContainer } from 'typescript-ioc';
import { ResourcesContext } from './ResourcesContext';

export interface RelatedProductsResourceGetRelatedProductsParams {
  currency: SupportedCurrency;
  limit: number;
  locale: SupportedLocale;
  offset: number;
  orderBy: string[];
  priceGroups: string[];
  pricesType: PricesType;
  region: SupportedRegion;
  relationType: string;
  sku: string;
}

export abstract class RelatedProductsResource {
  public ctx: ResourcesContext;

  constructor() {
    this.ctx = IocContainer.get(ResourcesContext);
  }

  abstract getRelatedProducts(
    params: RelatedProductsResourceGetRelatedProductsParams,
  ): Promise<EcomCatalogueItem[] | null>;
}
