import { pricesTypeState } from '@/client/atoms';
import { AuthResource } from '@/resources';
import type { ContactBase, RegistrationInfo } from '@/types';
import { RegistrationResultWarningType } from '@/types';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { Container as IocContainer } from 'typescript-ioc';
import { toastMessage } from '../../AppToaster';

export function useRegistrationCall(handlers?: {
  onDuplicity?: () => void;
  onError?: () => void;
  onSuccess?: () => void;
}) {
  const intl = useIntl();
  const pricesType = useRecoilValue(pricesTypeState);

  return useCallback(
    (info: RegistrationInfo, deliveryInfo?: ContactBase): void => {
      const authResource = IocContainer.get(AuthResource);
      authResource
        .registration({
          deliveryInfo,
          info,
          locale: 'cs',
          region: 'CZ',
          userSettings: {
            currency: 'CZK',
            locale: 'cs',
            priceGroup: '_default',
            pricesType,
          },
        })
        .then((res) => {
          if (res.status === 'error' && res.reason === 'userNameAlreadyRegistered') {
            if (handlers?.onDuplicity) handlers.onDuplicity();
            toastMessage.warning(
              intl.formatMessage({
                defaultMessage: 'Ve formuláři registrace byly nalezeny chyby.',
                description: 'Snackbar message in case of validatioon error on server side during registration.',
                id: '+QdUDc',
              }),
            );
          } else if (res.status === 'error') {
            if (handlers?.onError) handlers.onError();
            toastMessage.error(
              intl.formatMessage({
                defaultMessage: 'Při vytváření registrace došlo k chybě. Zkuste opakovat znova.',
                description: 'Snackbar message if any common error occurend during registration.',
                id: 'B3YZ9O',
              }),
            );
          } else if (res.status === 'success') {
            if (handlers?.onSuccess) handlers.onSuccess();
            toastMessage.success(
              intl.formatMessage({
                defaultMessage: 'Registrace proběhla úspěšně. Přihlašte se.',
                description: 'Snackbar message if registration succeeded.',
                id: 'mR7OHG',
              }),
            );
            if (res.warnings && res.warnings.length > 0) {
              if (
                res.warnings.some(
                  (x) =>
                    x === RegistrationResultWarningType.emailNotificationFailed ||
                    x === RegistrationResultWarningType.emailTemplateNotFound,
                )
              ) {
                toastMessage.warning(
                  intl.formatMessage({
                    defaultMessage:
                      'Nezdařilo se odeslat email s vygenerovaným heslem. Prosím použijte obnovení hesla k získání nového.',
                    description: 'Snackbar message if registration succeeded and email notification failed.',
                    id: 'I2thih',
                  }),
                );
              }
            }
          }
        })
        .catch((err) => {
          if (process.env.NODE_ENV !== 'production') {
            console.error('Při vytváření registrace došlo k chybě.', err);
          }

          toastMessage.error(
            intl.formatMessage({
              defaultMessage: 'Při vytváření registrace došlo k chybě. Zkuste opakovat znova.',
              description: 'Registration common error message.',
              id: 'wo7K11',
            }),
          );
        });
    },
    [handlers, intl, pricesType],
  );
}
