import { DOMAIN } from '@/constants';
import { Helmet } from 'react-helmet-async';

type SeoProps = {
  canonicalPath?: string;
  description?: string | null;
  keywords?: string | null;
  pageTitle: string | undefined;
};

const SeoHelmet = ({ canonicalPath, description, keywords, pageTitle }: SeoProps) => {
  return (
    <Helmet prioritizeSeoTags>
      {pageTitle && <title>{pageTitle}</title>}
      {description && <meta content={description} name='description' />}
      {keywords && <meta content={keywords} name='keywords' />}
      {canonicalPath && <link href={DOMAIN + canonicalPath} rel='canonical' />}
    </Helmet>
  );
};

SeoHelmet.displayName = 'SeoHelmet';

export default SeoHelmet;
