import EventEmitter from 'eventemitter3';
export class ResourcesContext {
  private eventEmitter = new EventEmitter();
  private alreadyLoadedFromGlobal = false;

  accessToken: string | undefined;
  accessTokenType: string | undefined;

  constructor(partial?: Partial<ResourcesContext>) {
    Object.assign(this, partial);
  }

  get authorization(): string {
    return `${this.accessTokenType ?? 'Bearer'} ${this.accessToken}`;
  }

  loadFromGlobal(force = false) {
    if ((force || (!force && !this.alreadyLoadedFromGlobal)) && typeof window !== 'undefined') {
      this.alreadyLoadedFromGlobal = true;
      const accessToken = (window as any).appContext?.accessToken as string | undefined;
      const accessTokenType = (window as any).appContext?.accessTokenType as string | undefined;
      this.update(accessTokenType, accessToken);
    }
  }

  update(accessTokenType: string | null | undefined, accessToken: string | null | undefined) {
    this.accessToken = accessToken ?? undefined;
    this.accessTokenType = accessTokenType ?? undefined;

    this.eventEmitter.emit('updated', { accessToken: this.accessToken, accessTokenType: this.accessTokenType });
  }

  onUpdated(handler: (accessTokenState: { accessToken: string; accessTokenType: string }) => void): {
    cleanup: () => void;
  } {
    this.eventEmitter.addListener('updated', handler);

    return {
      cleanup: () => {
        this.eventEmitter.removeListener('updated', handler);
      },
    };
  }
}
