/**
 * @generated SignedSource<<b82834292ec11f52d733f09ebb04aadf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodeInput = {
  id: string;
};
export type useCartUpdateGift_removeGiftMutation$variables = {
  checkoutId: string;
  gifts: ReadonlyArray<NodeInput>;
  locale: string;
};
export type useCartUpdateGift_removeGiftMutation$data = {
  readonly checkout_cart_gifts_remove: {
    readonly cart: {
      readonly " $fragmentSpreads": FragmentRefs<"cartState_cart">;
    } | null;
    readonly errors: ReadonlyArray<string> | null;
    readonly ok: boolean;
  };
};
export type useCartUpdateGift_removeGiftMutation = {
  response: useCartUpdateGift_removeGiftMutation$data;
  variables: useCartUpdateGift_removeGiftMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "checkoutId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gifts"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v3 = [
  {
    "kind": "Variable",
    "name": "checkoutId",
    "variableName": "checkoutId"
  },
  {
    "kind": "Variable",
    "name": "gifts",
    "variableName": "gifts"
  },
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountWithTax",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "price",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productSku",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "subjectTotal",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatCode",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subjectType",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timing",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "CartSubjectDiscount",
  "kind": "LinkedField",
  "name": "discounts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discountCodes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceSimple",
      "kind": "LinkedField",
      "name": "totalDiscount",
      "plural": false,
      "selections": [
        (v8/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCartUpdateGift_removeGiftMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CartMutationResult",
        "kind": "LinkedField",
        "name": "checkout_cart_gifts_remove",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "cartState_cart",
                "selections": [
                  (v6/*: any*/),
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "cartSubjectsState_cart_subjects",
                    "selections": [
                      {
                        "alias": null,
                        "args": (v7/*: any*/),
                        "concreteType": "CartSubjectConnection",
                        "kind": "LinkedField",
                        "name": "subjects",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CartSubjectConnectionEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v14/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductCommerce",
                                    "kind": "LinkedField",
                                    "name": "product",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ProductStockQuantity",
                                        "kind": "LinkedField",
                                        "name": "stockAvailableQuantity",
                                        "plural": false,
                                        "selections": [
                                          (v15/*: any*/),
                                          (v11/*: any*/),
                                          (v16/*: any*/),
                                          (v17/*: any*/),
                                          (v18/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "CartSubjectIndirect",
                                        "kind": "LinkedField",
                                        "name": "indirectSubjects",
                                        "plural": true,
                                        "selections": [
                                          (v10/*: any*/),
                                          (v11/*: any*/),
                                          (v12/*: any*/),
                                          (v13/*: any*/),
                                          (v14/*: any*/),
                                          (v19/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v20/*: any*/)
                                    ],
                                    "type": "CartSubjectPrimary",
                                    "abstractKey": null
                                  },
                                  (v19/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "subjects(first:100)"
                      }
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "useCartUpdateGift_removeGiftMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CartMutationResult",
        "kind": "LinkedField",
        "name": "checkout_cart_gifts_remove",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "CartSubjectConnection",
                "kind": "LinkedField",
                "name": "subjects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CartSubjectConnectionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductCommerce",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductStockQuantity",
                                "kind": "LinkedField",
                                "name": "stockAvailableQuantity",
                                "plural": false,
                                "selections": [
                                  (v15/*: any*/),
                                  (v11/*: any*/),
                                  (v16/*: any*/),
                                  (v17/*: any*/),
                                  (v18/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CartSubjectIndirect",
                                "kind": "LinkedField",
                                "name": "indirectSubjects",
                                "plural": true,
                                "selections": [
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v14/*: any*/),
                                  (v19/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v20/*: any*/)
                            ],
                            "type": "CartSubjectPrimary",
                            "abstractKey": null
                          },
                          (v19/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "subjects(first:100)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cd173f855eb2d5f914800eee15a17cca",
    "id": null,
    "metadata": {},
    "name": "useCartUpdateGift_removeGiftMutation",
    "operationKind": "mutation",
    "text": "mutation useCartUpdateGift_removeGiftMutation(\n  $checkoutId: String!\n  $locale: String!\n  $gifts: [NodeInput!]!\n) {\n  checkout_cart_gifts_remove(checkoutId: $checkoutId, locale: $locale, gifts: $gifts) {\n    ok\n    errors\n    cart {\n      ...cartState_cart\n      id\n    }\n  }\n}\n\nfragment cartState_cart on Cart {\n  id\n  ...cartSubjectsState_cart_subjects\n}\n\nfragment cartSubjectsState_cart_subjects on Cart {\n  subjects(first: 100) {\n    edges {\n      node {\n        __typename\n        price {\n          amount\n          amountWithTax\n        }\n        quantity\n        productSku\n        subjectTotal {\n          amount\n          amountWithTax\n          vatAmount\n          vatCode\n        }\n        subjectType\n        product {\n          stockAvailableQuantity {\n            note\n            quantity\n            state\n            timing\n            unit\n            id\n          }\n          id\n        }\n        ... on CartSubjectPrimary {\n          indirectSubjects {\n            price {\n              amount\n              amountWithTax\n            }\n            quantity\n            productSku\n            subjectTotal {\n              amount\n              amountWithTax\n              vatAmount\n              vatCode\n            }\n            subjectType\n            text\n            id\n          }\n          discounts {\n            discountCodes\n            totalDiscount {\n              amount\n              amountWithTax\n              currency\n            }\n          }\n        }\n        text\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d9ddac81e1e29ef14ac26f051b711f73";

export default node;
