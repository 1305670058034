import type { cartState_cart$data, cartState_cart$key } from '@/graphql/__generated__/cartState_cart.graphql';
import { selector } from 'recoil';
import { graphql, readInlineData } from 'relay-runtime';
import invariant from 'tiny-invariant';
import { checkoutState } from './checkoutState';

/* eslint-disable relay/must-colocate-fragment-spreads */
const fragmentNode = graphql`
  fragment cartState_cart on Cart @inline {
    id
    ...cartSubjectsState_cart_subjects
  }
`;
/* eslint-enable relay/must-colocate-fragment-spreads */

export const cartState = selector<{ fragmentKey: cartState_cart$data; id: string }>({
  get: ({ get }) => {
    const checkout = get(checkoutState);
    invariant(checkout.cart);
    const cartData = readInlineData<cartState_cart$key>(fragmentNode, checkout.cart);
    return {
      fragmentKey: cartData,
      id: cartData.id,
    };
  },
  key: 'cartState',
});
