export enum ContactType {
  company = 'company',
  office = 'office',
  person = 'person',
}

export function convertToContactType<T extends string>(
  v: T | null | undefined,
  defaultValue = ContactType.person,
): ContactType {
  if (Object.values(ContactType).includes(v as any)) return v as ContactType;
  return defaultValue;
}

export function convertFromContactType(v: ContactType): 'company' | 'office' | 'person' {
  return v;
}
