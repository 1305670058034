import type { ProductBase } from '@/types';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { recentlyViewedProducts } from '../atoms';

export function useSetRecentlyViewedProducts() {
  const setRecentlyViewed = useSetRecoilState(recentlyViewedProducts);

  return useCallback(
    (product: ProductBase) => {
      setRecentlyViewed((prev) => {
        if (product && !prev.some((p) => p.sku === product.sku)) {
          if (prev && prev.length >= 20) {
            return prev.slice(19, 20);
          }
          return [product, ...prev];
        }
        return prev;
      });
    },
    [setRecentlyViewed],
  );
}
