import type { FC } from 'react';

const AppCustomFooterLinkBlocksSkeleton: FC = () => (
  <>
    <div className='w-full animate-pulse overflow-hidden pb-6 text-sm sm:my-1 sm:w-1/2 sm:px-1 md:my-px md:w-1/2 md:px-px lg:my-2 lg:w-1/4 lg:px-2 xl:my-2 xl:w-1/4 xl:px-2'>
      <div className='h-4 w-full' />
      <div className='h-4 w-full' />
      <div className='h-4 w-full' />
      <div className='h-4 w-full' />
      <div className='h-4 w-full' />
      <div className='h-4 w-full' />
    </div>
    <div className='w-full animate-pulse overflow-hidden pb-6 text-sm sm:my-1 sm:w-1/2 sm:px-1 md:my-px md:w-1/2 md:px-px lg:my-2 lg:w-1/4 lg:px-2 xl:my-2 xl:w-1/4 xl:px-2'>
      <div className='h-4 w-full' />
      <div className='h-4 w-full' />
      <div className='h-4 w-full' />
      <div className='h-4 w-full' />
    </div>
  </>
);

AppCustomFooterLinkBlocksSkeleton.displayName = 'AppCustomFooterLinkBlocksSkeleton';

export default AppCustomFooterLinkBlocksSkeleton;
