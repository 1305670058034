import { productPackResolve } from '@/common/productPackResolve';
import type { ProductPack } from '@/types';
import { ProductPackType } from '@/types';
import { useMemo } from 'react';

export function useProductPackages(productSku: string, productHasPackCarton: boolean): ProductPack[] {
  const pack = productPackResolve(productSku);

  return useMemo(() => {
    const data: ProductPack[] = [];
    data.push({ packType: ProductPackType.pcs, parentSku: pack.parentSku, sku: pack.parentSku });

    if (productHasPackCarton) {
      data.push({ packType: ProductPackType.carton, parentSku: pack.parentSku, sku: `${pack.parentSku}-carton` });
    }

    return data;
  }, [pack.parentSku, productHasPackCarton]);
}
