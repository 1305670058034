import { useRouteUrl } from '@/client/hooks';
import { mdiPlusThick } from '@/client/icons';
import Icon from '@mdi/react';
import clsx from 'clsx';
import type { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

type CartSummaryBulkAddLinkProps = {
  className?: string;
};

const CartSummaryBulkAddLink: FC<CartSummaryBulkAddLinkProps> = ({ className }) => {
  const intl = useIntl();
  const routeUrl = useRouteUrl();

  return (
    <RouterLink
      className={clsx('mr-2 flex flex-row items-center text-primary underline hover:no-underline', className)}
      to={routeUrl('cartBulkAdd', {})}
    >
      <Icon className='text-primary' path={mdiPlusThick} size='1rem' />
      <span>
        {intl.formatMessage({
          defaultMessage: 'Hromadný nákup podle kódů',
          description: 'Content of link to page with bulk add to cart.',
          id: '5c2zf+',
        })}
      </span>
    </RouterLink>
  );
};

export default CartSummaryBulkAddLink;
