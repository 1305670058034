import { NotificationResource } from '@/resources/NotificationResource';
import type { NotificationSendEmailRequest } from '@/types';
import type { CmmSendmailResult } from '@sprinx/knihovna-api-types';
import axios from 'axios';

export class ClientNotificationResource extends NotificationResource {
  async sendEmail(params: NotificationSendEmailRequest): Promise<CmmSendmailResult | null> {
    return axios
      .post('/api/notification/sendEmail', params)
      .then((result) => result.data)
      .catch(() => {
        return { status: 'error' };
      });
  }
}
