import { productDetailOffsetTopState } from '@/client/atoms';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import AppCustomHeaderTopBar from '../AppCustomHeaderTopBar';
import AppCustomHeaderTopHorizontalNav from '../AppCustomHeaderTopHorizontalNav';
import AppCustomHeaderTopStatusBar from '../AppCustomHeaderTopStatusBar';
import AppCustomHeaderTopToolbar from '../AppCustomHeaderTopToolbar';

export type AppCustomLayoutHeaderProps = {};

const AppCustomLayoutHeader: FC<AppCustomLayoutHeaderProps> = () => {
  const navRef = useRef<HTMLDivElement>(null);

  const setProductDetailOffsetTopState = useSetRecoilState(productDetailOffsetTopState);

  useEffect(() => {
    if (typeof navRef?.current?.offsetHeight === 'number') setProductDetailOffsetTopState(navRef.current.offsetHeight);
  }, [setProductDetailOffsetTopState]);

  return (
    <>
      {/* Top bars are both sticky on top of a page */}
      <div ref={navRef} className='sticky top-0 z-50 print:hidden'>
        <AppCustomHeaderTopStatusBar />
        <AppCustomHeaderTopBar />
        <AppCustomHeaderTopToolbar />
        {/* <AppCustomHeaderInvitationToRegister /> */}
      </div>
      <AppCustomHeaderTopHorizontalNav />
    </>
  );
};

AppCustomLayoutHeader.displayName = 'AppCustomLayoutHeader';
export default AppCustomLayoutHeader;
