import Alert from '@/client/components/Alert';
import type { DialogRenderModalProps } from '@/client/components/Dialog';
import { DialogModal, DialogTitle } from '@/client/components/Dialog';
import HtmlContainer from '@/client/components/HtmlContainer';
import { mdiAccount, mdiLock } from '@/client/icons';
import trpc from '@/client/lib/trpc';
import { MsgErrorRequired } from '@/client/localized-messages';
import Icon from '@mdi/react';
import ms from 'ms';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import type { LoginErrorReason, LoginFormCredentials } from '../../types';
import PasswordRenewButton from '../PasswordRenewButton';
import RegisterButton from '../RegisterButton';

export type LoginFormModalProps = {
  loginError?: LoginErrorReason;
  onLogin: (credentials: LoginFormCredentials) => void;
} & DialogRenderModalProps;

const LoginFormModal: FC<LoginFormModalProps> = ({ close, loginError = null, onLogin }) => {
  const {
    formState: { errors, isSubmitting, submitCount },
    handleSubmit,
    register,
    setValue,
  } = useForm<LoginFormCredentials>();
  const registrationBanner = trpc.banners.textBanner.useQuery({ position: 'registration' }, { staleTime: ms('2h') });

  useEffect(() => {
    if (loginError) {
      setValue('password', '', { shouldDirty: true, shouldTouch: true });
    }
  }, [loginError, submitCount, setValue]);

  return (
    <DialogModal className='bg-primary-200 p-4'>
      <DialogTitle onCloseDialog={close} showCloseAction>
        <h1>
          <FormattedMessage defaultMessage='Přihlášení' description='Title of the login form.' id='5iZBfz' />
        </h1>
      </DialogTitle>
      <div className='flex flex-col lg:flex-row'>
        <div className='w-full px-2 pb-4 sm:p-6 sm:pb-4 lg:w-1/2'>
          {loginError && loginError === 'credentials' && (
            <Alert className='my-2' severity='error'>
              <FormattedMessage
                defaultMessage='Nesprávné uživatelské jméno, nebo heslo.'
                description='Login error message in case of incorrect username or password.'
                id='XBoUbT'
              />
            </Alert>
          )}
          {loginError && loginError === 'fatal' && (
            <Alert className='my-2' severity='error'>
              <FormattedMessage
                defaultMessage='Nastala chyba při komunikaci se serverem. Zkuste znovu prosím.'
                description='Login error message in case of communication with the server fails.'
                id='+LMGIm'
              />
            </Alert>
          )}
          <form onSubmit={handleSubmit(onLogin)}>
            <div className='form-styles'>
              <div className='mb-2'>
                <label className='font-bold' htmlFor='username'>
                  <FormattedMessage
                    defaultMessage='Váš email'
                    description='Label of of the username form field.'
                    id='wLCde1'
                  />
                </label>
                <div className='relative'>
                  <Icon className='absolute top-4 left-[10px] text-black' path={mdiAccount} size='1.3rem' />
                </div>
                <input
                  aria-placeholder='Zadejte Váš email'
                  className='pl-10'
                  id='username'
                  placeholder='Zadejte Váš email'
                  type='text'
                  {...register('username', { required: true })}
                  aria-errormessage={
                    {
                      required: 'username-err-required',
                    }[(errors?.username?.type ?? '') as string]
                  }
                  aria-invalid={errors?.username ? 'true' : 'false'}
                />
                {errors?.username?.type === 'required' && (
                  <div id={'email-username-required'} role='alert'>
                    <MsgErrorRequired />
                  </div>
                )}
              </div>
              <div>
                <label className='font-bold' htmlFor='password'>
                  <FormattedMessage
                    defaultMessage='Heslo'
                    description='Label of of the password form field.'
                    id='fH4Aw+'
                  />
                </label>
                <div className='relative'>
                  <Icon className='absolute top-4 left-[10px] text-black' path={mdiLock} size='1.3rem' />
                </div>
                <input
                  aria-placeholder='Zadejte heslo'
                  className='pl-10'
                  id='password'
                  placeholder='Zadejte heslo'
                  type='password'
                  {...register('password')}
                />
              </div>
            </div>
            <div className='mt-4 flex justify-between py-2 '>
              <button
                className='max-w-[150px] rounded-md bg-orange px-4 py-2 font-bold text-white hover:bg-orange-600'
                disabled={isSubmitting}
                type='submit'
              >
                <FormattedMessage defaultMessage='Přihlásit' description='Label of the login button.' id='4K+/AG' />
              </button>
              <PasswordRenewButton className='underline' type='button' />
            </div>
          </form>
        </div>
        <div className='flex w-full flex-col items-center px-2 pb-4 sm:pb-4 lg:w-1/2'>
          {/* <p>DOPLNIT PREKLAD - textace proc by se mel registrovat</p> */}
          {registrationBanner.data != null
            ? registrationBanner.data.map((i, idx) => (
                <div key={idx.toString()}>
                  <h2 className='felx-row mb-4 flex items-center text-xl text-text'>{i.title}</h2>
                  <HtmlContainer className='html-container-checklist list-test ml-2 text-left' html={i.text ?? ''} />
                </div>
              ))
            : null}

          <div className='mt-auto mb-2'>
            <RegisterButton
              className='rounded-md bg-orange px-4 py-2 font-bold  text-white hover:bg-orange-600'
              onClick={() => {
                close();
              }}
            />
          </div>
        </div>
      </div>
    </DialogModal>
  );
};

LoginFormModal.displayName = 'LoginFormModal';

export default LoginFormModal;
