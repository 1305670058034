import { useSetRecoilStateEventHandler } from '@/client/hooks';
import type { RefAttributes } from 'react';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { appCustomLayoutState } from '../../atoms';

export type AppCustomHeaderNavBarLogoProps = RefAttributes<HTMLAnchorElement> & {};

const AppCustomHeaderNavBarLogo = forwardRef<HTMLAnchorElement, AppCustomHeaderNavBarLogoProps>((props, ref) => {
  const handleSetMobileOpen = useSetRecoilStateEventHandler(
    appCustomLayoutState,
    (v: boolean, { isMobileMenuOpen, ...o }) => ({ isMobileMenuOpen: v, ...o }),
  );

  return (
    <RouterLink
      ref={ref}
      className='mr-2 block h-[33px] w-[130px] min-w-[130px] lg:h-[68px] lg:min-w-[267px]'
      onClick={handleSetMobileOpen(false)}
      to='/'
    >
      <img alt='logo' className='object-contain object-left' height={135} src='/images/logos/logo.webp' width={534} />
    </RouterLink>
  );
});

AppCustomHeaderNavBarLogo.displayName = 'AppCustomHeaderNavBarLogo';

export default AppCustomHeaderNavBarLogo;
