/**
 * @generated SignedSource<<bcd7541e6b4d9d6c01558893717d11af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserExistingCartDecision_loadCartPreviewQuery$variables = {
  cartId: string;
  locale: string;
};
export type UserExistingCartDecision_loadCartPreviewQuery$data = {
  readonly cart: {
    readonly createdAt: any;
    readonly subjects: {
      readonly edges: ReadonlyArray<{
        readonly __id: string;
        readonly cursor: string;
        readonly node: {
          readonly product: {
            readonly primaryImage: {
              readonly src: string;
            } | null;
          } | null;
        };
      }> | null;
    };
  };
};
export type UserExistingCartDecision_loadCartPreviewQuery = {
  response: UserExistingCartDecision_loadCartPreviewQuery$data;
  variables: UserExistingCartDecision_loadCartPreviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cartId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locale"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cartId",
    "variableName": "cartId"
  },
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "src",
  "storageKey": null
},
v6 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserExistingCartDecision_loadCartPreviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Cart",
        "kind": "LinkedField",
        "name": "cart",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "CartSubjectConnection",
            "kind": "LinkedField",
            "name": "subjects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CartSubjectConnectionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductCommerce",
                        "kind": "LinkedField",
                        "name": "product",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductMediaItem",
                            "kind": "LinkedField",
                            "name": "primaryImage",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "subjects(first:1000)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserExistingCartDecision_loadCartPreviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Cart",
        "kind": "LinkedField",
        "name": "cart",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "CartSubjectConnection",
            "kind": "LinkedField",
            "name": "subjects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CartSubjectConnectionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductCommerce",
                        "kind": "LinkedField",
                        "name": "product",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductMediaItem",
                            "kind": "LinkedField",
                            "name": "primaryImage",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "subjects(first:1000)"
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d79c05a796f51e381a38125ff2d7a80e",
    "id": null,
    "metadata": {},
    "name": "UserExistingCartDecision_loadCartPreviewQuery",
    "operationKind": "query",
    "text": "query UserExistingCartDecision_loadCartPreviewQuery(\n  $cartId: ID!\n  $locale: String!\n) {\n  cart(cartId: $cartId, locale: $locale) {\n    createdAt\n    subjects(first: 1000) {\n      edges {\n        cursor\n        node {\n          __typename\n          product {\n            primaryImage {\n              src\n              id\n            }\n            id\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "408a205762c08121ba36c49f7843ec6b";

export default node;
