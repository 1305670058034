import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const MsgCartBuy: FC = () => (
  <FormattedMessage defaultMessage='Koupit' description='Název akce pro tlačítkou Koupit' id='29ptH/' />
);

MsgCartBuy.displayName = 'MsgCartBuy';

export default MsgCartBuy;
