import type { NotificationSendEmailRequest } from '@/types';
import type { CmmSendmailResult } from '@sprinx/knihovna-api-types';
import { Container as IocContainer } from 'typescript-ioc';
import { ResourcesContext } from './ResourcesContext';

export abstract class NotificationResource {
  public ctx: ResourcesContext;

  constructor() {
    this.ctx = IocContainer.get(ResourcesContext);
  }

  abstract sendEmail(params?: NotificationSendEmailRequest): Promise<CmmSendmailResult | null>;
}
