import type { SupportedLocale } from '@sprinx/knihovna-api-types';
import { routesDefinition } from './routes-definition';

/**
 * Returns a `path` from roteDefinitions by `type` and `opts.locale`.
 *
 * ```
 * routePath('home', { locale }); // => '/'
 * routePath('productDetail', { locale }); // => '/:sn([^/]+)-product-:id([^/]+)'
 * ```
 */
export function routePath(type: keyof typeof routesDefinition, opts: { locale: SupportedLocale }): string {
  const def = routesDefinition[type];
  return typeof def.path === 'string' ? def.path : def.path[opts.locale];
}
