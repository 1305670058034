import type { ProductBase } from '@/types';
import type { AtomEffect } from 'recoil';
import { atom } from 'recoil';

const sessionStorageEffect =
  (key: string): AtomEffect<ProductBase[]> =>
  ({ onSet, setSelf }): void => {
    if (typeof window !== 'undefined') {
      const savedProducts = sessionStorage.getItem(key);
      if (savedProducts != null) {
        setSelf(JSON.parse(savedProducts));
      }
      onSet((newValue) => {
        sessionStorage.setItem(key, JSON.stringify(newValue));
      });
    }
  };

export const recentlyViewedProducts = atom<ProductBase[]>({
  default: [],
  effects: [sessionStorageEffect('recentlyViewedProducts')],
  key: 'recentlyViewedProducts',
});
