import { mdiImageBrokenVariant } from '@/client/icons';
import { Icon } from '@mdi/react';
import clsx from 'clsx';
import type { HTMLAttributes, Ref, RefAttributes } from 'react';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import type { AspectRatioOptions } from '../ImageWrapper';
import ImageWrapper from '../ImageWrapper';

export type ImageSimpleProps = HTMLAttributes<HTMLDivElement> &
  RefAttributes<HTMLDivElement> & {
    alt: string;
    aspectRatio?: AspectRatioOptions;
    fit?: 'cover' | 'contain';
    imgClassName?: string;
    imgRef?: Ref<HTMLImageElement>;
    src: string;
  };

const ImageSimple = forwardRef<HTMLDivElement, ImageSimpleProps>(
  ({ alt, aspectRatio, className, fit = 'cover', imgClassName, imgRef: pImgRef, src, ...rest }, ref) => {
    const [loadingError, setLoadingError] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);
    useImperativeHandle(pImgRef, () => imgRef.current as any, []);

    return (
      <ImageWrapper ref={ref} aspectRatio={aspectRatio} {...rest}>
        <img
          ref={imgRef}
          alt={alt}
          className={clsx(
            fit === 'contain' && 'object-contain',
            fit === 'cover' && 'object-cover',
            'object-center',
            loadingError && 'opacity-0',
            imgClassName,
          )}
          onError={() => setLoadingError(true)}
          src={src}
        />
        <div className='flex items-center justify-center'>
          <Icon className={clsx('opacity-0', loadingError && 'opacity-100')} path={mdiImageBrokenVariant} size='40%' />
        </div>
      </ImageWrapper>
    );
  },
);

ImageSimple.displayName = 'ImageSimple';

export default ImageSimple;
