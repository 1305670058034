import { createPopper } from '@popperjs/core';
import clsx from 'clsx';
import type { FC, ReactNode } from 'react';
import { useEffect, useRef } from 'react';

type TooltipProps = {
  children: ReactNode;
  referenceElement: Element | null;
};

const Tooltip: FC<TooltipProps> = ({ children, referenceElement }) => {
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (referenceElement && tooltipRef.current) {
      createPopper(referenceElement, tooltipRef.current, {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ],
        placement: 'bottom',
        strategy: 'absolute',
      });
    }
  }, [referenceElement]);

  return (
    <div ref={tooltipRef} className={clsx('z-30 rounded bg-gray-800 px-2 py-1 text-xs text-white shadow-xl')}>
      {children}
    </div>
  );
};

export default Tooltip;
