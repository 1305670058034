import { productPackResolve } from '@/common/productPackResolve';
import type { ProductAdditionalServiceRelated, ProductSimplePrice } from '@/types';
import { ProductPackType } from '@/types';
import type { EcomCartUpdateSubject } from '@sprinx/knihovna-api-types';
import { EcomCartUpdateSubjectOperation } from '@sprinx/knihovna-api-types';
import { useCallback } from 'react';
import { useShoppingCartUpdateMultipleSubjects } from './useShoppingCartUpdateMultipleSubjects';

export function useShoppingCartUpdateSubject() {
  const updateCartBatch = useShoppingCartUpdateMultipleSubjects();

  return useCallback(
    async (
      product: EcomCartUpdateSubject & { externalId?: string; name?: string },
      quantity: number,
      packType: ProductPackType,
      prices?: ProductSimplePrice | null,
      additionalServices?: ProductAdditionalServiceRelated[],
      operation: EcomCartUpdateSubjectOperation = EcomCartUpdateSubjectOperation.set,
    ) => {
      // Pokud jsou do kosiku poslany ceny produktu, jsou fixni.
      // Pro kartonovy produkt musim posilat cenu - neni doreseno na strane api
      const productPack = productPackResolve(product.sku);

      const _product: EcomCartUpdateSubject & { externalId?: string; name?: string | undefined } = {
        ...product,
        sku: packType === ProductPackType.carton && productPack ? `${productPack.parentSku}-carton` : product.sku,
      };

      return updateCartBatch([
        {
          additionalServices,
          operation,
          prices,
          product: _product,
          quantity,
        },
      ]);
    },
    [updateCartBatch],
  );
}
