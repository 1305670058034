import type { FC, HTMLAttributes } from 'react';

export type AppCustomFooterSocialsProps = HTMLAttributes<HTMLDivElement> & {};

const AppCustomFooterSocials: FC<AppCustomFooterSocialsProps> = ({ className, ...props }) => (
  // <div className={clsx('grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:flex', className)} {...props}>
  <div className='print:hidden' {...props}>
    <div className='container flex flex-col md:flex-row'>
      <div className='flex flex-row items-center justify-around gap-12 md:w-1/2 md:justify-start'>
        <div className='max-w-[25%]'>
          <a href='https://obchody.heureka.cz/vybaveniprouklid-cz/recenze/'>
            <img alt='heureka' height='105' loading='lazy' src='/images/brands/heureka.png' width='105' />
          </a>
        </div>
        <div className=' max-w-[25%]'>
          <a href='https://www.apek.cz/seznam-clenu/allservices-group-sro'>
            <img alt='apek' height='91' loading='lazy' src='/images/brands/apek.webp' width='91' />
          </a>
        </div>
        <div className='mr-4 self-center'>
          <a href='https://www.ekokom.cz/'>
            <img alt='ekokom' height='44' loading='lazy' src='/images/brands/ekokom.png' width='197' />
          </a>
        </div>
      </div>
      <div className='social-links mt-4 flex w-full flex-row items-center justify-around bg-primary py-4 text-white sm:ml-0 sm:mr-0 sm:rounded-full md:mt-0 md:w-1/2'>
        <div>
          <a className='flex flex-col items-center sm:flex-row' href='https://www.facebook.com/vybaveniprouklid.cz/'>
            <div className='flex h-10 w-10 items-center justify-center'>
              <svg className='fill-white' height='34' viewBox='0 0 24 24' width='34'>
                <path d='M19 0H5a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5zm-3 7h-1.924C13.461 7 13 7.252 13 7.889V9h3l-.238 3H13v8h-3v-8H8V9h2V7.077C10 5.055 11.064 4 13.461 4H16v3z' />
              </svg>
            </div>
            <span>Facebook</span>
          </a>
        </div>
        <div>
          <a className='flex flex-col items-center sm:flex-row' href='https://www.instagram.com/vybaveniprouklid.cz/'>
            <div className='flex h-10 w-10 items-center justify-center'>
              <svg className='fill-white' height='42' viewBox='0 0 256 256' width='42'>
                <path d='M160,128a32,32,0,1,1-32-32A32.03667,32.03667,0,0,1,160,128Zm68-44v88a56.06353,56.06353,0,0,1-56,56H84a56.06353,56.06353,0,0,1-56-56V84A56.06353,56.06353,0,0,1,84,28h88A56.06353,56.06353,0,0,1,228,84Zm-52,44a48,48,0,1,0-48,48A48.05436,48.05436,0,0,0,176,128Zm16-52a12,12,0,1,0-12,12A12,12,0,0,0,192,76Z' />
              </svg>
            </div>
            <span>Instagram</span>
          </a>
        </div>
        <div>
          <a
            className='flex flex-col items-center sm:flex-row'
            href='https://www.youtube.com/channel/UCBdfU5uDl6Rp8ki_i2ugP9w'
          >
            <div className='flex h-10 w-10 items-center justify-center'>
              <svg className='fill-white text-white' height='34' viewBox='0 0 24 24' width='34'>
                <path d='M4.652 0h1.44l.988 3.702L7.996 0H9.45L7.785 5.505v3.757H6.354V5.505L4.652 0zm6.594 2.373c-1.119 0-1.861.74-1.861 1.835v3.349c0 1.204.629 1.831 1.861 1.831 1.022 0 1.826-.683 1.826-1.831V4.208c0-1.069-.797-1.835-1.826-1.835zm.531 5.127c0 .372-.19.646-.532.646-.351 0-.554-.287-.554-.646V4.321c0-.374.172-.651.529-.651.39 0 .557.269.557.651V7.5zm4.729-5.07v5.186c-.155.194-.5.512-.747.512-.271 0-.338-.186-.338-.46V2.43h-1.27v5.71c0 .675.206 1.22.887 1.22.384 0 .918-.2 1.468-.853v.754h1.27V2.43h-1.27zm2.203 13.858c-.448 0-.541.315-.541.763v.659h1.069v-.66c.001-.44-.092-.762-.528-.762zm-4.703.04a.927.927 0 0 0-.25.198v4.055c.099.106.194.182.287.229.197.1.485.107.619-.067.07-.092.105-.241.105-.449v-3.359c0-.22-.043-.386-.129-.5-.147-.193-.42-.214-.632-.107zm4.827-5.195c-2.604-.177-11.066-.177-13.666 0C2.353 11.325 2.021 13.025 2 17.5c.021 4.467.35 6.175 3.167 6.367 2.6.177 11.062.177 13.666 0 2.814-.192 3.146-1.893 3.167-6.367-.021-4.467-.35-6.175-3.167-6.367zM6.509 21.819H5.146v-7.54h-1.41v-1.28h4.182v1.28h-1.41v7.54zm4.846 0h-1.21v-.718a2.645 2.645 0 0 1-.696.605c-.652.374-1.547.365-1.547-.955v-5.438h1.209v4.988c0 .262.063.438.322.438.236 0 .564-.303.711-.487v-4.939h1.21v6.506zm4.657-1.348c0 .805-.301 1.431-1.106 1.431-.443 0-.812-.162-1.149-.583v.5h-1.221v-8.82h1.221v2.84c.273-.333.644-.608 1.076-.608.886 0 1.18.749 1.18 1.631v3.609zm4.471-1.752h-2.314v1.228c0 .488.042.91.528.91.511 0 .541-.344.541-.91v-.452h1.245v.489c0 1.253-.538 2.013-1.813 2.013-1.155 0-1.746-.842-1.746-2.013v-2.921c0-1.129.746-1.914 1.837-1.914 1.161 0 1.721.738 1.721 1.914v1.656z' />
              </svg>
            </div>
            <span>Youtube</span>
          </a>
        </div>
      </div>
    </div>
    <div className='mt-4 mb-2 h-px w-full bg-gray-300'></div>
    <div className='container flex flex-col items-center sm:flex-row'>
      <div className='footer-cards flex w-full justify-center py-4 sm:w-1/2 sm:justify-start'>
        <img alt='cc_cards' height='22' loading='lazy' src='/images/brands/cc_cards.webp' width='180' />
      </div>
      <div className='w-full text-center text-xs sm:w-1/2 sm:text-right'>{`© 2009 - ${new Date().getFullYear()} AllServices Group s.r.o., všechna práva vyhrazena`}</div>
    </div>
  </div>
);

AppCustomFooterSocials.displayName = 'AppCustomFooterSocials';

export default AppCustomFooterSocials;
