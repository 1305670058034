export type CheckoutCreatePurchaseResult =
  | CheckoutCreatePurchaseResultSuccess
  | CheckoutCreatePurchaseResultRedirectToPgw
  | CheckoutCreatePurchaseResultError;

export interface CheckoutCreatePurchaseResultSuccess {
  redirectToThankYouHref: string;
  status: CheckoutCreatePurchaseResultStatusType.success;
  warnings?: CheckoutCreatePurchaseResultWarningType[];
}

export interface CheckoutCreatePurchaseResultRedirectToPgw {
  redirectToPaymentGatewayHref?: string;
  status: CheckoutCreatePurchaseResultStatusType.redirectToPaymentGateway;
  warnings?: CheckoutCreatePurchaseResultWarningType[];
}

export interface CheckoutCreatePurchaseResultError {
  reason?: any;
  status: CheckoutCreatePurchaseResultStatusType.error;
}

export enum CheckoutCreatePurchaseResultStatusType {
  error = 'error',
  redirectToPaymentGateway = 'redirect-to-payment-gateway',
  success = 'success',
}

export enum CheckoutCreatePurchaseResultWarningType {
  emailNotificationFailed = 'email-notification-failed',
  emailTemplateNotFound = 'email-template-not-found',
  fulfillmentFetchFailed = 'fulfillment-fetch-failed',
  heurekaReviewFailed = 'heureka-review-failed',
  newsletterRegisterationFailed = 'newsletter-registeration-failed',
  paymentGatewayTransactionError = 'payment-gateway-transaction-error',
  zboziReviewFailed = 'zbozi-review-failed',
}
