import { useStatusBars } from '@/client/hooks/useStatusBars';
import type { FC } from 'react';
import AppCustomHeaderTopStatusBarContent from './AppCustomHeaderTopStatusBarContent';

const AppCustomHeaderTopStatusBar: FC = () => {
  const statusBars = useStatusBars();
  return <AppCustomHeaderTopStatusBarContent statusBars={statusBars} />;
};

AppCustomHeaderTopStatusBar.displayName = 'AppCustomHeaderTopStatusBar';

export default AppCustomHeaderTopStatusBar;
