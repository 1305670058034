import type { FC } from 'react';
import { Toaster } from 'react-hot-toast';

const AppToaster: FC = () => {
  // Informace o nastaveni toasteru na https://react-hot-toast.com/docs
  return <Toaster containerClassName='toasterContainer' position='top-right' reverseOrder={false} />;
};

AppToaster.displayName = 'AppToaster';

export default AppToaster;
