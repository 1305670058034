import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export type MsgErrorMinNumberProps = {
  num: number;
};

const MsgErrorMinNumber: FC<MsgErrorMinNumberProps> = ({ num }) => (
  <FormattedMessage
    defaultMessage={`Je vyžadována číselná hodnota větší než {num}.`}
    description='Form field error message for minimal number.'
    id='Vt4spR'
    values={{ num }}
  />
);

MsgErrorMinNumber.displayName = 'MsgErrorMinNumber';

export default MsgErrorMinNumber;
