import { TaxonomiesResource } from '@/resources';
import type { TaxonomyBase, TaxonomyDataStateBetterOrEqual, TaxonomyDataStateGuess } from '@/types';
import { isTaxonomyDataStateWorse, TaxonomyDataStateLevel } from '@/types';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import invariant from 'tiny-invariant';
import { Container as IocContainer } from 'typescript-ioc';
import { taxonomyDataState } from '../atoms/taxonomyDataState';
import { useEnhancedEffect } from './useEnhancedEffect';

export type TaxonomyDetalStateLoadable<L extends TaxonomyDataStateLevel> =
  | { contents: TaxonomyDataStateGuess<L>; state: 'loading' }
  | { contents: null; state: 'hasError' }
  | { contents: TaxonomyDataStateBetterOrEqual<L>; state: 'hasValue' };

export function useTaxonomyData<L extends TaxonomyDataStateLevel>(
  level: L,
  hash: string,
): TaxonomyDetalStateLoadable<L>;
export function useTaxonomyData<L extends TaxonomyDataStateLevel>(
  level: L,
  taxonomyBase: TaxonomyBase,
): TaxonomyDetalStateLoadable<L>;
export function useTaxonomyData<L extends TaxonomyDataStateLevel>(
  level: L,
  param: unknown,
): TaxonomyDetalStateLoadable<L> {
  const hash = typeof param === 'string' ? param : (param as TaxonomyBase).hash;

  invariant(hash != null && hash !== '', 'useTaxonomyData can return data for empty hash.');

  const [loading, setLoading] = useState(false);
  const [d, setD] = useRecoilState(taxonomyDataState(hash));

  useEnhancedEffect(() => {
    if (d.level !== TaxonomyDataStateLevel.none && isTaxonomyDataStateWorse(level, d)) {
      setLoading(true);

      const resource = IocContainer.get(TaxonomiesResource);

      resource
        .getTaxonomyDetail(level, hash, 'cs', 'CZ')
        .then((data) => {
          if (data) {
            setD({ data, level } as any);
          } else {
            setD({ data: null, level: TaxonomyDataStateLevel.none });
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setD({ data: null, level: TaxonomyDataStateLevel.none });
        });
    }
  }, [hash]);

  if (typeof window === 'undefined') {
    const serveritem = global.__taxonomies__.find((i) => i.hash === hash);
    if (serveritem != null) {
      return {
        contents: {
          data: {
            code: serveritem.code,
            hash: serveritem.hash,
            name: serveritem.name,
          },
          level: TaxonomyDataStateLevel.preview,
        } as any,
        state: 'hasValue',
      };
    }
  }

  if (loading) return { contents: d, state: 'loading' } as any;
  if (d.level === TaxonomyDataStateLevel.none) return { contents: null, state: 'hasError' };
  return { contents: d, state: 'hasValue' } as any;
}
