import type { RegistrationRequest, RegistrationResult } from '@/types';
import { Container as IocContainer } from 'typescript-ioc';
import { ResourcesContext } from './ResourcesContext';

export abstract class AuthResource {
  public ctx: ResourcesContext;

  constructor() {
    this.ctx = IocContainer.get(ResourcesContext);
  }

  abstract passwordRenew(email: string): Promise<{ reason: string; status: 'error' } | { status: 'success' }>;

  abstract registration(data: RegistrationRequest): Promise<RegistrationResult>;
}
