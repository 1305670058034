import { excludeFalsy } from '@/helpers';
import { selector } from 'recoil';
import { cartSubjectsState } from './cartSubjectsState';

export const cartSubjectsAdditionalServicesIndexState = selector<Record<string, string[]>>({
  get: ({ get }) => {
    const cartSubjects = get(cartSubjectsState);

    const next: Record<string, string[]> = cartSubjects.reduce((r: Record<string, string[]>, s) => {
      return {
        ...r,
        [s.sku]: s.indirectSubjects
          ? s.indirectSubjects.map((is) => (is.quantity > 0 ? is.sku : null)).filter(excludeFalsy)
          : [],
      };
    }, {});

    return next;
  },
  key: 'cartSubjectsAdditionalServicesIndexState',
});
