import type { ProductBase } from '@/types';
import type { AtomEffect } from 'recoil';
import { atom } from 'recoil';

const sessionStorageEffect =
  (key: string): AtomEffect<ProductBase[]> =>
  ({ onSet, setSelf }) => {
    if (typeof window !== 'undefined') {
      const savedProducts = sessionStorage.getItem(key);
      if (savedProducts != null) {
        setSelf(JSON.parse(savedProducts));
      }
      onSet((newValue) => {
        sessionStorage.setItem(key, JSON.stringify(newValue));
      });
    }
  };

export const productComparisonProducts = atom<ProductBase[]>({
  default: [],
  effects: [sessionStorageEffect('comparedProducts')],
  key: 'productComparisonProducts',
});
