import ms from 'ms';
import trpc, { type TrpcRouterOutputs } from '../lib/trpc';

export type CartTotals = TrpcRouterOutputs['cart']['totals'];

export function useCartTotals(initials?: CartTotals) {
  const cartTotals = trpc.cart.totals.useQuery(undefined, {
    initialData: initials,
    staleTime: ms('2min'),
  });

  if (cartTotals.data == null) {
    const defData: CartTotals = {
      subjects: {
        count: 0,
        quantity: 0,
        total: {
          amount: 0,
          amountWithTax: 0,
          currency: 'CZK',
        },
      },
      total: {
        count: 0,
        quantity: 0,
        total: {
          amount: 0,
          amountWithTax: 0,
          currency: 'CZK',
        },
      },
    };
    return {
      ...cartTotals,
      data: defData,
    };
  }
  return cartTotals;
}
