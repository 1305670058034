import { productRelationsByType } from '@/helpers';
import type { ProductBase, ProductDetail } from '@/types';
import type { PimProductRelationType } from '@sprinx/knihovna-api-types';
import { useMemo } from 'react';

export function useProductRelatedByRelationType(
  relationType: PimProductRelationType,
  product: ProductDetail,
): ProductBase[] | null {
  return useMemo(() => productRelationsByType(relationType, product), [product, relationType]);
}
