// list of tags to strip
const defautlTags = ['p', 'br'];

export default function normalizeOpeningHours(htmlString: string, tags: string[] = defautlTags): string {
  if (htmlString == null || htmlString === '') return '';

  let res = htmlString;
  for (const tg of tags) {
    res = res.replace(new RegExp(`<${tg}.*?>(.*?)</${tg}>`, 'ig'), '$1').replace(new RegExp(`<${tg}.*?/>`, 'ig'), '');
  }

  return res;
}
