import type { ProductPack, ProductPackQuantityPrice } from '@/types';
import { selectorFamily } from 'recoil';
import { productPackQuantityPricesState } from './productPackQuantityPricesState';

export const productPacksQuantityPricesState = selectorFamily<ProductPackQuantityPrice[], ProductPack[]>({
  get:
    (packs) =>
    ({ get }) => {
      return packs.map((pack) => get(productPackQuantityPricesState(pack.sku)));
    },
  key: 'productPacksQuantityPrices',
});
