import type { FC } from 'react';
import { FormattedNumber } from 'react-intl';

export type FormattedPriceProps = {
  currency?: string;
  value?: number;
  withoutDecimals?: boolean;
};

const FormattedPrice: FC<FormattedPriceProps> = ({ currency = 'cs', value = 0, withoutDecimals = false }) => (
  <FormattedNumber
    currency={currency}
    {...(withoutDecimals ? { maximumFractionDigits: 0, minimumFractionDigits: 0 } : {})}
    style='currency'
    value={value}
  />
);

FormattedPrice.displayName = 'FormattedPrice';

export default FormattedPrice;
