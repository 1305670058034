import type { ProductPack } from '@/types';
import { ProductPackType } from '@/types';

export type ProductPackCarton = string;

export function productPackResolve(sku: string | null): ProductPack {
  if (!sku) return { packType: ProductPackType.pcs, parentSku: 'na', sku: 'na' };
  if (sku.endsWith('-carton')) {
    return { packType: ProductPackType.carton, parentSku: sku.replace('-carton', ''), sku: sku };
  }
  return { packType: ProductPackType.pcs, parentSku: sku, sku: sku };
}
