import type { routesDefinition } from '@/routes';
import { routeUrl } from '@/routes';
import type { ArticlePreview, ArticleType } from '@/types';

const articleTypeRoutes: Record<ArticleType, keyof typeof routesDefinition> = {
  advisor: 'advisorArticle',
  article: 'article',
  blog: 'blogArticle',
};

export function useGetArticleUrl(): (article: ArticlePreview) => string {
  return (article: ArticlePreview) =>
    routeUrl(articleTypeRoutes[article.type], {
      locale: 'cs',
      params: { path: article.path },
    });
}
