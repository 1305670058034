import type { PimProductQuantityLimits } from '@sprinx/knihovna-api-types';
import { PimProductQuantityLimitsRounding } from '@sprinx/knihovna-api-types';
import { useMemo } from 'react';

export function useShoppingCartProductLimits(
  product: Partial<PimProductQuantityLimits>,
): Readonly<PimProductQuantityLimits> {
  const { quantityDefault, quantityMax, quantityMin, quantityStep, quantityStepRounding } = product;

  return useMemo(() => {
    const limits: Readonly<PimProductQuantityLimits> = {
      quantityDefault: quantityDefault ?? 1,
      quantityMax: quantityMax ?? 100000,
      quantityMin: quantityMin ?? 1,
      quantityStep: quantityStep ?? 1,
      quantityStepRounding: quantityStepRounding ?? PimProductQuantityLimitsRounding.up,
    };

    return limits;
  }, [quantityDefault, quantityMax, quantityMin, quantityStep, quantityStepRounding]);
}
