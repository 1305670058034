import type { SupportedCountry, SupportedCurrency, SupportedLocale, SupportedRegion } from '@sprinx/knihovna-api-types';

export function convertToSupportedLocale<T extends string>(v: T): SupportedLocale {
  if (typeof v === 'string') return v as SupportedLocale;
  throw new Error('convertToSupportedLocale expects param of type string.');
}

export function convertToSupportedCurrency<T extends string>(v: T): SupportedCurrency;
export function convertToSupportedCurrency<T extends string>(v: T | null | undefined): SupportedCurrency | undefined;
export function convertToSupportedCurrency<T extends string>(
  v: T | null | undefined,
  retAsNull: true,
): SupportedCurrency | undefined;
export function convertToSupportedCurrency<T extends string>(v: T | null | undefined, retAsNull?: boolean): unknown {
  if (v == null) return retAsNull ? null : undefined;
  if (typeof v === 'string') return v as SupportedCurrency;
  throw new Error('convertToSupportedCurrency expects param of type string.');
}

export function convertToSupportedCountry<T extends string>(v: T): SupportedCountry;
export function convertToSupportedCountry<T extends string>(
  v: T | null | undefined,
  retAsNull: true,
): SupportedCountry | null;
export function convertToSupportedCountry<T extends string>(
  v: T | null | undefined,
  retAsNull?: false,
): SupportedCountry | undefined;
export function convertToSupportedCountry<T extends string>(v: T | null | undefined, retAsNull?: boolean): unknown {
  if (v == null) return retAsNull ? null : undefined;
  if (typeof v === 'string') return v as SupportedCountry;
  throw new Error('convertToSupportedCountry expects param of type string.');
}

export function convertToSupportedRegion<T extends string>(v: T): SupportedRegion;
export function convertToSupportedRegion<T extends string>(
  v: T | null | undefined,
  retAsNull: true,
): SupportedRegion | null;
export function convertToSupportedRegion<T extends string>(
  v: T | null | undefined,
  retAsNull?: false,
): SupportedRegion | undefined;
export function convertToSupportedRegion<T extends string>(v: T | null | undefined, retAsNull?: boolean): unknown {
  if (v == null) return retAsNull ? null : undefined;
  if (typeof v === 'string') return v as SupportedRegion;
  throw new Error('convertToSupportedCountry expects param of type string.');
}
