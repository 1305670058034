import { Container as IocContainer } from 'typescript-ioc';
import type { AppConfiguration } from './AppConfiguration';

export function initializeConfiguration(): AppConfiguration {
  IocContainer.bindName('config').to({
    AUTH_AUTHORIZATION_URL: process.env.RAZZLE_AUTH_AUTHORIZATION_URL || '',
    AUTH_CLIENT_ID: process.env.RAZZLE_AUTH_CLIENT_ID || '',
    AUTH_CLIENT_SECRET: process.env.RAZZLE_AUTH_CLIENT_SECRET || '',
    AUTH_CLIENTINFO_URL: process.env.RAZZLE_AUTH_CLIENTINFO_URL || '',
    AUTH_SCOPE: (process.env.RAZZLE_AUTH_SCOPE || '').split(' '),
    AUTH_TOKEN_URL: process.env.RAZZLE_AUTH_TOKEN_URL || '',
    AUTH_USERINFO_URL: process.env.RAZZLE_AUTH_USERINFO_URL || '',
    COMPLAINT_TO_OVERRIDE: process.env.RAZZLE_COMPLAINT_TO_OVERRIDE || 'venuse@sprinx.com',
    FOXENTRY_BASE_CODE: process.env.RAZZLE_FOXENTRY_BASE_CODE || '',
    KNIHOVNA_API_URI: (process.env.RAZZLE_KNIHOVNA_API_URI || '').replace(/\/$/, ''),
    LUIGI_TRACKER_ID: process.env.RAZZLE_LUIGI_TRACKER_ID || '',
    REDIS_SESSION_STORE_HOST: process.env.RAZZLE_REDIS_SESSION_STORE_HOST || null,
    SMARTSUPP_KEY: process.env.RAZZLE_SMARTSUPP_KEY || '',
    SOLUTION_NAME: process.env.RAZZLE_SOLUTION_NAME || '',
  });

  return IocContainer.getValue('config');
}
