export enum ProductAdditionalServiceSkus {
  zar01,
  zar02,
  mont01,
  mont02,
  rekl01,
  rekl02,
  rekl03,
  rekl04,
  rekl05,
  rekl06,
  rekl07,
  rekl08,
  rekl09,
  rekl10,
  rekl11,
  rekl12,
  rekl13,
}
