import type { CheckoutData } from '@/types';
import { CheckoutDataOrderInfoUtilization } from '@/types';
import { useCallback, useMemo } from 'react';
import invariant from 'tiny-invariant';
import { useCheckoutData } from './useCheckoutData';

type Conatact = CheckoutData['orderInfo']['contact'];
type Customer = CheckoutData['orderInfo']['customer'];
type ValueOrSetter<T> = T | ((prev: T) => T);

export function useCheckoutDataOrderInfo() {
  const checkoutData = useCheckoutData();

  const setOrderInfo = useCallback(
    async (valueOrSetter: ValueOrSetter<Conatact>): Promise<void> => {
      invariant(
        checkoutData.isLoading === false && checkoutData.data !== undefined,
        'Wait for checkout data to be loaded first.',
      );
      const next =
        typeof valueOrSetter === 'function' ? valueOrSetter(checkoutData.data.orderInfo.contact) : valueOrSetter;

      await checkoutData.update((p) => ({
        ...p,
        orderInfo: {
          ...p.orderInfo,
          contact: next,
          utilization: CheckoutDataOrderInfoUtilization.assigned,
        },
      }));
    },
    [checkoutData],
  );

  const setCustomer = useCallback(
    async (valueOrSetter: ValueOrSetter<Customer>): Promise<void> => {
      invariant(
        checkoutData.isLoading === false && checkoutData.data !== undefined,
        'Wait for checkout data to be loaded first.',
      );
      const next =
        typeof valueOrSetter === 'function' ? valueOrSetter(checkoutData.data.orderInfo.customer) : valueOrSetter;

      await checkoutData.update((p) => ({
        ...p,
        orderInfo: {
          ...p.orderInfo,
          ...(next == null ? undefined : { contact: (({ id, ...withoutId }) => withoutId)(next) }),
          customer: next,
          utilization:
            next == null ? CheckoutDataOrderInfoUtilization.assigned : CheckoutDataOrderInfoUtilization.customer,
        },
      }));
    },
    [checkoutData],
  );

  const orderInfoPreview = useMemo(() => {
    if (checkoutData.data?.orderInfo.utilization === CheckoutDataOrderInfoUtilization.customer) {
      return checkoutData.data?.orderInfo.customer;
    }
    return checkoutData.data?.orderInfo.contact;
  }, [
    checkoutData.data?.orderInfo.contact,
    checkoutData.data?.orderInfo.customer,
    checkoutData.data?.orderInfo.utilization,
  ]);

  return {
    orderInfo: checkoutData.data?.orderInfo.contact,
    orderInfoPreview: orderInfoPreview ?? null,
    orderInfoUtilization: checkoutData.data?.orderInfo.utilization,
    setCustomer,
    setOrderInfo,
  };
}
