import type { ShoppingListCreateValues } from '@/types';
import type {
  EcomShoppingListCreationResultError,
  EcomShoppingListCreationResultSuccess,
} from '@sprinx/knihovna-api-types';
import { Container as IocContainer } from 'typescript-ioc';
import { ResourcesContext } from './ResourcesContext';

export abstract class ShoppingListsResource {
  public ctx: ResourcesContext;

  constructor() {
    this.ctx = IocContainer.get(ResourcesContext);
  }

  abstract create(
    checkoutId: string,
    userId: string,
    values: ShoppingListCreateValues,
  ): Promise<EcomShoppingListCreationResultSuccess | EcomShoppingListCreationResultError>;
}
