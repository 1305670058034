import { TaxonomiesResource } from '@/resources';
import type { TaxonomyDataExact, TaxonomyDataStateLevel, TaxonomyMeta, TaxonomyTreeItem } from '@/types';
import type { SupportedLocale, SupportedRegion } from '@sprinx/knihovna-api-types';
import axios from 'axios';

export class ClientTaxonomiesResource extends TaxonomiesResource {
  async getSubTree(hash: string, depth?: number): Promise<TaxonomyTreeItem[] | null> {
    depth = depth || 1;

    return axios
      .get<TaxonomyTreeItem[] | null>('/api/taxonomies/sub-tree', {
        params: { depth, hash },
      })
      .then((result) => result.data)
      .catch(() => {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ClientTaxonomiesResource: Failed to get taxonomy sub tree.');
        }
        return null;
      });
  }

  async getTaxonomyDetail<L extends TaxonomyDataStateLevel>(
    level: L,
    hash: string,
    locale: SupportedLocale,
    region: SupportedRegion,
  ): Promise<TaxonomyDataExact<L> | null> {
    return axios
      .get<TaxonomyDataExact<L> | null>('/api/taxonomies/detail', {
        params: { hash, level, locale, region },
      })
      .then((result) => result.data)
      .catch(() => {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ClientTaxonomiesResource: Failed to get taxonomy detal.');
        }
        return null;
      });
  }

  async hashToCode(hash: string): Promise<string | null> {
    return axios
      .get<string | null>('/api/taxonomies/code', {
        params: { hash },
      })
      .then((result) => result.data)
      .catch(() => {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ClientTaxonomiesResource: Failed to get taxonomy code.');
        }
        return null;
      });
  }

  async withAncestors(hash: string): Promise<TaxonomyMeta[] | null> {
    return axios
      .get<TaxonomyMeta[] | null>('/api/taxonomies/with-ancestors', {
        params: { hash },
      })
      .then((result) => result.data)
      .catch(() => {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ClientTaxonomiesResource: Failed to get taxonomy with ancestors.');
        }
        return null;
      });
  }
}
