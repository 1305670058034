import clsx from 'clsx';
import type { FC, ReactNode } from 'react';

export type DialogModalProps = {
  children?: ReactNode;
  className?: string;
};

const DialogModal: FC<DialogModalProps> = ({ children = null, className }) => (
  <div className={clsx('flex max-h-[100vh] flex-col overflow-auto', className)}>{children}</div>
);

DialogModal.displayName = 'DialogModal';

export default DialogModal;
