import type { SupportedLocale } from '@sprinx/knihovna-api-types';
import invariant from 'tiny-invariant';
import { routesBuilderDefaultdUrlBuilder } from './routesBuilderDefaultdUrlBuilder';

/**
 * Hash ended rote builder. For routes like `/:path*\/x-:hash`
 *
 * @param locale
 * @param path
 * @param otherParamsToQs
 * @param inputParams
 */

export function routesBuilderManufacturerUrlBuilder(
  locale: SupportedLocale,
  path: string,
  otherParamsToQs: boolean | string[] | undefined | null,
  inputParams: { [key: string]: any; hash: string; path: string } | undefined,
): string {
  const { hash, path: iPath, ...other } = inputParams || {};

  invariant(iPath, 'manufacturer is required.');

  return routesBuilderDefaultdUrlBuilder(locale, path, true, {
    ...other,
    hash,
    path: iPath,
  });
}
