import { ProductDetailResource } from '@/resources';
import type { ProductPackQuantityPrice } from '@/types';
import { selectorFamily } from 'recoil';
import { Container as IocContainer } from 'typescript-ioc';
import { checkoutContextState } from './checkoutState';

export const productPackQuantityPricesState = selectorFamily<ProductPackQuantityPrice, string>({
  get:
    (sku) =>
    async ({ get }) => {
      const resource = IocContainer.get(ProductDetailResource);

      const { checkoutId, locale } = get(checkoutContextState);

      return resource.getProductPackQuantityPrices({ checkoutId, locale, sku });
    },
  key: 'productPackQuantityPrices',
});
