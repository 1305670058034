/**
 * @generated SignedSource<<b89e9d52453dff5344c55105c7af1721>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ContactType = "company" | "office" | "person" | "%future added value";
export type loadCustomer_CustomerQuery$variables = {};
export type loadCustomer_CustomerQuery$data = {
  readonly customer: {
    readonly city: string | null;
    readonly companyName: string | null;
    readonly companyRegNo: string | null;
    readonly companyTaxNo: string | null;
    readonly contactType: ContactType;
    readonly country: string | null;
    readonly deliveryContacts: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly city: string | null;
          readonly companyName: string | null;
          readonly companyRegNo: string | null;
          readonly companyTaxNo: string | null;
          readonly contactType: ContactType;
          readonly country: string | null;
          readonly emailAddress: string | null;
          readonly id: string;
          readonly personName: string | null;
          readonly phoneNumber: string | null;
          readonly postalCode: string | null;
          readonly street: string | null;
        };
      }> | null;
    };
    readonly emailAddress: string | null;
    readonly id: string;
    readonly personName: string | null;
    readonly phoneNumber: string | null;
    readonly postalCode: string | null;
    readonly street: string | null;
  } | null;
};
export type loadCustomer_CustomerQuery = {
  response: loadCustomer_CustomerQuery$data;
  variables: loadCustomer_CustomerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailAddress",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "personName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "street",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyRegNo",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyTaxNo",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contactType",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Customer",
    "kind": "LinkedField",
    "name": "customer",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 100
          }
        ],
        "concreteType": "CustomerContactConnection",
        "kind": "LinkedField",
        "name": "deliveryContacts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerContactConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerContact",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v5/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v7/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "deliveryContacts(first:100)"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "loadCustomer_CustomerQuery",
    "selections": (v12/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "loadCustomer_CustomerQuery",
    "selections": (v12/*: any*/)
  },
  "params": {
    "cacheID": "b59b0f7dc0daaf95ebdaf3ebbe3573f8",
    "id": null,
    "metadata": {},
    "name": "loadCustomer_CustomerQuery",
    "operationKind": "query",
    "text": "query loadCustomer_CustomerQuery {\n  customer {\n    id\n    emailAddress\n    personName\n    phoneNumber\n    street\n    city\n    postalCode\n    country\n    companyName\n    companyRegNo\n    companyTaxNo\n    contactType\n    deliveryContacts(first: 100) {\n      edges {\n        node {\n          id\n          city\n          companyName\n          companyRegNo\n          companyTaxNo\n          contactType\n          country\n          emailAddress\n          personName\n          phoneNumber\n          postalCode\n          street\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "06add36e82269fb5f0ff8f340506ac54";

export default node;
