import fetchJson from '@/helpers/fetch-json';
import { ResourcesContext } from '@/resources';
import { useQuery } from '@tanstack/react-query';
import ms from 'ms';
import type { FC } from 'react';
import { Container as IocContainer } from 'typescript-ioc';

const AppAuthAccessTokenRefresher: FC = () => {
  useQuery({
    queryFn: async ({ signal }) => {
      try {
        const r = await fetchJson<{ accessToken: string; accessTokenType: string }>('/api/auth/refresh-token', {
          signal,
        });
        IocContainer.get(ResourcesContext).update(r.accessTokenType, r.accessToken);
      } catch (err) {
        console.error('Unable to refresh token...');
      }
      return new Date();
    },
    queryKey: ['/api/auth/refresh-token'],
    refetchInterval: ms('10 min'),
    refetchIntervalInBackground: true,
    retry: true,
    retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
    staleTime: ms('11 min'),
  });

  // Apply only functionality of token refresh. Render nothing...
  return null;
};

AppAuthAccessTokenRefresher.displayName = 'AppAuthAccessTokenRefresher';

export default AppAuthAccessTokenRefresher;
