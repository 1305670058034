import { NavigationType } from '@/types';
import clsx from 'clsx';
import type { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { navigationsState } from '../../atoms';
import AppCustomHeaderNavBarLinkItem from '../AppCustomHeaderNavBarLinkItem';

export type AppCustomHeaderTopBarNavLeftProps = {
  className?: string;
};

const AppCustomHeaderTopBarNavLeft: FC<AppCustomHeaderTopBarNavLeftProps> = ({ className, ...rootProps }) => {
  const navItems = useRecoilValue(navigationsState(NavigationType.topNav));

  return (
    <nav className={clsx('flex items-center text-text', className)} {...rootProps}>
      {navItems &&
        navItems.map((i, idx) => (
          <AppCustomHeaderNavBarLinkItem
            key={`top-bar-nav-item-${idx}`}
            className='mr-2 text-sm underline hover:no-underline xl:text-xs'
            navbarItem={i}
          />
        ))}
    </nav>
  );
};

AppCustomHeaderTopBarNavLeft.displayName = 'AppCustomHeaderTopBarNavLeft';

export default AppCustomHeaderTopBarNavLeft;
