import type { AppCommonData } from '@/types';
import type { AtomEffect } from 'recoil';

export const atomEffectFieldFromCommonData =
  <K extends keyof AppCommonData>(k: K, defaultValue: AppCommonData[K]): AtomEffect<AppCommonData[K]> =>
  ({ setSelf }) => {
    const initialValue = ((typeof window !== 'undefined' ? window : global) as any).commonData as AppCommonData;

    if (!initialValue || !initialValue[k]) {
      setSelf(defaultValue);
    } else {
      setSelf(initialValue[k]);
    }
  };
