import {
  authUserState,
  checkoutUserDecisionAfterLoginActivateState,
  customerIdState,
  priceGroupsState,
  pricesTypeState,
} from '@/client/atoms';
import type { AppAuthUser } from '@/types';
import { useCallback } from 'react';
import { useRecoilTransaction_UNSTABLE } from 'recoil';
import useRedirectOnAuthChange from './useRedirectOnAuthChange';

export type LoginSuccessOptions = { userData: AppAuthUser };
export type LoginSuccessEventHandler = (options: LoginSuccessOptions) => void | Promise<void>;

export function useLoginSuccess(): LoginSuccessEventHandler {
  const redirectOnAuthChange = useRedirectOnAuthChange();

  const makeUserLoggedIn = useRecoilTransaction_UNSTABLE<[options: LoginSuccessOptions]>(({ set }) => (options) => {
    const { userData } = options;
    const { settings, ...user } = userData;
    set(authUserState, user);

    if (settings?.priceGroupIds) {
      set(priceGroupsState, settings.priceGroupIds);
    }

    if (settings?.pricesType) {
      set(pricesTypeState, settings.pricesType);
    }

    if (settings?.customer) {
      set(customerIdState, settings.customer);
    }

    set(checkoutUserDecisionAfterLoginActivateState, true);
  });

  const handleLoginSuccess = useCallback<LoginSuccessEventHandler>(
    (options) => {
      makeUserLoggedIn(options);
      redirectOnAuthChange();
    },
    [redirectOnAuthChange, makeUserLoggedIn],
  );

  return handleLoginSuccess;
}
