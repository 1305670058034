import clsx from 'clsx';
import type { FC, HTMLAttributes, RefAttributes } from 'react';
import { forwardRef } from 'react';

export type HtmlContainerProps = HTMLAttributes<HTMLDivElement> & {
  html: string;
};

const HtmlContainer: FC<HtmlContainerProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  HtmlContainerProps
>(({ className, html, ...rest }, ref) => {
  // TODO: style supported elements
  return (
    <div ref={ref} className={clsx('html-container', className)} {...rest} dangerouslySetInnerHTML={{ __html: html }} />
  );
});

HtmlContainer.displayName = 'HtmlContainer';

export default HtmlContainer;
