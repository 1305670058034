import type { useShoppingCartReset_cart_resetMutation } from '@/graphql/__generated__/useShoppingCartReset_cart_resetMutation.graphql';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { graphql, useMutation } from 'react-relay';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { cartUpdateInProgressState, checkoutContextState } from '../atoms';
import { toastMessage } from '../compositions/AppToaster';
import trpc from '../lib/trpc';
import { usePromisifyCommitMutation } from './usePromisifyCommitMutation';

export function useShoppingCartReset() {
  const intl = useIntl();
  const trpcCtx = trpc.useContext();
  const checkoutCtx = useRecoilValue(checkoutContextState);
  const setUpdateInProgress = useSetRecoilState(cartUpdateInProgressState);

  const [commitCartReset] = useMutation<useShoppingCartReset_cart_resetMutation>(
    graphql`
      mutation useShoppingCartReset_cart_resetMutation($checkoutId: ID!, $locale: String!) {
        checkout_cart_reset(checkoutId: $checkoutId, locale: $locale) {
          ok
          errors
          cart {
            id
            ...cartState_cart
          }
        }
      }
    `,
  );

  const resetCart = usePromisifyCommitMutation(
    commitCartReset,
    (checkoutId: string) => ({ checkoutId, locale: 'cs' }),
    (response) =>
      !response.checkout_cart_reset.ok
        ? [new Error(response.checkout_cart_reset.errors?.join(', ') ?? undefined)]
        : [null, response.checkout_cart_reset.cart],
  );

  return useCallback(async () => {
    setUpdateInProgress(true);

    try {
      await resetCart(checkoutCtx.checkoutId);
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(err);
        console.error('Predchozi chyba nastala pri resetování košíku.');
      }

      toastMessage.error(
        intl.formatMessage({
          defaultMessage: 'Chyba při resetování košíku.',
          description: 'Shopping cart update error message.',
          id: 'kL8oCT',
        }),
      );
    }

    setUpdateInProgress(false);
    trpcCtx.cart.invalidate();
  }, [setUpdateInProgress, trpcCtx.cart, resetCart, checkoutCtx, intl]);
}
