import { useMemo } from 'react';
import { MOBILE_WINDOW_WIDTH, TABLET_WINDOW_WIDTH, useWindowSize } from './useWindowSize';

export function useWindowSizeIsTablet(): boolean {
  const { width } = useWindowSize();
  const isTablet = useMemo(
    () => (typeof width === 'number' ? width <= TABLET_WINDOW_WIDTH && width >= MOBILE_WINDOW_WIDTH : false),
    [width],
  );
  return isTablet;
}
