import type { ReactNode } from 'react';
import { useRecoilState } from 'recoil';
import { authUserState, customerIdState, priceGroupsState, pricesTypeState } from './atoms';

export default function AppPreloader({ children }: { children: ReactNode }): JSX.Element {
  useRecoilState(authUserState);
  useRecoilState(priceGroupsState);
  useRecoilState(pricesTypeState);
  useRecoilState(customerIdState);

  return <>{children}</>;
}
