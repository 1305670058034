import { getInitialDataFromGlobal, removeInitialDataInGlobal } from '@/helpers';
import { TaxonomyDataStateLevel, type TaxonomyDataState } from '@/types';
import { atomFamily } from 'recoil';
import { getTaxonomyStateExtStore } from '../lib/globalizeData';

function getInitialDataFromCommonDataByHash(hash: string) {
  return getTaxonomyStateExtStore().find((i) => i.data?.hash === hash);
}

export const taxonomyDataState = atomFamily<TaxonomyDataState, string>({
  effects: (taxonomyHash) => [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        let taxonomyState = getInitialDataFromGlobal<TaxonomyDataState>('taxonomyState');

        if (taxonomyState == null) {
          taxonomyState = getInitialDataFromCommonDataByHash(taxonomyHash);
        }

        // if (typeof window !== 'undefined' && taxonomyState == null) {
        //   type Item = { code: string; hash: string; isExpandable: boolean; items?: Item[]; name: string };
        //   const ft = <T extends Item>(arr: T[]): TaxonomyDataState | undefined => {
        //     for (const i of arr) {
        //       if (i.hash === taxonomyHash) {
        //         return {
        //           data: {
        //             children: i.items,
        //             code: i.code,
        //             hasChildren: i.isExpandable,
        //             hash: i.hash,
        //             name: i.name,
        //           },
        //           level: TaxonomyDataStateLevel.preview,
        //         };
        //       }
        //       if (i.items != null) {
        //         const x = ft(i.items as T[]);
        //         if (x != null) {
        //           return x;
        //         }
        //       }
        //     }
        //     return undefined;
        //   };

        //   taxonomyState = ft((window as any).commonData.navigations.categories);
        // }

        if (
          taxonomyState &&
          typeof taxonomyState.level !== 'undefined' &&
          taxonomyState.data &&
          taxonomyState.data.hash === taxonomyHash
        ) {
          if (typeof window !== 'undefined') {
            removeInitialDataInGlobal('taxonomyState');
          }
          setSelf(taxonomyState);
        } else {
          setSelf({ data: { hash: taxonomyHash }, level: TaxonomyDataStateLevel.base });
        }
      }
    },
  ],
  key: 'taxonomyData',
});
