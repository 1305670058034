import { PricesType } from '@sprinx/knihovna-api-types';
import type { UiContext } from './UiContext';

export const defaultUiContext: Omit<UiContext, 'accessToken' | 'accessTokenType' | 'sessionCheckoutId'> = {
  authenticatedUser: undefined,
  currency: 'CZK',
  customerId: null,
  customerInfo: null,
  locale: 'cs',
  priceGroups: ['_default'],
  pricesType: PricesType.B2C,
  region: 'CZ',
};
