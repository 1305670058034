import type {
  CatalogueCategory,
  CatalogueSettingsForFilterOptionsParameter,
  CatalogueSettingsForResource,
  CatalogueSettingsForResourceWithLimitation,
} from '@/types';
import type {
  EcomCatalogueFilterOptionsNumberRange,
  EcomCatalogueFilterOptionsParameterValueDisplay,
  EcomCatalogueItem,
  PricesType,
} from '@sprinx/knihovna-api-types';
import { Container as IocContainer } from 'typescript-ioc';
import { ResourcesContext } from './ResourcesContext';

export abstract class CatalogueResource {
  public ctx: ResourcesContext;

  constructor() {
    this.ctx = IocContainer.get(ResourcesContext);
  }

  abstract getCatalogue(params: CatalogueSettingsForResourceWithLimitation): Promise<EcomCatalogueItem[] | null>;

  abstract getCatalogueCategory(params: { hash: string }): Promise<CatalogueCategory | null>;

  abstract getFilterPriceRange(
    params: CatalogueSettingsForResource & { forceCheckoutPricesType?: PricesType },
  ): Promise<EcomCatalogueFilterOptionsNumberRange | null>;

  abstract getFilterParameterRange(
    params: CatalogueSettingsForResource & { parameterCode: string },
  ): Promise<EcomCatalogueFilterOptionsNumberRange | null>;

  abstract getFilterOptionsParameter(
    params: CatalogueSettingsForFilterOptionsParameter,
  ): Promise<EcomCatalogueFilterOptionsParameterValueDisplay[] | null>;
}
