import { checkoutContextState } from '@/client/atoms';
import fetchJson from '@/helpers/fetch-json';
// import fetchJson from '@/helpers/fetch-json';
import type { SupportedLocale } from '@sprinx/knihovna-api-types';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { toastMessage } from '../AppToaster';
import CartShareActionDialog from './CartShareActionDialog';

export type FormData = {
  customerEmail: string;
  customerName: string;
  message: string;
  to: string;
};

type RequestData = FormData & { checkoutId: string; locale: SupportedLocale };
type ResponseData = { errors?: string[]; ok: boolean };

// *****************************************
// *
// * CartShareActionDialog
// *
// *****************************************

export default function CartShareHandler({
  isDialogOpen,
  setIsDialogOpen,
}: {
  isDialogOpen: boolean;
  setIsDialogOpen: (v: boolean) => void;
}) {
  const intl = useIntl();
  const { checkoutId, locale } = useRecoilValue(checkoutContextState);

  const handleSubmitForm = useCallback(
    async (data: FormData) => {
      const requestData: RequestData = { ...data, checkoutId: checkoutId, locale };

      const response = await fetchJson<ResponseData>('/api/utils/share-cart', {
        body: JSON.stringify(requestData),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });

      if (response.ok === true) {
        toastMessage.success(
          intl.formatMessage(
            {
              defaultMessage: 'Email byl odeslán příjemci <strong>{to}</strong>',
              description: 'Message after email with shared cart was send successfully.',
              id: '+YD1P3',
            },
            {
              strong: (ch: any) => <strong>{ch}</strong>,
              to: requestData.to,
            },
          ),
        );
        setIsDialogOpen(false);
      } else {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Share cart failed with errors.', response.errors);
        }
        toastMessage.error('Při odesílání emailu došlo k chybě. Prosíme zkuste akci opakovat.');
      }
    },
    [checkoutId, intl, locale, setIsDialogOpen],
  );

  return <CartShareActionDialog isOpen={isDialogOpen} onSubmit={handleSubmitForm} setIsOpen={setIsDialogOpen} />;
}
