import { NavigationType } from '@/types';
import type { FC, HTMLAttributes } from 'react';
import { useRecoilValue } from 'recoil';
import { navigationsState } from '../../atoms';
import AppCustomHeaderNavBarLinkItem from '../AppCustomHeaderNavBarLinkItem';

export type AppCustomHeaderNavBarLinksProps = HTMLAttributes<HTMLDivElement> & {};

const AppCustomHeaderNavBarLinks: FC<AppCustomHeaderNavBarLinksProps> = ({ className, ...props }) => {
  const navItems = useRecoilValue(navigationsState(NavigationType.mobileAfter));

  return (
    <nav
      className='mobile-menu-helper flex h-full flex-col items-center px-4 pt-4 lg:hidden lg:px-4 lg:py-0'
      {...props}
    >
      {navItems &&
        navItems.map((i, idx) => <AppCustomHeaderNavBarLinkItem key={`mobile-afted-item-${idx}`} navbarItem={i} />)}
    </nav>
  );
};

AppCustomHeaderNavBarLinks.displayName = 'AppCustomHeaderNavBarLinks';

export default AppCustomHeaderNavBarLinks;
