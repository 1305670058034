import type { routesDefinition } from '@/routes';
import { routeUrl } from '@/routes';
import { useCallback } from 'react';

export function useRouteUrl() {
  return useCallback(
    <T extends keyof typeof routesDefinition, P extends (typeof routesDefinition)[T]['urlBuilder']>(
      routeType: T,
      params: P extends (...args: any[]) => any ? Parameters<P>[3] : Record<string, any> | undefined,
      otherParamsToQs?: boolean | string[],
    ) => {
      return routeUrl(routeType, {
        locale: 'cs',
        otherParamsToQs,
        params,
      });
    },
    [],
  );
}
