import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export default function useRedirectOnAuthChange() {
  const history = useHistory();

  return useCallback(() => {
    // Redirect in special cases
    // todo: make redirecton after login/logout more elegant...
    if (history != null) {
      if (history.location.pathname.startsWith('/kosik/')) {
        history.replace('/kosik');
      } else if (history.location.pathname.startsWith('/profil')) {
        history.replace('/');
      }
    }
  }, [history]);
}
