import type { FC, FormEventHandler, HTMLAttributes } from 'react';
import type { DialogModalProps } from './DialogModal';
import DialogModal from './DialogModal';

export type DialogFormModalProps = DialogModalProps & {
  formProps?: HTMLAttributes<HTMLFormElement>;
  onSubmit?: FormEventHandler<HTMLFormElement>;
};

const DialogFormModal: FC<DialogFormModalProps> = ({ children = null, formProps, onSubmit, ...props }) => (
  <form onSubmit={onSubmit} {...formProps}>
    <DialogModal {...props}>{children}</DialogModal>
  </form>
);

DialogFormModal.displayName = 'DialogFormModal';

export default DialogFormModal;
