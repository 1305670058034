import { selector } from 'recoil';
import { cartSubjectsState } from './cartSubjectsState';

/**
 * Index poctu produktov v kosiku.
 *
 * Obsahuje objekt `{ [sku: string]: number }`.
 */
export const cartSubjectsIndexState = selector<Record<string, number>>({
  get: ({ get }) => {
    const cartSubjects = get(cartSubjectsState);
    const nextP = Object.fromEntries(
      Object.entries({
        ...Object.fromEntries(cartSubjects.map((i) => [i.sku, i.quantity])),
      }).filter(([sku, q]) => sku && q > 0),
    );

    return nextP;
  },
  key: 'cartSubjectsIndexState',
});
