import Dialog from '@/client/components/Dialog';
import clsx from 'clsx';
import type { ButtonHTMLAttributes, FC } from 'react';
import { FormattedMessage } from 'react-intl';
import PasswordRenewModal from './PasswordRenewModal';

export type PasswordRenewButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
};

const PasswordRenewButton: FC<PasswordRenewButtonProps> = ({ className, type = 'button', ...props }) => {
  return (
    <Dialog closeOnClickOutside renderModal={(dialogProps) => <PasswordRenewModal {...dialogProps} />}>
      <button className={clsx('text-primary', className)} type={type} {...props}>
        <FormattedMessage
          defaultMessage='Zapomenuté heslo'
          description='Label of the password renew password button.'
          id='virkVE'
        />
      </button>
    </Dialog>
  );
};

PasswordRenewButton.displayName = 'PasswordRenewButton';

export default PasswordRenewButton;
