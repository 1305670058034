import { AuthResource } from '@/resources';
import type { RegistrationRequest, RegistrationResult } from '@/types';
import type { AxiosResponse } from 'axios';
import axios from 'axios';

export class ClientAuthResource extends AuthResource {
  async passwordRenew(email: string): Promise<{ reason: string; status: 'error' } | { status: 'success' }> {
    const response = await fetch('/api/auth/password-renew', {
      body: JSON.stringify({
        email,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const json = await response.json();

    if (response.ok) {
      return json;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.error('Password renew failed.', json);
    }

    return { reason: 'unknown', status: 'error' };
  }

  async registration(params: RegistrationRequest): Promise<RegistrationResult> {
    return axios
      .post<RegistrationResult, AxiosResponse<RegistrationResult>, RegistrationRequest>(
        '/api/auth/registration',
        params,
      )
      .then((result) => result.data);
  }
}
