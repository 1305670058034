import type { MediaSourceItem } from '@sprinx/knihovna-api-types';
import { useMemo } from 'react';
import type { ImageResponsiveProps } from '../components/ImageResponsive';

export default function useImageResponsiveSources(
  sources: MediaSourceItem[] | null | undefined,
  media: (string | null)[],
): ImageResponsiveProps['sources'] {
  return useMemo(
    () =>
      sources && Array.isArray(sources)
        ? [null, ...media].reduce((acc, media, idx) => {
            const { height, src, width } = sources[idx] ?? {};
            if (src) {
              // todo: if `width` is undefined is 100 correct fallback value?
              acc.push({ height, media: media ?? undefined, srcSet: src, width: width ?? 100 });
            }
            return acc;
          }, [] as ImageResponsiveProps['sources'])
        : [],
    [sources, media],
  );
}
