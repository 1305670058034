export enum StatusBarType {
  danger = 'danger',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

export type StatusBar = {
  closable: boolean;
  message: string;
  pathList: string[] | null;
  statusBarId: number;
  type: StatusBarType;
};
