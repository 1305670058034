export type ProductAvailabilityDisplayInfo = {
  color: string;
  note?: string | null;
  text: string;
};

export type ProductPackAvailability = {
  onSale: boolean;
  onStock: boolean;
  placeholder: ProductAvailabilityDisplayInfo | null;
  quantity: number;
  state: string;
  unit?: string;
};

export function calculateProductAvailability(opts: {
  productOnSale: boolean;
  productStockAvailableQuantity:
    | {
        note: string | null;
        quantity: number;
        state:
          | 'on-way'
          | 'onWay'
          | 'onway'
          | 'ordered'
          | 'sold-out'
          | 'soldOut'
          | 'soldout'
          | 'stock'
          | 'to-order'
          | 'toOrder'
          | 'toorder'
          | null
          | undefined;
        timing: Date | null;
        unit: string | null;
      }
    | null
    | undefined;
}) {
  const { productOnSale, productStockAvailableQuantity } = opts;

  // Product does not have `taxonomy = '/eshop/CZ'`
  if (!productOnSale) {
    return {
      onSale: false,
      onStock: false,
      placeholder: {
        color: '#EF3434',
        text: 'Prodej byl ukončen',
      },
      quantity: 0,
      state: 'sold-out',
      unit: undefined,
    };
  }

  // Product has no stockAvailability record in case where in Pohoda
  // does not exist stoct availability. Qunatity is 0.
  if (productStockAvailableQuantity == null) {
    return {
      onSale: true,
      onStock: false,
      placeholder: {
        color: '#5E9900',
        text: 'Skladem u dodavatele',
      },
      quantity: 0,
      state: 'to-order',
      unit: undefined,
    };
  }

  const quantity = ((v) => (typeof v === 'string' ? parseFloat(v) : v))(productStockAvailableQuantity.quantity ?? 0);
  const note = productStockAvailableQuantity.note;
  const unit = productStockAvailableQuantity.unit ?? undefined;
  const state = productStockAvailableQuantity.state;

  // Product is on stock
  if (state === 'stock') {
    const renderMaskedQuantity = (quantity: number) => {
      if (!quantity) {
        return '0';
      } else if (quantity < 3) {
        // !!! Stock availability is only for pcs product.
        // !!! For cartons the stock availability of the pcs product is divided
        // !!! by the number of pieces in the carton. So we can get the number 1.2345.
        // !!! The result is rounded to the nearest lower integer. If the quantity for
        // !!! the carton is 0.3444, then there will be at least 1 piece.
        return Math.floor(quantity) || 1;
      } else if (quantity >= 3 && quantity <= 5) {
        return '3-5';
      } else if (quantity > 5 && quantity <= 10) {
        return '5-10';
      } else if (quantity > 10 && quantity < 50) {
        return '10+';
      } else {
        return '50+';
      }
    };

    const renderPackUnit = (unit: string | null | undefined) => {
      if (unit === 'carton') {
        return 'karton';
      }
      return 'ks';
    };

    return {
      onSale: true,
      onStock: true,
      placeholder: {
        color: '#5E9900',
        note: quantity >= 3 ? `Skladem ${quantity} ${renderPackUnit(unit)}` : undefined,
        text: `Skladem ${renderMaskedQuantity(quantity || 1)} ${renderPackUnit(unit)}`,
      },
      quantity: quantity,
      state: 'stock',
      unit: unit,
    };
  }

  if (state === 'on-way' || (state as any) === 'onWay') {
    const expected =
      productStockAvailableQuantity.timing == null
        ? ''
        : new Intl.DateTimeFormat('cs').format(new Date(productStockAvailableQuantity.timing));

    return {
      onSale: true,
      onStock: false,
      placeholder: {
        color: '#EF3434',
        note,
        text: `Očekáváme ${expected}`,
      },
      quantity: quantity,
      state: 'on-way',
      unit: unit,
    };
  }

  if (state === 'ordered') {
    return {
      onSale: true,
      onStock: false,
      placeholder: {
        color: '#FFAF32',
        text: 'Na cestě',
      },
      quantity,
      state: 'ordered',
      unit,
    };
  }

  // Product have to be orderd.
  if (state === 'to-order' || (state as any) === 'toOrder') {
    return {
      onSale: true,
      onStock: false,
      placeholder: {
        color: '#5E9900',
        note,
        text: `Skladem u dodavatele ${note !== null && note.length > 0 ? `(${note})` : ''}`,
      },
      quantity: quantity,
      state: 'to-order',
      unit,
    };
  }

  // Product is temporary or permanenty sold out. Final state is based on `note`.
  if (state === 'sold-out' || (state as any) === 'soldOut') {
    if (note === 'Momentálně nedostupné') {
      return {
        onSale: false,
        onStock: false,
        placeholder: {
          color: '#EF3434',
          text: 'Momentálně nedostupné',
        },
        quantity: 0,
        state: 'sold-out',
        unit,
      };
    }

    return {
      onSale: false,
      onStock: false,
      placeholder: {
        color: '#EF3434',
        text: 'Prodej byl ukončen',
      },
      quantity: 0,
      state: 'sold-out',
      unit,
    };
  }

  return {
    onSale: true,
    onStock: false,
    placeholder: {
      color: '#5E9900',
      note,
      text: 'Skladem u dodavatele',
    },
    quantity: 0,
    state: 'sold-out',
    unit,
  };
}
