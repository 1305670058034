import type {
  ProductDetail,
  ProductMeta,
  ProductPackQuantityPrice,
  ProductPreview,
  ProductQuantityPrice,
  ProductReview,
} from '@/types';
import type { SupportedLocale } from '@sprinx/knihovna-api-types';
import { Container as IocContainer } from 'typescript-ioc';
import { ResourcesContext } from './ResourcesContext';

export interface ProductDetailResourceGetProductDetailParams {
  checkoutId: string;
  locale: SupportedLocale;
  sku: string;
}

export abstract class ProductDetailResource {
  public ctx: ResourcesContext;

  constructor() {
    this.ctx = IocContainer.get(ResourcesContext);
  }

  abstract getProductMeta(params: ProductDetailResourceGetProductDetailParams): Promise<ProductMeta | null>;
  abstract getProductPreview(params: ProductDetailResourceGetProductDetailParams): Promise<ProductPreview | null>;
  /** @deprecated */
  abstract getProductDetail(params: ProductDetailResourceGetProductDetailParams): Promise<ProductDetail | null>;
  abstract getProductReviews(params: { ean: string }): Promise<ProductReview[]>;
  abstract getProductQuantityPrices(params: { checkoutId: string; sku: string }): Promise<ProductQuantityPrice[]>;
  abstract getProductPackQuantityPrices(params: {
    checkoutId: string;
    locale: SupportedLocale;
    sku: string;
  }): Promise<ProductPackQuantityPrice>;
}
