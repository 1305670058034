import { Dialog as HlDialog, Transition } from '@headlessui/react';
import type { ReactElement, RefAttributes, SyntheticEvent } from 'react';
import { Fragment, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

export type DialogRef = {
  close: () => void;
  open: () => void;
  toggle: () => void;
};

export type DialogRenderModalProps = { close: () => void; isOpen: boolean };

export type DialogProps = {
  children?: ReactElement;
  className?: string;
  closeOnClickOutside?: boolean;
  defaultOpen?: boolean;
  onClose?: (event?: SyntheticEvent | KeyboardEvent | TouchEvent | MouseEvent) => void;
  onOpen?: (event?: SyntheticEvent | KeyboardEvent | TouchEvent | MouseEvent) => void;
  open?: boolean;
  renderModal: (props: DialogRenderModalProps) => ReactElement;
} & RefAttributes<DialogRef>;

const Dialog = forwardRef<DialogRef, DialogProps>(
  (
    { children, className, closeOnClickOutside = false, defaultOpen = false, onClose, onOpen, open, renderModal },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(open || defaultOpen);
    // const focusedElBeforeOpen = useRef<Element | null>(null);

    const openPopup = useCallback(
      (event?: SyntheticEvent) => {
        if (isOpen) return;
        setIsOpen(true);
        setTimeout(() => {
          if (onOpen) onOpen(event);
        }, 0);
      },
      [isOpen, onOpen],
    );

    const closePopup = useCallback(
      (event?: SyntheticEvent | KeyboardEvent | TouchEvent | MouseEvent) => {
        if (!isOpen) return;
        setIsOpen(false);
        // (focusedElBeforeOpen.current as HTMLElement)?.focus();
        setTimeout(() => {
          if (onClose) onClose(event);
        }, 0);
      },
      [isOpen, onClose],
    );

    const togglePopup = useCallback(
      (event?: React.SyntheticEvent) => {
        event?.stopPropagation();
        if (!isOpen) openPopup(event);
        else closePopup(event);
      },
      [closePopup, isOpen, openPopup],
    );

    useEffect(() => {
      if (typeof open === 'boolean') {
        if (open) openPopup();
        else closePopup();
      }
    }, [closePopup, open, openPopup]);

    useImperativeHandle(ref, () => ({
      close: () => {
        closePopup();
      },
      open: () => {
        openPopup();
      },
      toggle: () => {
        togglePopup();
      },
    }));

    return (
      <>
        {children && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              openPopup();
            }}
            role='presentation'
          >
            {children}
          </div>
        )}
        <Transition appear as={Fragment} show={isOpen}>
          <HlDialog
            as='div'
            className='relative z-[100]'
            onClose={() => {
              if (closeOnClickOutside) {
                closePopup();
              }
            }}
            open={isOpen}
          >
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-black bg-opacity-25' />
            </Transition.Child>

            <div className='fixed inset-0 overflow-y-auto'>
              <div className='flex min-h-full items-center justify-center text-center md:p-4'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0' // scale-95
                  enterTo='opacity-100' //  scale-100
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100' //  scale-100
                  leaveTo='opacity-0' // scale-95
                >
                  <HlDialog.Panel className={className}>{renderModal({ close: closePopup, isOpen })}</HlDialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </HlDialog>
        </Transition>
      </>
    );
  },
);

Dialog.displayName = 'Dialog';

export default Dialog;
