import type { TaxonomyBase } from './taxonomy-base';
import type { TaxonomyDetail } from './taxonomy-detail';
import type { TaxonomyMeta } from './taxonomy-meta';
import type { TaxonomyPreview } from './taxonomy-preview';

export enum TaxonomyDataStateLevel {
  none = 0,
  base,
  meta,
  preview,
  detail,
}

export type TaxonomyData = TaxonomyBase | TaxonomyMeta | TaxonomyPreview | TaxonomyDetail;

export type TaxonomyDataState =
  | TaxonomyDataStateNone
  | TaxonomyDataStateBase
  | TaxonomyDataStateMeta
  | TaxonomyDataStatePreview
  | TaxonomyDataStateDetail;

export type TaxonomyDataStateWorse<L extends TaxonomyDataStateLevel> = L extends TaxonomyDataStateLevel.detail
  ? TaxonomyDataStateNone | TaxonomyDataStateBase | TaxonomyDataStateMeta | TaxonomyDataStatePreview
  : L extends TaxonomyDataStateLevel.preview
  ? TaxonomyDataStateNone | TaxonomyDataStateBase | TaxonomyDataStateMeta
  : L extends TaxonomyDataStateLevel.meta
  ? TaxonomyDataStateNone | TaxonomyDataStateBase
  : TaxonomyDataStateNone;

export type TaxonomyDataStateWorseOrEqual<L extends TaxonomyDataStateLevel> =
  | TaxonomyDataStateWorse<L>
  | TaxonomyDataStateExact<L>;

export type TaxonomyDataBetter<L extends TaxonomyDataStateLevel> = L extends TaxonomyDataStateLevel.none
  ? TaxonomyBase | TaxonomyMeta | TaxonomyPreview | TaxonomyDetail
  : L extends TaxonomyDataStateLevel.base
  ? TaxonomyMeta | TaxonomyPreview | TaxonomyDetail
  : L extends TaxonomyDataStateLevel.meta
  ? TaxonomyPreview | TaxonomyDetail
  : TaxonomyDetail;

export type TaxonomyDataStateBetter<L extends TaxonomyDataStateLevel> = L extends TaxonomyDataStateLevel.none
  ? TaxonomyDataStateBase | TaxonomyDataStateMeta | TaxonomyDataStatePreview | TaxonomyDataStateDetail
  : L extends TaxonomyDataStateLevel.base
  ? TaxonomyDataStateMeta | TaxonomyDataStatePreview | TaxonomyDataStateDetail
  : L extends TaxonomyDataStateLevel.meta
  ? TaxonomyDataStatePreview | TaxonomyDataStateDetail
  : TaxonomyDataStateDetail;

export type TaxonomyDataStateBetterOrEqual<L extends TaxonomyDataStateLevel> =
  | TaxonomyDataStateBetter<L>
  | TaxonomyDataStateExact<L>;

export type TaxonomyDataStateExact<L extends TaxonomyDataStateLevel> = L extends TaxonomyDataStateLevel.none
  ? TaxonomyDataStateNone
  : L extends TaxonomyDataStateLevel.base
  ? TaxonomyDataStateBase
  : L extends TaxonomyDataStateLevel.meta
  ? TaxonomyDataStateMeta
  : L extends TaxonomyDataStateLevel.preview
  ? TaxonomyDataStatePreview
  : TaxonomyDataStateDetail;

export type TaxonomyDataExact<L extends TaxonomyDataStateLevel> = L extends TaxonomyDataStateLevel.none
  ? null
  : L extends TaxonomyDataStateLevel.base
  ? TaxonomyBase
  : L extends TaxonomyDataStateLevel.meta
  ? TaxonomyMeta
  : L extends TaxonomyDataStateLevel.preview
  ? TaxonomyPreview
  : TaxonomyDetail;

export type TaxonomyDataStateGuess<L extends TaxonomyDataStateLevel> = {
  data: TaxonomyBase & Partial<Omit<TaxonomyDataStateExact<L>['data'], keyof TaxonomyBase>>;
  level: TaxonomyDataStateLevel;
};

export type TaxonomyDataStateNone = { data: null; level: TaxonomyDataStateLevel.none };
export type TaxonomyDataStateBase = { data: TaxonomyBase; level: TaxonomyDataStateLevel.base };
export type TaxonomyDataStateMeta = { data: TaxonomyMeta; level: TaxonomyDataStateLevel.meta };
export type TaxonomyDataStatePreview = { data: TaxonomyPreview; level: TaxonomyDataStateLevel.preview };
export type TaxonomyDataStateDetail = { data: TaxonomyDetail; level: TaxonomyDataStateLevel.detail };

export function isTaxonomyDataStateWorse<L extends TaxonomyDataStateLevel>(
  level: L,
  d: TaxonomyDataState,
): d is TaxonomyDataStateWorse<L> {
  return d.level < level;
}

export function isTaxonomyDataStateWorseOrEqual<L extends TaxonomyDataStateLevel>(
  level: L,
  d: TaxonomyDataState,
): d is TaxonomyDataStateWorseOrEqual<L> {
  return d.level <= level;
}

export function isTaxonomyDataLevelBetter<L extends TaxonomyDataStateLevel>(
  level: L,
  d: TaxonomyDataState,
): d is TaxonomyDataStateBetter<L> {
  return d.level > level;
}

export function isTaxonomyDataLevelBetterOrEqual<L extends TaxonomyDataStateLevel>(
  level: L,
  d: TaxonomyDataState,
): d is TaxonomyDataStateBetter<L> {
  return d.level >= level;
}

export function isTaxonomyDataLevelEqual<L extends TaxonomyDataStateLevel>(
  level: L,
  d: TaxonomyDataState,
): d is TaxonomyDataStateExact<L> {
  return d.level === level;
}
