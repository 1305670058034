import type { UiContext } from '@/context';
import { PricesType } from '@sprinx/knihovna-api-types';
import { atom } from 'recoil';
import Cookies from 'js-cookie';

const PRICES_TYPE_COOKIE_NAME = 'alls_pricestype';

export const pricesTypeState = atom<PricesType>({
  default: PricesType.B2C,
  effects: [
    ({ onSet, setSelf }) => {
      const initialValue =
        (Cookies.get(PRICES_TYPE_COOKIE_NAME) as PricesType) ||
        (((typeof window !== 'undefined' ? window : global) as any).appContext as UiContext).pricesType;
      if (typeof initialValue === 'string' && initialValue) {
        setSelf(initialValue);
      }
      onSet((newValue) => {
        Cookies.set(PRICES_TYPE_COOKIE_NAME, newValue, { expires: 5 * 365 });
      });
    },
  ],
  key: 'pricesType',
});
