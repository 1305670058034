import { checkoutContextState } from '@/client/atoms';
import { useCheckoutUpdateContext } from '@/client/hooks/useCheckoutUpdateContext';
import type { UiContext } from '@/context';
import fetchJson, { FetchError } from '@/helpers/fetch-json';
import { ResourcesContext } from '@/resources';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Container as IocContainer } from 'typescript-ioc';
import type { LogoutCallback, LogoutErrorReason } from '../types';

export function useLogoutCall(options: {
  onError: (reason: LogoutErrorReason) => void;
  onSuccess: (nextUiCtx: Partial<UiContext>) => void;
}): LogoutCallback {
  const checkoutCtx = useRecoilValue(checkoutContextState);
  const updateCheckoutContext = useCheckoutUpdateContext();

  return useCallback<LogoutCallback>(async () => {
    try {
      const r = await fetchJson<{ status: 'error' } | { ctx: Partial<UiContext>; status: 'success' }>(
        '/api/auth/local-logout',
      );

      if (r.status === 'success') {
        if (r.ctx.accessToken) {
          IocContainer.get(ResourcesContext).update(r.ctx.accessTokenType, r.ctx.accessToken);
        }

        //
        // Update checkout settings.
        await updateCheckoutContext({
          cartLeaveActiveAndReturnEmpty: {
            nextPricingSettings: {
              currency: checkoutCtx.currency,
              distributor: '_default',
              priceGroups: ['_default'],
              pricesType: checkoutCtx.pricesType,
              region: checkoutCtx.region,
            },
            prevCartResetGifts: true,
            prevCartResetMethods: true,
          },
          checkoutId: checkoutCtx.checkoutId,
        });

        options.onSuccess(r.ctx);
      } else {
        options.onError('fatal');
      }
    } catch (err) {
      if (process.env.NODE_ENV === 'production') {
        if (err instanceof FetchError) {
          console.error(err);
          console.error('Abo');
        }
      }
      options.onError('fatal');
    }
  }, [checkoutCtx, options, updateCheckoutContext]);
}
