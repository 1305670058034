import type { MediaSourceItem, MediaSourceResponsive, MediaSourceSize } from '@sprinx/knihovna-api-types';
import type { HTMLAttributes, RefAttributes } from 'react';
import { forwardRef, useMemo } from 'react';
import invariant from 'tiny-invariant';
import type { SrcSetItem } from '../ImageLazy';
import ImageLazy from '../ImageLazy';
import ImageSimple from '../ImageSimple';
import type { AspectRatioOptions } from '../ImageWrapper';

export type ImageProps = HTMLAttributes<HTMLDivElement> &
  RefAttributes<HTMLDivElement> & {
    alt: string;
    aspectRatio?: AspectRatioOptions;
    fit?: 'cover' | 'contain';
    src: null | undefined | string | MediaSourceItem | MediaSourceResponsive;
    // sizes?:
  };

const Image = forwardRef<HTMLDivElement, ImageProps>(({ className, fit, src: pSrc = '', ...rest }, ref) => {
  pSrc = pSrc === null ? '' : pSrc;

  invariant(
    typeof pSrc === 'string' ||
      (typeof pSrc === 'object' &&
        (typeof (pSrc as MediaSourceItem).src === 'string' ||
          (Array.isArray((pSrc as MediaSourceResponsive).srcSet) && (pSrc as MediaSourceResponsive).srcSet.length))),
    "'Image' 'src' prop have to be an string, or array of sizes tuple.",
  );

  const imgOptions: { lqip?: string; sizes?: MediaSourceSize[]; src: string; srcSet?: SrcSetItem[] } = useMemo(() => {
    if (typeof pSrc === 'string') return { src: pSrc };

    const { sizes: xSizes, srcSet: xSrcSet }: MediaSourceResponsive = (pSrc as MediaSourceItem).src
      ? { sizes: undefined, srcSet: [pSrc as MediaSourceItem] }
      : (pSrc as MediaSourceResponsive);

    // chceme extrahovat lqip
    const [lqip] = xSrcSet.reduce(
      (l, i) => (l[1] > (i.width || 10000) ? [i.src, i.width ?? 1000] : l),
      [xSrcSet[0].src, xSrcSet[0].width || 10000],
    );

    const srcSet: SrcSetItem[] = [...xSrcSet]
      .sort((a, b) => (a.width || 1) - (b.width || 1))
      .map((i) => [i.src, i.width ?? 1000]);
    const src = srcSet[0][0];

    return {
      lqip,
      sizes: Array.isArray(xSizes) ? xSizes : undefined,
      src,
      srcSet: srcSet.length ? srcSet : undefined,
    };
  }, [pSrc]);

  if (imgOptions.lqip) {
    return (
      <ImageLazy
        ref={ref}
        className={className}
        fit={fit}
        lqip={imgOptions.lqip}
        sizes={imgOptions.sizes}
        src={imgOptions.src}
        srcSet={imgOptions.srcSet}
        {...rest}
      />
    );
  }

  return <ImageSimple ref={ref} fit={fit} src={imgOptions.src} {...rest} />;
});

Image.displayName = 'Image';

export default Image;
