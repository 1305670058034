import clsx from 'clsx';
import type { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuthHandlerContext } from '../AuthHandler';

export type LogoutButtonProps = {
  className?: string;
  label?: ReactNode;
};

const LogoutButton: FC<LogoutButtonProps> = ({ className, label }) => {
  const authActions = useAuthHandlerContext();

  return (
    <button
      className={clsx('text-[0.9375rem]', className)}
      /* onClick={handleLogoutClick} */ onClick={authActions.handleLogoutClick}
    >
      {label ? label : <FormattedMessage defaultMessage='Odhlásit' description='Label of logout button.' id='hCCUgi' />}
    </button>
  );
};

LogoutButton.displayName = 'LogoutButton';

export default LogoutButton;
