import { defineMessage } from '@formatjs/intl';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const definition = defineMessage({
  defaultMessage:
    '{pagesize, plural, zero {Zobrazit další} one {Zobrazit další 1} few {Zobrazit další {pagesize}} other {Zobrazit dalších {pagesize}}}',
  description: 'Lebel for load next page action.',
  id: 'XGCEI6',
});

const MsgLoadNextPageComponent: FC<{ pageSize?: number }> = ({ pageSize }) => (
  <FormattedMessage {...definition} values={{ pagesize: pageSize ?? 0 }} />
);

MsgLoadNextPageComponent.displayName = 'MsgLoadNextPage';

const MsgLoadNextPage = Object.assign(MsgLoadNextPageComponent, { definition });

export default MsgLoadNextPage;
