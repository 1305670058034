import pathToRegexp from 'path-to-regexp';

/**
 * Returns `inputParameters` object splitted to two separate
 * @param otherParamsToQs
 * @param path
 * @param inputParams
 */
export function routesBuilderSplitInputParams<P extends Record<string, any>>(
  otherParamsToQs: boolean | string[] | undefined | null,
  path: string,
  inputParams: P,
): {
  otherParamsExists: boolean;
  pathParams: Record<string, any>;
  queryParams?: Record<string, any>;
} {
  const [pathParams, queryParams] = !otherParamsToQs
    ? [inputParams]
    : ((reg) => {
        const pathParamKeys = reg.keys.map((i) => i.name);
        return Object.entries(inputParams).reduce<[Record<string, any>, Record<string, any>]>(
          (acc, [k, v]) => {
            if (pathParamKeys.includes(k)) {
              return [{ ...acc[0], [k]: v }, acc[1]];
            }

            if (Array.isArray(otherParamsToQs) && !otherParamsToQs.includes(k)) {
              return acc;
            }

            return [acc[0], { ...acc[1], [k]: v }];
          },
          [{}, {}],
        );
      })(pathToRegexp(path));

  const otherParamsExists = !!queryParams && Object.keys(queryParams).length > 0;

  return {
    otherParamsExists,
    pathParams,
    queryParams,
  };
}
