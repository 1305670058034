import type { UiContext } from '@/context';
import type { AppAuthUser } from '@/types';
import { atom } from 'recoil';

export const authUserState = atom<AppAuthUser | undefined>({
  default: undefined,
  effects: [
    ({ setSelf }) => {
      const initialValue = (((typeof window !== 'undefined' ? window : global) as any).appContext as UiContext)
        .authenticatedUser;
      if (initialValue && typeof initialValue === 'object') {
        setSelf(initialValue);
      }
    },
  ],
  key: 'authUser',
});
