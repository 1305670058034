import { ResourcesContext } from '@/resources';
import fetch from 'cross-fetch';
import { useMemo } from 'react';
import type { FetchFunction } from 'relay-runtime';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import type { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
import { Container as IocContainer } from 'typescript-ioc';

export const RELAY_AUTHORIZATION_CONTEXT_KEY = 'RELAY_AUTHORIZATION';
export const RELAY_INITIAL_RECORDS_CONTEXT_KEY = 'RELAY_INITIAL_RECORDS';
export const RELAY_INITIAL_RECORDS_PROP = '__RELAY_INITIAL_RECORDS__';

let relayEnvironment: Environment;

// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise
const fetchRelay: FetchFunction = async (operation, variables) => {
  const { accessToken, accessTokenType } = IocContainer.get(ResourcesContext);

  if (!accessToken || !accessTokenType) {
    throw new Error('Call of GraphQL end point is not authenticated.');
  }

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.groupCollapsed(`Fetching GQL '${operation.name}' MODERN`);
    // eslint-disable-next-line no-console
    console.log('With variables', variables);
    // eslint-disable-next-line no-console
    console.log('AccessToken', accessToken);
    // eslint-disable-next-line no-console
    console.groupEnd();
  }

  // todo: cesta ku graphql by mala byt v nastaveni
  const GRAPHQL_URI =
    typeof window !== 'undefined'
      ? '/api/utils/gql'
      : (process.env.RAZZLE_KNIHOVNA_API_URI || '').replace('/api', '/__ecom__/graphql') ||
        'https://be-vybaveniprouklid.stages.udolni.net/__ecom__/graphql';

  const response = await fetch(GRAPHQL_URI, {
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
    headers: {
      Authorization: `${accessTokenType} ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  const json = await response.json();

  // GraphQL returns exceptions (for example, a missing required variable) in the "errors"
  // property of the response. If any exceptions occurred when processing the request,
  // throw an error to indicate to the developer what went wrong.
  if (Array.isArray(json.errors)) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(json.errors);
      console.error('GraphQL request ends with errors. See above error.');
    }
    throw new Error(
      `Error fetching GraphQL query '${operation.name}' with variables '${JSON.stringify(variables)}': ${JSON.stringify(
        json.errors,
      )}`,
    );
  }

  // Otherwise, return the full payload.
  return json;
};

const createEnvironment = () =>
  new Environment({
    // Create a network layer from the fetch function
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource()),
  });

// For use in non-react contexts: SSR.
// Should be paired with finalizeRelay().
export const initializeRelay = (initialRecords?: RecordMap | undefined): Environment => {
  // Create a network layer from the fetch function
  const environment = relayEnvironment ?? createEnvironment();

  // If your page has data fetching methods that use Relay, the initial records
  // will get hydrated here
  if (initialRecords) {
    environment.getStore().publish(new RecordSource(initialRecords));
  }

  if (typeof window === 'undefined') {
    // Tell relay to stop its normal garbage collection processes. This prevents
    // data being lost between calling relay's `fetchQuery` and our
    // `finalizeRelay` method below
    environment.getStore().holdGC();

    // For SSG and SSR always create a new Relay environment
    return environment;
  }

  // Create the Relay environment once in the client
  if (!relayEnvironment) {
    relayEnvironment = environment;
  }

  return relayEnvironment;
};

// For use in react context to update data of relay Store.
// Data should be received from BE action after `fetchQuery` was persormed
// followed with `finalizeRelay`.
export const updateRelayStore = (initialRecords: RecordMap | undefined): void => {
  // Only on client with already initialized relayEnvironment
  if (typeof window === 'undefined' || relayEnvironment == null) return;

  // Update store and notify subscribers.
  if (initialRecords) {
    const store = relayEnvironment.getStore();
    store.publish(new RecordSource(initialRecords));
    store.notify();
  }
};

// Used to re-hydrate the relay cache in the client.
export const finalizeRelay = <P extends Record<string, any>>(
  environment: Environment,
  pageProps: P | undefined,
): PagePropsWithRelay<P> => {
  const nextPageProps = { ...pageProps };
  nextPageProps[RELAY_INITIAL_RECORDS_PROP] = environment.getStore().getSource().toJSON();

  return nextPageProps as PagePropsWithRelay<P>;
};

// For use in react components
export const useRelayEnvironmentInitialized = <P extends Record<string, any>>(pageProps: P | null | undefined) => {
  const initialRecords: RecordMap | undefined = ((pageProps ?? {}) as Record<string, any>)[RELAY_INITIAL_RECORDS_PROP];
  return useMemo(() => initializeRelay(initialRecords), [initialRecords]);
};

export type PagePropsWithRelay<P extends Record<string, any>> = P & {
  [RELAY_INITIAL_RECORDS_PROP]: RecordMap;
};
