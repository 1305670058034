/**
 * @generated SignedSource<<dc05c5b6eaf04feef39f4fe9b0c7ea1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type cartState_cart$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"cartSubjectsState_cart_subjects">;
  readonly " $fragmentType": "cartState_cart";
};
export type cartState_cart$key = {
  readonly " $data"?: cartState_cart$data;
  readonly " $fragmentSpreads": FragmentRefs<"cartState_cart">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "cartState_cart"
};

(node as any).hash = "49c692c6e84124ee00637f6e43d5c9a6";

export default node;
