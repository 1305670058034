import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const MsgCartEmpty: FC = () => (
  <FormattedMessage
    defaultMessage='Váš košík je prázdný'
    description='Empty shopping cart alert message.'
    id='6Z8XIZ'
  />
);

MsgCartEmpty.displayName = 'MsgCartEmpty';

export default MsgCartEmpty;
