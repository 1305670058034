import Dialog, { DialogActions, DialogContent, DialogModal, DialogTitle } from '@/client/components/Dialog';
import { useCartTotals } from '@/client/hooks';
import type { FC } from 'react';
import { useIntl } from 'react-intl';
import { useAuthHandlerContext } from './useAuthHandlerContext';

const LogoutConfirmDialog: FC<{ close: () => void }> = ({ close }) => {
  const intl = useIntl();
  const cartTotals = useCartTotals();
  const { handleLogoutConfimYesClick } = useAuthHandlerContext();

  const msgs =
    cartTotals.data.total.count > 0
      ? {
          content: intl.formatMessage({
            defaultMessage: 'Máte produkty v košíku. Skutečně se chcete odhlásit?',
            description:
              'The confirmation message of the dialogue displayed at the logout in case the shopping cart is not empty.',
            id: 'C0/vh4',
          }),
          title: intl.formatMessage({
            defaultMessage: 'Skutečně se chcete odhlásit?',
            description: 'The title of the dialogue displayed at the logout in case the shopping cart is not empty.',
            id: 'EwP3Yu',
          }),
        }
      : {
          content: intl.formatMessage({
            defaultMessage: 'Skutečně se chcete odhlásit?',
            description:
              'The confirmation message of the dialogue displayed at the logout in case the shopping cart is empty.',
            id: 'kzD8kD',
          }),
          title: intl.formatMessage({
            defaultMessage: 'Skutečně se chcete odhlásit?',
            description: 'The title of the dialogue displayed at the logout in case the shopping cart is empty.',
            id: '4GFEzl',
          }),
        };

  return (
    <Dialog
      closeOnClickOutside
      onClose={close}
      open={true}
      renderModal={({ close }) => (
        <DialogModal className='bg-primary-200 p-5'>
          <DialogTitle onCloseDialog={close} showCloseAction>
            {msgs.title}
          </DialogTitle>
          <DialogContent className='mt-4 flex flex-col gap-4'>{msgs.content}</DialogContent>
          <DialogActions className='mt-2 flex flex-row items-center gap-4'>
            <button className='button-primary-contained-normal hover:underline' onClick={() => close()}>
              {intl.formatMessage({ defaultMessage: 'Zrušit', description: 'cancel', id: 'M2dVFi' })}
            </button>
            <button
              className='rounded-md bg-orange px-4 py-2 font-bold text-white hover:bg-orange-600'
              onClick={handleLogoutConfimYesClick}
            >
              {intl.formatMessage({
                defaultMessage: 'Odhlásit',
                description: 'Label of logout button.',
                id: 'hCCUgi',
              })}
            </button>
          </DialogActions>
        </DialogModal>
      )}
    />
  );
};

export default LogoutConfirmDialog;
