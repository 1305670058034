import { AuthResource } from '@/resources';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Container as IocContainer } from 'typescript-ioc';
import { toastMessage } from '../../AppToaster';

export function usePasswordRenewCall(handlers?: { onSuccess?: () => void }) {
  const intl = useIntl();

  const showSaveMessage = useCallback(
    (status: 'error' | 'success', reason?: string) => {
      if (status === 'success') {
        toastMessage.success(
          intl.formatMessage({
            defaultMessage: 'Obnova hesla proběhla úspěšně. Nové heslo bude v email zprávě.',
            description: 'Password renew success snackbar message.',
            id: 'wUandV',
          }),
        );
      } else {
        if (reason === 'emailNotFound') {
          toastMessage.warning(
            intl.formatMessage({
              defaultMessage: 'Obnovu hesla nebylo možné provést.',
              description:
                'Password renew warning snackbar message. User not found (for security reasons do not propagate this information).',
              id: 'CgrnVC',
            }),
          );
        } else {
          toastMessage.error(
            intl.formatMessage({
              defaultMessage: 'Při obnově hesla nastala chyba.',
              description: 'Password renew error snackbar message.',
              id: '07BCzL',
            }),
          );
        }
      }
    },
    [intl],
  );

  const call = useCallback(
    (data: { email: string }): void => {
      const authResource = IocContainer.get(AuthResource);
      authResource
        .passwordRenew(data.email)
        .then((res) => {
          if (res.status === 'success') {
            if (handlers?.onSuccess) handlers.onSuccess();
            showSaveMessage(res.status);
          } else {
            if (process.env.NODE_ENV !== 'production') {
              console.error('PasswordRenewDialog error.', res.reason);
            }
            showSaveMessage(res.status, res.reason);

            // User account was not found. Close dialog.
            if (res.reason === 'emailNotFound') {
              if (handlers?.onSuccess) handlers.onSuccess();
            }
          }
        })
        .catch((err) => {
          if (process.env.NODE_ENV !== 'production') {
            console.error('PasswordRenewDialog error.', err);
          }

          showSaveMessage('error');
        });
    },
    [handlers, showSaveMessage],
  );

  return call;
}
