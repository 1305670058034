import clsx from 'clsx';
import type { ComponentProps, ElementType, ReactElement, ReactNode, Ref } from 'react';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const defaultElement = RouterLink;

export type AppCustomHeaderNavBarLinkItemComponentOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children?: ReactNode;
  className?: string;
};

export type AppCustomHeaderNavBarLinkItemComponentProps<E extends ElementType> =
  AppCustomHeaderNavBarLinkItemComponentOwnProps<E> &
    Omit<ComponentProps<E>, keyof AppCustomHeaderNavBarLinkItemComponentOwnProps>;

interface AppCustomHeaderNavBarLinkItemComponentType {
  <E extends ElementType = typeof defaultElement>(props: AppCustomHeaderNavBarLinkItemComponentProps<E>): ReactElement<
    any,
    any
  > | null;
  displayName?: string | undefined;
}

/**
 * @component
 */
const AppCustomHeaderNavBarLinkItemComponent: AppCustomHeaderNavBarLinkItemComponentType = forwardRef(
  (
    { as: Component = RouterLink, className, ...rest }: AppCustomHeaderNavBarLinkItemComponentOwnProps,
    ref: Ref<Element>,
  ) => {
    return (
      <Component
        ref={ref}
        className={clsx('flex items-center text-base hover:underline', className)}
        {...rest}
        as={undefined}
      />
    );
  },
);

AppCustomHeaderNavBarLinkItemComponent.displayName = 'AppCustomHeaderNavBarLinkItemComponent';

export default AppCustomHeaderNavBarLinkItemComponent;
