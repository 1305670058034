import NoSsr from '@/client/components/NoSsr';
import { toastMessage } from '@/client/compositions/AppToaster';
import type { FC, MouseEvent, ReactNode } from 'react';
import { Suspense, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLoginCall, useLoginSuccess, useLogoutCall, useLogoutSuccess } from '../../hooks';
import type { LoginErrorReason } from '../../types';
import { AuthHandlerContext } from './AuthHandlerContext';
import LoginDialog from './LoginDialog';
import LogoutConfirmDialog from './LogoutConfirmDialog';

const AuthHandler: FC<{ children?: ReactNode }> = ({ children }) => {
  const intl = useIntl();
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const onLoginSuccess = useLoginSuccess();
  const onLogoutSuccess = useLogoutSuccess();

  const [loginError, setLoginError] = useState<LoginErrorReason | undefined>(undefined);

  const handleLoginSubmit = useLoginCall({
    onError(reason) {
      setLoginError(reason);
    },
    async onSuccess(userData) {
      setShowLoginDialog(false);
      setLoginError(undefined);
      if (onLoginSuccess) {
        await onLoginSuccess(userData);
      }
    },
  });

  const handleLoginClick = useCallback(() => {
    setShowLoginDialog(true);
  }, []);

  const logoutCall = useLogoutCall({
    onError(reason?: 'fatal') {
      if (reason === 'fatal') {
        toastMessage.error(
          intl.formatMessage({
            defaultMessage: 'Při odhlášení došlo k chybě. Zkuste odhlášení opakovat prosím.',
            description: 'Error message when logout process ends with an fatal error.',
            id: 'iSTuqf',
          }),
        );
      }
    },
    onSuccess(nextUiCtx) {
      toastMessage.success(
        intl.formatMessage({
          defaultMessage: 'Odhlášení proběhlo úspěšně.',
          description: 'Success logout message.',
          id: 'adlM5x',
        }),
      );

      if (onLogoutSuccess) {
        onLogoutSuccess(nextUiCtx);
      }
    },
  });

  const handleLogoutClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (event: MouseEvent) => {
      setShowLogoutConfirm(true);
    },
    [],
  );

  const handleLogoutConfimYesClick = useCallback(
    (event: MouseEvent) => {
      // Prevent closing menu. Menu closing causes unmount of component and state update has no effect.
      event.stopPropagation();
      logoutCall();
      setShowLogoutConfirm(false);
    },
    [logoutCall],
  );

  return (
    <AuthHandlerContext.Provider
      value={{
        handleLoginClick,
        handleLoginSubmit,
        handleLogoutClick,
        handleLogoutConfimYesClick,
      }}
    >
      {children}
      <NoSsr>
        <Suspense>
          {showLoginDialog && (
            <LoginDialog
              close={() => {
                setShowLoginDialog(false);
              }}
              loginError={loginError}
            />
          )}
          {showLogoutConfirm && (
            <LogoutConfirmDialog
              close={() => {
                setShowLogoutConfirm(false);
              }}
            />
          )}
        </Suspense>
      </NoSsr>
    </AuthHandlerContext.Provider>
  );
};

export default AuthHandler;
