import { mdiImageBrokenVariant } from '@/client/icons';
import { Icon } from '@mdi/react';
import type { MediaSourceSize } from '@sprinx/knihovna-api-types';
import clsx from 'clsx';
import type { HTMLAttributes, Ref, RefAttributes } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import type { AspectRatioOptions } from '../ImageWrapper';
import ImageWrapper from '../ImageWrapper';

export type SrcSetItem = [src: string, width: number];

export type ImageLazyProps = HTMLAttributes<HTMLDivElement> &
  RefAttributes<HTMLDivElement> & {
    alt: string;
    aspectRatio?: AspectRatioOptions;
    fit?: 'cover' | 'contain';
    imgRef?: Ref<HTMLImageElement>;
    lqip: string;
    sizes?: MediaSourceSize[];
    src: string;
    srcSet?: SrcSetItem[];
  };

const ImageLazy = forwardRef<HTMLDivElement, ImageLazyProps>(
  ({ alt, aspectRatio, className, fit = 'cover', imgRef: pImgRef, lqip, sizes, src, srcSet, ...rest }, ref) => {
    const [loaded, setLoaded] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);
    useImperativeHandle(pImgRef, () => imgRef.current as any);

    useEffect(() => {
      if (imgRef.current && imgRef.current.complete) {
        setLoaded(true);
      }
    }, []);

    return (
      <ImageWrapper ref={ref} aspectRatio={aspectRatio} {...rest}>
        <img
          alt={alt}
          aria-hidden='true'
          className={clsx(
            fit === 'contain' && 'object-contain',
            fit === 'cover' && 'object-cover',
            'object-center',
            loadingError && 'opacity-0',
            className,
          )}
          src={lqip}
        />
        <img
          ref={imgRef}
          alt={alt}
          className={clsx(
            fit === 'contain' && 'object-contain',
            fit === 'cover' && 'object-cover',
            'object-center opacity-0 transition-opacity duration-300',
            loaded && !loadingError && 'opacity-100',
            className,
          )}
          loading='lazy'
          onError={() => setLoadingError(true)}
          onLoad={() => setLoaded(true)}
          sizes={Array.isArray(sizes) ? sizes.join(', ') : undefined}
          src={src}
          srcSet={Array.isArray(srcSet) ? srcSet.map((i) => `${i[0]} ${i[1]}w`).join(', ') : ''}
        />
        <div className='flex items-center justify-center'>
          <Icon className={clsx('opacity-0', loadingError && 'opacity-100')} path={mdiImageBrokenVariant} size='40%' />
        </div>
      </ImageWrapper>
    );
  },
);

ImageLazy.displayName = 'ImageLazy';

export default ImageLazy;
