import { mdiTrashCanOutline } from '@/client/icons';
import { Icon } from '@mdi/react';
import clsx from 'clsx';
import type { FC, HTMLAttributes, MouseEvent } from 'react';
import { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from '../Tooltip';

export type IconButtonDeleteProps = Omit<HTMLAttributes<HTMLButtonElement>, 'onClick'> & {
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void | PromiseLike<unknown>;
  size?: string | number;
};

const IconButtonDelete: FC<IconButtonDeleteProps> = ({ className, onClick, size = '1.2rem', ...props }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  const handleButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onClick?.(event);
    },
    [onClick],
  );

  return (
    <>
      <button
        ref={buttonRef}
        className={clsx('print:hidden', className)}
        onClick={handleButtonClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...props}
      >
        <Icon path={mdiTrashCanOutline} size={size} />
      </button>
      {isTooltipVisible && (
        <Tooltip referenceElement={buttonRef.current}>
          <FormattedMessage
            defaultMessage='Odstranit'
            description='Delete item button confirmation message.'
            id='M+tDpT'
          />
        </Tooltip>
      )}
    </>
  );
};

IconButtonDelete.displayName = 'IconButtonDelete';

export default IconButtonDelete;
