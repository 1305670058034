export enum EmailTemplateType {
  cancelOrder = 'cancelOrder',
  passwordRenew = 'passwordRenew',
  productDemand = 'productDemand',
  purchaseCreated = 'purchaseCreated',
  readyToPickup = 'readyToPickup',
  registration = 'registration',
  sentToParcelService = 'sentToParcelService',
  watchdog = 'watchdog',
}
