import type { TaxonomyDataExact, TaxonomyDataStateLevel, TaxonomyMeta, TaxonomyTreeItem } from '@/types';
import type { SupportedLocale, SupportedRegion } from '@sprinx/knihovna-api-types';
import { Container as IocContainer } from 'typescript-ioc';
import { ResourcesContext } from './ResourcesContext';

export abstract class TaxonomiesResource {
  public ctx: ResourcesContext;

  constructor() {
    this.ctx = IocContainer.get(ResourcesContext);
  }

  abstract getSubTree(hash: string, depth?: number): Promise<TaxonomyTreeItem[] | null>;

  abstract getTaxonomyDetail<L extends TaxonomyDataStateLevel>(
    level: L,
    hash: string,
    locale: SupportedLocale,
    region: SupportedRegion,
  ): Promise<TaxonomyDataExact<L> | null>;

  abstract hashToCode(hash: string): Promise<string | null>;

  abstract withAncestors(hash: string): Promise<TaxonomyMeta[] | null>;
}
