/**
 * @generated SignedSource<<88c378468a3f9989276dcd2da03c9b90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type checkoutUserExistingCartsState_cart$data = {
  readonly existingCarts: ReadonlyArray<{
    readonly id: string;
  }> | null;
  readonly " $fragmentType": "checkoutUserExistingCartsState_cart";
};
export type checkoutUserExistingCartsState_cart$key = {
  readonly " $data"?: checkoutUserExistingCartsState_cart$data;
  readonly " $fragmentSpreads": FragmentRefs<"checkoutUserExistingCartsState_cart">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "checkoutUserExistingCartsState_cart"
};

(node as any).hash = "4ce76538aa912de785ff5946ddf8f0ad";

export default node;
