import { mdiClose } from '@/client/icons';
import { Icon } from '@mdi/react';
import clsx from 'clsx';
import type { FC, ReactElement, ReactNode } from 'react';

export type DialogTitleProps = {
  children?: ReactNode;
  className?: string;
  closeActionIcon?: ReactElement;
  onCloseDialog?: () => void;
  showCloseAction?: boolean;
};

const DialogTitle: FC<DialogTitleProps> = ({
  children = null,
  className,
  closeActionIcon,
  onCloseDialog: handleCloseDialog,
  showCloseAction = false,
}) => (
  <div className={clsx('mb-4 inline-flex w-full items-center', className)}>
    <div className='inline-block flex-1 text-left text-2xl font-medium'>{children}</div>
    {showCloseAction && (
      <div>
        <button
          className='iconbutton-default-text-large'
          onClick={() => {
            if (handleCloseDialog) handleCloseDialog();
          }}
          type='button'
        >
          {closeActionIcon || <Icon path={mdiClose} size={'1.5em'} />}
        </button>
      </div>
    )}
  </div>
);

DialogTitle.displayName = 'DialogTitle';

export default DialogTitle;
