import clsx from 'clsx';
import type { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';

export type QuantityInputIconButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const QuantityInputIconButton: FC<QuantityInputIconButtonProps> = ({ className, ...props }) => (
  <button
    className={clsx(
      'm-1 flex max-h-[1.5rem] min-h-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] items-center justify-center rounded-md bg-primary font-bold  text-white hover:bg-primary-600 disabled:bg-slate-500',
      className,
    )}
    {...props}
  />
);

QuantityInputIconButton.displayName = 'QuantityInputIconButton';

export default QuantityInputIconButton;
