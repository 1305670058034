import type { CheckoutData } from '@/types';
import { useCallback } from 'react';
import invariant from 'tiny-invariant';
import { useCheckoutData } from './useCheckoutData';

type Utilization = CheckoutData['invoiceInfo']['utilization'];
type Conatact = CheckoutData['invoiceInfo']['contact'];
type ValueOrSetter<T> = T | ((prev: T) => T);

export function useCheckoutDataInvoiceInfo() {
  const checkoutData = useCheckoutData();

  const setInvoiceInfo = useCallback(
    async (valueOrSetter: ValueOrSetter<Conatact>): Promise<void> => {
      invariant(checkoutData.isLoading === false && checkoutData.data !== undefined, 'Wait for checkout data first');
      const next =
        typeof valueOrSetter === 'function' ? valueOrSetter(checkoutData.data.invoiceInfo.contact) : valueOrSetter;

      await checkoutData.update((p) => ({
        ...p,

        invoiceInfo: {
          ...p.invoiceInfo,
          contact: next,
        },
      }));
    },
    [checkoutData],
  );

  const setInvoiceInfoUtilization = useCallback(
    async (valueOrSetter: ValueOrSetter<Utilization>): Promise<void> => {
      invariant(checkoutData.isLoading === false && checkoutData.data !== undefined, 'Wait for checkout data first');
      const next =
        typeof valueOrSetter === 'function' ? valueOrSetter(checkoutData.data.invoiceInfo.utilization) : valueOrSetter;

      await checkoutData.update((p) => ({
        ...p,
        invoiceInfo: {
          ...p.invoiceInfo,
          utilization: next,
        },
      }));
    },
    [checkoutData],
  );

  return {
    invoiceInfo: checkoutData.data?.invoiceInfo.contact ?? null,
    invoiceInfoUtilization: checkoutData.data?.invoiceInfo.utilization,
    setInvoiceInfo,
    setInvoiceInfoUtilization,
  };
}
