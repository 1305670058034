import classes from './AppLoadingProgressBar.module.css';

export type AppLoadingProgressBarProps = {
  active?: boolean;
};

const AppLoadingProgressBar = ({ active = true }: AppLoadingProgressBarProps): JSX.Element => (
  <>
    {active ? (
      <div className={classes.container}>
        <div aria-busy={'true'} className={classes.loading} role='progressbar' />
      </div>
    ) : null}
  </>
);

export default AppLoadingProgressBar;
