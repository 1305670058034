import AppLoadingProgressBar from '@/client/components/AppLoadingProgressBar';
import { CartShareProvider } from '@/client/compositions/CartShareAction';
import clsx from 'clsx';
import type { FC, HTMLAttributes, ReactNode, RefAttributes } from 'react';
import { forwardRef } from 'react';
import AppCustomLayoutFooter from './components/AppCustomLayoutFooter';
import AppCustomLayoutHeader from './components/AppCustomLayoutHeader';
import AppCustomProductComparisonWidget from './components/AppCustomProductComparisonWidget';

export type AppCustomLayoutProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> &
  RefAttributes<HTMLDivElement> & {
    children: ReactNode;
    isLoading?: boolean;
    mainNavAfter?: React.ReactNode;
  };

const AppCustomLayout: FC<AppCustomLayoutProps> = forwardRef(
  ({ children, className, isLoading, mainNavAfter, ...rest }, ref) => {
    return (
      <CartShareProvider>
        <div ref={ref} className={clsx('flex min-h-screen flex-col', className)} {...rest}>
          <AppLoadingProgressBar active={isLoading} />
          <AppCustomLayoutHeader />

          {children}

          <AppCustomProductComparisonWidget />
          <AppCustomLayoutFooter />
        </div>
      </CartShareProvider>
    );
  },
);

AppCustomLayout.displayName = 'AppCustomLayout';

export default AppCustomLayout;
