import { isNotEmptyArray } from '@/helpers';
import type { ProductPackType } from '@/types';
import { type ProductDetail, type ProductPackPrice } from '@/types';
import { useMemo } from 'react';

export function useProductPackPrice(product: ProductDetail, packType: ProductPackType, qty: number): ProductPackPrice {
  const packPrices = product.quantityPrices?.find((p) => p.pack?.packType === packType)?.prices;
  const qtyPrice =
    isNotEmptyArray(packPrices) &&
    [...packPrices].sort((a, b) => b.quantityFrom - a.quantityFrom).find((i) => i.quantityFrom <= (qty ?? 1));

  return useMemo(() => {
    return qtyPrice
      ? {
          packType: packType,
          prices: {
            price: qtyPrice.amount ?? 0,
            priceWithTax: qtyPrice.amountWithTax ?? 0,
            vatAmount: qtyPrice.vatAmount,
            vatCode: qtyPrice.vatCode,
          },
        }
      : {
          packType: packType,
          prices: {
            price: product.price,
            priceWithTax: product.priceWithTax,
            vatAmount: product.vatAmount,
            vatCode: product.vatCode,
          },
        };
  }, [product, qtyPrice, packType]);
}
