import { cmsResultToArticlePreview } from '@/helpers';
import type { ArtriclesResourceGetArticlesForTaxonomyParams } from '@/resources';
import { ArticleResource } from '@/resources';
import type { ArticlePreview } from '@/types';
import type { WctKentico12DocumentsResult } from '@sprinx/knihovna-api-types';
import axios from 'axios';
import { isAxiosError } from '../../server/utils/utils';
import type { ShowroomType } from '../pages/ShowroomsDetailPage/types/ShowroomType';
import type { ShowroomsPageType } from '../pages/ShowroomsPage/types/ShowroomsPageType';

export class ClientArticleResource extends ArticleResource {
  async getArticlesForTaxonomy(
    params: ArtriclesResourceGetArticlesForTaxonomyParams,
  ): Promise<ArticlePreview[] | null> {
    try {
      const r = await axios
        .get<WctKentico12DocumentsResult>(`/api/articles/for-taxonomy`, {
          headers: { Authorization: `${this.ctx.accessTokenType} ${this.ctx.accessToken}` },
          params,
        })
        .then((result) => result.data);

      return r?.status === 'success' ? cmsResultToArticlePreview(null, r.data) : null;
    } catch (err) {
      if (isAxiosError(err)) {
        console.error(
          'ClientArticleResource.getArticlesForTaxonomy request faild.',
          err.response?.status,
          err.response?.data,
        );
      }
    }

    return null;
  }

  async getShowrooms(): Promise<ShowroomsPageType[] | null> {
    try {
      const r = await axios
        .get(`/api/articles/showrooms`, {
          headers: { Authorization: `${this.ctx.accessTokenType} ${this.ctx.accessToken}` },
        })
        .then((result) => result.data);

      return r;
    } catch (err) {
      if (isAxiosError(err)) {
        console.error('ClientArticleResource.getShowrooms request faild.', err.response?.status, err.response?.data);
      }
    }

    return null;
  }

  async getShowroomDetail(path: string): Promise<ShowroomType | null> {
    try {
      const r = await axios
        .get(`/api/articles/showrooms/${encodeURIComponent(path)}`, {
          headers: { Authorization: `${this.ctx.accessTokenType} ${this.ctx.accessToken}` },
        })
        .then((result) => result.data);

      return r;
    } catch (err) {
      if (isAxiosError(err)) {
        console.error(
          'ClientArticleResource.getShowroomDetail request faild.',
          err.response?.status,
          err.response?.data,
        );
      }
    }

    return null;
  }
}
