import type { ProductBase, ProductDetail } from '@/types';
import type { PimProductRelationType } from '@sprinx/knihovna-api-types';

export function productRelationsByType(
  relationType: PimProductRelationType,
  product: ProductDetail,
): ProductBase[] | null {
  if (!product.relations || !Array.isArray(product.relations)) return null;

  const related = product.relations.find((relation) => relation.relationType === relationType);

  return related && related.count > 0 && Array.isArray(related.related) && related.related.length > 0
    ? related.related.map((acc) => ({ sku: acc.sku }))
    : null;
}
