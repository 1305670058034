import type { FC } from 'react';
import AppCustomHeaderLogo from '../AppCustomHeaderLogo';

const AppCustomHeaderNavBarError: FC = () => (
  <div className='flex h-10 items-center text-navbar-contrast sm:h-14'>
    <AppCustomHeaderLogo />
  </div>
);

AppCustomHeaderNavBarError.displayName = 'AppCustomHeaderNavBarError';

export default AppCustomHeaderNavBarError;
