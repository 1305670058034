import type { EcomCartSubjectItem } from '@sprinx/knihovna-api-types';

export type CartSubjectItem = EcomCartSubjectItem & {
  discounts?: {
    discountCodes: readonly string[];
    totalDiscount: {
      amount: number;
      amountWithTax: number;
      currency: string;
    };
  } | null;
  stockAvailableQuantity?: {
    note: string;
    quantity?: number;
    state: 'on-way' | 'ordered' | 'sold-out' | 'stock' | 'to-order';
    timing?: Date;
    unit?: string;
  };
  subjectType: CartSubjectType;
  text: string;
};

export enum CartSubjectType {
  'discount' = 'discount',
  'gift' = 'gift',
  'item' = 'item',
  'method' = 'method',
  'subjectIndirect' = 'subjectIndirect',
}

export function convertToCartSubjectType<T extends string>(
  v: T | null | undefined,
  defaultValue = CartSubjectType.discount,
): CartSubjectType {
  if (Object.values(CartSubjectType).includes(v as any)) return v as CartSubjectType;
  return defaultValue;
}

export function convertFromCartSubjectType(
  v: CartSubjectType,
): 'discount' | 'gift' | 'item' | 'method' | 'subjectIndirect' {
  return v as any;
}
