import { useState, useTransition } from 'react';
import { useEnhancedEffect } from './useEnhancedEffect';

export function useInitialData<T>(
  getInitialData: () => T,
  cleanInitialData: () => void,
  loadData?: () => T | Promise<T>,
): [loadingState: 'loading' | 'hasValue' | 'hasError', data: T | undefined] {
  const [error, setError] = useState<any>(undefined);
  const [data, setData] = useState<T | undefined>(getInitialData);
  const [inTransition, startTransition] = useTransition();

  useEnhancedEffect(() => {
    if (typeof data === 'undefined' && loadData) {
      startTransition(() => {
        const promC = loadData();
        const prom = promC instanceof Promise ? promC : Promise.resolve(promC);

        prom.then(
          (data) => {
            setError(undefined);
            setData(data);
          },
          (err) => {
            setError(err);
            setData(undefined);
          },
        );
      });
    }

    cleanInitialData();
  }, []);

  if (inTransition || (typeof data === 'undefined' && loadData)) return ['loading', undefined];
  if (error) return ['hasError', error];
  return ['hasValue', data];
}
