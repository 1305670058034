export enum CheckoutMethodPaymentPettyCash {
  // 10.3222 -> 10.32
  cent = 'cent',
  // 10.3222 -> 10.30
  dime = 'dime',
  // 10.3222 => 10.50
  fifty = 'fifty',
  // 10.3222 -> 10.3222
  none = 'none',
  // 10.3222 -> 10.00
  one = 'one',
}
