import type { ProductBase } from '@/types';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { productComparisonProducts } from '../atoms';
import { toastMessage } from '../compositions/AppToaster';

export function useProductComparisonAddProducts() {
  const intl = useIntl();
  const [productsToCompare, setProductsToCompare] = useRecoilState(productComparisonProducts);

  return useCallback(
    (product: ProductBase) => {
      if (product && !productsToCompare.some((p) => p.sku === product.sku)) {
        setProductsToCompare([...productsToCompare, { sku: product.sku }]);
        toastMessage.success(
          intl.formatMessage({
            defaultMessage: 'Produkt byl přidán k porovnání',
            description: 'Message after successfully adding product to comparison.',
            id: 'A9XJUu',
          }),
        );
      } else if (product && productsToCompare.some((p) => p.sku === product.sku)) {
        toastMessage.warning(
          intl.formatMessage({
            defaultMessage: 'Produkt je již v porovnávači',
            description: 'Message after trying to add product to comparison, but it was already added.',
            id: 'nZWVAI',
          }),
        );
      } else {
        toastMessage.error(
          intl.formatMessage({
            defaultMessage: 'Nastala chyba',
            description: 'Message after error adding product to comparison.',
            id: 'GkeX9Z',
          }),
        );
      }
    },
    [intl, productsToCompare, setProductsToCompare],
  );
}
