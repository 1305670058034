import type { FC } from 'react';
import AppCustomHeaderLogo from '../AppCustomHeaderLogo';

export type AppCustomHeaderTopToolbarSkeletonProps = {};

const AppCustomHeaderTopToolbarSkeleton: FC<AppCustomHeaderTopToolbarSkeletonProps> = () => (
  <div>
    <AppCustomHeaderLogo />
  </div>
);

AppCustomHeaderTopToolbarSkeleton.displayName = 'AppCustomHeaderTopToolbarSkeleton';

export default AppCustomHeaderTopToolbarSkeleton;
