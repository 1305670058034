import type { EcomProductBatchValidatorItem, EcomProductBatchValidatorResultItem } from '@sprinx/knihovna-api-types';
import { Container as IocContainer } from 'typescript-ioc';
import { ResourcesContext } from './ResourcesContext';

export abstract class UtilsResource {
  public ctx: ResourcesContext;

  constructor() {
    this.ctx = IocContainer.get(ResourcesContext);
  }

  abstract validateProductBatch(params: {
    batch: EcomProductBatchValidatorItem[];
    checkoutId: string;
    onlyOnStock?: boolean;
  }): Promise<EcomProductBatchValidatorResultItem[] | null>;

  abstract saveNewsletterEmail(params: { agreement: boolean; email: string }): Promise<Record<string, any> | null>;
}
