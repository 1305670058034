import type { StatusBar } from '@/types/common/StatusBar';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

const statusBarsForPath = (path: string) => {
  const res = (typeof window === 'undefined' ? global : window).commonData.statusBars ?? [];
  return res.filter((sb) => sb.pathList == null || sb.pathList?.includes(path));
};

export function useStatusBars(): StatusBar[] {
  const match = useRouteMatch();

  const [data, setData] = useState<StatusBar[]>(() => statusBarsForPath(match.path));

  useEffect(() => {
    const filtered = statusBarsForPath(match.path);
    setData(filtered);
  }, [match.path]);

  return data;
}
