import type { ShowroomType } from '@/client/pages/ShowroomsDetailPage/types/ShowroomType';
import type { ShowroomsPageType } from '@/client/pages/ShowroomsPage/types/ShowroomsPageType';
import type { ArticlePreview, ArticlesOrderByType, ArticleType } from '@/types';
import type { SupportedLocale, SupportedRegion } from '@sprinx/knihovna-api-types';
import { Container as IocContainer } from 'typescript-ioc';
import { ResourcesContext } from './ResourcesContext';

export type ContentResourceParams = {
  locale: SupportedLocale;
  region: SupportedRegion;
};

export type ArtriclesResourceGetArticlesForTaxonomyParams = ContentResourceParams & {
  taxonomyHash: string;
};

export type ArtriclesResourceGetArticlesParams = ContentResourceParams & {
  category?: string;
  limit?: number;
  orderBy?: ArticlesOrderByType;
  type: ArticleType;
};

export type ArtriclesResourceGetArticleParams = ContentResourceParams & {
  path: string;
};
export abstract class ArticleResource {
  public ctx: ResourcesContext;

  constructor() {
    this.ctx = IocContainer.get(ResourcesContext);
  }

  abstract getArticlesForTaxonomy(
    params: ArtriclesResourceGetArticlesForTaxonomyParams,
  ): Promise<ArticlePreview[] | null>;

  abstract getShowrooms(): Promise<ShowroomsPageType[] | null>;

  abstract getShowroomDetail(path: string): Promise<ShowroomType | null>;
}
