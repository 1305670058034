import type { UiContext } from '@/context';
import { atom } from 'recoil';

export const customerIdState = atom<string | undefined>({
  default: undefined,
  effects: [
    ({ setSelf }) => {
      const initialValue = (((typeof window !== 'undefined' ? window : global) as any).appContext as UiContext)
        .customerId;
      if (typeof initialValue === 'string' && initialValue != null) {
        setSelf(initialValue);
      }
    },
  ],
  key: 'customerId',
});
