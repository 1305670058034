import { cartUpdateInProgressState, pricesTypeState } from '@/client/atoms';
import FormattedPrice from '@/client/components/FormattedPrice';
import IconButtonDelete from '@/client/components/IconButtonDelete';
import Image from '@/client/components/Image';
import ImageCanvas from '@/client/components/ImageCanvas';
import QuantityInput from '@/client/components/QuantityInput';
import {
  useCartSubjectAdditionalServices,
  useProductDetailUrl,
  useShoppingCartProductLimits,
  useShoppingCartUpdateSubject,
} from '@/client/hooks';
import { modernImgLoaderNullish } from '@/client/lib/modern-img-loader';
import type { TrpcRouterOutputs } from '@/client/lib/trpc';
import { productPackResolve } from '@/common/productPackResolve';
import type { ProductAdditionalServiceRelated } from '@/types';
import { PricesType } from '@sprinx/knihovna-api-types';
import clsx from 'clsx';
import { useCallback } from 'react';
import type { IntlShape } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

export default function CartAsidePreviewItem({
  className,
  intl,
  subject,
}: {
  className?: string;
  intl: IntlShape;
  subject: TrpcRouterOutputs['cart']['subjects'][number];
}) {
  const productDetailUrl = useProductDetailUrl();
  const pricesType = useRecoilValue(pricesTypeState);
  const pack = productPackResolve(subject.productSku);
  const cartUpdateInProgress = useRecoilValue(cartUpdateInProgressState);
  const limits = useShoppingCartProductLimits({});
  const updateCart = useShoppingCartUpdateSubject();
  const availableAdditionalServices = useCartSubjectAdditionalServices(subject);

  const handleSubjectChange = useCallback(
    async (nextQuantity: number, additionalServices: ProductAdditionalServiceRelated[] | undefined): Promise<any> => {
      await updateCart(
        {
          sku: subject.productSku,
        },
        nextQuantity,
        pack.packType,
        undefined,
        additionalServices,
      );
    },
    [pack.packType, subject, updateCart],
  );

  const detailUrl = productDetailUrl({
    name: subject.text,
    sku: pack.parentSku,
  });

  return (
    <div className={clsx('grid grid-cols-8 grid-rows-2 gap-2 py-2 sm:grid-rows-1', className)}>
      <div className='col-span-2 row-span-2 sm:col-span-1 sm:row-span-1'>
        <RouterLink className='inline-flex h-full w-full items-center justify-center' to={detailUrl}>
          <ImageCanvas className='h-auto w-12'>
            <Image
              alt={subject.text}
              aspectRatio={[3, 4]}
              fit='contain'
              src={modernImgLoaderNullish(undefined, subject.product?.primaryImage?.src)}
            />
          </ImageCanvas>
        </RouterLink>
      </div>
      <div className='col-span-6 flex flex-col items-start sm:col-span-3'>
        <RouterLink className='text-sm font-medium text-primary underline hover:no-underline' to={detailUrl}>
          {subject.text}
        </RouterLink>
        <span className='mr-0 text-sm font-bold text-text sm:mr-4'>{pack.parentSku}</span>
      </div>
      <div className='row-2 sm:row-1 col-span-3 flex items-center justify-end text-text sm:col-span-2'>
        <QuantityInput
          disabled={cartUpdateInProgress}
          intl={intl}
          limits={{ ...limits, quantityMin: 1 }}
          onQuantityChange={(q) => handleSubjectChange(q, availableAdditionalServices)}
          postponeUpdate
          qty={subject.quantity}
        />
      </div>
      <div className='col-span-3 flex items-center sm:col-span-2'>
        <div className='flex-1'>
          <p className='text-right text-sm font-medium text-gray-900'>
            <FormattedPrice
              currency={subject.subjectTotal.currency}
              value={pricesType === PricesType.B2B ? subject.subjectTotal.amount : subject.subjectTotal.amountWithTax}
            />
          </p>
        </div>
        <div className='ml-2 text-right'>
          <IconButtonDelete
            className='text-red'
            onClick={() => {
              handleSubjectChange(0, []);
            }}
          />
        </div>
      </div>
    </div>
  );
}
