import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export type MsgErrorMaxLengthProps = {
  length: number;
};

const MsgErrorMaxLength: FC<MsgErrorMaxLengthProps> = ({ length }) => (
  <FormattedMessage
    defaultMessage='Je vyžadovaný text o maximální délce {length, plural, one {1 znak} few {# znaky} other {# znaků}}.'
    description='Form field error message for maximal string length.'
    id='lPXgJf'
    values={{ length }}
  />
);

MsgErrorMaxLength.displayName = 'MsgErrorMaxLength';

export default MsgErrorMaxLength;
