import { TaxonomyDataStateLevel, type TaxonomyPreview } from '@/types';
import { useMemo } from 'react';
import { getTaxonomyStateExtStore } from '../lib/globalizeData';
import type { TaxonomyDetalStateLoadable } from './useTaxonomyData';
import { useTaxonomyData } from './useTaxonomyData';

export function useTaxonomyDataPreview(hash: string): TaxonomyDetalStateLoadable<TaxonomyDataStateLevel.preview> {
  return useTaxonomyData(TaxonomyDataStateLevel.preview, hash);
}

export function useTaxonomyDataPreviewDirect(hash: string): TaxonomyPreview | undefined {
  return useMemo(() => {
    if (hash == null || hash === '') return undefined;
    const store = getTaxonomyStateExtStore();
    const f = store.find((i) => i.data?.hash === hash);
    return f?.data as any;
  }, [hash]);
}

export function useTaxonomyDataPreviewWithAncestorsDirect(hash: string): TaxonomyPreview[] {
  return useMemo(() => {
    if (hash == null || hash === '') return [];
    const store = getTaxonomyStateExtStore();
    const f = (store.find((i) => i.data?.hash === hash)?.data ?? null) as TaxonomyPreview | null;
    if (f == null) return [];
    const pts = f.code.split('/').slice(2);
    if (pts.length === 0) return [];
    return pts
      .reduce<string[]>((a, cp) => [...a, a.length === 0 ? `/catalogue/${cp}` : `${a.slice(-1)}/${cp}`], [])
      .map((code) => store.find((x) => (x.data as TaxonomyPreview).code === code)?.data)
      .filter((x): x is TaxonomyPreview => x != null);
  }, [hash]);
}
