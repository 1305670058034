import type { TrpcRouterOutputs } from '../lib/trpc';
import trpc from '../lib/trpc';

export type CartStats = TrpcRouterOutputs['cart']['stats'];

export function useCartStats(): CartStats {
  const cartStats = trpc.cart.stats.useQuery(undefined, {
    initialData: {
      maxProductDimension: 0,
      volume: 0,
      weight: 0,
    },
  });

  return cartStats.data;
}
