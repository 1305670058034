import ErrorPage from '@/client/pages/ErrorPage';
import NotFoundPage from '@/client/pages/NotFoundPage';
import { routeAllRoutesToRouter } from './route-all-routes-to-router';

export const routes = [
  ...routeAllRoutesToRouter(),
  {
    component: ErrorPage,
    exact: true,
    path: '/_error',
  },
  {
    component: NotFoundPage,
  },
];
