import { appRelayKey, useRelayEnvironmentInitialized } from '@/relay';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import fetch from 'cross-fetch';
import type { ReactNode } from 'react';
import { Suspense, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet-async';
import { RecoilRoot } from 'recoil';
import { RecoilRelayEnvironmentProvider } from 'recoil-relay';
import superjson from 'superjson';
import AppAuthAccessTokenRefresher from './AppAuthAccessTokenRefresher';
import AppError from './AppError';
import AppIntl from './AppIntl';
import AppPreloader from './AppPreloader';
import AppRouteHandler from './AppRouteHandler';
import './chat-application.css';
import AppLoadingProgressBar from './components/AppLoadingProgressBar';
import AppToaster from './compositions/AppToaster';
import AuthHandler from './compositions/Auth/AuthModal/AuthHandler';
import UserExistingCartDecision from './compositions/Auth/UserExistingCartDecision';
import './cookiebot.css';
import './globals.css';
import './heureka.css';
import trpc from './lib/trpc';

export type AppProps = {
  initialData?: Record<string, any>;
  Router: (props: { children?: ReactNode }) => JSX.Element;
};

export default function App({ initialData, Router }: AppProps): JSX.Element {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          fetch,
          // optional
          // headers() {
          //   return {
          //     authorization: getAuthCookie(),
          //   };
          // },

          // url: 'http://localhost:3000/api/trpc',
          url: '/api/trpc',
        }),
      ],
      transformer: superjson,
    }),
  );

  const realyEnvironment = useRelayEnvironmentInitialized(initialData ?? {});

  return (
    <ErrorBoundary fallback={<AppError />}>
      <RecoilRoot>
        <RecoilRelayEnvironmentProvider environment={realyEnvironment} environmentKey={appRelayKey}>
          <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
              <Helmet defaultTitle='Vybavení pro úklid' titleTemplate='%s | Vybavení pro úklid' />
              <Suspense fallback={<AppLoadingProgressBar />}>
                <AppIntl>
                  <Router>
                    <AuthHandler>
                      <AppPreloader>
                        <AppRouteHandler initialData={initialData} />
                        <UserExistingCartDecision />
                      </AppPreloader>
                    </AuthHandler>
                  </Router>
                  <AppAuthAccessTokenRefresher />
                </AppIntl>
              </Suspense>
              <AppToaster />
            </QueryClientProvider>
          </trpc.Provider>
        </RecoilRelayEnvironmentProvider>
      </RecoilRoot>
    </ErrorBoundary>
  );
}
