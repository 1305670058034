import { NavigationType } from '@/types';
import clsx from 'clsx';
import type { FC, HTMLAttributes } from 'react';
import { useRecoilValue } from 'recoil';
import { navigationsState } from '../../atoms';
import AppCustomHeaderNavBarLinkItem from '../AppCustomHeaderNavBarLinkItem';

export type AppCustomHeaderTopBarNavRightProps = HTMLAttributes<HTMLDivElement> & {};

const AppCustomHeaderTopBarNavRight: FC<AppCustomHeaderTopBarNavRightProps> = ({ className, ...props }) => {
  const navItems = useRecoilValue(navigationsState(NavigationType.topNavLeft));

  return (
    <nav className={clsx('items-center justify-end text-text', className)} {...props}>
      {navItems &&
        navItems.map((i, idx) => (
          <AppCustomHeaderNavBarLinkItem
            key={`top-bar-nav-item-${idx}`}
            className='mr-2 text-sm underline hover:no-underline xl:text-xs'
            navbarItem={i}
          />
        ))}
    </nav>
  );
};

AppCustomHeaderTopBarNavRight.displayName = 'AppCustomHeaderTopBarNavRight';

export default AppCustomHeaderTopBarNavRight;
