import clsx from 'clsx';
import type { FC, ReactNode } from 'react';

export type DialogContentProps = {
  children?: ReactNode;
  className?: string;
};

const DialogContent: FC<DialogContentProps> = ({ children = null, className }) => (
  <div className={clsx('flex-1', className)}>{children}</div>
);

DialogContent.displayName = 'DialogContent';

export default DialogContent;
