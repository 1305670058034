import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const MsgErrorInvalidPhonenumber: FC = () => (
  <FormattedMessage
    defaultMessage='Neplatné telefonní číslo. Prosím zadejte telefonní číslo v mezinárodním formátu +420 XXX XXX XXX.'
    description='Form field error message for invalid phone number.'
    id='Tz44dg'
  />
);

MsgErrorInvalidPhonenumber.displayName = 'MsgErrorInvalidPhonenumber';

export default MsgErrorInvalidPhonenumber;
