import normalizeOpeningHours from '@/client/helpers/normalize-opening-hours';
import { usePragueShowroom } from '@/client/hooks';
import { mdiPhoneInTalk } from '@/client/icons';
import { Icon } from '@mdi/react';
import clsx from 'clsx';
import type { FC } from 'react';

export type AppCustomHeaderTopBarCallUsProps = {
  className?: string;
};

const AppCustomHeaderTopBarCallUs: FC<AppCustomHeaderTopBarCallUsProps> = ({ className, ...rootProps }) => {
  const pragueShowroom = usePragueShowroom();

  if (pragueShowroom == null) return null;

  return (
    <div className={clsx('h-full items-center px-4', className)} {...rootProps}>
      <span className='text-sx font-medium text-primary'>
        <Icon className='inline-block' path={mdiPhoneInTalk} size='18px' />
        <a className='ml-0.5 whitespace-nowrap' href={`tel:${pragueShowroom.telephone.replace(/\s+/g, '')}`}>
          {pragueShowroom.telephone}
        </a>
      </span>
      <span
        className='ml-2 text-xs text-textGrayDark'
        dangerouslySetInnerHTML={{ __html: normalizeOpeningHours(pragueShowroom.openingHours) }}
      />
    </div>
  );
};

AppCustomHeaderTopBarCallUs.displayName = 'AppCustomHeaderTopBarCallUs';

export default AppCustomHeaderTopBarCallUs;
