import type { FC, ReactNode } from 'react';
import { useCartShareContext } from './CartShareProvider';

type CartShareActionProps = {
  children: ReactNode;
  className?: string;
};

const CartShareAction: FC<CartShareActionProps> = ({ children, className }) => {
  const { setIsDialogOpen } = useCartShareContext();

  return (
    <>
      <button
        className={className}
        onClick={() => {
          setIsDialogOpen(true);
        }}
        type='button'
      >
        {children}
      </button>
    </>
  );
};

CartShareAction.displayName = 'CartShareAction';

export default CartShareAction;
