import type { ArticlePreview } from '@/types';
import { cmsHandleImageUrl } from './cms-handle-image-url';

const classNameMap = {
  'sx.article': 'article',
  'vybaveniprouklid.advisorarticle': 'advisor',
  'vybaveniprouklid.blogpost': 'blog',
};

const mapper =
  (type: ArticlePreview['type'] | null) =>
  ({
    className,
    date,
    // documentPageDescription,
    // documentPageKeyWords,
    // documentPageTitle,
    nodeAlias,
    teaserImage,
    teaserText,
    title,
  }: Record<string, any>): ArticlePreview => ({
    categories: [],
    date,
    // documentPageDescription,
    // documentPageKeyWords,
    // documentPageTitle,
    path: nodeAlias.toLowerCase(),
    teaserImage: cmsHandleImageUrl(teaserImage),
    teaserText,
    title,
    type: type ? type : (className && classNameMap[className.toLowerCase() as keyof typeof classNameMap]) || 'article',
  });

export function cmsResultToArticlePreview(
  type: ArticlePreview['type'] | null,
  data: Record<string, any>[],
): ArticlePreview[];
export function cmsResultToArticlePreview(
  type: ArticlePreview['type'] | null,
  data: Record<string, any>,
): ArticlePreview;
export function cmsResultToArticlePreview(type: ArticlePreview['type'] | null, data: unknown): unknown {
  if (!data) return data;
  return Array.isArray(data)
    ? (data as Record<string, any>[]).map(mapper(type))
    : mapper(type)(data as Record<string, any>);
}
