import type { ComponentProps, ElementType, ReactElement, Ref } from 'react';
import { forwardRef } from 'react';

const defaultElement = 'div';

export type ImageCanvasOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  className?: string;
};

export type ImageCanvasProps<E extends ElementType> = ImageCanvasOwnProps<E> &
  Omit<ComponentProps<E>, keyof ImageCanvasOwnProps>;

interface ImageCanvasComponent {
  <E extends ElementType = typeof defaultElement>(props: ImageCanvasProps<E>): ReactElement<any, any> | null;
  displayName?: string | undefined;
}

const ImageCanvas: ImageCanvasComponent = forwardRef(
  ({ as: Component = defaultElement, className, ...props }: ImageCanvasOwnProps, ref: Ref<Element>) => (
    <Component ref={ref} className={className} {...props} />
  ),
);

ImageCanvas.displayName = 'ImageCanvas';

export default ImageCanvas;
