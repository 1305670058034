import { useEnhancedEffect } from '@/client/hooks';
import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';

// fork from https://github.com/mui/material-ui/blob/master/packages/mui-base/src/NoSsr/NoSsr.js

/**
 * NoSsr purposely removes components from the subject of Server Side Rendering (SSR).
 *
 * This component can be useful in a variety of situations:
 * - Escape hatch for broken dependencies not supporting SSR.
 * - Improve the time-to-first paint on the client by only rendering above the fold.
 * - Reduce the rendering time on the server.
 * - Under too heavy server load, you can turn on service degradation.
 */
export type NoSsrProps = {
  /**
   * You can wrap a node.
   */
  children?: ReactNode;
  /**
   * If `true`, the component will not only prevent server-side rendering.
   * It will also defer the rendering of the children into a different screen frame.
   * @default false
   */
  defer?: boolean;
  /**
   * The fallback content to display.
   * @default null
   */
  fallback?: ReactNode;
};

const NoSsr: FC<NoSsrProps> = ({ children, defer = false, fallback = null }) => {
  const [mountedState, setMountedState] = useState(false);

  useEnhancedEffect(() => {
    if (!defer) {
      setMountedState(true);
    }
  }, [defer]);

  useEffect(() => {
    if (defer) {
      setMountedState(true);
    }
  }, [defer]);

  return <>{mountedState ? children : fallback}</>;
};

export default NoSsr;
