import type { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

const AppError: FC = () => {
  return (
    <div>
      <FormattedMessage
        defaultMessage='<h1>Něco se pokazilo...</h1>\n<p>Zkuste to znovu.</p>'
        description='Hlaska o chybe na urovni aplikace'
        id='FM4yxq'
        values={{
          h1: (c: ReactNode) => <h1 className='text-2xl'>{c}</h1>,
          p: (c: ReactNode) => <p>{c}</p>,
        }}
      />
    </div>
  );
};

AppError.displayName = 'AppError';

export default AppError;
