import { productDetailOffsetTopState } from '@/client/atoms/productDetailOffsetTopState';
import HtmlContainer from '@/client/components/HtmlContainer';
import { mdiClose } from '@/client/icons';
import { STORED_CLOSED_STATUS_BARS } from '@/constants';
import type { StatusBar } from '@/types/common/StatusBar';
import { StatusBarType } from '@/types/common/StatusBar';
import Icon from '@mdi/react';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCookie } from 'react-use';
import { useSetRecoilState } from 'recoil';

function AppCustomHeaderTopStatusBarContent({ statusBars }: { statusBars: StatusBar[] }) {
  const [closedStatusBarsCookie, updateClosedStatusBarsCookie] = useCookie(STORED_CLOSED_STATUS_BARS);
  const ref = useRef<HTMLDivElement>(null);
  const setProductDetailOffsetTop = useSetRecoilState(productDetailOffsetTopState);
  const [lastOffsetHeight, setLastOffsetHeight] = useState<number | null>(null);

  useEffect(() => {
    if (ref.current != null && lastOffsetHeight !== ref.current?.offsetHeight) {
      if (lastOffsetHeight != null)
        setProductDetailOffsetTop((prev) => prev - (lastOffsetHeight - (ref.current?.offsetHeight ?? 0)));
      setLastOffsetHeight(ref.current?.offsetHeight);
    }
  }, [lastOffsetHeight, setProductDetailOffsetTop, closedStatusBarsCookie]);

  const closedStatusBars = useMemo<number[]>(() => {
    if (closedStatusBarsCookie == null) return [];
    return JSON.parse(closedStatusBarsCookie);
  }, [closedStatusBarsCookie]);

  const setClosedStatusBars = useCallback(
    (newVal: number[]) => {
      const now = new Date();
      return updateClosedStatusBarsCookie(JSON.stringify(newVal), {
        expires: new Date(now.setFullYear(now.getFullYear() + 1)),
      });
    },
    [updateClosedStatusBarsCookie],
  );

  if (!statusBars || statusBars.length === 0) return null;

  return (
    <div ref={ref} className='print:hidden'>
      {statusBars
        .filter((sb) => !closedStatusBars.includes(sb.statusBarId))
        .map(({ closable, message, statusBarId, type }: StatusBar) => (
          <div
            key={`status-bar-${statusBarId}`}
            className={clsx(
              'main-content w-full border-[1px] p-3',
              type === StatusBarType.danger && 'border-red-200 bg-red-100 text-red-500',
              type === StatusBarType.info && 'border-blue-200 bg-blue-100',
              type === StatusBarType.success && 'border-green-200 bg-green-100 text-green-600',
              type === StatusBarType.warning && 'border-orange-200 bg-orange-100  text-orange-600',
            )}
          >
            <HtmlContainer className='flex-1 text-center' html={message} />
            {closable && (
              <div className='flex'>
                <button
                  aria-label={`Zavřít ${type} status bar`}
                  className='iconbutton-default-text-large'
                  onClick={() => setClosedStatusBars([...closedStatusBars, statusBarId])}
                  type='button'
                >
                  <Icon path={mdiClose} size={'1.5em'} />
                </button>
              </div>
            )}
          </div>
        ))}
    </div>
  );
}

AppCustomHeaderTopStatusBarContent.displayName = 'AppCustomHeaderTopStatusBarContent';

export default AppCustomHeaderTopStatusBarContent;
