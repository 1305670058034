import {
  lazyComponent,
  routesBuilderCatalogueUrlBuilder,
  routesBuilderDefaultdUrlBuilder,
  routesBuilderManufacturerUrlBuilder,
  routesBuilderPathUrlBuilder,
  routesBuilderProductUrlBuilder,
} from './utils';

export const routesDefinition = {
  advisor: {
    component: lazyComponent(() => import('@/client/pages/AdvisorPage')),
    exact: true,
    path: '/poradna',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  advisorArticle: {
    component: lazyComponent(() => import('@/client/pages/AdvisorArticlePage')),
    exact: true,
    path: '/poradna/:path',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  article: {
    component: lazyComponent(() => import('@/client/pages/ArticlePage')),
    exact: true,
    path: '/stranky/:path',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  blog: {
    component: lazyComponent(() => import('@/client/pages/BlogPage')),
    exact: true,
    path: '/blog',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  blogArticle: {
    component: lazyComponent(() => import('@/client/pages/BlogArticlePage')),
    exact: true,
    path: '/blog/:path',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  cart: {
    component: lazyComponent(() => import('@/client/pages/CartSummaryPage')),
    exact: true,
    path: '/kosik',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  cartAddress: {
    component: lazyComponent(() => import('@/client/pages/CartAddressPage')),
    exact: true,
    path: '/kosik/adresa',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  cartBulkAdd: {
    component: lazyComponent(() => import('@/client/pages/CartBulkAddPage')),
    exact: true,
    path: '/kosik/hromadne-pridani',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  cartGift: {
    component: lazyComponent(() => import('@/client/pages/CartGiftPage')),
    exact: true,
    path: '/kosik/vernostni-program',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  cartRecapitulation: {
    component: lazyComponent(() => import('@/client/pages/CartRecapitulationPage')),
    exact: true,
    path: '/kosik/rekapitulace',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  cartSaveInProgress: {
    component: lazyComponent(() => import('@/client/pages/CartSaveInProgressPage')),
    exact: true,
    path: '/kosik/ukladani-objednavky',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  cartSharedReveal: {
    component: lazyComponent(() => import('@/client/pages/CartSharedRevealPage')),
    exact: true,
    path: '/nahrat-sdileny-kosik',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  cartShipping: {
    component: lazyComponent(() => import('@/client/pages/CartShippingPage')),
    exact: true,
    path: '/kosik/dodani',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  catalogue: {
    component: lazyComponent(() => import('@/client/pages/CataloguePage')),
    path: '/:path*/c/:hash',
    urlBuilder: routesBuilderCatalogueUrlBuilder,
  },
  club: {
    component: lazyComponent(() => import('@/client/pages/ClubPage')),
    exact: true,
    path: '/klub',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  clubCatalog: {
    component: lazyComponent(() => import('@/client/pages/ClubCatalogPage')),
    exact: true,
    path: '/klub/odmeny',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  clubPoints: {
    component: lazyComponent(() => import('@/client/pages/ClubPointsPage')),
    exact: true,
    path: '/klub/body',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  clubRules: {
    component: lazyComponent(() => import('@/client/pages/ClubRulesPage')),
    exact: true,
    path: '/klub/stranky/podminky',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  complaint: {
    component: lazyComponent(() => import('@/client/pages/ComplaintPage')),
    exact: true,
    path: '/reklamace',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  content: {
    component: lazyComponent(() => import('@/client/pages/ArticlePage')),
    path: '/:path*/w/:hash',
    urlBuilder: routesBuilderPathUrlBuilder,
  },
  home: {
    component: lazyComponent(() => import('@/client/pages/HomePage')),
    exact: true,
    path: '/',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  manufacturer: {
    component: lazyComponent(() => import('@/client/pages/ManufacturerPage')),
    path: '/:path*/z/:hash',
    urlBuilder: routesBuilderManufacturerUrlBuilder,
  },
  product: {
    component: lazyComponent(() => import('@/client/pages/ProductDetailPage')),
    path: '/:name*/p/:sku',
    urlBuilder: routesBuilderProductUrlBuilder,
  },
  productComparisonPage: {
    component: lazyComponent(() => import('@/client/pages/ProductComparisonPage')),
    exact: true,
    path: '/porovnani-produktu',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  profileAccount: {
    component: lazyComponent(() => import('@/client/pages/ProfileAccountPage')),
    exact: true,
    path: '/profil/osobni-udaje',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  profileDashboard: {
    component: lazyComponent(() => import('@/client/pages/ProfileDashboardPage')),
    exact: true,
    path: '/profil',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  profileDeliveryContacts: {
    component: lazyComponent(() => import('@/client/pages/ProfileDeliveryAddressPage')),
    exact: true,
    path: '/profil/dodaci-adresy',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  profileInvoiceDetail: {
    component: lazyComponent(() => import('@/client/pages/ProfileInvoiceDetailPage')),
    exact: true,
    path: '/profil/faktury/:invoiceNumber',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  profileInvoices: {
    component: lazyComponent(() => import('@/client/pages/ProfileInvoicesPage')),
    exact: true,
    path: '/profil/faktury',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  profileLoyaltyProgramme: {
    component: lazyComponent(() => import('@/client/pages/ProfileLoyaltyProgrammePage')),
    exact: true,
    path: '/profil/vernostni-program',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  profileOrderDetail: {
    component: lazyComponent(() => import('@/client/pages/ProfileOrderDetailPage')),
    exact: true,
    path: '/profil/objednavky/:orderNumber',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  profileOrders: {
    component: lazyComponent(() => import('@/client/pages/ProfileOrdersPage')),
    exact: true,
    path: '/profil/objednavky',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  registration: {
    component: lazyComponent(() => import('@/client/pages/CustomerRegistrationPage')),
    exact: true,
    path: '/registrace',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  search: {
    component: lazyComponent(() => import('@/client/pages/SearchGlobalPage')),
    exact: true,
    path: '/vyhledavani',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  showroomDetail: {
    component: lazyComponent(() => import('@/client/pages/ShowroomsDetailPage')),
    exact: true,
    path: '/showrooms/:path',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  showrooms: {
    component: lazyComponent(() => import('@/client/pages/ShowroomsPage')),
    path: '/showrooms',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  styleguide: {
    component: lazyComponent(() => import('@/client/pages/StyleGuidePage')),
    exact: true,
    path: '/__styleguide',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  thankYou: {
    component: lazyComponent(() => import('@/client/pages/ThankYouPage')),
    exact: true,
    path: '/dekujeme',
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
};
