import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const MsgErrorInvalidEmail: FC = () => (
  <FormattedMessage
    defaultMessage='Neplatná email adresa.'
    description='Form field error message for invalid email address.'
    id='u3GVIB'
  />
);

MsgErrorInvalidEmail.displayName = 'MsgErrorInvalidEmail';

export default MsgErrorInvalidEmail;
