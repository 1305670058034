import type { CheckoutData } from '@/types';
import { convertFromContactBase, convertFromContactBaseWithIdNullable } from '@/types';
import ms from 'ms';
import { useCallback } from 'react';
import invariant from 'tiny-invariant';
import type { TrpcRouterOutputs } from '../lib/trpc';
import trpc from '../lib/trpc';

export function useUpdateCheckoutData(checkoutData: TrpcRouterOutputs['checkout']['checkoutData'] | undefined) {
  const trpcCtx = trpc.useContext();
  const checkoutDataMutation = trpc.checkout.updateCheckoutData.useMutation();

  return useCallback(
    async (setter: (prev: CheckoutData) => CheckoutData): Promise<void> => {
      invariant(checkoutData !== undefined, 'First wait for chechcout data to be loaded.');

      const next = setter(checkoutData);

      try {
        const checkoutDataUpdated = await checkoutDataMutation.mutateAsync({
          agreements: next.agreements,
          customerOrderNumber: next.customerOrderNumber ?? undefined,
          deliveryInfo: {
            contact: convertFromContactBase(next.deliveryInfo.contact, true),
            customerContact: convertFromContactBaseWithIdNullable(next.deliveryInfo.customerContact),
            utilization: next.deliveryInfo.utilization,
          },
          destiny: next.destiny,
          invoiceInfo: {
            contact: convertFromContactBase(next.invoiceInfo.contact, true),
            utilization: next.invoiceInfo.utilization,
          },
          notes: next.notes,
          orderInfo: {
            contact: convertFromContactBase(next.orderInfo.contact),
            customer: convertFromContactBaseWithIdNullable(next.orderInfo.customer),
            utilization: next.orderInfo.utilization,
          },
          personalPickupInfo:
            next.personalPickupInfo == null
              ? null
              : {
                  contact: convertFromContactBase(next.personalPickupInfo.contact),
                  methodSku: next.personalPickupInfo.methodSku,
                  providerData: next.personalPickupInfo.providerData,
                },
        });

        trpcCtx.checkout.checkoutData.setData(undefined, checkoutDataUpdated);
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
          console.error('Checkout data mutation failed. See above error for details.');
        }
      }
    },
    [checkoutData, checkoutDataMutation, trpcCtx.checkout.checkoutData],
  );
}

export function useCheckoutData() {
  const checkoutDataQuery = trpc.checkout.checkoutData.useQuery(undefined, { staleTime: ms('5m') });
  const updateCheckoutData = useUpdateCheckoutData(checkoutDataQuery.data);

  return {
    ...checkoutDataQuery,
    update: updateCheckoutData,
  };
}
