import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { cartLastStepState, ShoppingCartStep } from '../../../client/atoms/cartLastStepState';
import { useRouteUrl } from '../../../client/hooks';

export function useCheckCartStep(callingStep: ShoppingCartStep) {
  const routeUrl = useRouteUrl();
  const history = useHistory();
  const lastStep = useRecoilValue(cartLastStepState);
  const lastStepRef = useRef(lastStep);
  lastStepRef.current = lastStep;

  useEffect(
    () => {
      if (callingStep > lastStepRef.current) {
        switch (lastStepRef.current) {
          case ShoppingCartStep.CART:
            history.replace(routeUrl('cart', {}));
            return;
          case ShoppingCartStep.ADDRESS:
            history.replace(routeUrl('cartAddress', {}));
            return;
          case ShoppingCartStep.DELIVERY:
            history.replace(routeUrl('cartShipping', {}));
            return;
          case ShoppingCartStep.GIFT:
            history.replace(routeUrl('cartGift', {}));
            return;
          case ShoppingCartStep.SUMMARY:
            history.replace(routeUrl('cartRecapitulation', {}));
            return;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
