import type { ProductPackAvailability } from '@/types/products/product-pack-availability';
import type { MeasuresUnit, PimProductQuantityStateType } from '@sprinx/knihovna-api-types';
import { calculateProductAvailability } from '../lib/calculate-product-availability';

export function useProductPackAvailability(product: {
  onSale?: boolean;
  stockAvailableQuantity?: {
    note?: string;
    quantity?: number;
    state?: PimProductQuantityStateType | 'ordered' | 'stock' | 'on-way' | 'sold-out' | 'to-order';
    timing?: Date;
    unit?: MeasuresUnit | string;
  };
}): ProductPackAvailability {
  const x = product.stockAvailableQuantity;
  return calculateProductAvailability({
    productOnSale: product.onSale ?? false,
    productStockAvailableQuantity:
      x == null
        ? null
        : {
            note: x.note ?? null,
            quantity: x.quantity ?? 0,
            state: x.state,
            timing: x.timing ?? null,
            unit: x.unit ?? null,
          },
  });
}
