import type { ComponentProps, ElementType, ReactElement, ReactNode, Ref } from 'react';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const defaultElement = RouterLink;

export type AppCustomFooterLinkBlocksItemLinkOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children?: ReactNode;
  className?: string;
};

export type AppCustomFooterLinkBlocksItemLinkProps<E extends ElementType> =
  AppCustomFooterLinkBlocksItemLinkOwnProps<E> &
    Omit<ComponentProps<E>, keyof AppCustomFooterLinkBlocksItemLinkOwnProps>;

interface AppCustomFooterLinkBlocksItemLinkType {
  <E extends ElementType = typeof defaultElement>(props: AppCustomFooterLinkBlocksItemLinkProps<E>): ReactElement<
    any,
    any
  > | null;
  displayName?: string | undefined;
}

/**
 * @component
 */
const AppCustomFooterLinkBlocksItemLink: AppCustomFooterLinkBlocksItemLinkType = forwardRef(
  (
    { as: Component = defaultElement, className, ...props }: AppCustomFooterLinkBlocksItemLinkOwnProps,
    ref: Ref<Element>,
  ) => <Component {...props} ref={ref} as={undefined} className={className} />,
);

AppCustomFooterLinkBlocksItemLink.displayName = 'AppCustomFooterLinkBlocksItemLink';

export default AppCustomFooterLinkBlocksItemLink;
