import type { IntlShape } from 'react-intl';

export default class NumberParser {
  _decimal: RegExp;
  _group: RegExp;
  _index: (d: string) => string;
  _numeral: RegExp;

  constructor(intl: IntlShape) {
    const parts = intl.formatNumberToParts(-12345.6);
    const numerals = [...intl.formatNumber(9876543210, { useGrouping: false })].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._group = new RegExp(`[${parts.find((d) => d.type === 'group')?.value}]`, 'g');
    this._decimal = new RegExp(`[${parts.find((d) => d.type === 'decimal')?.value}]`);
    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g');
    this._index = (d) => (index.get(d) ?? '').toString();
  }

  parse(string: string): number {
    string = string.trim().replace(this._group, '').replace(this._decimal, '.').replace(this._numeral, this._index);
    return string ? +string : NaN;
  }
}
