import { initializeConfiguration } from '@/context';
import fetchJson from '@/helpers/fetch-json';
import { ResourcesContext } from '@/resources';
import { detect as detectBrowser } from 'detect-browser';
import { hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import 'reflect-metadata';
import { Container as IocContainer } from 'typescript-ioc';
import App from './App';
import { globalizeData } from './lib/globalizeData';
import { registerClientResources } from './resources';

initializeConfiguration();
registerClientResources();

// Detect browser and show "not supported browser" message.
const detectedBrowser = detectBrowser();
if (detectedBrowser?.type === 'browser') {
  if (
    ['aol', 'yandexbrowser', 'kakaotalk', 'silk', 'miui', 'beaker', 'pie', 'netfront', 'ie', 'bb10'].includes(
      detectedBrowser.name,
    ) &&
    document.getElementById('browser-is-not-supported') == null
  ) {
    console.error('Browser is not supported!!!');

    const fragment = document.createDocumentFragment();
    const dv = fragment.appendChild(document.createElement('div'));
    dv.setAttribute('id', 'browser-is-not-supported');
    dv.setAttribute('style', 'padding: 1rem 2rem; text-align: center');

    let p = dv.appendChild(document.createElement('p'));
    p.setAttribute('style', 'color: red; font-weight: bold');
    p.textContent = 'Váš prohlížeč není podporován nebo aktualizován.';

    p = dv.appendChild(document.createElement('p'));
    p.setAttribute('style', 'color: #4b4b4b');
    p.textContent = 'Některé funkce e-shopu nemusejí fungovat. Prosím, stáhněte si novou verzi prohlížeče.';

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.body.insertBefore(fragment, document.getElementById('root')!);
  }
}

// Render client...
fetchJson('/api/auth/uictx')
  .then((ctx) => {
    (window as any).appContext = ctx;
    IocContainer.get(ResourcesContext).loadFromGlobal();
  })
  .finally(() => {
    globalizeData();
    hydrateRoot(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.getElementById('root')!,
      <HelmetProvider>
        <App
          initialData={(window as any).initialData}
          Router={({ children }) => <BrowserRouter>{children}</BrowserRouter>}
        />
      </HelmetProvider>,
    );
  });

if (module.hot) {
  module.hot.accept();
}
