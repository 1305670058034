import { pricesTypeState } from '@/client/atoms';
import FormattedPrice from '@/client/components/FormattedPrice';
import { useIntlMessages } from '@/client/hooks';
import { mdiShareVariantOutline } from '@/client/icons';
import type { TrpcRouterOutputs } from '@/client/lib/trpc';
import Icon from '@mdi/react';
import { PricesType } from '@sprinx/knihovna-api-types';
import clsx from 'clsx';
import { defineMessages, useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import CartShareAction from '../CartShareAction';

const totalMessages = defineMessages({
  total_with_vat: { defaultMessage: 'Celkem s DPH:', description: 'Total with VAT', id: 'KcjwoV' },
  total_without_vat: { defaultMessage: 'Celkem bez DPH:', description: 'Total without VAT', id: '5dsXuJ' },
});

export default function CartAsideTotals({
  cartTotals,
  className,
}: {
  cartTotals: TrpcRouterOutputs['cart']['totals'];
  className?: string;
}) {
  const intl = useIntl();
  const messages = useIntlMessages();
  const pricesType = useRecoilValue(pricesTypeState);

  return (
    <div className={clsx('grid grid-cols-8 grid-rows-2 gap-2 sm:grid-rows-1', className)}>
      <div className='col-span-full sm:col-span-4'>
        <CartShareAction className='mr-4 flex flex-row items-center justify-start rounded-md text-sm text-primary underline hover:no-underline sm:justify-center sm:py-2 sm:pr-4 sm:text-base'>
          <Icon
            className='mr-2 text-primary underline hover:no-underline'
            path={mdiShareVariantOutline}
            size='1.5rem'
          />
          {intl.formatMessage(messages.cart_share)}
        </CartShareAction>
      </div>
      <div className='col-span-4 sm:col-span-2'>
        <span className='text-sm font-semibold text-text sm:text-right sm:text-base'>
          {intl.formatMessage(
            pricesType === PricesType.B2B ? totalMessages.total_without_vat : totalMessages.total_with_vat,
          )}
        </span>
      </div>
      <div className='col-span-4 pr-4 text-right text-base font-semibold text-text sm:col-span-2'>
        <CartAsideTotalsValue cartTotals={cartTotals} />
      </div>
    </div>
  );
}

function CartAsideTotalsValue({ cartTotals }: { cartTotals: TrpcRouterOutputs['cart']['totals'] }) {
  const pricesType = useRecoilValue(pricesTypeState);
  const value = pricesType === PricesType.B2B ? cartTotals.total.total.amount : cartTotals.total.total.amountWithTax;

  return <FormattedPrice currency={cartTotals.total.total.currency} value={value} />;
}
