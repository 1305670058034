import { Route } from 'react-router-dom';

const ErrorPage = (): JSX.Element => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) staticContext.statusCode = 500;
      return (
        <>
          <h1>Something went wrong</h1>
          <p>An error occured!</p>
        </>
      );
    }}
  />
);

export default ErrorPage;
