import type { SupportedLocale } from '@sprinx/knihovna-api-types';
import pathToRegexp from 'path-to-regexp';
import qs from 'qs';
import { routesBuilderSplitInputParams } from './routesBuilderSplitInputParams';
import { safeAbsoluteUrl } from './safeAbsoluteUrl';

/**
 * Standard route builder
 * @param locale
 * @param path
 * @param otherParamsToQs
 * @param inputParams
 */
export function routesBuilderDefaultdUrlBuilder(
  locale: SupportedLocale,
  path: string,
  otherParamsToQs: boolean | string[] | undefined | null,
  inputParams: Record<string, any> | undefined,
): string {
  const { otherParamsExists, pathParams, queryParams } = routesBuilderSplitInputParams(
    otherParamsToQs,
    path,
    inputParams || {},
  );

  const toUrl = pathToRegexp.compile(path);
  const urlB = toUrl(pathParams);

  return !otherParamsToQs || !otherParamsExists
    ? safeAbsoluteUrl(path, urlB)
    : safeAbsoluteUrl(
        path,
        `${urlB}${qs.stringify(queryParams, {
          addQueryPrefix: true,
        })}`,
      );
}
