import type {
  cartSubjectsState_cart_subjects$key,
  ProductQuantityStateType,
} from '@/graphql/__generated__/cartSubjectsState_cart_subjects.graphql';
import { excludeFalsy } from '@/helpers';
import type { CartSubjectItem } from '@/types';
import { convertToCartSubjectType } from '@/types';
import { selector } from 'recoil';
import { graphql, readInlineData } from 'relay-runtime';
import { cartState } from './cartState';

const fragmentNode = graphql`
  fragment cartSubjectsState_cart_subjects on Cart @inline {
    subjects(first: 100) {
      edges {
        node {
          # id
          price {
            amount
            amountWithTax
          }
          quantity
          productSku
          subjectTotal {
            amount
            amountWithTax
            vatAmount
            vatCode
          }
          subjectType
          product {
            stockAvailableQuantity {
              note
              quantity
              state
              timing
              unit
            }
          }
          ... on CartSubjectPrimary {
            indirectSubjects {
              price {
                amount
                amountWithTax
              }
              quantity
              productSku
              subjectTotal {
                amount
                amountWithTax
                vatAmount
                vatCode
              }
              subjectType
              text
            }
            discounts {
              discountCodes
              totalDiscount {
                amount
                amountWithTax
                currency
              }
            }
          }
          text
        }
      }
    }
  }
`;

const converQauantityState = (
  inp: ProductQuantityStateType,
): 'ordered' | 'stock' | 'on-way' | 'sold-out' | 'to-order' => {
  if (inp === 'stock') return 'stock';
  if (inp === 'onWay') return 'on-way';
  if (inp === 'ordered') return 'ordered';
  if (inp === 'soldOut') return 'sold-out';
  if (inp === 'toOrder') return 'to-order';
  return 'sold-out';
};

export const cartSubjectsState = selector<CartSubjectItem[]>({
  get: ({ get }) => {
    const cart = get(cartState);
    const { subjects } = readInlineData<cartSubjectsState_cart_subjects$key>(fragmentNode, cart.fragmentKey);
    return (subjects.edges || [])
      .map((s) => {
        if (s.node == null) return null;
        const r: CartSubjectItem = {
          discounts:
            s.node.discounts == null
              ? null
              : {
                  discountCodes: s.node.discounts.discountCodes,
                  totalDiscount: {
                    amount: s.node.discounts.totalDiscount.amount,
                    amountWithTax: s.node.discounts.totalDiscount.amountWithTax,
                    currency: s.node.discounts.totalDiscount.currency,
                  },
                },
          indirectSubjects: s.node.indirectSubjects?.map((is) => ({
            price: is.price.amount,
            priceWithTax: is.price.amountWithTax,
            quantity: is.quantity,
            sku: is.productSku,
            subjectTotal: is.subjectTotal.amount,
            subjectTotalWithTax: is.subjectTotal.amountWithTax,
            text: is.text,
            vatAmount: is.subjectTotal.vatAmount,
            vatCode: is.subjectTotal.vatCode ?? undefined,
          })),
          price: s.node.price.amount,
          priceWithTax: s.node.price.amountWithTax,
          quantity: s.node.quantity,
          sku: s.node.productSku,
          stockAvailableQuantity:
            s.node.product?.stockAvailableQuantity == null
              ? undefined
              : {
                  note: s.node.product.stockAvailableQuantity.note ?? '',
                  quantity: s.node.product.stockAvailableQuantity.quantity ?? undefined,
                  state: converQauantityState(s.node.product.stockAvailableQuantity.state),
                  timing: s.node.product.stockAvailableQuantity.timing,
                  unit: s.node.product.stockAvailableQuantity.unit ?? undefined,
                },
          subjectTotal: s.node.subjectTotal.amount,
          subjectTotalWithTax: s.node.subjectTotal.amountWithTax,
          subjectType: convertToCartSubjectType(s.node.subjectType),
          text: s.node.text,
          vatAmount: s.node.subjectTotal.vatAmount,
          vatCode: s.node.subjectTotal.vatCode ?? undefined,
        };
        return r;
      })
      .filter(excludeFalsy);
  },
  key: 'cartSubjectsState',
});
