import { selectorFamily } from 'recoil';
import { cartSubjectsAdditionalServicesIndexState } from './cartSubjectsAdditionalServicesIndexState';

export const cartProductAdditionalServicesState = selectorFamily<string[], string>({
  get:
    (sku) =>
    ({ get }) => {
      const cartSubjectIndirectsIndex = get(cartSubjectsAdditionalServicesIndexState);
      return cartSubjectIndirectsIndex[sku];
    },
  key: 'cartProductAdditionalServicesState',
});
