import type { ProductDetailResourceGetProductDetailParams } from '@/resources';
import { ProductDetailResource } from '@/resources';
import type {
  ProductDetail,
  ProductMeta,
  ProductPackQuantityPrice,
  ProductPreview,
  ProductQuantityPrice,
  ProductReview,
} from '@/types';
import type { SupportedLocale } from '@sprinx/knihovna-api-types';
import axios from 'axios';

export class ClientProductDetailResource extends ProductDetailResource {
  async getProductReviews(params: { ean: string }): Promise<ProductReview[]> {
    const { ean } = params;

    const url = `/api/products/${encodeURIComponent(ean)}/review`;

    const result = await axios.get<ProductReview[]>(url, {
      params,
    });

    return result.data;
  }

  async getProductMeta(params: ProductDetailResourceGetProductDetailParams): Promise<ProductMeta> {
    return this._getProductDetail(params, 'meta');
  }

  async getProductPreview(params: ProductDetailResourceGetProductDetailParams): Promise<ProductPreview> {
    return this._getProductDetail(params, 'preview');
  }

  /** @deprecated */
  async getProductDetail(params: ProductDetailResourceGetProductDetailParams): Promise<ProductDetail> {
    return this._getProductDetail(params);
  }

  async getProductQuantityPrices(params: { checkoutId: string; sku: string }): Promise<ProductQuantityPrice[]> {
    const { sku, ...query } = params;

    const url = `/api/products/${encodeURIComponent(sku)}/quantity-prices`;

    const result = await axios.get<ProductQuantityPrice[] | null>(url, {
      params: query,
    });

    return result.data || [];
  }

  async getProductPackQuantityPrices(params: {
    checkoutId: string;
    locale: SupportedLocale;
    sku: string;
  }): Promise<ProductPackQuantityPrice> {
    const { sku, ...query } = params;
    const url = `/api/products/${encodeURIComponent(sku)}/pack-prices`;

    const result = await axios.get<ProductPackQuantityPrice>(url, {
      params: query,
    });
    return result.data;
  }

  private async _getProductDetail(
    params: ProductDetailResourceGetProductDetailParams,
    level?: 'meta' | 'preview',
  ): Promise<ProductDetail> {
    const { sku, ...query } = params;

    let url = `/api/products/${encodeURIComponent(sku)}/detail`;
    if (level) {
      url = `${url}/${encodeURIComponent(level)}`;
    }

    const result = await axios.get<ProductDetail>(url, {
      params: query,
    });

    return result.data;
  }
}
