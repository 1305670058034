import type { AdvisorArticleCategory } from '@/types';
import { useIntl } from 'react-intl';

export function useAdvisorCategories(): Record<AdvisorArticleCategory, string> {
  const intl = useIntl();

  return {
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    'rady-a-tipy': intl.formatMessage({
      defaultMessage: 'Rady a tipy',
      description: 'Advice and tips category name',
      id: 'zhGHQv',
    }),
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    recenze: intl.formatMessage({
      defaultMessage: 'Recenze produktů',
      description: 'Reviews category name',
      id: 'PH905u',
    }),
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    'nakupni-radci': intl.formatMessage({
      defaultMessage: 'Nákupní rádci',
      description: 'Shopping advisor  category name',
      id: 'qsZpxA',
    }),
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    'sezonni-tipy': intl.formatMessage({
      defaultMessage: 'Sezonní tipy',
      description: 'Seasonal tips category name',
      id: 'qXbsy5',
    }),
  };
}
