import type { checkoutUserExistingCartsState_cart$key } from '@/graphql/__generated__/checkoutUserExistingCartsState_cart.graphql';
import { selector } from 'recoil';
import { graphql, readInlineData } from 'relay-runtime';
import { checkoutState } from './checkoutState';

const fragmentNode = graphql`
  fragment checkoutUserExistingCartsState_cart on Checkout @inline {
    existingCarts {
      id
    }
  }
`;

export const checkoutUserExistingCartsState = selector<{ id: string }[] | null>({
  get: ({ get }) => {
    const checkout = get(checkoutState);
    const data = readInlineData<checkoutUserExistingCartsState_cart$key>(fragmentNode, checkout);

    return data.existingCarts == null
      ? null
      : data.existingCarts.map((i) => ({
          id: i.id,
        }));
  },
  key: 'checkoutUserExistingCartsState',
});
