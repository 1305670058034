import Alert from '@/client/components/Alert';
import { useCartSubjects } from '@/client/hooks';
import type { TrpcRouterOutputs } from '@/client/lib/trpc';
import MsgCartEmpty from '@/client/localized-messages/MsgCartEmpty';
import CartSummaryBulkAddLink from '@/client/pages/CartSummaryPage/components/CartSummaryBulkAddLink';
import { useIntl } from 'react-intl';
import CartAsidePreviewItem from './CartAsidePreviewItem';
import CartAsidePreviewRoot from './CartAsidePreviewRoot';

export default function CartAsidePreview({
  cartTotals,
  className,
}: {
  cartTotals: TrpcRouterOutputs['cart']['totals'];
  className?: string;
}) {
  return (
    <CartAsidePreviewRoot className={className}>
      {cartTotals.subjects.count === 0 ? (
        <div>
          <Alert severity='info'>
            <MsgCartEmpty />
          </Alert>
          <CartSummaryBulkAddLink className='mt-6 mb-2' />
        </div>
      ) : (
        <CartAsidePreviewLoader subjectsCount={cartTotals.subjects.count} />
      )}
    </CartAsidePreviewRoot>
  );
}

function CartAsidePreviewLoader({ subjectsCount }: { subjectsCount: number }) {
  const intl = useIntl();
  const cartSubjects = useCartSubjects();

  if (cartSubjects.isLoading) {
    return (
      <>
        {Array.from({ length: subjectsCount }).map((_, idx) => (
          <div key={`spn-${idx}`} className='my-4 animate-pulse bg-slate-200'>
            <div className='h-20' />
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      {(cartSubjects.data ?? []).map((subject) => (
        <CartAsidePreviewItem key={subject.productSku} intl={intl} subject={subject} />
      ))}
    </>
  );
}
