import { checkoutContextState } from '@/client/atoms';
import { useCheckoutDataOrderInfo, useCheckoutUpdateAfterLogin } from '@/client/hooks';
import fetchJson from '@/helpers/fetch-json';
import { ResourcesContext } from '@/resources';
import type { AppAuthUser } from '@/types';
import { createContactBase } from '@/types';
import { useCallback } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { useRecoilValue } from 'recoil';
import type { IEnvironment } from 'relay-runtime';
import { Container as IocContainer } from 'typescript-ioc';
import type { LoginCallback, LoginErrorReason } from '../types';
import { loadCustomer, type CustomerInfoState } from './load-customer';

export function useLoginCall(options: {
  onError: (reason: LoginErrorReason) => void;
  onSuccess: (ctx: { customerInfo: CustomerInfoState; userData: AppAuthUser }) => void;
}): LoginCallback {
  const relayEnvironment: IEnvironment = useRelayEnvironment();
  const checkoutCtx = useRecoilValue(checkoutContextState);
  const updateCheckoutContext = useCheckoutUpdateAfterLogin();
  const { setCustomer } = useCheckoutDataOrderInfo();

  return useCallback<LoginCallback>(
    async (credentials: { password: string; username: string }) => {
      try {
        const r = await fetchJson<
          | { accessToken: string; accessTokenType: string; status: 'success'; user: AppAuthUser }
          | { reason?: string; status: 'error' }
        >('/api/auth/local-login', {
          body: JSON.stringify({ password: credentials.password.trim(), username: credentials.username.trim() }),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });

        if (r.status === 'success') {
          if (r.accessToken) {
            IocContainer.get(ResourcesContext).update(r.accessTokenType, r.accessToken);
          }

          await updateCheckoutContext({
            checkoutId: checkoutCtx.checkoutId,
            pricingSettings: {
              currency: r.user.settings?.currency ?? checkoutCtx.currency,
              distributor: '_default',
              priceGroups: r.user.settings?.priceGroupIds ? r.user.settings?.priceGroupIds : ['_default'],
              pricesType: r.user.settings?.pricesType ?? checkoutCtx.pricesType,
              region: checkoutCtx.region,
            },
          });

          //
          // get customer info
          const customerInfo = await loadCustomer(relayEnvironment);
          const { deliveryContacts, ...customerContactBase } = customerInfo;

          try {
            await setCustomer(createContactBase(customerContactBase));
          } catch (setOrderInfoEerr) {
            if (process.env.NODE_ENV !== 'production') {
              console.error('SET ORDER INFO ERR', setOrderInfoEerr);
            }
          }

          options.onSuccess({ customerInfo, userData: r.user });
        } else if (r.status === 'error' && r.reason === 'no-user-name') {
          options.onError('validation');
        } else if (r.status === 'error' && r.reason === 'invalid-credentials') {
          options.onError('credentials');
        } else {
          options.onError('fatal');
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Login call failed with error', err);
        }
        options.onError('fatal');
      }
    },
    [updateCheckoutContext, checkoutCtx, relayEnvironment, options, setCustomer],
  );
}
