import NoSsr from '@/client/components/NoSsr';
import type { ReactNode } from 'react';
import { createContext, Suspense, useContext, useState } from 'react';
import invariant from 'tiny-invariant';
import CartShareHandler from './CartShareHandler';

const CartShareContext = createContext<{ isDialogOpen: boolean; setIsDialogOpen: (v: boolean) => void }>(null as any);

export default function CartShareProvider({ children }: { children: ReactNode }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <CartShareContext.Provider value={{ isDialogOpen, setIsDialogOpen }}>{children}</CartShareContext.Provider>
      <NoSsr defer>
        <Suspense>
          <CartShareHandler isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
        </Suspense>
      </NoSsr>
    </>
  );
}

// *****************************************
// *
// * useCartShareContext
// *
// *****************************************

export function useCartShareContext() {
  const ctx = useContext(CartShareContext);
  invariant(ctx != null, "'useCartShareContext' is used outside of 'CartShareProvider'.");
  return ctx;
}
