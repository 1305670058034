import type { HTMLAttributes, RefAttributes } from 'react';
import { forwardRef } from 'react';
import AppCustomHeaderTopBarCallUs from './AppCustomHeaderTopBarCallUs';
import AppCustomHeaderTopBarNavLeft from './AppCustomHeaderTopBarNavLeft';
import AppCustomHeaderTopBarNavRight from './AppCustomHeaderTopBarNavRight';

export type AppCustomHeaderTopBarProps = HTMLAttributes<HTMLDivElement> & RefAttributes<HTMLDivElement> & {};

const AppCustomHeaderTopBar = forwardRef<HTMLDivElement, AppCustomHeaderTopBarProps>((props, ref) => (
  <div
    ref={ref}
    className='hidden h-[35px] w-full border-b-[1px] bg-topbar align-middle text-xs text-topbar-contrast lg:flex'
  >
    <div className='container flex w-full justify-between text-xs'>
      <AppCustomHeaderTopBarNavLeft className='flex flex-1' />
      <AppCustomHeaderTopBarCallUs className='hidden xl:flex' />
      <AppCustomHeaderTopBarNavRight className='flex flex-1' />
    </div>
  </div>
));

AppCustomHeaderTopBar.displayName = 'AppCustomHeaderTopBar';

export default AppCustomHeaderTopBar;
