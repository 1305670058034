import type { MediaSourceItem } from '@sprinx/knihovna-api-types';
import { useMemo } from 'react';

export function useProductPrimaryImage(media: string | MediaSourceItem[] | undefined): MediaSourceItem | undefined {
  const cmpv = Array.isArray(media) ? media.map((m) => m.src).join(',') : media;
  return useMemo(
    () => {
      if (Array.isArray(media)) {
        return media.find((m) => m.type === 'image');
      }

      if (typeof media === 'string') {
        return {
          src: media,
          type: 'image',
        } as MediaSourceItem;
      }

      return undefined;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cmpv],
  );
}
