import type { AspectRatioOptions } from './AspectRatioOptions';
import type { AspectRatioTuple } from './AspectRatioTuple';

export function convertAspectRatioOptions(options: AspectRatioOptions): AspectRatioTuple | false {
  if (
    typeof options === 'string' ||
    (Array.isArray(options) &&
      options.length === 2 &&
      options[0] > 0 &&
      options[0] <= 16 &&
      options[1] > 0 &&
      options[1] <= 16)
  ) {
    if (Array.isArray(options)) return options;

    switch (options) {
      case 'W':
        return [16, 9];
      case 'T':
        return [9, 16];
      case 'S':
        return [1, 1];
      case 'P':
        return [3, 4];
      case 'L':
      default:
        return [4, 3];
    }
  }
  return false;
}
