import { checkoutContextState } from '@/client/atoms';
import { checkoutUserDecisionAfterLoginActivateState } from '@/client/atoms/checkoutUserDecisionAfterLoginActivateState';
import { checkoutUserExistingCartsState } from '@/client/atoms/checkoutUserExistingCartsState';
import Alert from '@/client/components/Alert';
import Dialog, { DialogActions, DialogContent, DialogModal, DialogTitle } from '@/client/components/Dialog';
import Image from '@/client/components/Image';
import ImageCanvas from '@/client/components/ImageCanvas';
import NoSsr from '@/client/components/NoSsr';
import { useCartTotals } from '@/client/hooks';
import { useCheckoutUserExistingCartRemove } from '@/client/hooks/useCheckoutUserExistingCartRemove';
import type { UserExistingCartDecision_loadCartPreviewQuery } from '@/graphql/__generated__/UserExistingCartDecision_loadCartPreviewQuery.graphql';
import clsx from 'clsx';
import type { MouseEvent, ReactNode } from 'react';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useRecoilState, useRecoilValue } from 'recoil';
import type { UseCheckoutUserExistingCartMakeDecisionOptions } from '../../../hooks/useCheckoutUserExistingCartMakeDecision';
import { useCheckoutUserExistingCartMakeDecision } from '../../../hooks/useCheckoutUserExistingCartMakeDecision';

export default function UserExistingCartDecision() {
  return (
    <NoSsr>
      <Suspense>
        <ExistingCartUserDecisionActivator />
      </Suspense>
    </NoSsr>
  );
}

function ExistingCartUserDecisionActivator() {
  const userExistingCarts = useRecoilValue(checkoutUserExistingCartsState);
  const cartTotals = useCartTotals();
  const checkoutCtx = useRecoilValue(checkoutContextState);
  const [checkAfterLogin, setCheckAfterLogin] = useRecoilState(checkoutUserDecisionAfterLoginActivateState);
  const makeDecision = useCheckoutUserExistingCartMakeDecision();
  const removeCart = useCheckoutUserExistingCartRemove();

  const userExistingCartCount = (userExistingCarts != null && userExistingCarts.length) || 0;

  useEffect(() => {
    if (checkAfterLogin && userExistingCartCount === 0) {
      setCheckAfterLogin(false);
      reloadWindow();
    }
  }, [checkAfterLogin, setCheckAfterLogin, userExistingCartCount]);

  const createFastForwardHandler = useCallback(
    (checkoutId: string, nextCartId: string) => async (event?: MouseEvent) => {
      event?.preventDefault();

      setCheckAfterLogin(false);
      await makeDecision({ action: 'takeNext', checkoutId, nextCartId });
      reloadWindow();
    },
    [makeDecision, setCheckAfterLogin],
  );

  const createDecisionHandler = useCallback(
    (checkoutId: string) =>
      (params: Omit<UseCheckoutUserExistingCartMakeDecisionOptions, 'checkoutId'>) =>
      async (event: MouseEvent) => {
        event.preventDefault();
        await makeDecision({ ...params, checkoutId });
        setCheckAfterLogin(false);
        reloadWindow();
      },
    [makeDecision, setCheckAfterLogin],
  );

  const createRemoveCartHandler = useCallback(
    (checkoutId: string) => (cartToRemoveId: string) => async (event: MouseEvent) => {
      event.preventDefault();
      await removeCart({ cartToRemoveId, checkoutId });
    },
    [removeCart],
  );

  if (
    checkAfterLogin &&
    userExistingCarts != null &&
    userExistingCarts.length === 1 &&
    cartTotals.data.subjects.count === 0
  ) {
    return (
      <Suspense>
        <ExistingCartUserDecisionFastForwardNotify
          onClose={createFastForwardHandler(checkoutCtx.checkoutId, userExistingCarts[0].id)}
        />
      </Suspense>
    );
  }

  if (checkAfterLogin && userExistingCarts != null) {
    return (
      <Suspense>
        <ExistingCartUserDecisionContent
          createDecisionHandler={createDecisionHandler(checkoutCtx.checkoutId)}
          createRemoveCartHandler={createRemoveCartHandler(checkoutCtx.checkoutId)}
          currentCartItemCount={cartTotals.data.subjects.count}
          otherCarts={userExistingCarts}
        />
      </Suspense>
    );
  }

  return null;
}

function ExistingCartUserDecisionFastForwardNotify({
  onClose,
}: {
  onClose: (event: MouseEvent) => void;
}): JSX.Element | null {
  const intl = useIntl();

  const [o, setO] = useState(false);
  useEffect(() => {
    setO(true);
    return () => {
      setO(false);
    };
  }, []);

  return (
    <Dialog
      closeOnClickOutside
      onClose={onClose as any}
      open={o}
      renderModal={({ close }) => (
        <DialogModal className='bg-[#e4f2fc] p-8 md:h-[265px] md:max-h-[315px]'>
          <DialogTitle onCloseDialog={() => close()} showCloseAction>
            {intl.formatMessage({
              defaultMessage: 'Pokračování v nákupu',
              description: 'Confirmation dialog title of using previous cart, displayed after user login.',
              id: 'NWOOg0',
            })}
          </DialogTitle>
          <DialogContent className='text-left'>
            {intl.formatMessage(
              {
                defaultMessage:
                  '<p>Váš poslední nákup nebyl dokončen a zůstaly vám v košíku produkty.</p><p>Budete pokračovat s obsahem košíku z předchozího nákupu.</p>',
                description:
                  "Confirmation dialog content of using previous cart, displayed after user login. Supported tags: '<p>', '<b>'",
                id: 'cIP7N6',
              },
              {
                b: (ch: any) => <strong>{ch}</strong>,
                p: (ch: any) => <p>{ch}</p>,
              },
            )}
          </DialogContent>
          <DialogActions>
            <button
              className='flex items-center gap-2 rounded-md bg-orange  px-4 py-2 font-bold text-white hover:bg-orange-600'
              onClick={() => close()}
              type='button'
            >
              OK
            </button>
          </DialogActions>
        </DialogModal>
      )}
    />
  );
}

const actionMessages = defineMessages({
  mergeCart: {
    defaultMessage: 'Sloučit tento košík s aktuálním košíkem',
    description: 'Choose from previous carts action label for merge the cart and actual cart.',
    id: '7RhlMy',
  },
  removeCart: {
    defaultMessage: 'Odstranit košík',
    description: 'Choose from previous carts action label for remove the cart.',
    id: 'ugkHK3',
  },
  removeCartConfirmation: {
    defaultMessage: 'Skutečně odstranit košík?',
    description: 'Choose from previous carts action label for remove the cart confirmaion message.',
    id: 'sy1JFm',
  },
  takeEmptyCart: {
    defaultMessage: 'Pokračovat s prázdným košíkem',
    description: 'Choose from previous carts action label for use empty cart.',
    id: 'LJbxhq',
  },
  takeThisCart: {
    defaultMessage: 'Pokračovat s tímto košíkem',
    description: 'Choose from previous carts action label for use the cart.',
    id: 'ysscK6',
  },
});

function ExistingCartUserDecisionContent({
  createDecisionHandler,
  createRemoveCartHandler,
  currentCartItemCount,
  otherCarts,
}: {
  createDecisionHandler: (
    params: Omit<UseCheckoutUserExistingCartMakeDecisionOptions, 'checkoutId'>,
  ) => (event: MouseEvent) => void;
  createRemoveCartHandler: (cartToRemoveId: string) => (event: MouseEvent) => void;
  currentCartItemCount: number;
  otherCarts: { id: string }[];
}): JSX.Element {
  const intl = useIntl();

  const [o, setO] = useState(false);
  useEffect(() => {
    setO(true);
    return () => {
      setO(false);
    };
  }, []);

  const currentCartNotEmpy = currentCartItemCount > 0;
  const remCount = currentCartItemCount > 1 ? 1 : 2;

  return (
    <Dialog
      closeOnClickOutside={false}
      open={o}
      renderModal={() => (
        <DialogModal className='overflow-hidden bg-[#e4f2fc] p-8'>
          <DialogTitle>
            {intl.formatMessage({
              defaultMessage: 'Máte několik rozpracovaných košíků',
              description: 'Confirmation dialog title of choose from previous carts, displayed after user login.',
              id: '9OXTLt',
            })}
          </DialogTitle>
          <DialogContent className='max-h-[75vh] overflow-y-auto text-left'>
            <div className='mb-4 w-full py-2'>
              <div className='font-semibold'>
                {currentCartNotEmpy
                  ? intl.formatMessage(
                      {
                        defaultMessage:
                          'Aktuální košík má {subjects, plural, one {1 položku} few {# položky} other {# položek}}',
                        description: 'Choose from previous carts information about current cart number of subjects.',
                        id: 't3m+fy',
                      },
                      {
                        subjects: currentCartItemCount,
                      },
                    )
                  : intl.formatMessage({
                      defaultMessage: 'Aktuální košík je prázdný',
                      description: 'Choose from previous carts message displayed when current cart is empty.',
                      id: '7V9mSg',
                    })}
              </div>
              <div className='flex items-center justify-end gap-2 px-2 pt-2'>
                <button
                  className='inline-flex items-center rounded-md border border-primary-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:border-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2'
                  onClick={createDecisionHandler({ action: 'keepCurrent' })}
                  type='button'
                >
                  {intl.formatMessage(currentCartNotEmpy ? actionMessages.takeThisCart : actionMessages.takeEmptyCart)}
                </button>
              </div>
            </div>

            <h2 className='font-semibold'>
              {intl.formatMessage({
                defaultMessage: 'Vaše další rozpracované košíky',
                description: 'Choose from previous cart secion title of previous carts',
                id: '4DeitN',
              })}
            </h2>
            {otherCarts.map((cart) => (
              <div key={cart.id} className='mt-2 w-full py-2'>
                <div>
                  <CartPreview cartId={cart.id} />
                </div>
                <div className='flex items-center justify-end gap-2 p-2'>
                  {otherCarts.length > remCount && (
                    <ButtonWithConfirm
                      className='inline-flex items-center rounded-md border border-red bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:border-red focus:outline-none focus:ring-2 focus:ring-offset-2'
                      confirmationLabel={intl.formatMessage(actionMessages.removeCartConfirmation)}
                      onClick={createRemoveCartHandler(cart.id)}
                    >
                      {intl.formatMessage(actionMessages.removeCart)}
                    </ButtonWithConfirm>
                  )}

                  <button
                    className='inline-flex items-center rounded-md border border-primary-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:border-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2'
                    onClick={createDecisionHandler({ action: 'takeNext', nextCartId: cart.id })}
                    type='button'
                  >
                    {intl.formatMessage(actionMessages.takeThisCart)}
                  </button>

                  {/* Prozatim nepodporuje knihovna */}
                  {/* {currentCartNotEmpy && (
                    <button
                      className='inline-flex items-center rounded-md border border-primary-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:border-primary-600 focus:ring-offset-2'
                      onClick={createDecisionHandler({ action: 'mergeWithNext', checkoutId, nextCartId: cart.id })}
                      type='button'
                    >
                      {intl.formatMessage(actionMessages.mergeCart)}
                    </button>
                  )} */}
                </div>
              </div>
            ))}
          </DialogContent>
        </DialogModal>
      )}
    />
  );
}

function ButtonWithConfirm({
  children,
  className,
  confirmationLabel,
  confirmTime = 5000,
  onClick,
  type = 'button',
}: {
  children: ReactNode;
  className?: string;
  confirmationLabel: ReactNode;
  confirmTime?: number;
  onClick: (event: MouseEvent) => void;
  type?: 'button' | 'submit' | 'reset';
}): JSX.Element {
  const [confirmation, setConfirmation] = useState<NodeJS.Timeout | null>(null);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (!confirmation) {
        setConfirmation(
          setTimeout(() => {
            setConfirmation(null);
          }, confirmTime),
        );
      } else {
        clearTimeout(confirmation);
        setConfirmation(null);
        onClick(event);
      }

      return () => {
        if (confirmation) {
          clearTimeout(confirmation);
        }
      };
    },
    [confirmTime, confirmation, onClick],
  );

  return (
    <button className={clsx({ 'text-red': !!confirmation }, className)} onClick={handleClick} type={type}>
      <>{confirmation ? confirmationLabel : children}</>
    </button>
  );
}

function CartPreview({ cartId }: { cartId: string }): JSX.Element {
  return (
    <>
      <Suspense
        fallback={
          <div className='flex animate-pulse space-x-4'>
            <div className='h-10 w-10 bg-slate-200' />
            <div className='h-10 w-10 bg-slate-200' />
            <div className='h-10 w-10 bg-slate-200' />
          </div>
        }
      >
        <CartPreviewLoader cartId={cartId} />
      </Suspense>
    </>
  );
}

function CartPreviewLoader({ cartId }: { cartId: string }): JSX.Element {
  const intl = useIntl();

  const data = useLazyLoadQuery<UserExistingCartDecision_loadCartPreviewQuery>(
    graphql`
      query UserExistingCartDecision_loadCartPreviewQuery($cartId: ID!, $locale: String!) {
        cart(cartId: $cartId, locale: $locale) {
          createdAt
          subjects(first: 1000) {
            edges {
              __id
              cursor
              node {
                product {
                  primaryImage {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      cartId,
      locale: 'cs',
    },
  );

  if (data == null || data.cart == null) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(`Afer login decision cart '${cartId}' load error.`);
    }
    return (
      <div>
        <Alert severity='error'>
          {intl.formatMessage({
            defaultMessage: 'Nastala chyba při načítání detailních informací o košíku.',
            description: 'Choose previous carts error message when cart content loading failed.',
            id: 'GjEgbB',
          })}
        </Alert>
      </div>
    );
  }

  const cart = data.cart;

  return (
    <div>
      <div>{intl.formatDate(cart.createdAt, { dateStyle: 'medium' })}</div>
      <div className='mt-2 flex flex-wrap gap-2'>
        {cart.subjects.edges &&
          cart.subjects.edges.map(
            (edge: any) =>
              edge.node && (
                <div
                  key={edge.cursor}
                  className='flex h-14 w-14 items-center justify-center border border-primary-600 bg-white'
                >
                  <ImageCanvas key={edge.__id} className='h-auto w-full'>
                    {edge.node.product && edge.node.product.primaryImage?.src && (
                      <Image alt='thumb' aspectRatio={[4, 3]} fit='contain' src={edge.node.product.primaryImage.src} />
                    )}
                  </ImageCanvas>
                </div>
              ),
          )}
      </div>
    </div>
  );
}

// *****************************************
// *
// * reloadWindow
// *
// *****************************************

function reloadWindow() {
  if (typeof window !== 'undefined') {
    window.location.reload();
  }
}
