import { useRouteUrl } from '@/client/hooks';
import { mdiEmail } from '@/client/icons';
import Icon from '@mdi/react';
import clsx from 'clsx';
import type { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

export type RegisterButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  label?: ReactNode;
};

const RegisterButton: FC<RegisterButtonProps> = ({ className, label, type = 'button', ...props }) => {
  const routeUrl = useRouteUrl();
  const history = useHistory();

  const registrationUrl = routeUrl('registration', {});

  return (
    <button
      className={clsx(
        'flex items-center gap-2 rounded-md bg-orange  px-4 py-2 font-bold text-white hover:bg-orange-600',
        className,
      )}
      type={type}
      {...props}
      onClick={(event) => {
        if (props.onClick) {
          props.onClick(event);
        }
        history.push(registrationUrl);
      }}
    >
      {label ? (
        <>{label}</>
      ) : (
        <>
          <Icon className='text-white' path={mdiEmail} size='1.3rem' />
          <FormattedMessage
            defaultMessage='Registrovat se'
            description='Label of the registratin action.'
            id='nMp9x7'
          />
        </>
      )}
    </button>
  );
};

RegisterButton.displayName = 'RegisterButton';

export default RegisterButton;

// const _RegisterButton: FC<RegisterButtonProps> = ({ className, label, type = 'button', ...props }) => {
//   return (
//     <Dialog closeOnClickOutside renderModal={(dialogProps) => <RegisterModal {...dialogProps} />}>
//       <button
//         className={clsx(
//           'px-4 py-2 bg-orange hover:bg-orange-600 font-bold  text-white rounded-md flex items-center gap-2',
//           className,
//         )}
//         type={type}
//         {...props}
//       >
//         {label ? (
//           <>{label}</>
//         ) : (
//           <>
//             <Icon className='text-white' path={mdiEmail} size='1.3rem' />
//             <FormattedMessage
//               defaultMessage='Registrovat se'
//               description='Label of the registratin action.'
//               id='nMp9x7'
//             />
//           </>
//         )}
//       </button>
//     </Dialog>
//   );
// };
