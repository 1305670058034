import clsx from 'clsx';
import type { HTMLAttributes, RefAttributes } from 'react';
import { forwardRef } from 'react';
import type { AspectRatioOptions } from './AspectRatioOptions';
import { useAspectRatio } from './useAspectRatio';

export type ImageWrapperProps = HTMLAttributes<HTMLDivElement> &
  RefAttributes<HTMLDivElement> & {
    aspectRatio?: AspectRatioOptions;
  };

const ImageWrapper = forwardRef<HTMLDivElement, ImageWrapperProps>(
  ({ aspectRatio = 'L', className, ...props }, ref) => {
    const [aspectWidth, aspectHeight] = useAspectRatio(aspectRatio);

    return (
      <div
        ref={ref}
        className={clsx(
          'overflow-hidden',
          {
            'aspect-h-1': aspectHeight === 1,
            'aspect-h-2': aspectHeight === 2,
            'aspect-h-3': aspectHeight === 3,
            'aspect-h-4': aspectHeight === 4,
            'aspect-h-5': aspectHeight === 5,
            'aspect-h-6': aspectHeight === 6,
            'aspect-h-7': aspectHeight === 7,
            'aspect-h-8': aspectHeight === 8,
            'aspect-h-9': aspectHeight === 9,
            'aspect-h-10': aspectHeight === 10,
            'aspect-h-11': aspectHeight === 11,
            'aspect-h-12': aspectHeight === 12,
            'aspect-h-13': aspectHeight === 13,
            'aspect-h-14': aspectHeight === 14,
            'aspect-h-15': aspectHeight === 15,
            'aspect-h-16': aspectHeight === 16,
            'aspect-w-1': aspectWidth === 1,
            'aspect-w-2': aspectWidth === 2,
            'aspect-w-3': aspectWidth === 3,
            'aspect-w-4': aspectWidth === 4,
            'aspect-w-5': aspectWidth === 5,
            'aspect-w-6': aspectWidth === 6,
            'aspect-w-7': aspectWidth === 7,
            'aspect-w-8': aspectWidth === 8,
            'aspect-w-9': aspectWidth === 9,
            'aspect-w-10': aspectWidth === 10,
            'aspect-w-11': aspectWidth === 11,
            'aspect-w-12': aspectWidth === 12,
            'aspect-w-13': aspectWidth === 13,
            'aspect-w-14': aspectWidth === 14,
            'aspect-w-15': aspectWidth === 15,
            'aspect-w-16': aspectWidth === 16,
          },
          className,
        )}
        {...props}
      />
    );
  },
);

ImageWrapper.displayName = 'ImageWrapper';

export default ImageWrapper;
