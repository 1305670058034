import type { SupportedCountry } from '@sprinx/knihovna-api-types';
import { convertToSupportedCountry } from '../common';
import { ContactType, convertFromContactType, convertToContactType } from './ContactType';

export interface ContactBase {
  city: string;
  companyName: string;
  companyRegNo: string;
  companyTaxNo: string;
  contactType: ContactType;
  country: SupportedCountry;
  emailAddress: string;
  id?: string;
  officeName: string;
  personName: string;
  phoneNumber: string;
  postalCode: string;
  street: string;
}

type CreatePartial<T> = {
  [K in keyof T]?: T[K] | null;
};

export function createContactBase<T extends ContactBase>(partial?: CreatePartial<T>): T {
  return {
    city: '',
    companyName: '',
    companyRegNo: '',
    companyTaxNo: '',
    contactType: ContactType.person,
    country: 'CZ',
    emailAddress: '',
    officeName: '',
    personName: '',
    phoneNumber: '',
    postalCode: '',
    street: '',
    ...partial,
  } as T;
}

type CF<CntType extends string, CouType extends string> = Omit<
  CreatePartial<ContactBase>,
  'contactType' | 'country'
> & { contactType: CntType; country?: CouType | null };

export function convertToContactBase<CntType extends string, CouType extends string>(
  contact: CF<CntType, CouType>,
): ContactBase;
export function convertToContactBase<CntType extends string, CouType extends string>(
  contact: CF<CntType, CouType> | null | undefined,
  retAsNull: false,
): ContactBase | undefined;
export function convertToContactBase<CntType extends string, CouType extends string>(
  contact: CF<CntType, CouType> | null | undefined,
  retAsNull: true,
): ContactBase | null;
export function convertToContactBase<CntType extends string, CouType extends string>(
  contact: CF<CntType, CouType> | null | undefined,
  r?: boolean,
): unknown {
  if (contact == null) return r ? null : undefined;
  const { contactType, country, ...rest } = contact;
  return createContactBase({
    ...rest,
    contactType: convertToContactType(contactType),
    country: convertToSupportedCountry(country),
  });
}

function _convertFromContactBase({ contactType, ...rest }: ContactBase) {
  return { ...rest, contactType: convertFromContactType(contactType) };
}

type R = ReturnType<typeof _convertFromContactBase>;

export function convertFromContactBase(contact: ContactBase): R;
export function convertFromContactBase(contact: ContactBase | null | undefined): R | undefined;
export function convertFromContactBase(contact: ContactBase | null | undefined, retAsNull: true): R | null;
export function convertFromContactBase(contact: ContactBase | null | undefined, r?: boolean): unknown {
  if (contact == null) return r ? null : undefined;
  return _convertFromContactBase(contact);
}

export function convertFromContactBaseWithId(
  contact: ContactBase,
): Omit<ContactBase, 'id' | 'contactType'> & { contactType: 'company' | 'office' | 'person'; id: string } {
  const { contactType, id, ...rest } = contact;
  return { ...rest, contactType: convertFromContactType(contactType), id: id ?? 'n/a' };
}

export function convertFromContactBaseWithIdNullable(
  contact: ContactBase | null | undefined,
): ReturnType<typeof convertFromContactBaseWithId> | null {
  return contact == null ? null : convertFromContactBaseWithId(contact);
}
